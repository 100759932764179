
import { getWithParams, post, delte } from "./API";

export function getAllNotes(data, callback) {
  getWithParams({ endPoint: "notes/getAllNotesById" }, data, function(response) {
    callback(response);
  });
}
export function updateNotesById(data, callback) {
  post({ endPoint: "notes/updateNotesById" }, data, function(response) {
    callback(response);
  });
}
export function createNotes(data, callback) {
    post({ endPoint: "notes/StoreNotes" }, data, function(response) {
      callback(response);
    });
  }

export function deleteNotesById(data, callback) {
  delte({ endPoint: "notes/deleteNotesById" }, data, function(response) {
    callback(response);
  });
}
