import React, { useState, useEffect, useContext, useMemo } from "react";
import { ReactComponent as DownloadIcon } from "../assets/SVG/DownloadIcon.svg";
import { Button } from "@mui/material";
import { GlobalData } from "../App";

function DownloadReportButton({ onClick, id, className }) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    onlyXs,
    smDown,
    mdDown,
    baseUrl,
    userObject,
  } = useContext(GlobalData);
  return (
    <>
      <Button
        id={id}
        variant="contained"
        endIcon={<DownloadIcon />}
        className={className ? className : "download_report_btn"}
        onClick={onClick}
      >
        {mdDown ? (
          <div>
            <div>Download</div>
            <div style={{ marginTop: "-8px" }}>Report</div>
          </div>
        ) : (
          "Download Report"
        )}
      </Button>
    </>
  );
}

export default DownloadReportButton;
