import {
  Grid,
  IconButton,
  DialogActions,
  DialogContent,
  Dialog,
} from "@mui/material";
import Button from "@mui/material/Button";
import CustomTable from "../../widgets/CustomTable";
import SearchField from "../../widgets/SearchField";
import Box from "@mui/material/Box";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { debounce } from "lodash";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import PointOfSaleRoundedIcon from "@mui/icons-material/PointOfSaleRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import { getAllVendors, deleteVendor } from "../../api/VendorAPI";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import { ReactComponent as EditIcon } from "../../assets/SVG/EditIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import { ReactComponent as DialogCrossIcon } from "../../assets/SVG/DialogBoxDeleteIcon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/SVG/DownloadIcon.svg";
import DeleteDialog from "../../widgets/DeleteDialog";
import MainTitle from "../../widgets/MainTitle";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { ReactComponent as BalanceIcon } from "../../assets/SVG/BalanceIcon.svg";
// import { ReactComponent as CreditIcon } from "../../assets/SVG/CreditIcon.svg";
import { AiOutlinePlus } from "react-icons/ai";
// import { ReactComponent as DebitIcon } from "../../assets/SVG/DebitIcon.svg";
import { ReactComponent as DebitIcon } from "../../assets/SVG/DebitSmallIcon.svg";
import { ReactComponent as CreditIcon } from "../../assets/SVG/CreditSmallIcon.svg";
import "./VendorKhata.css";
import DateFilter from "../../widgets/DateFilter";
import DownloadReportButton from "../../widgets/DownloadReportButton";
import AddNewButton from "../../widgets/AddNewButton";
import NoVendor from "../../assets/Images/EmptyScreenImage/NoVendor.png";

import { Textfit } from "react-textfit";

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    minWidth: 30,
    align: "left",
  },
  {
    id: "vendor_name",
    label: "Name",
    minWidth: 30,
    align: "left",
  },
  {
    id: "vendor_phone",
    label: "Phone Number",
    minWidth: 30,
    align: "left",
  },
  {
    id: "vendor_address",
    label: "Address",
    minWidth: 30,
    align: "left",
  },
  {
    id: "vendor_shop_name",
    label: "Shop Name",
    minWidth: 30,
    align: "left",
  },
  {
    id: "vendor_total_credit",
    label: "Total Credit",
    minWidth: 100,
    align: "left",
  },
  {
    id: "vendor_total_debit",
    label: "Total Debit",
    minWidth: 100,
    align: "left",
  },
  {
    id: "vendor_total_balance",
    label: "Total Balance",
    minWidth: 100,
    align: "left",
  },
];

const options = [
  {
    icon: (
      <div>
        <EyeIcon />
      </div>
    ),
    label: "View Khata",
    method: "view",
    type: "icon",
    id: "view_khata",
  },
  {
    icon: (
      <div>
        <EditIcon />
      </div>
    ),
    label: "Edit Vendor",
    method: "edit",
    type: "icon",
    id: "edit_vendor",
  },
  {
    icon: (
      <div>
        <DeleteIcon />
      </div>
    ),
    label: "Delete Vendor",
    method: "delete",
    type: "icon",
    id: "delete_vendor",
  },
];

function Vendors(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
    userObject,
  } = useContext(GlobalData);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [vendors, setVendors] = useState([]);
  const [totalVendorsCredit, setTotalVendorsCredit] = useState(0);
  const [totalVendorsDebit, setTotalVendorsDebit] = useState(0);
  const [vendorsCount, setVendorsCount] = useState(0);
  const [currentVendorId, setCurrentVendorId] = useState("");
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState("This Month");

  const handleViewVendor = (data) => {
    navigate("/vendors/view-khata", {
      state: { vendorData: data.id },
    });
  };

  const getAllVendorsMethod = (pn) => {
    let data = {
      search_text: searchText,
      per_page: process.env.REACT_APP_PER_PAGE,
      page: pn,
      date: JSON.stringify(date),
    };
    showLoader();
    getAllVendors(data, (resp) => {
      if (resp.statusCode === 200) {
        setVendorsCount(resp.successData.all_vendors_count);
        setTotalVendorsCredit(resp.successData.total_vendors_credit);
        setTotalVendorsDebit(resp.successData.total_vendors_debit);
        if (pn === 1) {
          setVendors(resp.successData.all_vendors);
          hideLoader();
        } else {
          setVendors((dt) => {
            dt.push(...resp.successData.all_vendors);
            hideLoader();
            return dt;
          });
        }
        setLastPage(resp.successData.last_page);
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const openDeleteVendorDialog = (vendor) => {
    console.log("this is Vendor", vendor);
    setCurrentVendorId(vendor.id);
    setDeleteProductDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const handleDeleteVendor = () => {
    showLoader();
    const tempdata = { vendor_id: currentVendorId };

    deleteVendor(tempdata, (resp) => {
      if (resp.statusCode === 200) {
        flashMessage("success", resp.message);
        updateDataUsingPageNumber();
        setDeleteProductDialog(false);
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        setDeleteProductDialog(false);
        hideLoader();
      }
    });
  };

  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const handleEditVendor = (vendor) => {
    navigate("/vendors/add-new-vendor", {
      state: { vendorData: vendor },
    });
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    getAllVendorsMethod(page);
  }, [page]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      getAllVendorsMethod(page);
    }
  };

  useEffect(() => {
    updateDataUsingPageNumber();
  }, [searchText, date]);

  return (
    <Grid id="header__orderm" container>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <NavigationBar
          path={"Dashboard>Vendors Management>Vendors"}
          paddingBottom={2}
        />

        <Grid container marginBottom={2}>
          <Grid
            xl={6}
            lg={6}
            md={5}
            sm={12}
            xs={12}
            item
            paddingRight={onlyXs ? "5px" : "10px"}
          >
            <MainTitle title={"Vendors Management"} />
          </Grid>
          <Grid
            xl={6}
            lg={6}
            md={7}
            sm={12}
            xs={12}
            item
            display="flex"
            justifyContent={smDown ? "center" : "end"}
            paddingTop={smDown ? 1 : 0}
            paddingRight={smDown ? "0px" : "10px"}
          >
            <DateFilter
              id={"date_filter__vendor"}
              setDateObject={setDate}
              dateObject={date}
              className={"download_report_btn__orders"}
            />
          </Grid>
        </Grid>

        {/* <Grid
          paddingBottom={2}
          container
          dispaly="flex"
          justifyContent="center"
          paddingTop={smDown ? "4px" : "0px"}
        >
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            paddingTop={smDown ? "15px" : "15px"}
          >
            <Grid container p={1} className="Box_background__vendor">
              <Grid
                item
                className="box_img__vendor"
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                <div className="total_balnac_top">
                  <BalanceIcon className="icons_w_h_vendors" />
                </div>
              </Grid>

              <Grid item paddingLeft={2} xl={9} lg={9} md={9} sm={9} xs={9}>
                <Box>
                  <div className="Balance_vendors__khata">Total Balance</div>
                  <div className="AED_vendors__khata">
                    {Number(totalVendorsCredit) - Number(totalVendorsDebit)}{" "}
                    {userObject.currency}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            paddingLeft={smDown ? "0" : "10px"}
            paddingTop={smDown ? "4px" : "15px"}
          >
            <Grid container className="Box_background__vendor">
              <Grid
                item
                className="box_img__vendor"
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                <div className="total_balnac_top">
                  <CreditIcon className="icons_w_h_vendors" />
                </div>
              </Grid>

              <Grid item paddingLeft={2} xl={7} lg={7} md={7} sm={7} xs={7}>
                <Box>
                  <div className="Balance_vendors__khata">Total Credit</div>
                  <div className="AED_vendors__khata">
                    {totalVendorsCredit} {userObject.currency}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            paddingLeft={smDown ? "0" : "10px"}
            paddingTop={smDown ? "4px" : "15px"}
          >
            <Grid container className="Box_background__vendor">
              <Grid
                item
                className="box_img__vendor"
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                <div className="total_balnac_top">
                  <DebitIcon className="icons_w_h_vendors" />
                </div>
              </Grid>

              <Grid item paddingLeft={1} xl={7} lg={7} md={7} sm={7} xs={7}>
                <Box>
                  <div className="Balance_vendors__khata">Total Debit</div>
                  <div className="AED_vendors__khata">
                    {totalVendorsDebit} {userObject.currency}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}

        <Grid
          paddingBottom={2}
          container
          dispaly="flex"
          justifyContent="center"
          paddingTop={smDown ? "4px" : "0px"}
        >
          <Grid
            item
            xl={3}
            lg={3}
            md={4}
            sm={12}
            xs={12}
            paddingTop={smDown ? "15px" : "15px"}
          >
            <Grid container p={1} className="Box_background__vendor_stf">
              <Grid
                item
                className="box_img__vendor_stf"
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                <div className="balanc_icon_marg">
                  <BalanceIcon height="35" width="35" />
                </div>
              </Grid>

              <Grid item paddingLeft={2} xl={9} lg={9} md={9} sm={9} xs={9}>
                <Box>
                  <div className="Balance_vendors__khata_stf">
                    Total Balance
                  </div>
                  <div className="AED_vendors__khata_stf">
                    <Textfit mode="single" forceSingleModeWidth={false}>
                      {Number(totalVendorsCredit) - Number(totalVendorsDebit)}{" "}
                      {userObject.currency}
                    </Textfit>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xl={3}
            lg={3}
            md={4}
            sm={12}
            xs={12}
            paddingLeft={smDown ? "0" : "10px"}
            paddingTop={smDown ? "4px" : "15px"}
          >
            <Grid
              container
              className="Box_background__vendor_stf Box_background__vendor_cred_stf"
            >
              <Grid
                item
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="2px"
                className="box_img__vendor_stf"
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                <div className="balanc_icon_marg">
                  <CreditIcon width="35" height="35" />
                </div>
              </Grid>

              <Grid item paddingLeft={2} xl={7} lg={7} md={7} sm={7} xs={7}>
                <Box>
                  <div className="Balance_vendors__khata_stf">Total Credit</div>

                  <div className="AED_vendors__khata_stf">
                    <Textfit mode="single" forceSingleModeWidth={false}>
                      {totalVendorsCredit} {userObject.currency}
                    </Textfit>
                  </div>
                </Box>
              </Grid>
              <Grid
                item
                pt={2}
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                className="container_add_btn_stf balanc_icon_marg"
              />
            </Grid>
          </Grid>

          <Grid
            item
            xl={3}
            lg={3}
            md={4}
            sm={12}
            xs={12}
            paddingLeft={smDown ? "0" : "10px"}
            paddingTop={smDown ? "4px" : "15px"}
          >
            <Grid container className="Box_background__vendor_stf">
              <Grid
                item
                className="box_img__vendor_stf"
                xl={3}
                lg={3}
                md={3}
                sm={3}
                xs={3}
              >
                <div className="balanc_icon_marg">
                  <DebitIcon width="35" height="35" />
                </div>
              </Grid>

              <Grid item paddingLeft={1} xl={7} lg={7} md={7} sm={7} xs={7}>
                <Box>
                  <div className="Balance_vendors__khata_stf">Total Debit</div>

                  <div className="AED_vendors__khata_stf">
                    <Textfit mode="single" forceSingleModeWidth={false}>
                      {totalVendorsDebit} {userObject.currency}
                    </Textfit>
                  </div>
                </Box>
              </Grid>

              <Grid
                item
                pt={2}
                xl={2}
                lg={2}
                md={2}
                sm={2}
                xs={2}
                className="container_add_btn balanc_icon_marg"
              />
            </Grid>
          </Grid>

          {/* <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={12}
              xs={12}
              paddingLeft={smDown ? "0" : "10px"}
              paddingTop={smDown ? "4px" : "15px"}
            >
              <Grid
                container
                p={1}
                backgroundColor="#E2FFE9"
                className="Box_background__vendor_stf"
              >
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="2px"
                  className="box_img__vendor_stf"
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                >
                  <div style={{ marginTop: "3px" }}>
                    <InvestmentIcon height="35" width="35" color="green" />
                  </div>
                </Grid>

                <Grid item paddingLeft={1} xl={7} lg={7} md={7} sm={7} xs={7}>
                  <Box>
                    <div className="Balance_vendors__khata_stf">
                      Total Salary
                    </div>
                    <div className="AED_vendors__khata_stf">
                      <Textfit mode="single" forceSingleModeWidth={false}>
                        {total_salary} {userObject.currency}
                      </Textfit>
                    </div>
                  </Box>
                </Grid>

                <Grid
                  item
                  pt={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="container_add_btn_stf"
                  style={{ marginTop: "5px" }}
                />
              </Grid>
            </Grid> */}
        </Grid>

        <Grid container marginBottom={2}>
          <Grid
            xl={6}
            lg={6}
            md={smDown ? 7 : 5}
            sm={smDown ? 7 : 5}
            xs={12}
            item
            className="padn_invoice__icon"
          >
            <SearchField
              id="search_field__vendors"
              onChange={updateProductList}
              tableDataCount={vendors.length}
              totalAvailableDataCount={vendorsCount}
            />
          </Grid>
          <Grid
            xl={6}
            lg={6}
            md={smDown ? 5 : 7}
            sm={smDown ? 5 : 7}
            xs={12}
            item
            display="flex"
            justifyContent={onlyXs ? "center" : "end"}
            paddingTop={onlyXs ? 1 : 0}
            paddingRight={smDown ? "0px" : "10px"}
          >
            {smDown ? (
              <>
                <IconButton
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  className="action_btn__orderm action_btn_filled__orderm"
                >
                  <AddShoppingCartRoundedIcon />
                </IconButton>
                <IconButton
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  className="action_btn__orderm"
                >
                  <CloudDownloadRoundedIcon />
                </IconButton>
                <IconButton
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  className="action_btn__orderm"
                >
                  <PointOfSaleRoundedIcon />
                </IconButton>
                <IconButton
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  className="action_btn__orderm"
                >
                  <InventoryRoundedIcon />
                </IconButton>
                <IconButton
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  className="action_btn__orderm"
                >
                  <DateRangeRoundedIcon />
                </IconButton>
              </>
            ) : (
              <>
                {/* <Button
                  variant="contained"
                  className="add_order_btn__orders"
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  onClick={() => {
                    navigate("/vendors/add-new-vendor");
                  }}
                >
                  Add New Vendor
                </Button> */}
                {/* <Button
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  className="download_report_btn__orders"
                >
                  Download Report
                </Button> */}
                <AddNewButton
                  id={"add_new_vendor__vendors"}
                  name={"Add New Vendor"}
                  onClick={() => {
                    navigate("/vendors/add-new-vendor");
                  }}
                />
                <DownloadReportButton id={"download_report__vendor"} />
              </>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <CustomTable
              view={handleViewVendor}
              edit={handleEditVendor}
              delete={openDeleteVendorDialog}
              columns={columns}
              data={vendors}
              options={options}
              image={NoVendor}
            />
          </Grid>
        </Grid>
      </Grid>
      <DeleteDialog
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteProductDialog}
        handleRemove={handleDeleteVendor}
        title={"Remove this Vendor?"}
        sub_title={
          "You are about to remove a Vendor. Click on the “Remove” button to remove it."
        }
      />
    </Grid>
  );
}

export default Vendors;
