

import { getWithParams , post } from "./API";

export function getAllCategories(data, callback) {
  getWithParams({ endPoint: "inventory/category/getAllCategories" }, data, function(response) {
    callback(response);
  });
}

export function getAllCategoriesObjects(data, callback) {
  getWithParams({ endPoint: "inventory/category/getAllCategoriesObjects" }, data, function(response) {
    callback(response);
  });
}

export function updateCategories(data, callback) {
  post({ endPoint: "inventory/category/updateCategories" }, data, function(response) {
    callback(response);
  });
}

