import { get, post, getWithParams } from "./API";

export function getAllProducts(data, callback) {
  post({ endPoint: "inventory/getAllProducts" }, data, function(response) {
    callback(response);
  });
}

export function getAllReturnInventory(data, callback) {
  post({ endPoint: "inventory/getAllReturnInventory" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function changeReturnInventoryStatus(data, callback) {
  post({ endPoint: "inventory/changeReturnInventoryStatus" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function getProductById(data, callback) {
  post({ endPoint: "inventory/getProductById" }, data, function(response) {
    callback(response);
  });
}

export function addProduct(data, callback) {
  post({ endPoint: "inventory/addProduct" }, data, function(response) {
    callback(response);
  });
}

export function editProduct(data, callback) {
  post({ endPoint: "inventory/editProduct" }, data, function(response) {
    callback(response);
  });
}

export function deleteProduct(data, callback) {
  post({ endPoint: "inventory/deleteProduct" }, data, function(response) {
    callback(response);
  });
}
