import "./Login.css";
import { Grid, ButtonBase } from "@mui/material";
import React, { useContext, useState, useEffect, useRef } from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { GlobalData } from "../../App";
import { login, forget } from "../../api/AuthAPI";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "./ForgetPassword.css";
import { useParams } from "react-router-dom";
import { encrypt } from "../../middleware/Encryption";

const ForgetPassword = () => {
  let { id } = useParams();

  const {
    smDown,
    flashMessage,
    navigate,
    location,
    showLoader,
    hideLoader,
    setIsUserAuthenticated,
  } = useContext(GlobalData);

  const [showPassword, setShowPassword] = React.useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [forgetEmail, setForgetEmail] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const emailRef = useRef(null);
  const passRef = useRef(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [forgetPassword, setForgetPassword] = useState(false);

  function handleLogin(e) {
    e.preventDefault();
    let formData = new FormData();
    formData.append("email", emailRef.current.value);
    formData.append("password", passRef.current.value);
    showLoader();
    login(formData, (resp) => {
      if (resp.statusCode === 200) {
        setIsUserAuthenticated(true);
        localStorage.setItem("user", JSON.stringify(resp.successData.user));
        navigate(location.pathname);
        hideLoader();
        flashMessage("success", resp.message);
        if ("caches" in window) {
          caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          window.location.reload(true);
        }
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  }

  const handleForgetPassword = () => {
    if (emailRef.current.value !== passRef.current.value) {
      flashMessage("error", "Password does not match");
      return 0;
    }
    showLoader();
    let formData = new FormData();
    formData.append("id", id);
    formData.append("user_password", encrypt(passRef.current.value));
    formData.append("password", passRef.current.value);
    forget(formData, (resp) => {
      if (resp.statusCode === 200) {
        hideLoader();
        flashMessage("success", resp.message);
        navigate("/login");
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  const handleLoginAfterLogin = () => {
    emailRef.current.value = "";
    setForgetEmail(false);
    setForgetPassword(false);
  };

  return (
    <>
      {forgetEmail && (
        <Box className="passforget_alert__login">
          <Box className="passforget_msg__login">
            <Box className="close_msg__login">
              <ButtonBase
                style={{ borderRadius: "50%" }}
                onClick={() => {
                  setForgetEmail(false);
                }}
              >
                <HighlightOffIcon />
              </ButtonBase>
            </Box>
            Forget password link successfully sent to your registered email.
          </Box>
        </Box>
      )}
      <Grid container style={{ height: "100vh" }}>
        {smDown && (
          <Box className="mobile_bg_container__login">
            <Box className="mobile_bg__login" />
          </Box>
        )}
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            className={`form__login ${
              smDown ? "login_form_mobile__login" : "login_form_laptop__login"
            }`}
          >
            <Box
              style={{
                // width: "423px",
                marginTop: "20px",
                marginBottom: "60px",
              }}
            >
              <Grid container>
                <Grid
                  className="wel__login"
                  display="flex"
                  alignItems="center"
                  item
                  xl={8}
                  lg={8}
                  md={8}
                  sm={8}
                  xs={8}
                >
                  <Box>
                    <Box className="sign__login">Forget Password</Box>
                    <Box className="parkour__login">Waqas Dubai Wala Shop</Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  display="flex"
                  justifyContent="end"
                >
                  <div
                    className={`${
                      smDown ? "logo_mobile__login" : "logo_laptop__login"
                    }`}
                  />
                </Grid>
              </Grid>

              {/* <Grid container>
              <Grid
                className="sign__login"
                item
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
              >
                Sign in to
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                className="parkour__login"
                item
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
              >
                Waqas Dubai Wala Shop
              </Grid>
            </Grid> */}

              {/* <Box component="form" noValidate autoComplete="off">
                <FormControl
                  sx={{
                    width: `${smDown ? "100%" : "423px"}`,
                    marginTop: "40px",
                    letterSpacing: "1px",
                  }}
                >
                  <Box mb={1}>Email</Box>
                  <OutlinedInput
                    placeholder="Enter your Email"
                    id="email"
                    name="email"
                    inputRef={emailRef}
                    sx={{ borderRadius: "10px" }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </FormControl>
              </Box> */}

              <Box component="form" noValidate autoComplete="off">
                <FormControl
                  sx={{
                    width: `${smDown ? "100%" : "423px"}`,
                    marginTop: "40px",
                    letterSpacing: "1px",
                  }}
                >
                  <Box mb={1}>Password</Box>
                  <OutlinedInput
                    sx={{ borderRadius: "10px" }}
                    inputRef={passRef}
                    id="pass"
                    name="pass"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Password"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </FormControl>
              </Box>

              <Box component="form" noValidate autoComplete="off">
                <FormControl
                  sx={{
                    width: `${smDown ? "100%" : "423px"}`,
                    marginTop: "40px",
                    letterSpacing: "1px",
                  }}
                >
                  <Box mb={1}>Confirm Password</Box>
                  <OutlinedInput
                    sx={{ borderRadius: "10px" }}
                    inputRef={emailRef}
                    id="pass"
                    name="pass"
                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Confirm Password"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                </FormControl>
              </Box>

              <Button
                onClick={handleForgetPassword}
                className="button__login"
                variant="contained"
                size="large"
                sx={{
                  marginTop: "60px",
                  borderRadius: "10px",
                  width: `${smDown ? "100%" : "423px"}`,
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Grid>

        {!smDown && (
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box paddingRight={!smDown && 20} className="side_image__login" />
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default ForgetPassword;
