import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
  TextField,
  InputBase,
} from "@mui/material";
import { Height } from "@mui/icons-material";

function LastFooter() {
  return (
    <>
      <footer className="footer-container">
        <Box className="footer-left">
          <Box className="parkExpre_txt">ParkourExpress</Box>
          <Box className="produt_txt" pt={2}>
            Get started now try our product
          </Box>
          <Box pt={3}>
            <InputBase
              className="lstField_d"
              placeholder="Enter your email here"
            />
          </Box>
          <Box pt={6} className="cr_last_lp">
            <Typography color="white" variant="subtitle1" >
              {`© ${new Date().getFullYear()} ParkourExpress Inc. Copyright and rights reserved`}
            </Typography>
          </Box>
        </Box>
        <div className="footer-right">
          <div className="column column1">
            <h3 className="points_main_heading">Support</h3>
            <ul className="list_desgn">
              <li>Help centre</li>
              <li>Account information</li>
              <li>About</li>
              <li>Contact us</li>
            </ul>
          </div>
          <div className="column column2">
            <h3>Help and Solution</h3>
            <ul className="list_desgn">
              <li>Talk to support</li>
              <li>Support docs</li>
              <li>System status</li>
              <li>Covid responde</li>
            </ul>
          </div>
          <div className="column column3">
            <h3>Product</h3>
            <ul className="list_desgn">
              <li>Update</li>
              <li>Security</li>
              <li>Beta test</li>
              <li>Pricing product</li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default LastFooter;
