import React, { useContext, useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import "./ViewOnlineInventory.css";
import { IoMdArrowDropleft } from "react-icons/io";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import AutoScrollContainer from "auto-scroll-container";
import ProtectComponent from "../../middleware/ProtectComponent";
import {getProductByIdOnline} from '../../api/OnlineInventoryAPI'
function ViewOnlineInventory(props) {

  const {
    smDown,
    navigate,
    baseUrl,
    location,
    showLoader,
    flashMessage,
    hideLoader,
    query,
    onlyXs,
  } = useContext(GlobalData);

  const[product,setProduct]=useState('')

//const product = location.state.product;

const handlegetProduct=()=>{
 let data={product_id:location.state.product_id};

  getProductByIdOnline(data, (resp) => {

    if (resp.statusCode === 200) {
       // console.log("PPPPPPPPPPP",resp.successData)
         setProduct(resp.successData.product) 
    }

    else 
    { 
 flashMessage("error", resp.message);
hideLoader()
    }
})}


useEffect(() => {
 
  handlegetProduct();
  showLoader();
  setTimeout(()=>{  hideLoader();},700)

 
  }, []);

  const general =
    product.category === "Mobile" || product.category === "Laptop"
      ? false
      : true;

  return (
    <>

      <NavigationBar
        path={`Dashboard>Online Inventory Management>Products>Product Detail>${
          product.serial_no
        }`}
        paddingBottom={1}
      />
      {product===''?(<Grid></Grid>):( <Grid
        container
        className="container__inven_view"
        style={{ paddingRight: `${onlyXs ? 5 : 5}px` }}
        paddingBottom={3}
      >
        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          item
          paddingTop={smDown && 3}
          style={smDown ? { zIndex: 1 } : {}}
        >
          <Box className="container__viewinv">
            <Box className="header__viewinv">
              <Grid display="flex" justifyContent="center" container mb={2}>
                <Grid
                  className="header__inven_view"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  item
                >
                  <div
                    className="header_back_btn__inven_view"
                    onClick={() => {
                      navigate("/products/online-product");
                    }}
                  >
                    <IoMdArrowDropleft
                      className="add__icon_orders_sty"
                    />
                    Back
                  </div>
                  <div className="header_text__inven_view">
                   Online Inventory Management {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                    <span style={smDown ? { fontSize: "14px" } : {}}>
                      Product Detail
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box className="body__viewinv">
              <Grid container>
                <Grid
                  className="card__inven_view"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  item
                  paddingLeft={query(10, 4, 0.5)}
                  paddingRight={query(10, 4, 0.5)}
                  paddingTop="30px"
                  paddingBottom="60px"
                >
                  <Grid
                    container
                    className="page__inven_view"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    paddingTop="30px"
                    paddingBottom="30px"
                    paddingLeft={query(10, 4, 1)}
                    paddingRight={query(10, 4, 1)}
                  >
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      item
                      display="flex"
                      justifyContent="center"
                      paddingBottom={3}
                    >
                      <img
                        alt=""
                        src={baseUrl + product.image}
                        width="120px"
                        height="120px"
                        style={{
                          borderRadius: "50%",
                          border: "1px solid rgba(0,0,0,0.2)",
                        }}
                      />
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid
                        container
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid
                          xl={3}
                          lg={3}
                          md={3}
                          sm={3}
                          xs={7}
                          display="flex"
                          justifyContent={onlyXs ? "start" : "center"}
                          item
                        >
                          <Box>
                            <Box className="product_title_text__ordview">
                              Product Name
                            </Box>
                            <Box className="product_text__ordview">
                              {product.name}
                            </Box>
                          </Box>
                        </Grid>

                        <ProtectComponent
                          id="product_source"
                          componentLocation={true}
                          componentNavigate={false}
                          component={
                            <Grid
                              xl={3}
                              lg={3}
                              md={3}
                              sm={3}
                              xs={5}
                              item
                              display="flex"
                              justifyContent={onlyXs ? "start" : "center"}
                            >
                              <Box>
                                <Box className="product_title_text__ordview">
                                  Source
                                </Box>
                                <Box className="product_text__ordview">
                                  {product.source}
                                </Box>
                              </Box>
                            </Grid>
                          }
                        />

                        <Grid
                          xl={3}
                          lg={3}
                          md={3}
                          sm={3}
                          xs={7}
                          item
                          display="flex"
                          justifyContent={onlyXs ? "start" : "center"}
                        >
                          <Box>
                            <Box className="product_title_text__ordview">
                              Material
                            </Box>
                            <Box className="product_text__ordview">
                              {product.material}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid
                          xl={3}
                          lg={3}
                          md={3}
                          sm={3}
                          xs={5}
                          item
                          display="flex"
                          justifyContent={onlyXs ? "start" : "center"}
                        >
                          <Box>
                            <Box className="product_title_text__ordview">
                              Category
                            </Box>
                            <Box className="product_text__ordview">
                              {product.category}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <hr style={{ marginTop: "20px", opacity: "0.5" }} />
                      <Grid container>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid
                            container
                            paddingTop="20px"
                            className="product_text__ordview"
                          >
                            Variants{product.id}
                          </Grid>
                          <Grid container className="padng_global_projct">
                       
                       <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      
                    {
                       product.variants.map((variant) => (
                       
                              <>
                                <Grid container>
                                  <Grid
                                    item
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="variant_box_con__inven_view"
                                  >
                                    <Grid
                                      container
                                      paddingLeft={1}
                                      paddingRight={1}
                                    >
                                      <Grid
                                        item
                                        xl={
                                          product.category === "Mobile" ||
                                          product.category === "Laptop"
                                            ? 3
                                            : 2
                                        }
                                        lg={
                                          product.category === "Mobile" ||
                                          product.category === "Laptop"
                                            ? 3
                                            : 2
                                        }
                                        md={
                                          product.category === "Mobile" ||
                                          product.category === "Laptop"
                                            ? 3
                                            : 2
                                        }
                                        sm={
                                          product.category === "Mobile" ||
                                          product.category === "Laptop"
                                            ? 3
                                            : 2
                                        }
                                        xs={
                                          product.category === "Mobile" ||
                                          product.category === "Laptop"
                                            ? 6
                                            : 4
                                        }
                                      >
                                        <Box
                                          fontSize="12px"
                                          className="variant_title_text__inven_view"
                                        >
                                          {product.category === "Mobile" ||
                                          product.category === "Laptop"
                                            ? "Specifications"
                                            : "Color"}
                                        </Box>
                                        <Box className="product_text__ordview">
                                          {variant.color}

                                          {product.category === "Mobile" ||
                                          product.category === "Laptop" ? (
                                            <>
                                              {variant.ram.length > 0
                                                ? ","
                                                : ""}
                                              {variant.ram}
                                              {variant.storage.length > 0
                                                ? ","
                                                : ""}
                                              {variant.storage}
                                            </>
                                          ) : null}
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        xl={2}
                                        lg={2}
                                        md={2}
                                        sm={2}
                                        xs={general ? 4 : 6}
                                      >
                                        <Box
                                          fontSize="12px"
                                          className="product_title_text__ordview"
                                        >
                                          Quantity
                                        </Box>
                                        <Box className="product_text__ordview">
                                          {variant.quantity}
                                        </Box>
                                      </Grid>

                                      <ProtectComponent
                                        id="product_cost_price"
                                        componentLocation={true}
                                        componentNavigate={false}
                                        component={
                                          <Grid
                                            item
                                            xl={2}
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={general ? 4 : 4}
                                          >
                                            <Box
                                              fontSize="12px"
                                              className="variant_title_text__inven_view"
                                            >
                                              Cost Price
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {variant.cost_price}
                                            </Box>
                                          </Grid>
                                        }
                                      />

                                      <Grid
                                        item
                                        xl={2}
                                        lg={2}
                                        md={2}
                                        sm={2}
                                        xs={general ? 4 : 4}
                                      >
                                        <Box
                                          fontSize="12px"
                                          className="variant_title_text__inven_view"
                                        >
                                          Sale Price
                                        </Box>
                                        <Box className="product_text__ordview">
                                          {variant.sale_price}
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        sm={3}
                                        xs={general ? 4 : 4}
                                      >
                                        <Box
                                          fontSize="12px"
                                          className="variant_title_text__inven_view"
                                        >
                                          M.Sale Price
                                        </Box>
                                        <Box className="product_text__ordview">
                                          {variant.min_sale_price}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                    <hr />
                                    <Grid
                                      container
                                      paddingLeft={1}
                                      paddingRight={1}
                                      paddingBottom={1}
                                    >
                                      {product.category === "Mobile" ||
                                      product.category === "Laptop" ? (
                                        <>
                                          <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                          >
                                            <Box
                                              fontSize="12px"
                                              className="variant_title_text__inven_view"
                                            >
                                              {product.category === "Mobile"
                                                ? "IMEI No."
                                                : "MAC Address"}
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {variant.imei
                                                .split(",")
                                                .join("\n")}
                                            </Box>
                                          </Grid>

                                          <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                          >
                                            <Box
                                              fontSize="12px"
                                              className="variant_title_text__inven_view"
                                            >
                                              Description
                                            </Box>

                                            <Box className="product_text__ordview">
                                              {variant.description}
                                            </Box>
                                          </Grid>
                                        </>
                                      ) : (
                                        <Grid
                                          item
                                          xl={12}
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                        >
                                          <Box
                                            fontSize="12px"
                                            className="variant_title_text__inven_view"
                                          >
                                            Description
                                          </Box>
                                          <Box className="product_text__ordview">
                                            {variant.description}
                                          </Box>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <br />
                              </>
                            ))}
                          </Grid> 
                        </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>)}
     
    </>
  );
}

export default ViewOnlineInventory;
