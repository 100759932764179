import React from "react";
import { Box, Button, Grid } from "@mui/material";
import "../LandingPage.css";
import { ReactComponent as DetailArrow } from "../../../assets/SVG/DetailFooterArrow.svg";
function DetailBoxFooter() {
  return (
    <>
      <Box className="detail_footer" display="flex" alignItems="center">
        <Grid container display="flex" justifyContent="center" pt={5}>
          <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
            <Box className="detail_footer_text">
              People who use <span className="dbl_color">ParkourExpress</span>{" "}
              for their shipping save on average 32% on their shipments compared
              to other shipping companies.
            </Box>
            <Box display="flex" justifyContent="center" pt={5} pb={6}>
              <Button className="detail_footer_btn">
                Get Started &nbsp; <DetailArrow />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DetailBoxFooter;
