import React from "react";
import { Box } from "@mui/material";

function SmallTitle({ small }) {
  return (
    <>
      <Box className="i-title wrt__invt">{small}</Box>
    </>
  );
}

export default SmallTitle;
