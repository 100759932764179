import { get, post } from "./API";

export function addUserRole(data, callback) {
  post({ endPoint: "user-roles/addUserRole" }, data, function(response) {
    callback(response);
  });
}

export function getAllUserRoles(callback) {
  get({ endPoint: "user-roles/getAllUserRoles" }, function(response) {
    callback(response);
  });
}

export function updateUserRole(data, callback) {
  post({ endPoint: "user-roles/updateUserRole" }, data, function(response) {
    callback(response);
  });
}

export function deleteUserRole(data, callback) {
  post({ endPoint: "user-roles/deleteUserRole" }, data, function(response) {
    callback(response);
  });
}
