import "./Staff.css";
import {
  Grid,
  DialogActions,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import Button from "@mui/material/Button";
import { AiFillCaretDown } from "react-icons/ai";
import CustomTable from "../../widgets/CustomTable";
import SearchField from "../../widgets/SearchField";
import Box from "@mui/material/Box";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { GlobalData } from "../../App";
import { debounce } from "lodash";
import NavigationBar from "../NavigationBar";
import DeleteDialog from "../../widgets/DeleteDialog";
import AddShoppingCartRoundedIcon from "@mui/icons-material/AddShoppingCartRounded";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import PointOfSaleRoundedIcon from "@mui/icons-material/PointOfSaleRounded";
import InventoryRoundedIcon from "@mui/icons-material/InventoryRounded";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as StaffDeleteIcon } from "../../assets/SVG/StaffDeleteMemberIcon.svg";
import { ReactComponent as StaffEditIcon } from "../../assets/SVG/StaffEditMemberIcon.svg";

import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";
import {
  getAllStaffMembers,
  deleteStaffMember,
  getMemberDetailsById,
} from "../../api/StaffAPI";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import MainTitle from "../../widgets/MainTitle";
import AddNewButton from "../../widgets/AddNewButton";
import NoStaff from "../../assets/Images/EmptyScreenImage/NoStaff.png";
const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    minWidth: 30,
    align: "left",
  },
  {
    id: "image",
    label: "Image",
    align: "left",
  },
  {
    id: "member_name",
    label: "Name",
    minWidth: 30,
    align: "left",
  },
  {
    id: "member_phone",
    label: "Phone Number",
    minWidth: 30,
    align: "left",
  },
  {
    id: "member_address",
    label: "Address",
    minWidth: 30,
    align: "left",
  },
  {
    id: "member_salary",
    label: "Salary",
    minWidth: 30,
    align: "left",
  },
  {
    id: "member_nationality",
    label: "Nationality",
    minWidth: 30,
    align: "left",
  },
  {
    id: "member_cnic",
    label: "CNIC No.",
    minWidth: 30,
    align: "left",
  },
];
const options = [
  {
    icon: (
      <div>
        <EyeIcon />
      </div>
    ),
    label: "View Orders",
    method: "viewOrders",
    type: "icon",
    id: "view_orders",
  },

  {
    icon: (
      <div>
        <EyeIcon />
      </div>
    ),
    label: "View Khata",
    method: "view",
    type: "icon",
    id: "view_khata",
  },
  {
    icon: (
      <div>
        <StaffEditIcon />
      </div>
    ),
    label: "Edit Member",
    method: "edit",
    type: "icon",
    id: "edit_member",
  },
  {
    icon: (
      <div>
        <StaffDeleteIcon />
      </div>
    ),
    label: "Delete Member",
    method: "delete",
    type: "icon",
    id: "delete_member",
  },
];

function Staff(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
  } = useContext(GlobalData);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [staff, setStaff] = useState([]);
  const [staffCount, setStaffCount] = useState(0);
  const [member, setMember] = useState("");
  const [searchText, setSearchText] = useState("");
  const [deleteMemberDialog, setDeleteMemberDialog] = useState(false);
  const [currentMemberId, setCurrentMemberId] = useState("");

  const getAllMembers = (pn) => {
    let data = {
      search_text: searchText,
      per_page: process.env.REACT_APP_PER_PAGE,
      page: pn,
    };
    showLoader();
    getAllStaffMembers(data, (resp) => {
      if (resp.statusCode === 200) {
        setStaffCount(resp.successData.all_staff_members_count);
        if (pn === 1) {
          setStaff(resp.successData.all_staff_members);
          hideLoader();
        } else {
          setStaff((dt) => {
            dt.push(...resp.successData.all_staff_members);
            hideLoader();
            return dt;
          });
        }
        setLastPage(resp.successData.last_page);
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };
  const handleEditStaffMember = (member) => {
    navigate("/staff/add-new-staff-member", {
      state: { memberData: member.id },
    });
  };
  const handleViewStaffMember = (member) => {
    navigate("/staff/staff-member-khata", {
      state: { memberData: member.id },
    });
  };
  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateStaffList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const openDeleteMemberDialog = (member) => {
    console.log("this is Member", member);
    setCurrentMemberId(member.id);
    setDeleteMemberDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteMemberDialog(false);
  };

  const handleDeleteMember = () => {
    showLoader();
    const tempdata = { member_id: currentMemberId };
    deleteStaffMember(tempdata, (resp) => {
      if (resp.statusCode === 200) {
        flashMessage("success", resp.message);
        updateDataUsingPageNumber();
        setDeleteMemberDialog(false);
      } else {
        flashMessage("error", resp.message);
        setDeleteMemberDialog(false);
        hideLoader();
      }
    });
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    getAllMembers(page);
  }, [page]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      getAllMembers(page);
    }
  };
  const handleViewMemberOrders = (member) => {
    navigate("/staff/orders", {
      state: { memberData: member },
    });
  };
  useEffect(() => {
    updateDataUsingPageNumber();
  }, [searchText]);

  return (
    <Grid id="header__orderm" container>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <NavigationBar
          path={"Dashboard>Staff Management>Staff"}
          paddingBottom={3}
        />
        <MainTitle title={"Staff Management"} />

        <Grid container marginBottom={2}>
          <Grid
            xl={6}
            lg={6}
            md={smDown ? 7 : 5}
            sm={smDown ? 7 : 5}
            xs={12}
            item
            className="padn_invoice__icon"
          >
            <SearchField
              id="search_field_staff"
              onChange={updateStaffList}
              tableDataCount={staff.length}
              totalAvailableDataCount={staffCount}
            />
          </Grid>
          <Grid
            xl={6}
            lg={6}
            md={smDown ? 5 : 7}
            sm={smDown ? 5 : 7}
            xs={12}
            item
            display="flex"
            justifyContent={onlyXs ? "center" : "end"}
            paddingTop={onlyXs ? 1 : 0}
            paddingRight={smDown ? "0px" : "10px"}
          >
            {/* <Button
              variant="contained"
              className="add_order_btn__orders"
              style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
              onClick={() => {
                navigate("/staff/add-new-staff-member");
              }}
            >
              Add New Staff Member
            </Button> */}
            <AddNewButton
              id={"staff_member_add__staff"}
              name={"Add New Staff Member"}
              onClick={() => {
                navigate("/staff/add-new-staff-member");
              }}
            />
            <Button
              id="staff_role__staff"
              variant="contained"
              className="download_report_btn__orders"
              onClick={() => {
                navigate("/staff/staff-roles");
              }}
            >
              Staff Roles
            </Button>
          </Grid>
        </Grid>

        <Grid container>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <CustomTable
              view={handleViewStaffMember}
              edit={handleEditStaffMember}
              delete={openDeleteMemberDialog}
              viewOrders={handleViewMemberOrders}
              columns={columns}
              data={staff}
              options={options}
              image={NoStaff}
            />
          </Grid>
        </Grid>
      </Grid>
      <DeleteDialog
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteMemberDialog}
        handleRemove={handleDeleteMember}
        title={"Remove this Transaction?"}
        sub_title={
          "You are about to remove a transaction. Click on the “Remove” button to remove it."
        }
      />
    </Grid>
  );
}

export default Staff;
