import React, { useContext, useState, useEffect } from "react";
import { Box, Grid, TextField, IconButton, Button } from "@mui/material";
import "./Whatsapp.css";
import NavigationBar from "../NavigationBar";
import { ReactComponent as BroadCastFilter } from "../../assets/SVG/broadCastFilterone.svg";
import { ReactComponent as WhatsappAttachIcon } from "../../assets/SVG/WhatsappAttachFileIcon.svg";

import FormControl, { useFormControl } from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled } from "@mui/material/styles";
import { GlobalData } from "../../App";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import PropTypes from "prop-types";
import useAutocomplete from "@mui/material/useAutocomplete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { autocompleteClasses } from "@mui/material/Autocomplete";

import Select from "react-select";

import makeAnimated from "react-select/animated";

import { getAllContacts } from "../../api/ContactsAPI";
import { sendMessage } from "../../api/WhatsappAPI";

const animatedComponents = makeAnimated();

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const Root = styled("div")(
  ({ theme }) => `
  color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
  };
  font-size: 14px;
`
);

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `
  width: 300px;
  border: 1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"};
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
  }

  &.focused {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    color: ${
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.65)"
        : "rgba(0,0,0,.85)"
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const objects = {
  title: "",
  body: "",
};

function Whatsapp() {
  const {
    query,
    showLoader,
    hideLoader,
    location,
    flashMessage,
    navigate,
  } = useContext(GlobalData);

  const [filterDropDown, setFilterDropDown] = React.useState(null);
  const [imageFile, setImageFile] = useState("");
  const [imageUri, setImageUri] = useState("");
  const [dataFields, setDataFields] = useState(objects);

  const [contacts, setContacts] = useState([]);

  const [selectedContacts, setSelectedContacts] = useState([]);

  const handleGetAllContacts = () => {
    let user = JSON.parse(localStorage.getItem("user"));

    let data = {
      branch_id: user.branch_id,
    };

    getAllContacts(data, (resp) => {
      if (resp.statusCode === 200) {
        let contactsData = [];
        resp.successData.contacts.map((value, index) => {
          contactsData.push({
            value: value.number,
            label: value.name + " " + value.number,
          });
        });
        setContacts(contactsData);
      } else {
      }
    });
  };

  const handleSendWhatsapp = () => {
    showLoader();
    let contactsList = [];
    selectedContacts.map((value, index) => {
      contactsList.push(value.value);
    });
    let formData = new FormData();
    formData.append("to", JSON.stringify(contactsList));
    formData.append("type", "text");
    formData.append("body", dataFields.body);
    sendMessage(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  useEffect(() => {
    handleGetAllContacts();
  }, []);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: "customized-hook-demo",
    defaultValue: [top100Films[1]],
    multiple: true,
    options: top100Films,
    getOptionLabel: (option) => option.title,
  });

  const open = Boolean(filterDropDown);
  const handleClick = (event) => {
    setFilterDropDown(event.currentTarget);
  };
  const handleClose = () => {
    setFilterDropDown(null);
  };

  function saveImageFile(e) {
    let image = document.getElementById("productImage").files;
    setImageFile(image[0]);
    setImageUri(window.URL.createObjectURL(new Blob(image, { type: "*" })));
  }

  const handleClickField = (e) => {
    const { name, value } = e.target;
    setDataFields({
      ...dataFields,
      [name]: value,
    });
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <NavigationBar
              path={"Dashboard>Whatsapp Message Management>Broadcasting"}
              paddingBottom={2}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} pl={2} pt={2}>
            <Box className="broadCst_text_i">Message Broadcasting</Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="box__broadCast_p">
        <Box className="broadcst__main_one_b">
          <Box display="flex" justifyContent="center">
            <Box className="broadCast__main_second_b">
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} pt={2}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    style={{ width: "130px" }}
                  >
                    <IconButton
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      // onClick={handleClick}
                    >
                      <BroadCastFilter color="#1E2738" />
                    </IconButton>
                    <span className="broadCast__filt">Filter</span>
                    {/* <Menu
        id="basic-menu"
        filterDropDown={filterDropDown}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu> */}
                  </Box>
                </Grid>
              </Grid>

              <Box pt={2} pl={3} paddingRight="20px">
                <Grid container display="flex" justifyContent="center">
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px", color: "#1E2738" }}>
                      Message Title
                    </div>
                    <CustomTextField
                      placeholder="Enter Message Title"
                      className="textField__AddInventory broadCast__back"
                      onChange={handleClickField}
                      name="title"
                    />
                  </Grid>
                </Grid>

                <Grid container mt={2}>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    <div style={{ marginBottom: "8px", color: "#1E2738" }}>
                      Message Body
                    </div>
                    <CustomTextField
                      placeholder="Enter Message Body"
                      className="textField__AddInventory broadCast__back"
                      multiline={true}
                      rows={4}
                      onChange={handleClickField}
                      name="body"
                    />
                  </Grid>
                </Grid>

                <Grid container mt={2} pr={1}>
                  <Grid item xs={12}>
                    {/* <div {...getRootProps()}>
                      <Label {...getInputLabelProps()}>Customized hook</Label>
                      <InputWrapper
                        ref={setAnchorEl}
                        className={focused ? "focused" : ""}
                      >
                        {value.map((option, index) => (
                          <StyledTag
                            label={option.title}
                            {...getTagProps({ index })}
                          />
                        ))}

                        <input {...getInputProps()} />
                      </InputWrapper>
                    </div>
                    {groupedOptions.length > 0 ? (
                      <Listbox {...getListboxProps()}>
                        {groupedOptions.map((option, index) => (
                          <li {...getOptionProps({ option, index })}>
                            <span>{option.title}</span>
                            <CheckIcon fontSize="small" />
                          </li>
                        ))}
                      </Listbox>
                    ) : null} */}

                    <div style={{ marginBottom: "8px", color: "#1E2738" }}>
                      Select Number
                    </div>

                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={contacts}
                      className="custome_select_input__whatsapp"
                      onChange={(value, actions) => {
                        setSelectedContacts(value);
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  display="flex"
                  paddingTop={query(2, 2, 0)}
                  justifyContent="center"
                  paddingBottom={3}
                >
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px", color: "#1E2738" }}>
                      Attach Media (Optional)
                    </div>
                    <input
                      style={{ display: "none" }}
                      id="productImage"
                      type="file"
                      onChange={saveImageFile}
                    />
                    <label htmlFor="productImage">
                      <Button
                        variant="outlined"
                        component="span"
                        style={{
                          width: "100%",
                          height: "54.5px",
                          backgroundColor: "white",
                          borderColor: "rgb(0,0,0,0.25)",
                          color: "rgb(0,0,0,0.35)",
                          textTransform: "none",
                          fontSize: "16px",
                          justifyContent: "flex-start",
                          borderRadius: "10px",
                        }}
                        onChange={saveImageFile}
                        endIcon={
                          <Box
                            sx={
                              imageUri === ""
                                ? {
                                    position: "absolute",
                                    right: "20px",
                                    bottom: "8px",
                                  }
                                : {
                                    position: "absolute",
                                    right: "0px",
                                    bottom: "-10px",
                                  }
                            }
                          >
                            {imageUri === "" && (
                             <WhatsappAttachIcon />
                            )}
                            {imageUri !== "" && (
                              <img
                                alt=""
                                style={{ borderRadius: "10px" }}
                                src={imageUri}
                                width="50px"
                                height="50px"
                              />
                            )}
                          </Box>
                        }
                      >
                        Attach only one file
                      </Button>
                    </label>
                    {/* <CustomTextField
                      placeholder="Attach only one file"
                      className="textField__AddInventory broadCast__back"
                    /> */}
                  </Grid>
                </Grid>

                <Grid container paddingBottom={3}>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      className="add_broad_btn"
                      onClick={handleSendWhatsapp}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  {
    title: "The Lord of the Rings: The Return of the King",
    year: 2003,
  },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  {
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001,
  },
  {
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980,
  },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
  {
    title: "The Lord of the Rings: The Two Towers",
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: "Goodfellas", year: 1990 },
  { title: "The Matrix", year: 1999 },
  { title: "Seven Samurai", year: 1954 },
  {
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977,
  },
  { title: "City of God", year: 2002 },
  { title: "Se7en", year: 1995 },
  { title: "The Silence of the Lambs", year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: "Life Is Beautiful", year: 1997 },
  { title: "The Usual Suspects", year: 1995 },
  { title: "Léon: The Professional", year: 1994 },
  { title: "Spirited Away", year: 2001 },
  { title: "Saving Private Ryan", year: 1998 },
  { title: "Once Upon a Time in the West", year: 1968 },
  { title: "American History X", year: 1998 },
  { title: "Interstellar", year: 2014 },
  { title: "Casablanca", year: 1942 },
  { title: "City Lights", year: 1931 },
  { title: "Psycho", year: 1960 },
  { title: "The Green Mile", year: 1999 },
  { title: "The Intouchables", year: 2011 },
  { title: "Modern Times", year: 1936 },
  { title: "Raiders of the Lost Ark", year: 1981 },
  { title: "Rear Window", year: 1954 },
  { title: "The Pianist", year: 2002 },
  { title: "The Departed", year: 2006 },
  { title: "Terminator 2: Judgment Day", year: 1991 },
  { title: "Back to the Future", year: 1985 },
  { title: "Whiplash", year: 2014 },
  { title: "Gladiator", year: 2000 },
  { title: "Memento", year: 2000 },
  { title: "The Prestige", year: 2006 },
  { title: "The Lion King", year: 1994 },
  { title: "Apocalypse Now", year: 1979 },
  { title: "Alien", year: 1979 },
  { title: "Sunset Boulevard", year: 1950 },
  {
    title:
      "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
    year: 1964,
  },
  { title: "The Great Dictator", year: 1940 },
  { title: "Cinema Paradiso", year: 1988 },
  { title: "The Lives of Others", year: 2006 },
  { title: "Grave of the Fireflies", year: 1988 },
  { title: "Paths of Glory", year: 1957 },
  { title: "Django Unchained", year: 2012 },
  { title: "The Shining", year: 1980 },
  { title: "WALL·E", year: 2008 },
  { title: "American Beauty", year: 1999 },
  { title: "The Dark Knight Rises", year: 2012 },
  { title: "Princess Mononoke", year: 1997 },
  { title: "Aliens", year: 1986 },
  { title: "Oldboy", year: 2003 },
  { title: "Once Upon a Time in America", year: 1984 },
  { title: "Witness for the Prosecution", year: 1957 },
  { title: "Das Boot", year: 1981 },
  { title: "Citizen Kane", year: 1941 },
  { title: "North by Northwest", year: 1959 },
  { title: "Vertigo", year: 1958 },
  {
    title: "Star Wars: Episode VI - Return of the Jedi",
    year: 1983,
  },
  { title: "Reservoir Dogs", year: 1992 },
  { title: "Braveheart", year: 1995 },
  { title: "M", year: 1931 },
  { title: "Requiem for a Dream", year: 2000 },
  { title: "Amélie", year: 2001 },
  { title: "A Clockwork Orange", year: 1971 },
  { title: "Like Stars on Earth", year: 2007 },
  { title: "Taxi Driver", year: 1976 },
  { title: "Lawrence of Arabia", year: 1962 },
  { title: "Double Indemnity", year: 1944 },
  {
    title: "Eternal Sunshine of the Spotless Mind",
    year: 2004,
  },
  { title: "Amadeus", year: 1984 },
  { title: "To Kill a Mockingbird", year: 1962 },
  { title: "Toy Story 3", year: 2010 },
  { title: "Logan", year: 2017 },
  { title: "Full Metal Jacket", year: 1987 },
  { title: "Dangal", year: 2016 },
  { title: "The Sting", year: 1973 },
  { title: "2001: A Space Odyssey", year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: "Toy Story", year: 1995 },
  { title: "Bicycle Thieves", year: 1948 },
  { title: "The Kid", year: 1921 },
  { title: "Inglourious Basterds", year: 2009 },
  { title: "Snatch", year: 2000 },
  { title: "3 Idiots", year: 2009 },
  { title: "Monty Python and the Holy Grail", year: 1975 },
];

export default Whatsapp;
