import { getWithParams, post } from "./API";

export function sendMessage(data, callback) {
  post({ endPoint: "whatsapp/sendtextmessage" }, data, function(response) {
    callback(response);
  });
}

export function getAllChatUsersByBranchId(data, callback) {
  getWithParams(
    { endPoint: "whatsapp/getAllChatUsersByBranchId" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function readAllMessagesByPhoneNumber(data, callback) {
  getWithParams(
    { endPoint: "whatsapp/readAllMessagesByPhoneNumber" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function sendMessageByPhoneNumber(data, callback) {
  post({ endPoint: "whatsapp/sendMessageByPhoneNumber" }, data, function(
    response
  ) {
    callback(response);
  });
}
