import { get, post, getWithParams, delte } from "./API";

export function getAllInvoice(data, callback) {
  getWithParams(
    { endPoint: "order-invoice/getAllOrderInvoices" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function createnewInvoice(data, callback) {
  post({ endPoint: "order-invoice/createOrderInvoice" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function updateInvoice(data, callback) {
  post({ endPoint: "order-invoice/updateOrderInvoice" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function deleteInvoice(data, callback) {
  delte({ endPoint: "order-invoice/deleteOrderInvoice" }, data, function(
    response
  ) {
    callback(response);
  });
}

// export function getOrderById(data, callback) {
//   getWithParams({ endPoint: "orders/getOrderById" }, data, function(response) {
//     callback(response);
//   });
// }
