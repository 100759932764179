import "./Welcome.css";
import anime from "animejs";
import { Grid, Box } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { Typewriter } from "react-simple-typewriter";
import logo from "../assets/Images/logo.png";
import HashLoader from "react-spinners/HashLoader";
import AdminLogo from "../assets/Images/admin.png";

function Welcome(props) {
  let baseUrl = props.baseUrl;
  const [secondText, setSecondText] = useState(false);
  const [thirdText, setThirdText] = useState(false);
  const [user, setUser] = useState("");

  function animate() {
    var tl = anime.timeline({
      easing: "easeOutExpo",
      duration: 5000,
    });
    tl.add({
      targets: "#textAnimation",
      opacity: [1, 0],
      easing: "easeInOutExpo",
      duration: 4000,
      direction: "linear",
    }).add({
      targets: "#signatureBackground",
      opacity: 0,
      zIndex: {
        value: [100, 0],
        round: true,
      },
      display: "none",
      duration: 1000,
    });
  }

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    let loader = document.getElementById("welcome-loader");
    if (props.isUserAuthenticated) {
      loader.style.display = "none";
    } else {
      setTimeout(() => {
        loader.style.display = "block";
      }, [1000]);
    }
    animate();
    setTimeout(() => {
      props.showWebsite();
    }, 3000);
    setTimeout(() => {
      setSecondText(true);
    }, 1000);
    setTimeout(() => {
      setThirdText(true);
    }, 2000);
  }, []);

  return (
    <Grid
      container
      style={{
        position: "absolute",
        backgroundColor: "#1E2738",
        width: "100vw",
        height: "100vh",
      }}
      id="signatureBackground"
    >
      <Grid
        xs={12}
        display="flex"
        justifyContent="center"
        item
        className="textContainer"
      >
        {props.isUserAuthenticated ? (
          <Box mt={"20vh"}>
            <div class="textCenter">
              <div class="textCenter">
                <div class="textCenter">
                  <span class="firstText">
                    <Typewriter
                      words={["Welcome to"]}
                      loop={1}
                      cursor
                      cursorStyle={
                        !secondText ? (
                          <img
                            alt=""
                            src={`${
                              user.shop !== null && user.shop !== undefined
                                ? baseUrl + user.shop.image
                                : AdminLogo
                            }`}
                            width="80px"
                            height="80px"
                            style={{
                              marginBottom: "-30px",
                              marginLeft: "10px",
                              borderRadius:"40px"
                            }}
                          />
                        ) : (
                          ""
                        )
                      }
                      typeSpeed={70}
                      deleteSpeed={50}
                      delaySpeed={1000}
                    />
                  </span>
                  <br />
                  {secondText && (
                    <span class="secondText">
                      <Typewriter
                        words={[
                          `${
                            user.shop !== null && user.shop !== undefined
                              ? user.shop.brand_name
                              : "Super Admin Panel"
                          }`,
                        ]}
                        loop={1}
                        cursor
                        cursorStyle={
                          !thirdText ? (
                            <img
                              alt=""
                              src={`${
                                user.shop !== null && user.shop !== undefined
                                  ? baseUrl + user.shop.image
                                  : AdminLogo
                              }`}
                              width="120px"
                              height="120px"
                              style={{
                                marginBottom: "-30px",
                                marginLeft: "10px",
                                borderRadius:"50%"
                                
                              }}
                            />
                          ) : (
                            ""
                          )
                        }
                        typeSpeed={70}
                        deleteSpeed={50}
                        delaySpeed={1000}
                      />
                    </span>
                  )}
                  <br />
                  {thirdText ? (
                    <span class="thirdText">
                      <Typewriter
                        words={[`${user.shop !== null && user.shop !== undefined ? "Shop" : ""}`]}
                        loop={1}
                        cursor
                        cursorStyle={
                          <img
                            alt=""
                            src={`${
                              user.shop !== null && user.shop !== undefined
                                ? baseUrl + user.shop.image
                                : AdminLogo
                            }`}
                            width="80px"
                            height="80px"
                            style={{ marginBottom: "-10px", marginLeft: "5px",borderRadius:"40px" }}
                          />
                        }
                        typeSpeed={70}
                        deleteSpeed={50}
                        delaySpeed={1000}
                      />
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </Box>
        ) : (
          <Box mt={"30vh"} id="welcome-loader" display="none">
            <HashLoader color="#ECAE33" size={100} />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default Welcome;
