import { get, post, getWithParams } from "./API";

export function register(data, callback) {
  post({ endPoint: "auth/register" }, data, function(response) {
    callback(response);
  });
}

export function login(data, callback) {
  post({ endPoint: "auth/login" }, data, function(response) {
    callback(response);
  });
}

export function logout(data, callback) {
  post({ endPoint: "auth/logout" }, data, function(response) {
    callback(response);
  });
}

export function getForgetPasswordLink(data, callback) {
  getWithParams({ endPoint: "auth/get-forget-password-link" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function forget(data, callback) {
  post({ endPoint: "auth/forget-password" }, data, function(response) {
    callback(response);
  });
}

export const isAuthenticated = JSON.parse(localStorage.getItem("user"))
  ? true
  : false;

export const ShopBranches = JSON.parse(localStorage.getItem("user"))
  ? JSON.parse(localStorage.getItem("user")).branches
  : [];

export const UserData = JSON.parse(localStorage.getItem("user"))
  ? JSON.parse(localStorage.getItem("user"))
  : "";

export const ShopBranch = JSON.parse(localStorage.getItem("shopBranch"))
  ? JSON.parse(localStorage.getItem("shopBranch"))
  : "";
