import { getWithParams, post, delte } from "./API";

export function getAllRepairingServices(data, callback) {
  getWithParams(
    { endPoint: "RepairingServices/getAllRepairingServices" },
    data,
    function(response) {
      callback(response);
    }
  );
}
export function createRepairingService(data, callback) {
  post({ endPoint: "RepairingServices/createRepairingService" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function updateRepairingService(data, callback) {
  post({ endPoint: "RepairingServices/updateRepairingService" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function getRepairingServiceByID(data, callback) {
  getWithParams(
    { endPoint: "RepairingServices/getRepairingServiceByID" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function deleteRepairingServiceByID(data, callback) {
  delte(
    { endPoint: "RepairingServices/deleteRepairingServiceByID" },
    data,
    function(response) {
      callback(response);
    }
  );
}
