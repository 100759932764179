import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { IoMdArrowDropleft } from "react-icons/io";
import { styled } from "@mui/material/styles";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import {
  registerStaffMember,
  updateStaffMember,
  getMemberDetailsById,
} from "../../api/StaffAPI";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./Staff.css";
import AutoScrollContainer from "auto-scroll-container";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getAllUserRoles } from "../../api/UserRolesAPI";
import { ReactComponent as UploadImageIcon } from "../../assets/SVG/UploadImageIcon.svg";
import { ReactComponent as StaffUploadIcon } from "../../assets/SVG/StaffUploadIcons.svg";
import { getTodayDate, pad } from "../../utils/common";
import PhoneInput from "react-phone-input-2";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});
const memberObject = {
  member_name: "",
  member_phone: "",
  member_address: "",
  member_salary: "",
  member_nationality: "",
  member_cnic: "",
  email: "",
  password: "",
  role: "",
  role_id: "",
  passport_number: "",
  visa_number: "",
  visa_expiry_date: "",
  emirates_id_number: "",
  picture: "",
  personal_email: "",
};
function AddStaff(props) {
  const {
    smDown,
    query,
    showLoader,
    hideLoader,
    location,
    flashMessage,
    navigate,
    baseUrl,
  } = useContext(GlobalData);

  const [imageUri, setImageUri] = useState("");
  const [imageFile, setImageFile] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [userRoles, setUserRoles] = useState([]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [edit, setEdit] = useState(false);
  const [member, setMember] = useState(memberObject);

  const handleOrderInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "role") {
      setMember({
        ...member,
        [name]: value,
        ["role_id"]: userRoles.find((ur) => ur.role === value).id,
      });
    } else {
      setMember({
        ...member,
        [name]: value,
      });
    }
  };

  const handleGetAllUserRoles = () => {
    showLoader();
    getAllUserRoles((resp) => {
      hideLoader();
      setUserRoles(resp.successData.user_roles);
    });
  };

  const handleGetMemberDetailsById = () => {
    const data = {
      member_id: location.state.memberData,
    };
    showLoader();
    getMemberDetailsById(data, (resp) => {
      if (resp.statusCode === 200) {
        setMember({
          ...member,
          member_name: resp.successData.staff.member_name,
          member_phone: resp.successData.staff.member_phone,
          member_address: resp.successData.staff.member_address,
          member_salary: resp.successData.staff.member_salary,
          member_cnic: resp.successData.staff.member_cnic,
          member_nationality: resp.successData.staff.member_nationality,
          email: resp.successData.staff.user.email,
          role: resp.successData.staff.user.role,
          role_id: resp.successData.staff.user.role_id,
          passport_number: resp.successData.staff.passport_number,
          visa_number: resp.successData.staff.visa_number,
          visa_expiry_date: resp.successData.staff.visa_expiry_date,
          emirates_id_number: resp.successData.staff.emirates_id_number,
          personal_email: resp.successData.staff.personal_email,
        });
        setImageUri(baseUrl + resp.successData.staff.image);
        setEdit(true);
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  useEffect(() => {
    handleGetAllUserRoles();
    if (location.state) {
      handleGetMemberDetailsById();
    } else {
      setMember({
        ...member,
        ["visa_expiry_date"]: getTodayDate(),
      });
    }
  }, []);

  const handleAddStaff = () => {
    showLoader();
    let formData = new FormData();
    formData.append("member_name", member.member_name);
    formData.append("member_phone", member.member_phone);
    formData.append("member_address", member.member_address);
    formData.append("member_salary", member.member_salary);
    formData.append("member_nationality", member.member_nationality);
    formData.append("member_cnic", member.member_cnic);
    formData.append("email", member.email);
    formData.append("password", member.password);
    formData.append("role", member.role);
    formData.append("role_id", member.role_id);
    formData.append("passport_number", member.passport_number);
    formData.append("visa_number", member.visa_number);
    formData.append("personal_email", member.personal_email);

    var date = new Date(member.visa_expiry_date);
    formData.append(
      "visa_expiry_date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );

    formData.append("emirates_id_number", member.emirates_id_number);
    if (imageFile) {
      formData.append("image", imageFile, imageFile.name);
    }
    registerStaffMember(formData, (resp) => {
      if (resp.statusCode === 200) {
        hideLoader();
        navigate("/staff");
        flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };
  const handleEditStaff = () => {
    showLoader();
    let formData = new FormData();
    formData.append("member_id", location.state.memberData);
    if (member.member_name !== "") {
      formData.append("member_name", member.member_name);
    }
    if (member.member_phone !== "") {
      formData.append("member_phone", member.member_phone);
    }
    if (member.member_address !== "") {
      formData.append("member_address", member.member_address);
    }
    if (member.member_salary !== "") {
      formData.append("member_salary", member.member_salary);
    }

    if (member.member_nationality !== "") {
      formData.append("member_nationality", member.member_nationality);
    }

    if (member.member_cnic !== "") {
      formData.append("member_cnic", member.member_cnic);
    }

    if (member.email !== "") {
      formData.append("email", member.email);
    }

    if (member.personal_email !== "") {
      formData.append("personal_email", member.personal_email);
    }

    if (member.password !== "") {
      formData.append("password", member.password);
    }

    if (member.role !== "") {
      formData.append("role", member.role);
    }

    if (member.role_id !== "") {
      formData.append("role_id", member.role_id);
    }

    if (member.passport_number !== "") {
      formData.append("passport_number", member.passport_number);
    }

    if (member.visa_number !== "") {
      formData.append("visa_number", member.visa_number);
    }

    if (member.visa_expiry_date !== "") {
      var date = new Date(member.visa_expiry_date);
      formData.append(
        "visa_expiry_date",
        date.getFullYear() +
          "-" +
          pad(date.getMonth() + 1) +
          "-" +
          pad(date.getDate())
      );
    }

    if (member.emirates_id_number !== "") {
      formData.append("emirates_id_number", member.emirates_id_number);
    }

    if (imageFile) {
      formData.append("image", imageFile, imageFile.name);
    }
    updateStaffMember(formData, (resp) => {
      if (resp.statusCode === 200) {
        hideLoader();
        navigate("/staff");
        flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  function saveImageFile(e) {
    let image = document.getElementById("productImage").files;
    setImageFile(image[0]);
    setImageUri(window.URL.createObjectURL(new Blob(image, { type: "*" })));
  }

  const handleSetVisaExpiryDate = (date) => {
    setMember({
      ...member,
      ["visa_expiry_date"]: date,
    });
  };

  return (
    <Grid container height="100vh" className="padn_invoice__icon">
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box height={smDown ? "30vh" : "20vh"}>
          <NavigationBar
            path={
              edit
                ? "Dashboard>Staff Management>Staff>Edit Staff Member"
                : "Dashboard>Staff Management>Staff>Add New Staff Member"
            }
            paddingBottom={1}
          />

          <Grid display="flex" justifyContent="center" container mb={2} mt={2}>
            <Grid
              className="header__ai"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
            >
              <div
                className="header_back_btn__ai"
                onClick={() => {
                  navigate("/staff");
                }}
              >
                <IoMdArrowDropleft className="add_vendor_back_icon" />
                Back
              </div>
              <div className="header_text__ai">
                Staff Management {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                {edit ? (
                  <span style={smDown ? { fontSize: "14px" } : {}}>
                    Edit Member
                  </span>
                ) : (
                  <span style={smDown ? { fontSize: "14px" } : {}}>
                    Add New Member
                  </span>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
        <AutoScrollContainer marginTop={0.01}>
          <Box className="div__scroll" height={smDown ? "70vh" : "80vh"}>
            <Grid container className="card__ai">
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                item
                paddingTop={query(2, 2, 2)}
                paddingBottom={query(3, 3, 3)}
                paddingLeft={query(5, 2, 1)}
                paddingRight={query(5, 2, 1)}
                id="addOrderStyle"
              >
                <Grid container display="flex" justifyContent="center">
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div className="field_text_main_global">Name</div>
                    <CustomTextField
                      value={member.member_name}
                      onChange={handleOrderInputChange}
                      name="member_name"
                      id="member_name"
                      key="member_name"
                      placeholder="Member Name"
                      className={"textField__AddInventory"}
                    />
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div className="field_text_main_global">Phone Number</div>
                    {/* <CustomTextField
                      value={member.member_phone}
                      onChange={handleOrderInputChange}
                      name="member_phone"
                      id="member_phone"
                      key="member_phone"
                      placeholder="Phone Number"
                      className="textField__AddInventory"
                      disabled={edit ? true : false}
                      type="number"
                    /> */}
                    <PhoneInput
                      country={"Us"}
                      value={member.member_phone}
                      onChange={(value) => {
                        setMember({
                          ...member,
                          ["member_phone"]: value,
                        });
                      }}
                      placeholder="Member Phone"
                      inputProps={{
                        name: "member_phone",
                        id: "member_phone",
                        key: "member_phone",
                      }}
                      inputClass="phone_input_search"
                      buttonClass="button_input_field"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  display="flex"
                  paddingTop={query(1, 1, 0)}
                  justifyContent="center"
                >
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div className="field_text_main_global">Address</div>
                    <CustomTextField
                      value={member.member_address}
                      name="member_address"
                      id="member_address"
                      onChange={handleOrderInputChange}
                      key="member_address"
                      placeholder="Member Address"
                      className="textField__AddInventory"
                    />
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div className="field_text_main_global">Nationality</div>
                    <CustomTextField
                      value={member.member_nationality}
                      onChange={handleOrderInputChange}
                      name="member_nationality"
                      id="member_nationality"
                      key="member_nationality"
                      placeholder="Nationality"
                      className="textField__AddInventory"
                      onKeyDown={(event) => {
                        if (!/[a-z]/i.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  display="flex"
                  paddingTop={query(1, 1, 0)}
                  justifyContent="center"
                >
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div className="field_text_main_global">Salary</div>
                    <CustomTextField
                      value={member.member_salary}
                      name="member_salary"
                      id="member_salary"
                      onChange={handleOrderInputChange}
                      key="member_salary"
                      placeholder="Member Salary"
                      className="textField__AddInventory"
                      type="number"
                    />
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div className="field_text_main_global">CNIC Number</div>
                    <CustomTextField
                      value={member.member_cnic}
                      onChange={handleOrderInputChange}
                      name="member_cnic"
                      id="member_cnic"
                      key="member_cnic"
                      placeholder="CNIC NO."
                      className="textField__AddInventory"
                      type="number"
                    />
                  </Grid>
                </Grid>

                {edit && (
                  <Grid
                    container
                    display="flex"
                    paddingTop={query(1, 1, 0)}
                    justifyContent="center"
                  >
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div className="field_text_main_global">Login Email</div>
                      <CustomTextField
                        value={member.email}
                        name="email"
                        id="email"
                        onChange={handleOrderInputChange}
                        key="email"
                        placeholder="Enter Email"
                        className="textField__AddInventory"
                        disabled={edit ? true : false}
                      />
                    </Grid>

                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div className="field_text_main_global">Password</div>

                      <CustomTextField
                        value={member.password}
                        onChange={handleOrderInputChange}
                        className="textField__AddInventory"
                        id="password"
                        name="password"
                        key="password"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Enter your Password"
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid
                  container
                  display="flex"
                  paddingTop={query(1, 1, 0)}
                  justifyContent="center"
                >
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div className="field_text_main_global">
                      Passport Number
                    </div>
                    <CustomTextField
                      value={member.passport_number}
                      name="passport_number"
                      id="passport_number"
                      onChange={handleOrderInputChange}
                      key="passport_number"
                      placeholder="Enter Passport Number"
                      className="textField__AddInventory"
                    />
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div className="field_text_main_global">Visa Number</div>

                    <CustomTextField
                      value={member.visa_number}
                      name="visa_number"
                      id="visa_number"
                      onChange={handleOrderInputChange}
                      key="visa_number"
                      placeholder="Visa Number"
                      className="textField__AddInventory"
                      type="number"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  display="flex"
                  paddingTop={query(1, 1, 0)}
                  justifyContent="center"
                >
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div className="field_text_main_global">
                      Visa Expiry Date
                    </div>
                    {/* <CustomTextField
                      value={member.visa_expiry_date}
                      name="visa_expiry_date"
                      id="visa_expiry_date"
                      onChange={handleOrderInputChange}
                      key="visa_expiry_date"
                      placeholder="Enter Visa Expiry Date"
                      className="textField__AddInventory"
                    /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="bnkmnu_field__bank"
                        value={member.visa_expiry_date}
                        onChange={(newValue) => {
                          handleSetVisaExpiryDate(newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            id="visa_expiry_date__add-staff"
                            error={false}
                            {...params}
                            placeholder={"mm/dd/yyyy"}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div className="field_text_main_global">
                      Emirates ID Number
                    </div>

                    <CustomTextField
                      value={member.emirates_id_number}
                      name="emirates_id_number"
                      id="emirates_id_number"
                      onChange={handleOrderInputChange}
                      key="emirates_id_number"
                      placeholder="Emirates ID Number"
                      className="textField__AddInventory"
                      type="number"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  display="flex"
                  paddingTop={query(1, 1, 0)}
                  justifyContent={smDown ? "center" : "start"}
                >
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div className="field_text_main_global">Roles</div>
                    <FormControl className="textField__AddInventory">
                      {member.role === "" && (
                        <InputLabel sx={{ opacity: 0.6 }}>
                          Select User Role
                        </InputLabel>
                      )}

                      <CustomTextField
                        select
                        value={member.role}
                        onChange={handleOrderInputChange}
                        name="role"
                        id="role"
                        key="role"
                      >
                        {userRoles.map((option) => (
                          <MenuItem key={option.role} value={option.role}>
                            {option.role}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                    </FormControl>
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    <div className="field_text_main_global">
                      Staff Member Picture (Optional)
                    </div>

                    <input
                      style={{ display: "none" }}
                      id="productImage"
                      type="file"
                      onChange={saveImageFile}
                    />
                    <label htmlFor="productImage">
                      <Button
                        id="staff_member_picture__add-staff"
                        variant="outlined"
                        component="span"
                        className="product_img_btn_staff"
                        endIcon={
                          <Box
                            sx={
                              imageUri === ""
                                ? {
                                    position: "absolute",
                                    right: "20px",
                                    bottom: "8px",
                                  }
                                : {
                                    position: "absolute",
                                    right: "0px",
                                    bottom: "-10px",
                                  }
                            }
                          >
                            {imageUri === "" && <StaffUploadIcon />}
                            {imageUri !== "" && (
                              <img
                                alt=""
                                src={imageUri}
                                className="staff_img_sty"
                              />
                            )}
                          </Box>
                        }
                      >
                        Upload Image
                      </Button>
                    </label>
                  </Grid>
                </Grid>

                {!edit && (
                  <Grid
                    container
                    display="flex"
                    paddingTop={query(1, 1, 0)}
                    justifyContent="start"
                  >
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div className="field_text_main_global">Password</div>

                      <CustomTextField
                        value={member.password}
                        onChange={handleOrderInputChange}
                        className="textField__AddInventory"
                        id="password"
                        name="password"
                        key="password"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Enter your Password"
                        onKeyPress={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                      />
                    </Grid>
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div className="field_text_main_global">
                        Personal Email
                      </div>
                      <CustomTextField
                        value={member.personal_email}
                        name="personal_email"
                        id="personal_email"
                        onChange={handleOrderInputChange}
                        key="personal_email"
                        placeholder="Enter Personal Email"
                        className="textField__AddInventory"
                      />
                    </Grid>
                  </Grid>
                )}

                {edit && (
                  <Grid
                    container
                    display="flex"
                    paddingTop={query(1, 1, 0)}
                    justifyContent="start"
                  >
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div className="field_text_main_global">
                        Personal Email
                      </div>
                      <CustomTextField
                        value={member.personal_email}
                        name="personal_email"
                        id="personal_email"
                        onChange={handleOrderInputChange}
                        key="personal_email"
                        placeholder="Enter Personal Email"
                        className="textField__AddInventory"
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid container paddingTop={2} paddingBottom={5}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    item
                    paddingLeft={smDown ? 1 : 0}
                    display="flex"
                    justifyContent="start"
                  >
                    <Button
                      id="discard_btn_add-staff"
                      variant="contained"
                      className="footer_dis__ai"
                      onClick={() => {
                        navigate("/staff");
                      }}
                    >
                      Discard
                    </Button>
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    item
                    paddingRight={smDown ? 1 : 0}
                    display="flex"
                    justifyContent="end"
                  >
                    {edit ? (
                      <Button
                        id="save_member_changes__add-staff"
                        variant="contained"
                        className="footer_add__ai"
                        onClick={handleEditStaff}
                      >
                        Save Member Changes
                      </Button>
                    ) : (
                      <Button
                        id="add_staff_member__add-staff"
                        variant="contained"
                        className="footer_add__ai"
                        onClick={handleAddStaff}
                      >
                        Add Staff Member
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </AutoScrollContainer>
      </Grid>
    </Grid>
  );
}
export default AddStaff;
