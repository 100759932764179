import React, { useContext, useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { ReactSmartScroller } from "react-smart-scroller";
import {
  List,
  Grid,
  ListItemButton,
  ListItemIcon,
  Paper,
  InputBase,
  ListItem,
} from "@mui/material";
import "./Chat.css";
import {
  getAllChatUsersByBranchId,
  sendMessageByPhoneNumber,
  readAllMessagesByPhoneNumber,
} from "../../api/WhatsappAPI";
import { GlobalData } from "../../App";
import Pusher from "pusher-js";
import doubleTicksPNG from "../../assets/Images/doubleTicks.png";
import ChatUserIcon from "../../assets/Images/chat_user.png";
import { ReactComponent as SearchIcon } from "../../assets/SVG/SearchChatIcon.svg";
import EmptyChatScreen from "../../assets/SVG/empty_chat_screen.svg";
import { RiBodyScanFill } from "react-icons/ri";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import $ from "jquery";

// Define styles for the layout components
const Container = styled(Box)({
  display: "flex",
  height: "100vh",
  width: "cal(100% + 5px)",
  marginLeft: "-5px",
});

const LeftSidebar = styled(Box)({
  backgroundColor: "#F4F4F5",
  width: "25%",
  padding: "16px 0px",
});

const RightSidebar = styled(Box)({
  backgroundColor: "#ffffff",
  width: "25%",
  padding: "16px",
});

const Header = styled(Box)({
  marginBottom: "16px",
  fontWeight: "bold",
});

const Body = styled(Box)({
  backgroundColor: "#E7E7E7",
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  overflowY: "auto",
});

const Footer = styled(Box)({
  backgroundColor: "#ffffff",
  display: "flex",
  alignItems: "center",
  padding: "16px",
});

const SearchBar = styled(TextField)({
  width: "100%",
});

const UserList = styled(Box)({
  overflowY: "auto",
});

const User = styled(Box)({
  cursor: "pointer",
  padding: "8px",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#d9d9d9",
  },
});

const ChatHeader = styled(Box)({
  backgroundColor: "#E7E7E7",
  display: "flex",
  alignItems: "center",
  paddingTop: "10px",
  marginBottom: "16px",
  "& > *": {
    marginRight: "16px",
  },
});

const ChatTitle = styled(Box)({
  flexGrow: 1,
});

const ChatBody = styled(Box)({
  flexGrow: 1,
  overflowY: "auto",
  padding: "16px",
});

const ChatFooter = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginTop: "16px",
  padding: "16px",
});

const ChatInput = styled(TextField)({
  flexGrow: 1,
});

const ChatSendButton = styled(IconButton)({
  marginLeft: "8px",
});

// Define the main Chat component
const Chat = () => {
  const {
    query,
    showLoader,
    hideLoader,
    location,
    flashMessage,
    navigate,
  } = useContext(GlobalData);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [chatUsers, setChatUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserIndex, setSelectedUserIndex] = useState("");

  const [pusherData, setPusherData] = useState("");

  const [userHovered, setUserHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [messageText, setMessageText] = useState("");

  const [uiCointer, setUiCointer] = useState(0);
  const listRef = useRef(null);

  const updateUI = () => {
    setUiCointer(uiCointer + 1);
  };

  const handleUserHoveredEnter = (index) => {
    setUserHovered(true);
    setHoveredIndex(index);
  };

  const handleUserHoveredLeave = (index) => {
    setUserHovered(false);
    setHoveredIndex(index);
  };

  const handleGetAllChatUsers = () => {
    showLoader();
    let data = {};
    getAllChatUsersByBranchId(data, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        setChatUsers(resp.successData.chat_users);
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const handleSendMessage = (message) => {
    showLoader();
    let formData = new FormData();
    formData.append("chat_id", selectedUser.chat_id);
    formData.append("shop_id", user.shop_id);
    formData.append("user_id", user.id);
    formData.append("message", message);
    formData.append("mobile", selectedUser.mobile);
    formData.append("read_status", "1");
    sendMessageByPhoneNumber(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        setMessageText("");
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  useEffect(() => {
    if (selectedUser != "" && selectedUser.chat_id == pusherData.chat_id) {
      let userData = selectedUser;
      userData.messages.push(pusherData);
      userData.message = pusherData.message;
      userData.created_at = pusherData.created_at;
      let allUserData = [...chatUsers];
      allUserData[selectedUserIndex] = { ...userData };
      setChatUsers(allUserData);
      setSelectedUser(userData);
    }
    let allUserData = [...chatUsers];
    allUserData.map((user, index) => {
      if (user.chat_id == pusherData.chat_id && selectedUser.chat_id != pusherData.chat_id) {
        user.unread_messages_count = Number(user.unread_messages_count) + 1;
      }
    });
    setChatUsers(allUserData);
    updateUI();
  }, [pusherData]);

  useEffect(() => {
    if (selectedUser !== "") {
      scrollsDown();
    }
  }, [uiCointer]);

  const scrollsDown = () => {
    var $container = $(".div__scroll");
    var height = $container[0].scrollHeight;
    $container.scrollTop(height * 10000);
  };

  const handleReadAllMessagesByPhoneNumber = () => {
    showLoader();
    let data = {
      mobile: selectedUser.mobile,
    };
    readAllMessagesByPhoneNumber(data, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        let allUserData = [...chatUsers];
        allUserData[selectedUserIndex].unread_messages_count = 0;
        setChatUsers(allUserData);
        updateUI();
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  useEffect(() => {
    if (selectedUser !== "") {
      scrollsDown();
      handleReadAllMessagesByPhoneNumber();
    }
  }, [selectedUser]);

  useEffect(() => {
    let channelName = "whatsapp";
    const pusher = new Pusher("e80f6a3fd9fdb15cd7f7", { cluster: "mt1" });
    let channel = pusher.channels.find(channelName);
    if (!channel) {
      channel = pusher.subscribe(channelName);
    }
    channel.bind("messages", function(data) {
      setPusherData(data);
    });
    handleGetAllChatUsers();
  }, []);

  const formatDate = (created_at) => {
    let date = new Date(created_at);
    const today = new Date();
    const dayOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    if (date.toDateString() === today.toDateString()) {
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (
      date.toDateString() ===
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1
      ).toDateString()
    ) {
      return "Yesterday";
    } else if (
      date >=
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
    ) {
      return dayOfWeek[date.getDay()];
    } else {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }
  };

  const filteredChatUsers = chatUsers.filter((user) => {
    return (
      user.name.toLowerCase().includes(searchText.toLowerCase()) ||
      user.mobile.toLowerCase().includes(searchText.toLowerCase())
      // JSON.stringify(user.messages)
      //   .toLowerCase()
      //   .includes(searchText.toLowerCase())
    );
  });

  return (
    <Container>
      <LeftSidebar>
        <Box display="flex" justifyContent="center" mt="8px" mb="20px">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 228,
              borderRight: "40px",
            }}
            className="MuiPaper-root1"
          >
            <IconButton
              type="button"
              sx={{ p: "10px", color: "#9393C1" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
            id="search_field__chat"
              sx={{ ml: 1, flex: 1, color: "#9393C1" }}
              placeholder="Search "
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </Paper>
        </Box>
        <UserList>
          <List>
            {filteredChatUsers.map((object, index) => (
              <>
                <ListItemButton
                  className=""
                  mt="30px"
                  onMouseEnter={() => {
                    handleUserHoveredEnter(index);
                  }}
                  onMouseLeave={() => {
                    handleUserHoveredLeave(index);
                  }}
                  onClick={() => {
                    setSelectedUser(filteredChatUsers[index]);
                    setSelectedUserIndex(index);
                  }}
                  style={
                    selectedUser.id === object.id
                      ? {
                          backgroundColor: "#1e2738",
                          color: "white",
                        }
                      : {}
                  }
                  sx={{
                    "&:hover": {
                      backgroundColor: "#33ECAE33", // Change this to the desired hover color
                    },
                  }}
                >
                  <ListItemIcon style={{ paddingRight: "10px" }}>
                    <img src={ChatUserIcon} width="60px" height="60px" />
                  </ListItemIcon>
                  <Box width="100%">
                    <Box
                      display="flex"
                      alignItems="end"
                      justifyContent="space-between"
                      mt="-9px"
                      style={
                        selectedUser.id === object.id
                          ? {
                              color: "white",
                            }
                          : {}
                      }
                    >
                      <Box className="chat__heading">{object.name}</Box>
                      <Box className="chat__time">
                        {formatDate(object.created_at)}
                      </Box>
                    </Box>

                    <Box display="flex" width="100%">
                      <Box className="text_chat_sm" mt="5px">
                        {object.message}
                      </Box>
                      {/* <Box display="flex" justifyContent="end" width="100%">
                        <Box>
                          <img
                            src={doubleTicksPNG}
                            alt="Double Ticks"
                            width="13"
                            height="13"
                          />
                        </Box>
                      </Box> */}
                      <Box display="flex" justifyContent="end" width="100%">
                        {object.unread_messages_count > 0 ? (
                          <Box className="chat_notif_con__chat">
                            {object.unread_messages_count}
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                </ListItemButton>
                {filteredChatUsers.length > index + 1 ? <Divider /> : null}
              </>
            ))}
          </List>
        </UserList>
      </LeftSidebar>

      {selectedUser === "" ? (
        <Body
          style={{
            backgroundColor: "#E7E7E7",
          }}
          className="empty_chat_screen__chat"
        >
          <Box>
            <Box className="display_center__chat">
              <img alt="" src={EmptyChatScreen} width="300px" height="300px" />
            </Box>
            <Box mt={2} className="text1__chat">
              No New Messages!
            </Box>
            <Box className="text2__chat">
              Your inbox is empty, start sending messages.
            </Box>
          </Box>
        </Body>
      ) : (
        <Body>
          <ChatHeader>
            <ListItem mt="30px">
              <ListItemIcon style={{ paddingRight: "10px" }}>
                <img src={ChatUserIcon} width="50px" height="50px" />
              </ListItemIcon>
              <Box width="100%">
                <Box
                  display="flex"
                  alignItems="end"
                  justifyContent="space-between"
                >
                  <Box className="chat__heading">
                    {selectedUser.name} - {selectedUser.mobile}
                  </Box>
                  <Box>
                    <heartIcon style={{ color: "1e2738" }} />
                  </Box>
                </Box>
                {/* <Box className="text_chat_sm" mt="10px">
                  <Box display="flex" alignItems="center">
                    <FiberManualRecordIcon fontSize="22" />
                    &nbsp;Online
                  </Box>
                </Box> */}
              </Box>
            </ListItem>
          </ChatHeader>

          <ChatBody className="div__scroll">
            {/* Replace this with your own list of messages */}

            <List
              ref={listRef}
              style={{ maxHeight: "100%", overflow: "auto" }}
              className="div__scroll"
            >
              <Messages user={user} selectedUser={selectedUser} />
            </List>
          </ChatBody>
          <ChatFooter>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                borderRight: "40px",
              }}
              className="MuiPaper-root1"
            >
              <IconButton
                type="button"
                sx={{ p: "10px", color: "#9393C1" }}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                value={messageText}
                sx={{ ml: 1, flex: 1, color: "#9393C1" }}
                placeholder="Search"
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    if (messageText != "") {
                      handleSendMessage(messageText);
                    }
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setMessageText(e.target.value);
                }}
              />
            </Paper>
            <ChatSendButton>
              <SendIcon
                onClick={() => {
                  if (messageText != "") {
                    handleSendMessage(messageText);
                  }
                }}
              />
            </ChatSendButton>
          </ChatFooter>
        </Body>
      )}
    </Container>
  );
};

const Messages = ({ user, selectedUser }) => {
  function formatTime1(time) {
    let date = new Date(time);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const second = 1000;
    const minute = 60 * second;
    const hour = 60 * minute;
    const day = 24 * hour;

    if (diff < second) {
      return "Now";
    } else if (diff < minute) {
      return `${Math.floor(diff / second)} sec ago`;
    } else if (diff < hour && diff >= minute * 60) {
      const hours = Math.floor(diff / hour);
      const minutes = Math.floor((diff - hours * hour) / minute);
      return `${hours} hr ${minutes} min ago`;
    } else if (diff < hour) {
      return `${Math.floor(diff / minute)} min ago`;
    } else if (diff < 2 * day && date.getDate() === now.getDate() - 1) {
      return "Yesterday";
    } else if (diff < 7 * day) {
      return `${Math.floor(diff / day)} days ago`;
    } else {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${month}/${day}/${year}`;
    }
  }

  const formatTime = (created_at) => {
    let date = new Date(created_at);
    const today = new Date();
    const dayOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    if (date.toDateString() === today.toDateString()) {
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (
      date.toDateString() ===
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1
      ).toDateString()
    ) {
      return "Yesterday";
    } else if (
      date >=
      new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
    ) {
      return dayOfWeek[date.getDay()];
    } else {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }
  };

  return (
    <>
      {selectedUser.messages.map((message, index) =>
        message.branch_id === "0" ? (
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} pt={2}>
              <Box>
                <Box className="chat_box__convers">{message.message}</Box>
                <Box className="chat_days">
                  {formatTime(message.created_at)}
                </Box>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              pt={2}
              pr={3}
              display="flex"
              justifyContent="end"
            >
              <Box>
                <Box className="chat_box_two__convers">{message.message}</Box>
                <Box
                  display="flex"
                  justifyContent="end"
                  className="chat_now__txt"
                >
                  {formatTime(message.created_at)}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )
      )}
    </>
  );
};

export default Chat;
