import { get, post, getWithParams, delte } from "./API";
export function getAllProductsOnline(data, callback) {
  getWithParams(
    { endPoint: "online/inventory/getAllOnlineInventoryProducts" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function getProductByIdOnline(data, callback) {
  getWithParams(
    { endPoint: "online/inventory/getAllOnlineInventoryProductsById" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function addProductOnline(data, callback) {
  post(
    { endPoint: "online/inventory/addOnlineInventoryProduct" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function editProductOnline(data, callback) {
  post(
    { endPoint: "online/inventory/editOnlineInventoryProduct" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function deleteProductOnline(data, callback) {
  delte(
    { endPoint: "online/inventory/deleteOnlineInventoryProduct" },
    data,
    function(response) {
      callback(response);
    }
  );
}
