import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { IoMdArrowDropleft } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import { styled } from "@mui/material/styles";

import {
  getVendorDetailsById,
  addTransaction,
  deleteTransaction,
} from "../../api/VendorAPI";
import CustomTable from "../../widgets/CustomTable";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import { ReactComponent as DialogCrossIcon } from "../../assets/SVG/DialogBoxDeleteIcon.svg";
import { ReactComponent as BalanceIcon } from "../../assets/SVG/BalanceIcon.svg";
// import { ReactComponent as DebitIcon } from "../../assets/SVG/DebitIcon.svg";
// import { ReactComponent as CreditIcon } from "../../assets/SVG/CreditIcon.svg";
import { ReactComponent as vendorkhataViewIcon } from "../../assets/SVG/VendorKhataViewIcon.svg";

import { ReactComponent as DebitIcon } from "../../assets/SVG/DebitSmallIcon.svg";
import { ReactComponent as CreditIcon } from "../../assets/SVG/CreditSmallIcon.svg";

import DeleteDialog from "../../widgets/DeleteDialog";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import SearchField from "../../widgets/SearchField";
import { debounce } from "lodash";
import "./VendorKhata.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SimpleMenu from "../../widgets/SimpleMenu";
import TopMidHeader from "../../widgets/TopMidHeader";
import SelectBank from "../../widgets/SelectBank";
import { getTodayDate, pad } from "../../utils/common";
import { Textfit } from "react-textfit";

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    minWidth: 30,
    align: "left",
  },
  {
    id: "vendor_name",
    label: "Name",
    minWidth: 30,
    align: "left",
  },
  {
    id: "vendor_shop_name",
    label: "Shop Name",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_amount",
    label: "Amount",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_date",
    label: "Date",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_description",
    label: "Description",
    minWidth: 30,
    align: "left",
  },
  {
    id: "cash_type",
    label: "Cash Type",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_type",
    label: "Status",
    minWidth: 30,
    align: "left",
  },
];

const options = [
  {
    icon: (
      <div>
        <vendorkhataViewIcon />
      </div>
    ),
    label: "View",
    method: "view",
    type: "icon",
  },
  {
    icon: (
      <div>
        <DeleteIcon />
      </div>
    ),
    label: "Delete",
    method: "delete",
    type: "icon",
  },
];

const transactionObject = {
  vendor_name: "",
  vendor_shop_name: "",
  transaction_amount: "",
  transaction_description: "",
  transaction_type: "",
  transaction_credit_order_id: "",
  vendor_id: "",
};

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
      height: "60px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
      height: "60px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
      height: "60px",
    },
  },
});
const CustomTextFieldM = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
      height: "103px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
      height: "103px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
      height: "103px",
    },
  },
});

function VendorKhata() {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    location,
    onlyXs,
    userObject,
  } = useContext(GlobalData);

  const [selectedCashType, setSelectedCashType] = useState("Shop Cash");

  const [allBanksData, setAllBanksData] = useState([]);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [vendor, setVendor] = useState("");
  const [transaction_id, setTransaction_id] = useState("");
  const [transactions, setTransactions] = useState([{}]);
  const [showForm, setShowForm] = useState(false);
  const [transactionData, setTransactionData] = useState(transactionObject);
  const [total_credit, setTotal_credit] = useState(0);
  const [total_debit, setTotal_debit] = useState(0);
  const [vendorDescription, setVendorDescription] = useState("");
  const [partnersCount, setPartnersCount] = useState([]);
  const [total_balance, setTotal_balance] = useState(0);
  const vendor_id = location.state.vendorData;
  const [transactionDate, setTransactionDate] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankId, setBankId] = useState("");
  const handlegetAllTransactions = (pn) => {
    const data = {
      vendor_id: vendor_id,
      per_page: 10,
      page: pn,
      search_text: searchText,
    };
    showLoader();
    getVendorDetailsById(data, (resp) => {
      if (resp.statusCode === 200) {
        setVendor(resp.successData.vendor);
        setTotal_credit(resp.successData.vendor.vendor_total_credit);
        setTotal_debit(resp.successData.vendor.vendor_total_debit);
        setTotal_balance(resp.successData.vendor.vendor_total_balance);
        setTransactionData({
          ...transactionData,
          vendor_name: resp.successData.vendor.vendor_name,
          vendor_id: resp.successData.vendor.id,
          vendor_shop_name: resp.successData.vendor.vendor_shop_name,
          transaction_amount: "",
          transaction_description: "",
        });
        setTransactionDate(null);
        if (pn === 1) {
          setTransactions(resp.successData.vendor.transactions);
          hideLoader();
        } else {
          setTransactions((dt) => {
            dt.push(...resp.successData.vendor.transactions);
            hideLoader();
            return dt;
          });
        }
        setLastPage(resp.successData.last_page);
      } else {
        setTransactionDate(null);
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const openDeleteProductDialog = (product) => {
    setTransaction_id(product.id);
    setDeleteProductDialog(true);
  };
  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };
  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);
  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const handleDeleteProduct = () => {
    showLoader();
    const tempdata = { vendor_id: vendor_id, transaction_id: transaction_id };

    deleteTransaction(tempdata, (resp) => {
      if (resp.statusCode === 200) {
        flashMessage("success", resp.message);
        updateDataUsingPageNumber();
        setDeleteProductDialog(false);
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        setDeleteProductDialog(false);
        hideLoader();
      }
    });
  };

  const openAddTransactionDialog = (value) => {
    setTransactionData({
      ...transactionData,
      transaction_type: value,
    });
    setTransactionDate(getTodayDate());
    setShowForm(true);
  };

  const closeTransactionDialog = () => {
    setShowForm(false);
    setTransactionDate(null);
  };
  const handleAddTransaction = () => {
    if (
      transactionData.transaction_amount === "" ||
      isNaN(transactionData.transaction_amount)
    ) {
      flashMessage("error", "Enter Amount in Numbers");
    } else {
      if (transactionDate == null) {
        flashMessage("error", "Please select date!");
        return 0;
      }

      if (allBanksData.length === 0 && selectedCashType === "Bank Cash") {
        flashMessage("error", "Please add bank first");
        return 0;
      }

      if (bankName == "" && selectedCashType === "Bank Cash") {
        flashMessage("error", "Please select bank first");
        return 0;
      }

      showLoader();
      let formData = new FormData();
      formData.append("vendor_name", transactionData.vendor_name);
      formData.append("vendor_id", transactionData.vendor_id);
      formData.append("vendor_shop_name", transactionData.vendor_shop_name);
      formData.append("transaction_amount", transactionData.transaction_amount);
      formData.append("transaction_description", vendorDescription);
      formData.append("transaction_type", transactionData.transaction_type);
      formData.append(
        "transaction_credit_order_id",
        transactionData.transaction_credit_order_id
      );
      formData.append("cash_type", selectedCashType);

      formData.append("bank_id", bankId);
      formData.append("bank_name", bankName);

      var date = new Date(transactionDate);
      formData.append(
        "transaction_date",
        date.getFullYear() +
          "-" +
          pad(date.getMonth() + 1) +
          "-" +
          pad(date.getDate())
      );

      addTransaction(formData, (resp) => {
        if (resp.statusCode === 200) {
          hideLoader();
          updateDataUsingPageNumber();
          setTransactionData({
            ...transactionData,
            transaction_amount: "",
            transaction_description: "",
          });
          setTransactionDate(null);
          setVendorDescription("");
          setShowForm(false);
        } else {
          flashMessage("error", resp.message);
          hideLoader();
        }
      });
    }
  };
  const handleTransactionInput = (e) => {
    const { name, value } = e.target;

    setTransactionData({
      ...transactionData,
      [name]: value,
    });
  };
  const changeOrderDescription = (e) => {
    setVendorDescription(e.target.value);
  };

  const handleVendorKhataView = (transection) => {
    if (transection.order !== null) {
      navigate("/orders/order-detail", {
        state: { orderData: transection.order, orderStatus: "All" },
      });
    }
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    handlegetAllTransactions(page);
  }, [page, searchText]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      handlegetAllTransactions(page);
    }
  };

  return (
    <>
      <DeleteDialog
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteProductDialog}
        handleRemove={handleDeleteProduct}
        title={"Remove this Transaction?"}
        sub_title={
          "You are about to remove a transaction. Click on the “Remove” button to remove it."
        }
      />
      {/*========================================================Add Transaction Dialog */}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={showForm}
        onClose={closeTransactionDialog}
      >
        <DialogTitle>Add New Transaction</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs className="padn_invoice__icon">
              <CustomTextField
                value={transactionData.transaction_amount}
                onChange={handleTransactionInput}
                name="transaction_amount"
                id="transaction_amount"
                key="transaction_amount"
                placeholder="Transaction Amount"
                className={"textField__AddInventory"}
                type="number"
              />
            </Grid>

            <Grid item xs>
              <SimpleMenu
                handleSetData={setSelectedCashType}
                data={["Bank Cash", "Shop Cash"]}
                selectedValue={selectedCashType}
                className={"bnkmnu_field__bank"}
                width={"268px"}
              />
            </Grid>
          </Grid>
          {selectedCashType === "Bank Cash" ? (
            <Grid container>
              <Grid item xs className="padn_invoice__icon">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="bnkmnu_field__bank"
                    value={transactionDate}
                    onChange={(newValue) => {
                      setTransactionDate(newValue);
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        error={false}
                        {...params}
                        placeholder={"mm/dd/yyyy"}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs>
                <SelectBank
                  setBankId={setBankId}
                  setBankName={setBankName}
                  bankName={bankName}
                  setAllBanksData={setAllBanksData}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs paddingRigh>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="bnkmnu_field__bank"
                    value={transactionDate}
                    onChange={(newValue) => {
                      setTransactionDate(newValue);
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        error={false}
                        {...params}
                        placeholder={"mm/dd/yyyy"}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          )}

          <Box>
            <CustomTextFieldM
              multiline
              rows={3}
              onChange={changeOrderDescription}
              value={vendorDescription}
              name="transaction_description"
              id="transaction_description"
              placeholder="Transaction Decription"
              className={"textField__AddInventory"}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="download_report_btn__orders"
            onClick={closeTransactionDialog}
          >
            Cancel
          </Button>
          <Button
            className="add_order_btn__orders"
            onClick={handleAddTransaction}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/* ========================================================================Main Page */}
      <Grid container height="100vh" className="padn_invoice__icon">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box height={smDown ? "30vh" : "20vh"}>
            <NavigationBar
              path={
                "Dashboard>Vendors Management>Vendors>" +
                transactionData.vendor_name +
                ">VendorKhata"
              }
              paddingBottom={1}
            />
            <TopMidHeader
              path={"vendors"}
              back={"Back"}
              title1={"Vendor Management"}
              name={transactionData.vendor_name}
              title2={"KHATA"}
            />
          </Box>

          <Grid
            container
            dispaly="flex"
            justifyContent="center"
            paddingTop={smDown ? "4px" : "5px"}
            mb={2}
          >
            <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={12}
              xs={12}
              paddingTop={smDown ? "15px" : "15px"}
            >
              <Grid container p={1} className="Box_background__vendor_stf">
                <Grid
                  item
                  className="box_img__vendor_stf"
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                >
                  <div className="balanc_icon_marg">
                    <BalanceIcon height="35" width="35" />
                  </div>
                </Grid>

                <Grid item paddingLeft={2} xl={9} lg={9} md={9} sm={9} xs={9}>
                  <Box>
                    <div className="Balance_vendors__khata_stf">
                      Total Balance
                    </div>
                    <div className="AED_vendors__khata_stf">
                      <Textfit mode="single" forceSingleModeWidth={false}>
                        {total_balance} {userObject.currency}
                      </Textfit>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={12}
              xs={12}
              paddingLeft={smDown ? "0" : "10px"}
              paddingTop={smDown ? "4px" : "15px"}
            >
              <Grid
                container
                className="Box_background__vendor_stf Box_background__vendor_cred_stf"
              >
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="2px"
                  className="box_img__vendor_stf"
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                >
                  <div className="balanc_icon_marg">
                    <CreditIcon width="35" height="35" />
                  </div>
                </Grid>

                <Grid item paddingLeft={2} xl={7} lg={7} md={7} sm={7} xs={7}>
                  <Box>
                    <div className="Balance_vendors__khata_stf">
                      Total Credit
                    </div>

                    <div className="AED_vendors__khata_stf">
                      <Textfit mode="single" forceSingleModeWidth={false}>
                        {total_credit} {userObject.currency}
                      </Textfit>
                    </div>
                  </Box>
                </Grid>
                <Grid
                  item
                  pt={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="container_add_btn_stf balanc_icon_marg"
                >
                  <div
                    className="add_Button_back_new"
                    onClick={() => {
                      openAddTransactionDialog("Credit");
                    }}
                  >
                    <AiOutlinePlus color={"#1E2738"} size={26} />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={12}
              xs={12}
              paddingLeft={smDown ? "0" : "10px"}
              paddingTop={smDown ? "4px" : "15px"}
            >
              <Grid container className="Box_background__vendor_stf">
                <Grid
                  item
                  className="box_img__vendor_stf"
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                >
                  <div className="balanc_icon_marg">
                    <DebitIcon width="35" height="35" />
                  </div>
                </Grid>

                <Grid item paddingLeft={1} xl={7} lg={7} md={7} sm={7} xs={7}>
                  <Box>
                    <div className="Balance_vendors__khata_stf">
                      Total Debit
                    </div>

                    <div className="AED_vendors__khata_stf">
                      <Textfit mode="single" forceSingleModeWidth={false}>
                        {total_debit} {userObject.currency}
                      </Textfit>
                    </div>
                  </Box>
                </Grid>

                <Grid
                  item
                  pt={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="container_add_btn balanc_icon_marg"
                >
                  {" "}
                  <div
                    className="add_Button_back_new"
                    onClick={() => {
                      openAddTransactionDialog("Debit");
                    }}
                  >
                    <AiOutlinePlus color={"#1E2738"} size={26} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid
            container
            dispaly="flex"
            justifyContent="center"
            paddingTop={smDown ? "4px" : "5px"}
          >
            <Grid
              item
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              paddingTop={smDown ? "15px" : "15px"}
            >
              <Grid container p={1} className="Box_background__vendor">
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="box_img__vendor"
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                >
                  <div className="total_balnac_top">
                    <BalanceIcon height="48" width="48" />
                  </div>
                </Grid>

                <Grid item paddingLeft={2} xl={9} lg={9} md={9} sm={9} xs={9}>
                  <Box>
                    <div className="Balance_vendors__khata">Total Balance</div>
                    <div className="AED_vendors__khata">
                      {total_balance} {userObject.currency}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              paddingLeft={smDown ? "0" : "10px"}
              paddingTop={smDown ? "4px" : "15px"}
            >
              <Grid
                container
                p={1}
                backgroundColor="#E4F3F1"
                className="Box_background__vendor"
              >
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="box_img__vendor"
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                >
                  <div style={{ marginTop: "3px" }}>
                    <CreditIcon width="48" height="48" />
                  </div>
                </Grid>

                <Grid item paddingLeft={2} xl={7} lg={7} md={7} sm={7} xs={7}>
                  <Box>
                    <div className="Balance_vendors__khata">Total Credit</div>
                    <div className="AED_vendors__khata">
                      {total_credit} {userObject.currency}
                    </div>
                  </Box>
                </Grid>
                <Grid
                  item
                  pt={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="container_add_btn"
                  style={{ marginTop: "5px" }}
                >
                  <div
                    className="add_Button_back"
                    onClick={() => {
                      openAddTransactionDialog("Credit");
                    }}
                  >
                    <AiOutlinePlus color={"#1E2738"} size={35} />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              paddingLeft={smDown ? "0" : "10px"}
              paddingTop={smDown ? "4px" : "15px"}
            >
              <Grid
                container
                p={1}
                backgroundColor="#FFE8DE"
                className="Box_background__vendor"
              >
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className="box_img__vendor"
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                >
                  <div style={{ marginTop: "3px" }}>
                    <DebitIcon width="48" height="48" />
                  </div>
                </Grid>

                <Grid item paddingLeft={1} xl={7} lg={7} md={7} sm={7} xs={7}>
                  <Box>
                    <div className="Balance_vendors__khata">Total Debit</div>
                    <div className="AED_vendors__khata">
                      {total_debit} {userObject.currency}
                    </div>
                  </Box>
                </Grid>

                <Grid
                  item
                  pt={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="container_add_btn"
                  style={{ marginTop: "5px" }}
                >
                  <div
                    className="add_Button_back"
                    onClick={() => {
                      openAddTransactionDialog("Debit");
                    }}
                  >
                    <AiOutlinePlus color={"#1E2738"} size={35} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid
            xl={12}
            lg={12}
            md={smDown ? 7 : 5}
            sm={smDown ? 7 : 5}
            xs={12}
            item
            className="padn_invoice__icon"
            marginBottom="5px"
            marginTop="8px"
            my={2}
            display="flex"
            justifyContent="center"
          >
            <SearchField
              onChange={updateProductList}
              tableDataCount={transactionData.length}
              totalAvailableDataCount={partnersCount}
            />
          </Grid>
          <Grid container>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <CustomTable
                view={handleVendorKhataView}
                // edit={handleEditProduct}
                delete={openDeleteProductDialog}
                options={options}
                columns={columns}
                data={transactions}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default VendorKhata;
