import React, { useState, useEffect, useContext } from "react";
import { GlobalData } from "../../App";
import "./LandingPage.css";
import { Box, Grid, Button, IconButton } from "@mui/material";
import DetailBox from "./Components/DetailBox";
import LandingFooter from "./Components/LandingFooter";
import DetailBoxFooter from "./Components/DetailBoxFooter";
import MainFooter from "./Components/MainFooter";
import Features from "./Components/Features";
import { ReactComponent as Unsplash } from "../../assets/SVG/LandingUnsplash.svg";
import { ReactComponent as Notion } from "../../assets/SVG/LandingNotion.svg";
import { ReactComponent as Intercom } from "../../assets/SVG/LandingIntercom.svg";
import { ReactComponent as Descript } from "../../assets/SVG/LandingDescript.svg";
import { ReactComponent as Grammarly } from "../../assets/SVG/LandingGrammarly.svg";
import LandingHeader from "./Components/LandingHeader";
import FeaturePoints from "./Components/FeaturePoints";
import Services from "../Home/Components/Services";
import Overview from "./Components/Overview";
import Benefit from "./Components/Benefit";
import LastFooter from "./Components/LastFooter";
const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];
const LandingPage = () => {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
    mdDown,
    onlyMd,
    baseUrl,
    location,
  } = useContext(GlobalData);
  return (
    <>
      <LandingHeader />
      <FeaturePoints />
      <MainFooter />
      <Services />
      <DetailBox />
      <DetailBoxFooter />
      <Features />
      <Overview />
      <LandingFooter />
      <Benefit />
      <LastFooter />
    </>
  );
};

export default LandingPage;
