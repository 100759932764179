import React from "react";
import { Grid, Box } from "@mui/material";
import { OrderStatus } from "../assets/SVG/DashboardSVG";
import { Textfit } from "react-textfit";

function DashboardSecndOrdStatus({ ordersStatusText, ordersCount,OrderIcon }) {
  return (
    <>
      <Box className="scdbone_box__dsh" marginBottom="6px">
        <Grid container className="scdbone_box__dsh_pading">
          <Grid item padding="5px" paddingRight="10px">
            {OrderIcon}
          </Grid>
          <Grid item padding="5px" display="flex" alignItems="center">
            <Box
              marginTop="-10px"
              fontSize="20px"
              color="rgba(0,0,0,0.8)"
              display="flex"
              alignItems="center"
            >
              <span
                style={{
                  fontSize: "14px",
                  color: "#1E2738",
                }}
              >
                {ordersStatusText}
              </span>
              &nbsp; - &nbsp;
              <Textfit max={18} style={{ width: "120px" }} mode="single">
                {ordersCount}
              </Textfit>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DashboardSecndOrdStatus;
