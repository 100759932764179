import {
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import NoOrders from "../../assets/Images/EmptyScreenImage/NoOrders.png";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { ReactComponent as EditIcon } from "../../assets/SVG/EditIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import NavigationBar from "../NavigationBar";
import MainTitle from "../../widgets/MainTitle";
import { GlobalData } from "../../App";
import SearchField from "../../widgets/SearchField";
import { debounce } from "lodash";
import AddNewButton from "../../widgets/AddNewButton";
import DateFilter from "../../widgets/DateFilter";
import CustomTable from "../../widgets/CustomTable";
import React, { useContext, useState, useEffect, useMemo } from "react";
import TableScrollbar from "react-table-scrollbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Menu, MenuItem, Button, Checkbox } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AnimatePresence, motion } from "framer-motion";
import ProtectComponent from "../../middleware/ProtectComponent";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getOrderById } from "../../api/OrderAPI";
import { FcPaid } from "react-icons/fc";
import debitCard from "../../assets/Images/debit-card.png";
import creditCard from "../../assets/Images/credit-card.png";
import cash from "../../assets/Images/cash.png";
// import { encrypt, decrypt } from "../middleware/Encryption";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getAllActivityLog } from "../../api/ActivityLogsAPI"
import NoActivityLog from "../../assets/Images/EmptyScreenImage/NoActivityLog.png";
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      paddingTop: "-10px",
      height: "40px",
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      paddingTop: "-10px",
      height: "40px",
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      height: "40px",
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const options = [
  {
    icon: <EyeIcon />,
    label: "View",
    method: "view",
    type: "icon",
  },

  {
    icon: <DeleteIcon />,
    label: "Delete",
    method: "delete",
    type: "icon",
  },
];

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    minWidth: 30,
    align: "left",
  },
  {
    id: "created_at",
    label: "Date & Time",
    type: 'date&time',
    align: "center",
  },
  {
    id: "endpoint",
    label: "Action",
    minWidth: 30,
    align: "center",
  },

];
const data = [
  {
    Date_Time: "05/02/2022 , 06:10 PM",
    action: "Inventory Added",
  
  },
  {
    Date_Time: "05/02/2022 , 06:10 PM",
    action: "Inventory Added",
   
  },
  {
    Date_Time: "05/02/2022 , 06:10 PM",
    action: "Inventory Added",

  },
];

function ActivityLog() {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
  } = useContext(GlobalData);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [activityDate, setActivityDate] = useState("This Month");
  const [activityLogData, setActivityLogData] = useState([]);

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateNotesList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);
 
  useEffect(()=>{
    HandleGetAllActivity(page) 
  },[page, searchText])

  const HandleGetAllActivity = ()=>{
     let data = {};
     showLoader();
     getAllActivityLog(data, (resp)=>{
      if (resp.statusCode === 200){
        setActivityLogData(resp.successData.logs);
        hideLoader();
        console.log(resp.successData.logs);
      }
     })

  }

    return (
    <>
      <Grid id="header__orderm" container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <NavigationBar
            path={"Dashboard>Activity Logs Management>Activity Logs"}
            paddingBottom={3}
          />

          <Grid container marginBottom={2}>
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingRight={onlyXs ? "5px" : "10px"}
            >
              <MainTitle title={"Activity Logs"} />
            </Grid>
          </Grid>

          <Grid container marginBottom={2}>
            <Grid
              xl={6}
              lg={6}
              md={smDown ? 7 : 5}
              sm={smDown ? 7 : 5}
              xs={12}
              item
              className="padn_invoice__icon"
            >
              <SearchField id="search_field_notes" onChange={updateNotesList} />
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={smDown ? 5 : 7}
              sm={smDown ? 5 : 7}
              xs={12}
              item
              display="flex"
              justifyContent={onlyXs ? "center" : "end"}
              paddingTop={onlyXs ? 1 : 0}
              paddingRight={smDown ? "0px" : "10px"}
            >
              <DateFilter
                id={"date_filter_notes"}
                className={"download_report_btn__orders"}
                setDateObject={setActivityDate}
                dateObject={activityDate}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <CustomTable
                columns={columns}
                options={options}
                data={activityLogData}
                image={NoActivityLog}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ActivityLog;
