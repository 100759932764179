import React, { useContext, useState, useEffect } from "react";
import { Box, Grid, Button } from "@mui/material";
import "./ViewInvoice.css";
import { IoMdArrowDropleft } from "react-icons/io";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import ProtectComponent from "../../middleware/ProtectComponent";

function ViewInvoice(props) {
  const {
    smDown,
    navigate,
    baseUrl,
    location,
    showLoader,
    hideLoader,
    query,
    queryx,
    onlyXs,
    userObject
  } = useContext(GlobalData);

  const order = location.state.orderData;
  //const orderStatus = location.state.orderStatus;

  useEffect(() => {
    showLoader();
    setTimeout(() => {
      hideLoader();
    }, 500);
  }, []);

  const general = (product) => {
    if (product.category === "Mobile" || product.category === "Laptop") {
      return true;
    } else {
      return false;
    }
  };

  const handleViewVendor = () => {
    // navigate("/vendors/view-khata", {
    //   state: { vendorData: order.vendor_id },
    // });
  };

  const handleBackToOrders = () => {
    if (location.state.path === "/staff/orders") {
      navigate("/staff/orders", {
        state: {
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/invoices");
    }
  };

  function getDate(created_at) {
    let date = new Date(created_at);
    var d = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var strDate = d + "-" + month + "-" + year;
    return strDate;
  }

  return (
    <>
      <NavigationBar
        path={`Dashboard>Invoice Management>Invoices>Invoice Details>${
          order.invoice_code
        }`}
        paddingBottom={1}
      />
      <Grid
        container
        className="container__inven_view"
        style={{ paddingRight: `${onlyXs ? 5 : 5}px` }}
        paddingBottom={3}
      >
        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          item
          paddingTop={smDown && 3}
          style={smDown ? { zIndex: 1 } : {}}
        >
          <Box className="container__viewinv">
            <Box className="header__viewinv">
              <Grid display="flex" justifyContent="center" container mb={2}>
                <Grid
                  className="header__inven_view"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  item
                >
                  <div
                    className="header_back_btn__inven_view"
                    onClick={handleBackToOrders}
                  >
                    <IoMdArrowDropleft
                      className="add__icon_orders_sty"
                    />
                    Back
                  </div>
                  <div className="header_text__inven_view">
                    Invoice Management {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                    <span style={smDown ? { fontSize: "14px" } : {}}>
                      Invoice Details
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box className="body__viewinv">
              <Grid container>
                <Grid
                  className="card__inven_view"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  item
                  paddingLeft={query(10, 4, 0.5)}
                  paddingRight={query(10, 4, 0.5)}
                  paddingTop="30px"
                  paddingBottom="60px"
                >
                  <Grid
                    container
                    className="page__inven_view"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    paddingBottom="5px"
                    paddingLeft={query(5, 4, 1)}
                    paddingRight={query(5, 4, 1)}
                  >
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box
                        className="order_ti__ordview"
                        justifyContent={queryx(
                          "start",
                          "start",
                          "start",
                          "space-between"
                        )}
                      >
                        {/* <Box
                          paddingTop="20px"
                          width="160px"
                          paddingRight={queryx("40px", "40px", "40px", "40px")}
                        >
                          <Box className="product_title_text__ordview">
                            Staff Member Name
                          </Box>
                          <Box className="product_text__ordview">
                            {order.user.name}
                          </Box>
                        </Box> */}
                        <Box
                          paddingTop="20px"
                          width="120px"
                          paddingRight={queryx("40px", "40px", "40px", "40px")}
                        >
                          <Box className="product_title_text__ordview">
                            Invoice Code
                          </Box>
                          <Box className="product_text__ordview">
                            {order.invoice_code}
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="130px"
                          paddingRight={queryx("40px", "40px", "40px", 0)}
                        >
                          <Box className="product_title_text__ordview">
                            Customer Name
                          </Box>
                          <Box className="product_text__ordview">
                            {order.customer_name}
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="132px"
                          paddingRight="40px"
                        >
                          <Box className="product_title_text__ordview">
                            Customer Phone
                          </Box>
                          <Box className="product_text__ordview">
                            {order.customer_phone}
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="90px"
                          paddingRight={queryx("40px", "40px", "40px", "40px")}
                        >
                          <Box className="product_title_text__ordview">
                            Sub Total
                          </Box>
                          <Box className="product_text__ordview">
                            {order.sub_total} {userObject.currency}
                          </Box>
                        </Box>
                        {order.status === "Returned" ? (
                          <Box
                            paddingTop="20px"
                            width="120px"
                            paddingRight={queryx(
                              "40px",
                              "40px",
                              "40px",
                              "40px"
                            )}
                          >
                            <Box className="product_title_text__ordview">
                              Return Amount
                            </Box>
                            <Box className="product_text__ordview">
                              {order.return_order_balance} {userObject.currency}
                            </Box>
                          </Box>
                        ) : null}

                        <Box
                          paddingTop="20px"
                          width="90px"
                          paddingRight={queryx("40px", "40px", "40px", "40px")}
                        >
                          <Box className="product_title_text__ordview">
                            Discount
                          </Box>
                          <Box className="product_text__ordview">
                            {order.order_discount} {userObject.currency}
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="120px"
                          paddingRight={queryx("40px", "40px", "40px", "10px")}
                        >
                          <Box className="product_title_text__ordview">Tax</Box>
                          <Box className="product_text__ordview">
                            {order.tax_amount} {userObject.currency}{" "}
                            {order.tax.length > 3 ? order.tax * 100 : order.tax}
                            %
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="108px"
                          paddingRight={queryx("40px", "40px", "40px", "25px")}
                        >
                          <Box className="product_title_text__ordview">
                            Total Amount
                          </Box>
                          <Box className="product_text__ordview">
                            {order.total} {userObject.currency}
                          </Box>
                        </Box>
                        <Box paddingTop="20px" width="90px" paddingRight="40px">
                          <Box className="product_title_text__ordview">
                            Order Date
                          </Box>
                          <Box className="product_text__ordview">
                            {order.order_date}
                          </Box>
                        </Box>

                        <Box
                          paddingTop="20px"
                          width="115px"
                          paddingRight={queryx("40px", "40px", "40px", "20px")}
                        >
                          <Box className="product_title_text__ordview">
                            Total Products
                          </Box>
                          <Box className="product_text__ordview">
                            {order.quantity}
                          </Box>
                        </Box>
                        <Box paddingTop="20px" width="90px" paddingRight="40px">
                          <Box className="product_title_text__ordview">
                            Order Type
                          </Box>
                          <Box className="product_text__ordview">
                            {order.order_type}
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="100px"
                          paddingRight={queryx("40px", "40px", "40px", "34px")}
                        >
                          {order.tre_no !== "" &&
                          order.tre_no !== null &&
                          order.tre_no !== "null" &&
                          order.tre_no.length > 4 && (
                            <Box
                             
                              width="60px"
                              paddingRight={queryx(
                                "40px",
                                "40px",
                                "40px",
                                "34px"
                              )}
                            >
                              <Box className="product_title_text__ordview">
                                TRE No.
                              </Box>
                              <Box className="product_text__ordview">
                                {order.tre_no}
                              </Box>
                            </Box>
                          )}
                        </Box>

                       
                     
                      </Box>

                      <hr className="hr_margn_opacty" />
                      <Grid container>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid container className="padng_global_projct">
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            {order.order_products.map((product, pio) => (
                               
                                    <>
                                      {pio === 0 && (
                                        <Grid
                                          container
                                          paddingTop="20px"
                                          paddingBottom="20px"
                                          paddingLeft="5px"
                                          className="product_text__ordview"
                                        >
                                          Products
                                        </Grid>
                                      )}
                                      <Box
                                        className="bg__ordview"
                                        style={{
                                          backgroundColor: `${
                                            product.order_returned === 1
                                              ? "rgb(255,0,0,0.3)"
                                              : ""
                                          }`,
                                        }}
                                      >
                                        <Box
                                          paddingLeft="5px"
                                          className="order_ti__ordview"
                                          justifyContent={queryx(
                                            "start",
                                            "start",
                                            "start",
                                            "space-between"
                                          )}
                                        >
                                          
                                          
                                          <Box
                                            paddingTop="3px"
                                            width="160px"
                                            paddingRight={queryx(
                                              "40px",
                                              "40px",
                                              "40px",
                                              "10px"
                                            )}
                                            alignSelf="center"
                                          >
                                            <Box className="product_title_text__ordview">
                                              Product Name
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {product.name}
                                            </Box>
                                          </Box>
                                          <Box
                                            paddingTop="3px"
                                            width="160px"
                                            paddingRight={queryx(
                                              "40px",
                                              "40px",
                                              "40px",
                                              "10px"
                                            )}
                                            alignSelf="center"
                                          >
                                            <Box className="product_title_text__ordview">
                                              Product Quantity
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {product.quantity}
                                            </Box>
                                          </Box>
                                          <Box
                                            paddingTop="3px"
                                            width="160px"
                                            paddingRight={queryx(
                                              "40px",
                                              "40px",
                                              "40px",
                                              "10px"
                                            )}
                                            alignSelf="center"
                                          >
                                            <Box className="product_title_text__ordview">
                                              Product Price
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {product.total_order_price}
                                            </Box>
                                          </Box>
                                        </Box>

                                       
                                      </Box>
                                      <br />
                                    </>
                                  
                               
                              ))}
                              <br />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ViewInvoice;
