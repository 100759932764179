import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Menu,
  ButtonGroup,
} from "@mui/material";
import "./AddInvoice.css";
import { IoMdArrowDropleft } from "react-icons/io";
import { styled } from "@mui/material/styles";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import AutoScrollContainer from "auto-scroll-container";
import ProductMultiLevelMenu from "../../widgets/ProductMultiLevelMenu";
import { orderInvoice } from "../../api/ReportAPI";
import { createnewInvoice } from "../../api/InvoiceAPI";
import { registerVendor } from "../../api/VendorAPI";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FcRating, FcPaid } from "react-icons/fc";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as GiftRedIcon } from "../../assets/SVG/GiftRedIcon.svg";
import { ReactComponent as SquareIcon } from "../../assets/SVG/SquareAddIconDark.svg";
import { getTodayDate, pad } from "../../utils/common";
import Autocomplete from "@mui/material/Autocomplete";
import { COUNTRIES } from "../../constants/Constants";
import { debounce } from "lodash";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const orderObject = {
  order_type: "Cash",
  tre_no: 0,
  invoice_type: "",
  invoice_code: 0,
  invoice_code_type: "",
  customer_name: "",
  customer_phone: "",
  order_date: "",
  order_discount: "",
  tax: 0,
  tax_amount: 0,
  sub_total: "",
  return_order_balance: 0,
  total: "",
  order_products: [],
};
const productObject = {
  name: "",
  description: "",
  order_price: "",
  quantity: "",
  total_order_price: "",
};

//   }
// const productObject = {
//   order: "0",
//   product: "",
//   variant: "",
//   order_price: 0,
//   order_quantity: 1,
//   total_order_price: 0,
//   product_imei: "",
//   product_description: "",
//   gifted: false,
// };

// Text Field Styling

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});
const CustomTextFieldForMobile1 = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "10px 0px 0px 10px",
      borderRight: "none",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "10px 0px 0px 10px",
      borderRight: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "10px 0px 0px 10px",
      borderRight: "none",
    },
  },
});

const CustomTextFieldForMobile2 = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "0px 10px 10px 0px",
      borderLeft: "none",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "0px 10px 10px 0px",
      borderLeft: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "0px 10px 10px 0px",
      borderLeft: "none",
    },
  },
});

function AddInvoice(props) {
  const {
    flashMessage,
    smDown,
    query,
    navigate,
    showLoader,
    hideLoader,
    baseUrl,
    location,
    userObject,
  } = useContext(GlobalData);
  const [countryCodeText, setCountryCodeText] = useState("");
  const [userCountry, setUserCountry] = useState(COUNTRIES[0]);

  const [order, setOrder] = useState(orderObject);

  const [disableDiscount, setDisableDiscount] = useState();

  const [orderProducts, setOrderProducts] = useState([]);
  const [orderProductsCount, setOrderProductsCount] = useState(0);
  const [product, setProduct] = useState(productObject);
  const [invoiceCodeDropDown, setInvoiceCodeDropDown] = useState(null);
  const isInvoiceCodeDropDownOpen = Boolean(invoiceCodeDropDown);

  const [tax, setTax] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  const [returnOrderBalance, setReturnOrderBalance] = useState(0);
  const [returnOrderTax, setReturnOrderTax] = useState(0);
  const [orderDate, setOrderDate] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);

  const [orderHasGift, setOrderHasGift] = useState(false);
  const [previousInvoiceTypeValue, setPreviousInvoiceTypeValue] = useState("");

  const [isProductSelected, setIsProductSelected] = useState(false);
  const [quantityGreaterThanError, setQuantityGreaterThanError] = useState(
    false
  );
  const [orderPriceSmallerThanError, setOrderPriceSmallerThanError] = useState(
    false
  );

  // Input Change Handling Methods

  const handleOrderInputChange = (e) => {
    const { name, value } = e.target;
    setOrder({
      ...order,
      [name]: value,
    });
  };

  const handleProductInputChange = (e) => {
    console.log("LOging", e.target.value);
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };

  // Invoice Code Drop Down Methods

  const handleInvoiceCodeDropDownOpen = (event) => {
    if (returnOrderBalance + returnOrderTax === 0) {
      setInvoiceCodeDropDown(event.currentTarget);
    }
  };

  const handleInvoiceCodeDropDownClose = (value) => {
    setInvoiceCodeDropDown(null);
    setOrder({
      ...order,
      ["invoice_code_type"]: value,
    });
  };

  // *** Create Order Methods ***
  // #########################################################################

  const handleCreateOrder = () => {
    if (orderProducts.length === 0) {
      flashMessage("error", "Please Add Service");
      return 0;
    } else {
      showLoader();
      let formData = new FormData();
      formData.append("order_type", order.order_type);
      formData.append("invoice_type", order.invoice_type);
      formData.append("tre_no", order.tre_no);

      // if (order.invoice_code_type !== "system_generated_code") {
      //   formData.append("invoice_code_type", "manual");
      // } else {
      //   formData.append("invoice_code_type", order.invoice_code_type);
      // }
      formData.append("invoice_code", order.invoice_code);
      formData.append("customer_name", order.customer_name);
      formData.append("customer_phone", "+" + order.customer_phone);
      formData.append("sub_total", subTotal);
      formData.append("total", totalOrderPrice);
      formData.append("tax", order.tax);

      if (orderProducts.length >= 0) {
        formData.append("order_products", JSON.stringify(orderProducts));
      }
      var date = new Date(orderDate);
      formData.append(
        "order_date",
        date.getFullYear() +
          "-" +
          pad(date.getMonth() + 1) +
          "-" +
          pad(date.getDate())
      );

      formData.append("order_discount", discountAmount);
      formData.append(
        "return_order_balance",
        Number(order.return_order_balance)
      );
      formData.append("tax_amount", Number(order.tax_amount));
      // if (location.state.path === "/staff/orders") {
      //   formData.append("staff_user_id", location.state.memberData.user_id);
      // }

      // if (location.state.order !== undefined) {
      //   formData.append(
      //     "return_order_balance",
      //     returnOrderBalance + returnOrderTax
      //   );
      //   formData.append("returnOrder", JSON.stringify(location.state.order));
      //   formData.append(
      //     "returnOrderProducts",
      //     JSON.stringify(location.state.returnOrderProducts)
      //   );
      //   formData.append("order_id", JSON.stringify(location.state.order_id));
      // }

      // let taxAmount = (Number(subTotal) - Number(discountAmount)) * tax;

      // formData.append(
      //   "total_without_tax",
      //   Number(subTotal) - (Number(discountAmount) + Number(returnOrderBalance))
      // );

      // formData.append(
      //   "total_tax_amount",
      //   Number(taxAmount) - Number(returnOrderTax)
      // );

      if (totalOrderPrice >= 0) {
        createnewInvoice(formData, (resp) => {
          if (resp.statusCode === 200) {
            console.log(resp.successData);
            //handlePrintSingleOrder("public/" + resp.successData.file_path);
            window.open(baseUrl + "public/" + resp.successData.file_path, "_blank");
            navigate("/invoices", {
              state: {
                path: location.pathname,
              },
            });
            hideLoader();
            // if (location.state.path === "/staff/orders") {
            //   navigate("/staff/orders", {
            //     state: {
            //       path: location.pathname,
            //       memberData: location.state.memberData,
            //     },
            //   });
            // } else {hideLoader();
            //   navigate("/invoices", {
            //     state: {
            //       path: location.pathname,
            //     },
            //   });
            // }
            flashMessage("success", resp.message);
          } else {
            hideLoader();
            flashMessage("error", resp.message);
          }
        });
      } else {
        hideLoader();
        flashMessage("error", "Negative order total amount is not allowed!");
      }
    }
  };

  const handlePrintSingleOrder = (order) => {
    showLoader();
    let formData = new FormData();
    formData.append("orders", JSON.stringify([order]));
    orderInvoice(formData, (resp) => {
      if (resp.statusCode === 200) {
        console.log("this is success", resp);
        window.open(baseUrl + "public/" + resp.successData.file_path, "_blank");
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  /* Get All Inventory */

  // Use Effect

  useEffect(() => {
    let total = Number(product.quantity) * product.order_price;
    setProduct({
      ...product,
      ["total_order_price"]: total,
    });
  }, [product.quantity, product.order_price]);

  // useEffect(() => {
  //   if (Number(product.order_price) < Number(product.variant.min_sale_price)) {
  //     setOrderPriceSmallerThanError(true);
  //   } else {
  //     setOrderPriceSmallerThanError(false);
  //   }
  // }, [product.order_price]);

  // Add Product to Products and create Empty Product Fields

  const handleAddProduct = () => {
    if (product.name === "") {
      flashMessage("error", "Please Add Service");
    }
    //  else if (product.variant === "") {
    //   flashMessage("error", "Please Select Product Variant");
    // }
    else if (product.order_price === "") {
      flashMessage("error", "Please Enter Product Price");
    } else {
      if (product.quantity < 1) {
        setProduct({
          ...product,
          ["quantity"]: 1,
        });
      }
      let productsData = orderProducts;
      productsData.push(product);
      let total =
        Number(product.order_price) *
        Number(product.quantity == 0 ? 1 : product.quantity);
      // setProduct({
      //   ...product,
      //   ["total_order_price"]: total,
      // });
      setTimeout(() => {
        console.log("Product Pricing", product);
      }, 2000);

      setSubTotal(Number(subTotal) + total);

      setOrderProducts(productsData);
      setProduct(productObject);
      console.log("Product", orderProducts);
    }
  };

  // Delete Product

  const handleDeleteProduct = (id) => {
    if (orderProducts[id].gifted === 1 || orderProducts[id].gifted) {
      setDiscountAmount((amount) => {
        amount = amount - orderProducts[id].total_order_price;
        if (amount === 0 && disableDiscount) {
          setDisableDiscount(false);
        }
        return amount;
      });
      setOrder({
        ...order,
        ["invoice_code_type"]: previousInvoiceTypeValue,
      });
      setOrderHasGift(false);
    }
    setOrderProducts((current) =>
      current.filter((value, index) => {
        return index !== id;
      })
    );
    let total =
      Number(orderProducts[id].order_price) *
      Number(orderProducts[id].quantity);
    setSubTotal(subTotal - total);
  };

  // Edit Product

  const handleEditProduct = (id) => {
    if (orderProducts[id].gifted === 1 || orderProducts[id].gifted) {
      setDiscountAmount((amount) => {
        amount = amount - orderProducts[id].total_order_price;
        if (amount === 0 && disableDiscount) {
          setDisableDiscount(false);
        }
        return amount;
      });
      setOrder({
        ...order,
        ["invoice_type"]: previousInvoiceTypeValue,
      });
      setOrderHasGift(false);
    }
    setIsProductSelected(true);

    let total =
      Number(orderProducts[id].order_price) *
      Number(orderProducts[id].quantity);
    setSubTotal(subTotal - total);
    setProduct(orderProducts[id]);
    setOrderProducts((current) =>
      current.filter((value, index) => {
        return index !== id;
      })
    );
  };

  // Add Selected Product to Product From Product Multi Level Menu Component

  const addSelectedProduct = (p, v, i) => {
    setProduct({
      ...product,
      ["product"]: p,
      ["variant"]: v,
      ["product_imei"]: i,
      ["order_price"]: v.sale_price,
      ["order_quantity"]: 1,
    });
  };

  const handleBackToOrders = () => {
    if (location.state.path === "/staff/orders") {
      navigate("/staff/orders", {
        state: {
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/invoices");
    }
  };

  //

  useEffect(() => {
    setOrderDate(getTodayDate());
    //   if (location.state.order !== undefined) {
    //     setOrder({
    //       ...order,
    //       ["order_type"]: location.state.order.order_type,
    //       ["invoice_code"]: location.state.order.invoice_code,
    //       ["tre_no"]: location.state.order.tre_no,
    //       ["invoice_type"]: location.state.order.invoice_type,
    //       ["customer_name"]: location.state.order.customer_name,
    //       ["customer_phone"]: location.state.order.customer_phone,
    //       ["order_date"]: getTodayDate(),
    //       ["invoice_code_type"]: "manual",
    //     });
    //     setReturnOrderBalance(location.state.returnOrderBalance);
    //     setReturnOrderTax(location.state.returnOrderTax);
    //   } else {
    //     setOrder({
    //       ...order,
    //       ["order_date"]: getTodayDate(),
    //     });
    // }
    //  setTimeout(()=>{  console.log("they called me",order.order_date)},5000)
  }, []);

  useEffect(() => {
    if (order.invoice_type === "With VAT") {
      let totalAmount = subTotal - discountAmount - order.return_order_balance;
      console.log("Total Price", totalAmount);
      setTotalOrderPrice(totalAmount + Number(order.tax_amount));
      setOrder({
        ...order,
        ["tax_amount"]: (
          (subTotal - discountAmount - order.return_order_balance) *
          (Number(order.tax) / 100)
        ).toFixed(2),
      });
      setTax(order.tax);
      // console.log("Total Price",totalOrderPrice)
    } else {
      setTotalOrderPrice((total) => {
        let totalAmount =
          subTotal - discountAmount - order.return_order_balance;
        total = totalAmount + 0;
        //  total = totalAmount - (returnOrderBalance + returnOrderTax);
        return total;
      });
      setOrder({
        ...order,
        ["tax_amount"]: 0,
        ["tax"]: 0,
        ["tre_no"]: 0,
      });
      // setOrder({
      //   ...order,
      //     ["tax"]: 0
      //   });
      setTax(0);
    }
  }, [
    subTotal,
    order.return_order_balance,
    order.invoice_type,
    discountAmount,
  ]);

  useEffect(() => {
    //  console.log("Total Price",totalOrderPrice)
    setTotalOrderPrice((total) => {
      let totalAmount = subTotal - discountAmount - order.return_order_balance;
      total = totalAmount + Number(order.tax_amount);
      //  total = totalAmount - (returnOrderBalance + returnOrderTax);
      return total;
    });
    setOrder({
      ...order,
      ["tax_amount"]: (
        (subTotal - discountAmount - order.return_order_balance) *
        (Number(order.tax) / 100)
      ).toFixed(2),
    });
  }, [order.tax_amount, order.tax]);

  const filterOptions = (options, { inputValue }) => {
    return options.filter(
      (option) =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.code.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <Grid container height="100vh" className="padn_invoice__icon">
      {/* START ******************************* Container Column ******************************************** */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {/* START ******************************* 
                          Header Box that includes header and navigation bar

        ******************************************** */}

        <Box height={smDown ? "30vh" : "20vh"}>
          {/* START ******************************* Navigation Bar ******************************************** */}

          <NavigationBar
            path={"Dashboard>Invoices and Services Management>Invoices>Add New"}
            paddingBottom={1}
          />

          {/* END ******************************* Navigation Bar ******************************************** */}

          {/* START ******************************* Header Row ******************************************** */}

          <Grid display="flex" justifyContent="center" container mb={2} mt={2}>
            <Grid
              className="header__ai"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
            >
              <div className="header_back_btn__ai" onClick={handleBackToOrders}>
                <IoMdArrowDropleft
                  style={{ marginBottom: "-5px", fontSize: "20px" }}
                />
                Back
              </div>
              <div className="header_text__ai">
                Invoice and Service Management{" "}
                {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                <span style={smDown ? { fontSize: "14px" } : {}}>
                  Add New Receipt
                </span>
              </div>
            </Grid>
          </Grid>

          {/* END ******************************* Header Row ******************************************** */}
        </Box>

        {/* END ******************************* 
        
                          Header Box that includes header and navigation bar

        ******************************************** */}

        {/* START ******************************* Content Box ******************************************** */}

        <Box className="div__scroll" height={smDown ? "70vh" : "80vh"}>
          {/* START ******************************* 
                
                      Content Gradiant Box
                
                ******************************************** */}
          <Grid container className="card__ai">
            {/* START ******************************* 
                
                      Content container Inside the Gradiant Box
                
                ******************************************** */}
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingTop={query(2, 2, 2)}
              paddingBottom={query(3, 3, 3)}
              paddingLeft={query(5, 2, 1)}
              paddingRight={query(5, 2, 1)}
              id="addOrderStyle"
            >
              {/* START ******************************* 
                
                          Auto Scroll Container for vertical scrolling
                
                ******************************************** */}

              <AutoScrollContainer marginTop={0.01}>
                {/* START ******************************* 
                
                          Order Type, Invoice Type and Invoice Code Fields
                
                ******************************************** */}

                <Grid container display="flex" justifyContent="center">
                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    <div style={{ marginBottom: "8px" }}>Order Type</div>
                    <FormControl className="textField__AddInventory">
                      {order.order_type === "" && (
                        <InputLabel sx={{ opacity: 0.6 }}>
                          Select Order Type
                        </InputLabel>
                      )}

                      <CustomTextField
                        select
                        value={order.order_type}
                        onChange={handleOrderInputChange}
                        name="order_type"
                        id="order_type"
                        key="order_type"
                      >
                        {["Cash", "Credit", "Card"].map((option, oi) => (
                          <MenuItem
                            key={option}
                            value={option}
                            style={
                              oi + 1 < 4
                                ? {
                                    borderBottom: "1px solid rgba(0,0,0,0.15)",
                                  }
                                : {}
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                    </FormControl>
                  </Grid>
                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingRight={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    <div style={{ marginBottom: "8px" }}>Receipt Type</div>

                    <FormControl className="textField__AddInventory">
                      {order.invoice_type === "" && (
                        <InputLabel sx={{ opacity: 0.6 }}>
                          Select Receipt Type
                        </InputLabel>
                      )}

                      <CustomTextField
                        select
                        value={order.invoice_type}
                        onChange={handleOrderInputChange}
                        name="invoice_type"
                        id="Receipt_type"
                        key="invoice_type"
                        disabled={orderHasGift}
                      >
                        {["With VAT", "Without VAT"].map((option, oi) => (
                          <MenuItem
                            key={option}
                            value={option}
                            style={
                              oi + 1 < 2
                                ? { borderBottom: "1px solid rgba(0,0,0,0.15)" }
                                : {}
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                    </FormControl>
                  </Grid>

                  {/* <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    <div style={{ marginBottom: "8px" }}>Receipt Code</div>
                    <CustomTextField
                            value={order.invoice_code}
                            onChange={handleOrderInputChange}
                            name="invoice_code"
                            id="invoice_code"
                            key="invoice_code"
                            placeholder="Enter Invoice Code"
                            className="textField__AddInventory"
                          />
                 
                  </Grid> */}
                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    <div style={{ marginBottom: "8px" }}>Receipt Code</div>

                    <Button
                      variant="outlined"
                      component="span"
                      id="receipt_code"
                      style={{
                        width: "100%",
                        height: "54.5px",
                        backgroundColor: "white",
                        borderColor: "rgb(0,0,0,0.25)",
                        color: "rgb(0,0,0,0.35)",
                        textTransform: "none",
                        fontSize: "16px",
                        justifyContent: "flex-start",
                        borderRadius: "10px",
                      }}
                      onClick={handleInvoiceCodeDropDownOpen}
                    >
                      {order.invoice_code_type === "" ? (
                        "Select Invoice Code Type"
                      ) : order.invoice_code_type !==
                        "system_generated_code" ? (
                        <Box className="product_inv__addord">
                          {order.invoice_code}
                        </Box>
                      ) : (
                        <Box className="product_inv__addord">
                          System Generated Code
                        </Box>
                      )}
                    </Button>
                    <Menu
                      id="invoice_code_type"
                      aria-labelledby="invoice_code_type"
                      name="invoice_code_type"
                      key="invoice_code_type"
                      anchorEl={invoiceCodeDropDown}
                      open={isInvoiceCodeDropDownOpen}
                      onClose={handleInvoiceCodeDropDownClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem
                        key="system_generated_code"
                        value="system_generated_code"
                        style={{ borderBottom: "1px solid rgba(0,0,0,0.15)" }}
                        onClick={() => {
                          handleInvoiceCodeDropDownClose(
                            "system_generated_code"
                          );
                        }}
                      >
                        System Generated Code
                      </MenuItem>
                      <MenuItem key="manually" value="manually">
                        <Box mt={1}>
                          <div style={{ marginBottom: "8px" }}>
                            Enter Manually
                          </div>
                          <CustomTextField
                            value={order.invoice_code}
                            onChange={handleOrderInputChange}
                            name="invoice_code"
                            id="invoice_code"
                            key="invoice_code"
                            placeholder="Enter Invoice Code"
                            className="textField__AddInventory"
                          />
                        </Box>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>

                {/* END ******************************* 
                
                          Order Type, Invoice Type and Invoice Code Fields
                
                ******************************************** */}

                {/* START ******************************* 
                
                          TRE NO. Field
                
                ******************************************** */}

                {order.invoice_type === "With VAT" ? (
                  <Grid container mt={2}>
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      paddingRight={query(1, 1, 0)}
                      item
                    >
                      <div style={{ marginBottom: "8px" }}>
                        TRE NO. (Optional)
                      </div>
                      <CustomTextField
                        value={order.tre_no}
                        onChange={handleOrderInputChange}
                        name="tre_no"
                        id="tre_no"
                        key="tre_no"
                        placeholder="Enter TRE Number"
                        className="textField__AddInventory"
                      />
                    </Grid>

                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>Tax Percentage</div>
                      <CustomTextField
                        value={order.tax}
                        onChange={handleOrderInputChange}
                        name="tax"
                        id="tax"
                        key="tax"
                        type="number"
                        InputProps={{
                          inputProps: { min: 1, max: 100 },
                        }}
                        placeholder="Tax Percentage"
                        className="textField__AddInventory"
                        disabled={
                          returnOrderBalance + returnOrderTax > 0 ? true : false
                        }
                      />
                    </Grid>
                    {/* <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>Tax Amount</div>
                    <CustomTextField
                      value={order.tax_amount}
                      onChange={handleOrderInputChange}
                      name="tax_amount"
                      id="tax_amount"
                      key="tax_amount"
                      placeholder="Tax Amount"
                      className="textField__AddInventory"
                     
                    /> 
                  </Grid> */}
                  </Grid>
                ) : null}

                {/* END ******************************* 
                
                          TRE NO. Field
                
                ******************************************** */}

                {/* START ******************************* 
                
                          Customer Name and Phone Fields
                
                ******************************************** */}

                <Grid
                  container
                  display="flex"
                  paddingTop={query(2, 2, 0)}
                  justifyContent="center"
                  paddingBottom={3}
                >
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>Customer Name</div>
                    <CustomTextField
                      value={order.customer_name}
                      onChange={handleOrderInputChange}
                      name="customer_name"
                      id="customer_name"
                      key="customer_name"
                      placeholder="Customer Name"
                      className="textField__AddInventory"
                      disabled={
                        returnOrderBalance + returnOrderTax > 0 ? true : false
                      }
                    />
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingLeft={query(1, 1, 0)}
                  >
                    {/* <div style={{ marginBottom: "8px" }}>Customer Phone</div>
                    <CustomTextField
                      value={order.customer_phone}
                      onChange={handleOrderInputChange}
                      name="customer_phone"
                      id="customer_phone"
                      key="customer_phone"
                      type="tel"
                      placeholder="Customer Phone"
                      className="textField__AddInventory"
                      disabled={
                        returnOrderBalance + returnOrderTax > 0 ? true : false
                      }
                    /> */}
                    {/* <div style={{ marginBottom: "8px" }}>Customer Phone</div>

                    <Box
                      display="flex"
                      width="100%"
                      className="box_border__addorder"
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pl={1}
                        className="country_code_label__addorder"
                      >
                        {userCountry?.code ? (
                          <Box className="country_code_label_text__addorder">
                            <img
                              loading="lazy"
                              width="40"
                              height="20px"
                              src={`https://flagcdn.com/w40/${userCountry?.code.toLowerCase()}.png`}
                              alt=""
                              style={{
                                border: "1px solid rgb(0, 0, 0, 0.2)",
                                borderRadius: "5px",
                              }}
                            />
                            &nbsp;
                            <Box className="country_code_text1__addorder">
                              {userCountry?.mobileCode} ({userCountry?.code})
                            </Box>
                          </Box>
                        ) : (
                          <Box className="select_code_text__addorder">
                            {countryCodeText === "" ? `Select Code` : ""}
                          </Box>
                        )}
                      </Box>
                      <Box width="50%">
                        <Autocomplete
                          disablePortal
                          id="selected_country__add_order"
                          options={COUNTRIES}
                          filterOptions={filterOptions}
                          onChange={(event, newValue) => {
                            setUserCountry(newValue);
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.name} ({option.code}) +{option.mobileCode}
                            </Box>
                          )}
                          value={userCountry}
                          getOptionLabel={(option) => option.mobileCode}
                          renderInput={(params) => (
                            <CustomTextFieldForMobile1
                              {...params}
                              onChange={(e) => {
                                setCountryCodeText(e.target.value);
                              }}
                              className="textField__AddInventory_right_radius"
                            />
                          )}
                        />
                      </Box>
                      <Box width="50%">
                        <CustomTextFieldForMobile2
                          value={order.customer_phone}
                          onChange={handleOrderInputChange}
                          name="customer_phone"
                          id="customer_phone"
                          key="customer_phone"
                          placeholder="Customer Phone"
                          className="textField__AddInventory_left_radius"
                          disabled={
                            returnOrderBalance + returnOrderTax > 0
                              ? true
                              : false
                          }
                          type="number"
                        />
                      </Box>
                    </Box> */}
                    <div style={{ marginBottom: "8px" }}>Customer Phone</div>
                    <PhoneInput
                      country={"Us"}
                      value={order.customer_phone}
                      onChange={(value) => {
                        setOrder({
                          ...order,
                          ["customer_phone"]: value,
                        });
                      }}
                      placeholder="Customer Phone"
                      inputProps={{
                        name: "customer_phone",
                        id: "customer_phone",
                        key: "customer_phone",
                      }}
                      inputClass="phone_input_search"
                      buttonClass="button_input_field"
                      disabled={
                        returnOrderBalance + returnOrderTax > 0 ? true : false
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container display="flex" paddingBottom={3}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>Order Date</div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="bnkmnu_field__bank"
                        value={orderDate}
                        onChange={(newValue) => {
                          setOrderDate(newValue);
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            error={false}
                            {...params}
                            id="order_date__invoice"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingLeft={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>Discount</div>
                    <CustomTextField
                      value={discountAmount}
                      onChange={(e) => {
                        setDiscountAmount(e.target.value);
                        //   console.log("Discount Amo",e.target.value)
                      }}
                      name="order_discount"
                      id="order_discount"
                      key="order_discount"
                      placeholder="Discount"
                      className="textField__AddInventory"
                      type="number"
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                      // disabled={ returnOrderBalance + returnOrderTax > 0 ? true : false}
                    />
                  </Grid>
                </Grid>
                {/* 
                <Grid container display="flex" paddingBottom={3}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>
                      Return Order Balance
                    </div>
                    <CustomTextField
                      value={order.return_order_balance}
                      onChange={handleOrderInputChange}
                      name="return_order_balance"
                      id="return_order_balance"
                      key="return_order_balance"
                      placeholder="Return Order Balance"
                      className="textField__AddInventory"
                      type="number"
                    />
                  </Grid>
                </Grid> */}
                {/* END ******************************* 
                
                          Customer Name and Phone Fields
                
                ******************************************** */}

                {!smDown ? <hr /> : null}

                {/* START ******************************* 

                            Order Products Heading in Mobile Screen
                
                ******************************************** */}

                {smDown ? (
                  <>
                    <hr style={{ marginTop: "40px" }} />
                    <div style={{ marginBottom: "8px", textAlign: "center" }}>
                      Order Products
                    </div>
                    <hr style={{ marginBottom: "40px" }} />
                  </>
                ) : null}

                {/* END ******************************* 

                        Order Products Heading in Mobile Screen
                
                ******************************************** */}

                {/* START ******************************* 
                
                          Product List Fields Header

                          Like,

                          Product Name, Price, Quantity

                          For Laptop to Medium Screen
                
                
                ******************************************** */}

                {!smDown ? (
                  <Grid container mt={4}>
                    <Grid
                      xl={5}
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                      paddingRight={query(1, 1, 0)}
                      item
                    >
                      <div style={{ marginBottom: "8px" }}>Service Name</div>
                    </Grid>
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={query(1, 1, 0)}
                      paddingRight={query(1, 1, 0)}
                      paddingTop={query(0, 0, 2)}
                    >
                      <div style={{ marginBottom: "8px" }}>Price</div>
                    </Grid>
                    <Grid
                      xl={2}
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={query(1, 1, 0)}
                      paddingTop={query(0, 0, 2)}
                    >
                      <div style={{ marginBottom: "8px" }}>Quantity</div>
                    </Grid>
                  </Grid>
                ) : null}

                {/* END ******************************* 
                
                          Product List Fields Header

                          Like,

                          Product Name, Price, Quantity

                          For Laptop to Medium Screen
                
                
                ******************************************** */}

                {/* START ******************************* Added Product List ******************************************** */}

                {orderProducts.map((pro, proi) => (
                  <>
                    <Grid
                      key={proi}
                      container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={2}
                    >
                      {smDown ? (
                        <Grid
                          xl={1}
                          lg={1}
                          md={1}
                          sm={12}
                          xs={12}
                          item
                          paddingLeft={query(1, 1, 0)}
                          paddingTop={query(0, 0, 2)}
                          display="flex"
                          justifyContent="center"
                        >
                          <ButtonGroup
                            orientation="horizontal"
                            variant="contained"
                            style={{
                              backgroundColor: "#ECAE33",
                              padding: "6px",
                              borderRadius: "10%",
                            }}
                          >
                            <HighlightOffIcon
                              style={{
                                cursor: "pointer",
                                paddingRight: "30px",
                                color: "white",
                              }}
                              onClick={() => {
                                handleDeleteProduct(proi);
                              }}
                            />
                            <EditIcon
                              onClick={() => {
                                handleEditProduct(proi);
                              }}
                              style={{ cursor: "pointer", color: "white" }}
                            />
                          </ButtonGroup>
                        </Grid>
                      ) : null}
                      <Grid
                        xl={5}
                        lg={5}
                        md={5}
                        sm={12}
                        xs={12}
                        paddingRight={query(1, 1, 0)}
                        item
                      >
                        {smDown ? (
                          <div style={{ marginBottom: "8px" }}>
                            Survice Name
                          </div>
                        ) : null}
                        <Button
                          variant="outlined"
                          component="span"
                          style={{
                            width: "100%",
                            height: "54.5px",
                            backgroundColor: "white",
                            borderColor: "rgb(0,0,0,0.25)",
                            color: "rgb(0,0,0,0.35)",
                            textTransform: "none",
                            fontSize: "16px",
                            justifyContent: "flex-start",
                            borderRadius: "10px",
                          }}
                        >
                          {/* <img
                            alt=""
                            src={baseUrl + pro.product.image}
                            width="30px"
                            height="30px"
                            style={{
                              borderRadius: "5px",
                              border: "1px solid rgba(0,0,0,0.2)",
                              marginRight: "10px",
                            }}
                          /> */}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                            alignItems="center"
                          >
                            <Box className="product_inv__addord">
                              {pro.name}
                            </Box>

                            {/*
                            <Box className="product_inv__addord">
                              {pro.product.name}
                              &nbsp;(
                              {pro.variant.color}
                              {pro.variant.storage !== "" &&
                              pro.variant.storage !== null
                                ? ","
                                : ""}
                              {pro.variant.storage !== null
                                ? pro.variant.storage
                                : ""}
                              {pro.variant.ram !== "" &&
                              pro.variant.ram !== null
                                ? ","
                                : ""}
                              {pro.variant.ram !== null ? pro.variant.ram : ""})
                              <br />
                              {pro.imei !== "" ? pro.product_imei : ""}
                            </Box>
                            {pro.gifted && (
                              <Box mt={1}>
                                <FcPaid size={34} />
                              </Box>
                            )}*/}
                          </Box>
                        </Button>
                      </Grid>
                      <Grid
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        item
                        paddingLeft={query(1, 1, 0)}
                        paddingRight={query(1, 1, 0)}
                        paddingTop={query(0, 0, 2)}
                      >
                        {smDown ? (
                          <div style={{ marginBottom: "8px" }}>Price</div>
                        ) : null}
                        <CustomTextField
                          value={pro.order_price}
                          className="textField__AddInventory"
                          type="number"
                        />
                      </Grid>
                      <Grid
                        xl={2}
                        lg={2}
                        md={2}
                        sm={12}
                        xs={12}
                        item
                        paddingLeft={query(1, 1, 0)}
                        paddingTop={query(0, 0, 2)}
                      >
                        {smDown ? (
                          <div style={{ marginBottom: "8px" }}>
                            Product Quantity
                          </div>
                        ) : null}
                        <CustomTextField
                          value={pro.quantity}
                          className="textField__AddInventory"
                          type="number"
                          InputProps={{
                            inputProps: { min: 1 },
                          }}
                        />
                      </Grid>

                      {!smDown ? (
                        <Grid
                          xl={1}
                          lg={1}
                          md={1}
                          sm={12}
                          xs={12}
                          item
                          paddingLeft={query(1, 1, 0)}
                          paddingTop={query(0, 0, 2)}
                          disabled
                          display="flex"
                        >
                          <ButtonGroup
                            orientation="vertical"
                            aria-label="vertical contained button group"
                            variant="contained"
                            style={{
                              backgroundColor: "#ECAE33",
                              padding: "2px 5px 2px 5px",
                              borderRadius: "10%",
                            }}
                          >
                            <HighlightOffIcon
                              style={{
                                cursor: "pointer",
                                paddingBottom: "5px",
                                color: "white",
                              }}
                              onClick={() => {
                                handleDeleteProduct(proi);
                              }}
                            />
                            <EditIcon
                              onClick={() => {
                                handleEditProduct(proi);
                              }}
                              style={{ cursor: "pointer", color: "white" }}
                            />
                          </ButtonGroup>
                        </Grid>
                      ) : null}
                    </Grid>

                    <Grid container mt={0.2}>
                      <Grid xs={11}>
                        <Box className="prod_added_des__addord">
                          {pro.description}
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                ))}

                {/* END ******************************* Added Product List ******************************************** */}

                {smDown ? (
                  <>
                    <hr style={{ marginTop: "40px" }} />
                    <div style={{ marginBottom: "8px", textAlign: "center" }}>
                      Add New Product Detail
                    </div>
                    <hr style={{ marginBottom: "40px" }} />
                  </>
                ) : null}

                {/* START ******************************* Add New Product Fields ******************************************** */}

                <Grid container mt={orderProducts.length > 0 ? 2 : 0}>
                  <Grid
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    {smDown ? (
                      <div style={{ marginBottom: "8px" }}>Service Name</div>
                    ) : null}
                    {/* <CustomTextField
                        //  value={pro.order_price}
                          className="textField__AddInventory"
                        />*/}
                    <CustomTextField
                      value={product.name}
                      onChange={handleProductInputChange}
                      name="name"
                      id="service_name__invoice"
                      key="product_name"
                      placeholder="Service Name"
                      className="textField__AddInventory"
                      disabled={
                        returnOrderBalance + returnOrderTax > 0 ? true : false
                      }
                    />
                    {/* <ProductMultiLevelMenu
                      setIsProductSelected={setIsProductSelected}
                      setProduct={addSelectedProduct}
                      product={product}
                      productsData={orderProducts}
                    /> */}
                  </Grid>
                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingRight={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    {smDown ? (
                      <div style={{ marginBottom: "8px" }}>Price</div>
                    ) : null}

                    <CustomTextField
                      value={product.order_price}
                      onChange={handleProductInputChange}
                      name="order_price"
                      id="order_price"
                      key="order_price"
                      placeholder="Price"
                      type="number"
                      className="textField__AddInventory"
                      disabled={
                        returnOrderBalance + returnOrderTax > 0 ? true : false
                      }
                    />
                    {/* <CustomTextField
                      error={orderPriceSmallerThanError ? true : false}
                      value={product.order_price}
                      onChange={handleProductInputChange}
                      name="order_price"
                      id="order_price"
                      key="order_price"
                      placeholder="Product Price"
                      className="textField__AddInventory"
                      helperText={
                        orderPriceSmallerThanError
                          ? `Min. Sale Price is ${
                              product.variant.min_sale_price
                            }`
                          : ""
                      }
                      disabled={!isProductSelected}
                    /> */}
                  </Grid>
                  <Grid
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    {smDown ? (
                      <div style={{ marginBottom: "8px" }}>
                        Product Quantity
                      </div>
                    ) : null}
                    <CustomTextField
                      error={quantityGreaterThanError ? true : false}
                      value={product.quantity}
                      onChange={handleProductInputChange}
                      name="quantity"
                      id="order_quantity"
                      type="number"
                      key="order_quantity"
                      placeholder="Quantity"
                      className="textField__AddInventory"
                      disabled={
                        returnOrderBalance + returnOrderTax > 0 ? true : false
                      }
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                    />
                    {/* <CustomTextField
                      disabled={
                        product.variant.category === "Mobile" ||
                        !isProductSelected
                      }
                      error={quantityGreaterThanError ? true : false}
                      value={product.order_quantity}
                      onChange={handleProductInputChange}
                      name="order_quantity"
                      id="order_quantity"
                      key="order_quantity"
                      type="number"
                      className="textField__AddInventory"
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                      helperText={
                        quantityGreaterThanError
                          ? `Available Quantity is ${product.variant.quantity}`
                          : ""
                      } 
                    />*/}
                  </Grid>

                  {/* <Grid
                    xl={1}
                    lg={1}
                    md={1}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                    disabled
                  >
                    <Checkbox
                      checked={product.gifted}
                      {...label}
                      icon={<GiftRedIcon />}
                      checkedIcon={<FcPaid size={34} />}
                      onClick={() => {
                        setProduct({
                          ...product,
                          ["gifted"]: !product.gifted,
                        });
                      }}
                    />
                  </Grid> */}
                </Grid>
                <Grid container mt={1}>
                  <Grid xs={11}>
                    <CustomTextField
                      value={product.description}
                      onChange={handleProductInputChange}
                      name="description"
                      id="product_description"
                      key="product_description"
                      placeholder="Description ..."
                      className="textField__AddInventory"
                    />
                  </Grid>
                </Grid>

                {/* END **************************** Add New Product Fields ******************************************** */}

                {/* START **************************** Add Product Button ******************************************** */}

                <Grid container display="flex" justifyContent="center" mt={2}>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    <Button
                      onClick={handleAddProduct}
                      className="add_product_btn__adord"
                      variant="contained"
                      endIcon={<SquareIcon />}
                      id="add_service__invoice"
                    >
                      {orderProducts.length > 0 ? "Add Service" : "Add Service"}
                    </Button>
                  </Grid>
                </Grid>

                {/* END **************************** Add Product Button ******************************************** */}

                {/* START **************************** Total Calculation ******************************************** */}

                <Grid
                  container
                  paddingTop={4}
                  paddingBottom={1}
                  display="flex"
                  justifyContent="end"
                >
                  <Grid
                    xl={3}
                    lg={5}
                    md={6}
                    sm={8}
                    xs={8}
                    item
                    display="flex"
                    justifyContent="end"
                  >
                    <Grid container>
                      <Grid md item>
                        SUBTOTAL
                      </Grid>
                      {/* <Grid md item>
                        BALANCE
                      </Grid> */}
                      {/* {location.state.order !== undefined ? (
                        <Grid md item>
                         
                       </Grid>
                      ) : null} */}
                      <Grid md item>
                        DISCOUNT
                      </Grid>

                      <Grid md item display="flex" justifyContent="end">
                        TAX RATE
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container display="flex" justifyContent="end">
                  <Grid
                    xl={3}
                    lg={5}
                    md={6}
                    sm={8}
                    xs={8}
                    item
                    display="flex"
                    justifyContent="end"
                    style={{ borderBottom: "1px solid black" }}
                  >
                    <Grid container>
                      <Grid className="price__addord" md item>
                        {subTotal} {userObject.currency}
                      </Grid>
                      {/* <Grid className="price__addord" md item>
                        {order.return_order_balance} {userObject.currency}
                      </Grid> */}

                      <Grid className="price__addord" md item>
                        {discountAmount} {userObject.currency}
                      </Grid>
                      <Grid
                        md
                        item
                        display="flex"
                        justifyContent="end"
                        className="price__addord"
                      >
                        <span>
                          {subTotal > 0
                            ? ` ${
                                order.invoice_type === "With VAT"
                                  ? order.tax_amount
                                  : "0"
                              } ${userObject.currency} ${
                                order.invoice_type === "With VAT"
                                  ? order.tax
                                  : "0"
                              }%`
                            : ""}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  paddingTop={1}
                  paddingBottom={1}
                  display="flex"
                  justifyContent="end"
                >
                  <Grid
                    xl={3}
                    lg={5}
                    md={6}
                    sm={8}
                    xs={8}
                    item
                    display="flex"
                    justifyContent="end"
                  >
                    TOTAL
                  </Grid>
                </Grid>
                <Grid container display="flex" justifyContent="end">
                  <Grid
                    xl={3}
                    lg={5}
                    md={6}
                    sm={8}
                    xs={8}
                    item
                    display="flex"
                    justifyContent="end"
                    style={{ borderBottom: "1px solid black" }}
                    className="price__addord"
                  >
                    {totalOrderPrice} {userObject.currency}
                  </Grid>
                </Grid>

                {/* END **************************** Total Calculation ******************************************** */}

                {/* START **************************** Discard and Place Order ******************************************** */}

                <Grid container paddingTop={5} paddingBottom={5}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    item
                    paddingLeft={smDown ? 1 : 0}
                    display="flex"
                    justifyContent="start"
                  >
                    <Button
                      onClick={() => {
                        navigate("/invoices");
                      }}
                      variant="contained"
                      className="footer_dis__ai"
                      id="discard_btn__invoice"
                    >
                      Discard
                    </Button>
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    item
                    paddingRight={smDown ? 1 : 0}
                    display="flex"
                    justifyContent="end"
                  >
                    <Button
                      onClick={handleCreateOrder}
                      variant="contained"
                      className="footer_add__ai"
                      id="create_invoice_btn"
                    >
                      Create Invoice
                    </Button>
                  </Grid>
                </Grid>

                {/* END ******************************* Discard and Place Order ******************************************** */}
              </AutoScrollContainer>

              {/* END ******************************* 
                
                          Auto Scroll Container for vertical scrolling
                
                ******************************************** */}
            </Grid>
            {/* END ******************************* 
                
                      Content container Inside the Gradiant Box
                
                ******************************************** */}
          </Grid>
          {/* END ******************************* 
                
                      Content Gradiant Box
                
                ******************************************** */}
        </Box>

        {/* END ******************************* Content Box ******************************************** */}
      </Grid>
      {/* END ******************************* Container Column ******************************************** */}
    </Grid>
  );
}

export default AddInvoice;
