import React, { useState, useEffect, useContext, useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { GlobalData } from "../App";
import { Textfit } from "react-textfit";
import {
  InventoryWorth,
  DashboardMobile,
  OrderStatus,
  StaffImageDashBoard,
  TsaleImageDashboard,
  TcashImageDashboard,
  TotalCreditImageDashboard,
  TotalDebitImageDashboard,
  TotalCashOut,
} from "../assets/SVG/DashboardSVG";
function DashboardTotalSale({
  onClick,
  text_cash,
  accountInfo_cash,
  userObject_cash,

  text_expense,
  accountInfo_expense,
  userObject_expense,

  text_sale_Profit,
  accountInfo_sale_profit,
  userObject_sale_profit,
  icon_sale_Profit,

  accountInfo_totalProfit,
  userObject_totalProfit,
  text_totalProfit,
  icon_totalProfit,
}) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    onlyXs,
    smDown,
    mdDown,
    onlyMd,
    baseUrl,
    userObject,
  } = useContext(GlobalData);
  return (
    <>
      <Box
        className="throne_box_con__dsh"
        // onClick={() => {
        //   navigate("accounts");
        // }}
        onClick={onClick}
      >
        <Box paddingLeft="10px">
          <Box>
            <Box className="thrdbone_box__dsh" marginBottom="6px">
              <Grid container>
                <Grid item padding="5px" paddingRight="10px">
                  <TsaleImageDashboard />
                </Grid>
                <Grid item padding="5px" display="flex" alignItems="center">
                  <Box
                    marginTop="-10px"
                    fontSize="20px"
                    color="rgba(0,0,0,0.8)"
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#1E2738",
                      }}
                    >
                      {text_cash}
                    </span>
                    <Textfit max={18} style={{ width: "230px" }} mode="single">
                      {accountInfo_cash} {userObject_cash}
                    </Textfit>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="thrdbone_box__dsh" marginBottom="6px">
              <Grid container>
                <Grid item padding="5px" paddingRight="10px">
                  <TotalCashOut />
                </Grid>
                <Grid item padding="5px" display="flex" alignItems="center">
                  <Box
                    marginTop="-10px"
                    fontSize="20px"
                    color="rgba(0,0,0,0.8)"
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#1E2738",
                      }}
                    >
                      {text_expense}
                    </span>
                    <Textfit max={18} style={{ width: "230px" }} mode="single">
                      {accountInfo_expense} {userObject_expense}
                    </Textfit>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="thrdbone_box__dsh" marginBottom="6px">
              <Grid container>
                <Grid item padding="5px" paddingRight="10px">
                  <img alt="" src={icon_sale_Profit} width="44" height="44" />
                </Grid>
                <Grid item padding="5px" display="flex" alignItems="center">
                  <Box
                    marginTop="-10px"
                    fontSize="20px"
                    color="rgba(0,0,0,0.8)"
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#1E2738",
                      }}
                    >
                      {text_sale_Profit}
                    </span>
                    <Textfit max={18} style={{ width: "230px" }} mode="single">
                      {accountInfo_sale_profit} {userObject_sale_profit}
                    </Textfit>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box className="thrdbone_box__dsh" marginBottom="6px">
              <Grid container>
                <Grid item padding="5px" paddingRight="10px">
                  <img alt="" src={icon_totalProfit} width="44" height="44" />
                </Grid>
                <Grid item padding="5px" display="flex" alignItems="center">
                  <Box
                    marginTop="-10px"
                    fontSize="20px"
                    color="rgba(0,0,0,0.8)"
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#1E2738",
                      }}
                    >
                      {text_totalProfit}
                    </span>
                    <Textfit max={18} style={{ width: "230px" }} mode="single">
                      {accountInfo_totalProfit} {userObject_totalProfit}
                    </Textfit>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default DashboardTotalSale;
