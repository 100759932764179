import "./Invoices.css";
import {
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import Button from "@mui/material/Button";
import { AiFillCaretDown } from "react-icons/ai";
import CustomTable from "../../widgets/CustomTable";
import SearchField from "../../widgets/SearchField";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useEffect, useContext, useMemo } from "react";
import DeleteDialog from "../../widgets/DeleteDialog";
import Divider from "@mui/material/Divider";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import { ReactComponent as DownloadIcon } from "../../assets/SVG/DownloadIcon.svg";
import {
  getAllOrders,
  changeOrderStatus,
  deleteOrder,
} from "../../api/OrderAPI";
import { getAllInvoice, deleteInvoice } from "../../api/InvoiceAPI";
import { orderInvoice } from "../../api/ReportAPI";
import { debounce } from "lodash";
import { styled } from "@mui/material/styles";
import { DateRangePicker } from "mui-daterange-picker";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { ReactComponent as EditIcon } from "../../assets/SVG/EditIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import PrintIcon from "@mui/icons-material/Print";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import MainTitle from "../../widgets/MainTitle";
import SmallTitle from "../../widgets/SmallTitle";
import AddNewButton from "../../widgets/AddNewButton";
import DateFilter from "../../widgets/DateFilter";
import NoBank from "../../assets/Images/EmptyScreenImage/NoBank.png";
import ScrollToTop from '../../widgets/ScrollToTop';
import NoInvoice from "../../assets/Images/EmptyScreenImage/NoInvoice.png";
import { Textfit } from "react-textfit";

const options = [
  {
    icon: <EyeIcon />,
    label: "View",
    method: "view",
    type: "icon",
    id: "view_invoice",
  },
  {
    icon: (
      <PublishedWithChangesIcon
        style={{ opacity: "0.8", marginLeft: "-2.5px" }}
      />
    ),
    label: <span className="margn_left_global__module">Status</span>,
    method: "status",
    type: "icon",
    id: "statue_invoice",
  },
  {
    icon: <EditIcon />,
    label: "Edit",
    method: "edit",
    type: "icon",
    id: "edit_invoice",
  },
  {
    icon: <PrintIcon style={{ opacity: "0.7", marginLeft: "-2.5px" }} />,
    label: <span className="margn_left_global__module">Print</span>,
    method: "print",
    type: "icon",
    id: "print_invoice",
  },
  {
    icon: <DeleteIcon />,
    label: "Delete",
    method: "delete",
    type: "icon",
    id: "delete_invoice",
  },
];

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    align: "left",
  },
  {
    id: "invoice_code",
    label: "Order ID",

    align: "left",
  },
  {
    id: "total",
    label: "Total Cash",

    align: "left",
  },
  {
    id: "customer_name",
    label: "Customer Name",
    align: "left",
  },
  {
    id: "customer_phone",
    label: "Phone Number",
    align: "left",
  },
  {
    id: "quantity",
    label: "Total Products",

    align: "left",
  },
  {
    id: "order_date",
    label: "Order Date",
    align: "left",
    type: "date",
  },
  {
    id: "invoice_type",
    label: "Type",
    align: "left",
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   align: "left",
  // },
];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

function Invoices(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
    mdDown,
    onlyMd,
    baseUrl,
    location,
    userObject,
  } = useContext(GlobalData);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  //
  const [ordersDate, setOrdersDate] = useState("This Month");
  //--
  const [dateMenuElement, setDateMenuElement] = useState(null);
  const isDateMenuOpen = Boolean(dateMenuElement);
  const ITEM_HEIGHT = 48;
  const handleOpenDateMenu = (event) => {
    setDateMenuElement(event.currentTarget);
  };
  const handleCloseDateMenu = () => {
    setDateMenuElement(null);
  };
  //--
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const toggleDateRangerPicker = () =>
    setOpenDateRangePicker(!openDateRangePicker);
  const handleOrdersDateFilter = (option) => {
    handleCloseDateMenu();
    if (option === "Custom") {
      toggleDateRangerPicker();
    } else {
      setOrdersDate(option);
    }
  };

  const handleDateRange = (range) => {
    setDateRange(range);
    setOrdersDate(range);
    setTimeout(() => {
      toggleDateRangerPicker();
    }, 500);
  };
  //

  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState("All");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [orderType, setOrderType] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [orderStatusCount, setOrderStatusCount] = useState("");
  const [orderTypeCount, setOrderTypeCount] = useState("");
  const [
    isChangeOrderStatusDialogOpen,
    setIsChangeOrderStatusDialogOpen,
  ] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const [orderDescription, setOrderDescription] = useState("");

  const [allOrderStatus, setAllOrderStatus] = useState([
    "Paid",
    "Unpaid",
    "Cancelled",
  ]);

  const [deleteProductDialog, setDeleteProductDialog] = useState(false);

  const [totalInvoicesAmount, setTotalInvoicesAmount] = useState(0);

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const handleDeleteOrder = () => {
    handleCloseDeleteDialog();
    showLoader();

    let data = { order_invoice_id: selectedOrder.id };
    deleteInvoice(data, (resp) => {
      if (resp.statusCode === 200) {
        updateDataUsingPageNumber();
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const openDeleteOrderDialog = (order) => {
    setSelectedOrder(order);
    setDeleteProductDialog(true);
  };

  const getAllOrdersMethod = (pn) => {
    showLoader();
    // let formData = new FormData();
    // formData.append("search_text", searchText);
    // formData.append("order_type", orderType);
    // formData.append("status", orderStatus);
    // formData.append("date", JSON.stringify(ordersDate));
    // formData.append("per_page", process.env.REACT_APP_PER_PAGE);
    // formData.append("page", pn);
    // if (location.pathname === "/staff/orders") {
    //   formData.append("staff_user_id", location.state.memberData.user_id);
    // }
    let data = {
      search_text: searchText,
      order_type: orderType,
      status: orderStatus,
      date: JSON.stringify(ordersDate),
      per_page: process.env.REACT_APP_PER_PAGE,
      page: pn,
    };
    getAllInvoice(data, (resp) => {
      if (resp.statusCode === 200) {
        // setOrderStatusCount(resp.successData.order_status_count);
        setOrderTypeCount(resp.successData.order_type_count);
        if (resp.statusCode === 200) {
          if (pn === 1) {
            setOrders(resp.successData.order_invoices);
            setTotalInvoicesAmount(
              resp.successData.order_invoices_total_amount
            );
            hideLoader();
          } else {
            setOrders((dt) => {
              dt.push(...resp.successData.order_invoices);
              hideLoader();
              return dt;
            });
          }
          setLastPage(resp.successData.last_page);
        }
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };
  const changeOrderDescription = (e) => {
    setOrderDescription(e.target.value);
  };

  const handleOrderStatus = (order) => {
    setSelectedOrder(order);
    setSelectedOrderStatus("");
    setOrderDescription(order.description);
    handleChangeOrderStatusDialogOpen();
  };

  const handleViewOrder = (data) => {
    // if (location.pathname === "/staff/orders") {
    //   navigate("/orders/order-detail", {
    //     state: {
    //       orderData: data,
    //       orderStatus: orderStatus,
    //       path: location.pathname,
    //       memberData: location.state.memberData,
    //     },
    //   });
    // } else {
    navigate("/invoices/invoice-detail", {
      state: {
        orderData: data,
        // orderStatus: orderStatus,
        path: location.pathname,
      },
    });
    // }
  };

  // Order Status Dialog Methods

  const handleChangeOrderStatusDialogOpen = () => {
    setIsChangeOrderStatusDialogOpen(true);
  };

  const handleChangeOrderStatusDialogClose = () => {
    setIsChangeOrderStatusDialogOpen(false);
  };

  const handleChangeOrderStatus = () => {
    if (selectedOrderStatus === "") {
      flashMessage("error", "Please Select Order Status");
    } else {
      handleChangeOrderStatusDialogClose();
      showLoader();
      let formData = new FormData();
      formData.append("order_id", selectedOrder.id);
      formData.append("order_status", selectedOrderStatus);
      formData.append("description", orderDescription);
      changeOrderStatus(formData, (resp) => {
        if (resp.statusCode === 200) {
          updateDataUsingPageNumber();
        } else {
          flashMessage("error", resp.message);
          hideLoader();
        }
      });
    }
  };

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const handleEditOrder = (data) => {
    // if (location.pathname === "/staff/orders") {
    //   navigate("/orders/update-order", {
    //     state: {
    //       orderData: data,
    //       path: location.pathname,
    //       memberData: location.state.memberData,
    //     },
    //   });
    // } else {
    navigate("/invoices/update-invoice", {
      state: {
        orderData: data,
        path: location.pathname,
      },
    });
    //}
  };

  const handlePrintSingleOrder = (order) => {
    showLoader();
    let formData = new FormData();
    formData.append("orders", JSON.stringify([order]));
    orderInvoice(formData, (resp) => {
      if (resp.statusCode === 200) {
        window.open(baseUrl + "public/" + resp.successData.file_path, "_blank");
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const handlePrintAllOrders = () => {
    showLoader();
    let formData = new FormData();
    formData.append("orders", JSON.stringify(orders));
    orderInvoice(formData, (resp) => {
      if (resp.statusCode === 200) {
        window.open(baseUrl + "public/" + resp.successData.file_path, "_blank");
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    getAllOrdersMethod(page);
  }, [page]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      getAllOrdersMethod(page);
    }
  };

  useEffect(() => {
    updateDataUsingPageNumber();
  }, [searchText, orderType, orderStatus, ordersDate]);

  const handleNavigateToAddOrder = () => {
    if (location.pathname === "/staff/orders") {
      navigate("/orders/add-new-order", {
        state: {
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/invoices/add-new-invoice", {
        state: {
          path: location.pathname,
        },
      });
    }
  };

  return (
    <>
      <DeleteDialog
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteProductDialog}
        handleRemove={handleDeleteOrder}
        title={"Remove this Service?"}
        sub_title={
          "You are about to remove Service. Click on the “Remove” button to remove it."
        }
      />
      {/* <Dialog
        open={isChangeOrderStatusDialogOpen}
        onClose={handleChangeOrderStatusDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Order Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ marginBottom: "8px", marginTop: "16px" }}>Status</div>
            <FormControl className="textField__AddInventory">
              {selectedOrderStatus === "" && (
                <InputLabel sx={{ opacity: 0.6 }}>
                  Select Order Status
                </InputLabel>
              )}

              {selectedOrder.order_type === "Card" || selectedOrder.order_type === "Cash" ? (
                <ChangeOrderStatus
                  setSelectedOrderStatus={setSelectedOrderStatus}
                  selectedOrderStatus={selectedOrderStatus}
                  orderStatus={["Cancelled"]}
                />
              ) : true ? (
                <ChangeOrderStatus
                  setSelectedOrderStatus={setSelectedOrderStatus}
                  selectedOrderStatus={selectedOrderStatus}
                  orderStatus={["Cancelled"]}
                />
              ) : null}
            </FormControl>

            <div style={{ marginBottom: "8px", marginTop: "16px" }}>
              Description
            </div>

            <CustomTextField
              multiline
              rows={4}
              style={{ width: "" }}
              value={orderDescription}
              onChange={changeOrderDescription}
              name="description"
              id="description"
              key="description"
              placeholder="Write about product here"
              className="textField__AddInventory"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="download_report_btn__orders"
            onClick={handleChangeOrderStatusDialogClose}
          >
            Cancel
          </Button>
          <Button
            className="add_order_btn__orders"
            onClick={handleChangeOrderStatus}
          >
            Change Status
          </Button>
        </DialogActions>
      </Dialog> */}

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <NavigationBar
            path={
              "Dashboard>Invoices and Services Management>Invoices & Services"
            }
            paddingBottom={3}
          />

          <Grid container marginBottom={2}>
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingRight={onlyXs ? "5px" : "10px"}
            >
              <MainTitle title={"Invoices and Services Management"} />
            </Grid>
            <Grid mt={1} item paddingRight={onlyXs ? "5px" : "10px"}>
              <SmallTitle small={"Total Invoices & Services Worth"} />

              <Box display="flex">
                <Box className="ttwrt__inv">
                  <Box display="flex" alignItems="end">
                    <span style={{ fontSize: "24px", opacity: "0.8" }}>
                      <Textfit
                        max={18}
                        style={{ width: "200px" }}
                        mode="single"
                      >
                        {totalInvoicesAmount}
                      </Textfit>
                    </span>
                    &nbsp;&nbsp;{userObject.currency}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container marginBottom={2}>
            <Grid
              xl={6}
              lg={6}
              md={5}
              sm={12}
              xs={12}
              item
              className="padn_invoice__icon"
            >
              <SearchField
                id={"search_field_invoice"}
                onChange={updateProductList}
                tableDataCount={orders.length}
                totalAvailableDataCount={orderTypeCount.All}
              />
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={7}
              sm={12}
              xs={12}
              item
              display="flex"
              justifyContent={smDown ? "center" : "end"}
              paddingTop={smDown ? 1 : 0}
              paddingRight={smDown ? "0px" : "5px"}
            >
              {/* <Button
                variant="contained"
                className="add_order_btn__orders"
                style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                onClick={handleNavigateToAddOrder}
              >
                Add New Invoice & Service
              </Button> */}
              <AddNewButton
                id={"add_new_invoice-service__invoices"}
                name={"Add New Invoice & Service"}
                onClick={handleNavigateToAddOrder}
              />
              {onlyXs && (
                // <Button
                //   variant="contained"
                //   endIcon={<DownloadIcon />}
                //   className="download_report_btn__orders"
                //   onClick={handleOpenDateMenu}
                // >
                //   {typeof ordersDate === "string" ? ordersDate : "Custom"}
                // </Button>
                <DateFilter
                  id={"date_filter__invoices"}
                  className="download_report_btn__orders"
                  dateObject={ordersDate}
                  setDateObject={setOrdersDate}
                />
              )}

              {/* <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={dateMenuElement}
                open={isDateMenuOpen}
                onClose={handleCloseDateMenu}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 6,
                    width: "20ch",
                    borderRadius: "10px",
                    boxShadow: "none",
                    border: "1px solid rgba(0,0,0,0.15)",
                  },
                }}
              >
                {["Today", "This Week", "This Month", "Custom"].map(
                  (option, oi) => (
                    <MenuItem
                      key={option}
                      selected={option === "Pyxis"}
                      onClick={() => {
                        handleOrdersDateFilter(option);
                      }}
                      style={
                        oi + 1 < 4
                          ? {
                              borderBottom: "1px solid rgba(0,0,0,0.15)",
                              paddingTop: 0,
                              paddingBottom: 0,
                            }
                          : { paddingTop: 0, paddingBottom: 0 }
                      }
                    >
                      <Box marginTop={1} marginRight={2}>
                        {option}
                      </Box>
                    </MenuItem>
                  )
                )}
              </Menu> */}
              {/* {openDateRangePicker ? (
                <Box position="absolute" top="20%" zIndex="9999">
                  <DateRangePicker
                    open={openDateRangePicker}
                    toggle={toggleDateRangerPicker}
                    onChange={(range) => handleDateRange(range)}
                  />
                </Box>
              ) : null} */}
              {!onlyXs && (
                // <Button
                //   variant="contained"
                //   endIcon={<DownloadIcon />}
                //   className="download_report_btn__orders"
                //   onClick={handleOpenDateMenu}
                // >
                //   {typeof ordersDate === "string" ? ordersDate : "Custom"}
                // </Button>
                <DateFilter
                  id={"date_filter__invoices"}
                  className="download_report_btn__orders"
                  dateObject={ordersDate}
                  setDateObject={setOrdersDate}
                />
              )}
              {/* <ProtectComponent
                id="download-all-btn"
                componentLocation={true}
                componentNavigate={false}
                component={
                  <Button
                    onClick={handlePrintAllOrders}
                    variant="contained"
                    endIcon={
                     <DownloadIcon/>
                    }
                    className="download_report_btn__orders"
                  >
                    {onlyXs || onlyMd ? (
                      <div>
                        <div>Download</div>
                        <div style={{ marginTop: "-8px" }}>Report</div>
                      </div>
                    ) : (
                      "Download Report"
                    )}
                  </Button>
                }
              /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CustomTable
            view={handleViewOrder}
            edit={handleEditOrder}
            delete={openDeleteOrderDialog}
            print={handlePrintSingleOrder}
            status={handleOrderStatus}
            columns={columns}
            data={orders}
            options={options}
            firstRowMargin={true}
            image={NoBank}
          />
        </Grid>
      </Grid>
      <ScrollToTop />
    </>
  );
}

const ChangeOrderStatus = (props) => {
  return (
    <CustomTextField
      select
      value={props.selectedOrderStatus}
      onChange={(e) => {
        props.setSelectedOrderStatus(e.target.value);
      }}
      name="order_status"
      id="order_status"
      key="order_status"
    >
      {props.orderStatus.map((value, index) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </CustomTextField>
  );
};

export default Invoices;
