import { get, post, getWithParams, delte } from "./API";


export function getAllOnlineOrders(data, callback) {
  getWithParams(
    { endPoint: "onlineOrder/GetAllOnlineOrder" },
    data,
    function(response) {
      callback(response);
    }
  );
}