import { get, post, getWithParams } from "./API";

export function getAllColors(data, callback) {
  getWithParams({ endPoint: "inventory/color/getAllColors" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function updateColors(data, callback) {
  post({ endPoint: "inventory/color/updateColors" }, data, function(response) {
    callback(response);
  });
}
