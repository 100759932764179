import { get, post, delte } from "./API";

export function addOnlineVariant(data, callback) {
  post(
    { endPoint: "online/inventoryVariant/addInventoryVariantProduct" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function editOnlineVariant(data, callback) {
  post(
    { endPoint: "online/inventoryVariant/editInventoryVariantProduct" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function deleteOnlineVariant(data, callback) {
  delte(
    { endPoint: "online/inventoryVariant/deleteInventoryVariantProduct" },
    data,
    function(response) {
      callback(response);
    }
  );
}
