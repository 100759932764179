import {
  Grid,
  DialogActions,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
  Box,
  Menu,
  InputLabel,
  FormControl,
  ButtonBase,
  Zoom,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import { IoMdArrowDropleft } from "react-icons/io";
import { styled } from "@mui/material/styles";
import "./Services.css";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { array, object } from "prop-types";
import "./CreateRepairingService.css";
import { warning } from "@remix-run/router";
import { findIndex } from "lodash";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SimpleMenu from "../../widgets/SimpleMenu";
import { Warning } from "@mui/icons-material";
import { getTodayDate,pad } from "../../utils/common";

import {
  getAllRepairingFaults,
  createRepairingFaults,
  deleteRepairingFaultsByID,
  getRepairingFaultsByID,
} from "../../api/ServicesFaultAPI";
import {
  getAllRepairingServices,
  createRepairingService,
  updateRepairingService,
  getRepairingServiceByID,
  deleteRepairingServiceByID,
} from "../../api/ServicesRepairingAPI";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const servicesInputObject = {
  name: "",
  phone_number: "",
  amount: "",
  date: "",
  description: "",
};

function CreateRepairingService() {
  const [openCashDialog, setOpenCashDialog] = useState(false);
  const [chips, setChips] = useState([]);
  const [activeIssues, setActiveIssues] = useState([]);
  const [deleteChips, setDeleteChips] = useState();
  const [name, setName] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [serviceInput, setServiceInput] = useState(servicesInputObject);
  const [valueChange, setValueChange] = useState("Add");

  const [edit, setEdit] = useState(false);
  const {
    smDown,
    query,
    showLoader,
    hideLoader,
    location,
    flashMessage,
    navigate,
  } = useContext(GlobalData);

  const service_id = location.state ? location.state.service_id : 0;



  const [serviceDateInput, setServiceDateInput] = useState("");

  const handleClick = (index) => {
    let chipsData = [...chips];
    chipsData[index].active = !chipsData[index].active;
    if (chipsData[index].active) {
      let array = [...activeIssues];
      array.push(chipsData[index]);
      setActiveIssues(array);
    } else {
      const activeOjectIndex = activeIssues.findIndex(
        (object) => object.id === chipsData[index].id
      );
      let array = [...activeIssues];
      array.splice(activeOjectIndex, 1);
      console.log(array);
    }
    setChips(chipsData);
  };

  const handleDelete = (index) => {
    let deleteChips = [...chips];
    deleteRepairingFaultsMethod(chips[index].id);
    deleteChips.splice(index, 1);
    setChips(deleteChips);
  };
  const btnClick = () => {
    let DataChips = [...chips];
    DataChips.push({
      id:
        DataChips.length > 0
          ? Number(DataChips[DataChips.length - 1].id) + 1
          : 1,
      name: name,
      active: false,
      textColor: "#fff",
    });
    handleCashDialogClose();
    setChips(DataChips);
    createRepairingFaultsMethod(name);
  };
  const inputChange = (e) => {
    let name = e.target.value;
    setName(name);
  };

  const handleCashDialogOpen = () => {
    setOpenCashDialog(true);
  };

  const handleCashDialogClose = () => {
    setOpenCashDialog(false);
  };

  useEffect(() => {
    getAllRepairingfaultMethod();
    if (location.state) {
      setEdit(location.state.edit);
      let serviceData = {
        id: service_id,
      };
      getRepairingServiceByID(serviceData, (resp) => {
        if (resp.statusCode === 200) {
          console.log(resp.successData);
          setServiceDateInput(resp.successData.service.date);
          setServiceInput(resp.successData.service);
          hideLoader();
        } else {
          hideLoader();
          flashMessage("error", resp.message);
        }
      });
    } else {
      setServiceDateInput(getTodayDate);
    }
  }, []);

  useEffect(() => {
    if (
      serviceInput.repairing_service_fault &&
      serviceInput.repairing_service_fault.length > 0
    ) {
      let chipsData;
      serviceInput.repairing_service_fault.map((serviceFault, sfIndex) => {
        chipsData = [...chips];
        chips.map((chip, cIndex) => {
          if (chip.id === serviceFault.repairing_faults_id) {
            chipsData[cIndex].active = true;
          }
        });
      });
      setChips(chipsData);
    }
  }, [serviceInput]);

  const getAllRepairingfaultMethod = () => {
    let Data = {};
    showLoader();
    getAllRepairingFaults(Data, (resp) => {
      if (resp.statusCode === 200) {
        console.log(resp.successData.faults);
        setChips(resp.successData.faults);
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const createRepairingFaultsMethod = () => {
    showLoader();
    let formData = new FormData();
    formData.append("name", name);
    formData.append("amount", "0");
    createRepairingFaults(formData, (resp) => {
      if (resp.statusCode === 200) {
        let chipsData = [...chips];
        chipsData.push(
          resp.successData.faults[resp.successData.faults.length - 1]
        );
        setChips(chipsData);
        hideLoader();
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  const deleteRepairingFaultsMethod = (id) => {
    showLoader();
    let serviceData = {
      id: id,
    };
    deleteRepairingFaultsByID(serviceData, (resp) => {
      if (resp.statusCode === 200) {
        let chipsData = [...chips];
        let cIndex = chipsData.findIndex((value, index) => value.id === id);
        chipsData.splice(cIndex, 1);
        setChips(chipsData);
        hideLoader();
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  const getRepairingFaultsByIDMethod = (id) => {
    showLoader();
    let serviceData = {
      id: 22,
    };
    getRepairingFaultsByID(serviceData, (resp) => {
      if (resp.statusCode === 200) {
        hideLoader();
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  const saveRepairing = () => {
    // console.log(activeIssues);
    // console.log(serviceInput);
  };

  const handleServiceFields = (e) => {
    const { name, value } = e.target;
    setServiceInput({
      ...serviceInput,
      [name]: value,
    });
  };

 
  const createRepairingMethod = () => {
    let formData = new FormData();
    formData.append("name", serviceInput.name);
    formData.append("phone_number", serviceInput.phone_number);
    formData.append("description", serviceInput.description);
    formData.append("amount", serviceInput.amount);
    let issues = [];
    chips.forEach((value, index) => {
      if (value.active) {
        issues.push(value.id);
      }
    });
    formData.append("repairing_faults_id", JSON.stringify(issues));
    if (issues.length === 0) {
      flashMessage("error", "Please Select Faults");
      return 0;
    }
    var ServiceDate = new Date(serviceDateInput);
    formData.append(
      "date",
      ServiceDate.getFullYear() +
        "-" +
        pad(ServiceDate.getMonth() + 1) +
        "-" +
        pad(ServiceDate.getDate())
    );
    showLoader();
    if (edit) {
      formData.append("repairing_service_id", service_id);
      updateRepairingService(formData, (resp) => {
        if (resp.statusCode === 200) {
          hideLoader();
          navigate("/services/mobile-repairing-services");
        } else {
          hideLoader();
          flashMessage("error", resp.message);
        }
      });
    } else {
      createRepairingService(formData, (resp) => {
        if (resp.statusCode === 200) {
          hideLoader();
          navigate("/services/mobile-repairing-services");
        } else {
          hideLoader();
          flashMessage("error", resp.message);
        }
      });
    }
  };

  const deleteRepairingMethod = (id) => {
    showLoader();
    let serviceData = {
      id: id,
    };
    deleteRepairingServiceByID(serviceData, (resp) => {
      if (resp.statusCode === 200) {
        console.log(resp.successData.service);
        // setServiceInput(resp.successData.service);
        hideLoader();
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openCashDialog}
        onClose={handleCashDialogClose}
      >
        <DialogTitle>Add Repairing Fault</DialogTitle>
        <DialogContent className="div__scroll">
          <Grid container>
            <Grid item xs={12}>
              <Box marginBottom="15px">
                <div className="field_text_main_global">Fault Name</div>
                <CustomTextField
                  placeholder="Fault Name"
                  className="textField__AddInventory"
                  onChange={inputChange}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCashDialogClose}
            className="download_report_btn__orders"
          >
            Cancel
          </Button>
          <Button onClick={btnClick} className="add_order_btn__orders">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container height="100vh" className="padn_invoice__icon">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <NavigationBar
            path={"Dashboard>Service Management>Services"}
            paddingBottom={3}
          />
          <Box height={smDown ? "30vh" : "20vh"}>
            <Grid display="flex" justifyContent="center" container>
              <Grid
                className="header__ai"
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                item
              >
                <div
                  className="header_back_btn__ai"
                  onClick={() => {
                    navigate("/services/mobile-repairing-services");
                  }}
                >
                  <IoMdArrowDropleft className="add_vendor_back_icon" />
                  Back
                </div>
                <div className="header_text__ai">
                  Repairing Service Management{" "}
                  {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                  <span style={smDown ? { fontSize: "14px" } : {}}>
                    {edit ? "Update" : "Create"} Repairing Service
                  </span>
                </div>
              </Grid>
            </Grid>
          </Box>

          {/* <Button variant="contained" className="footer_add__ai" onClick={()=> {
             deleteRepairingFaultsMethod();
          }}>
            delete method
          </Button>

          <Button variant="contained" className="footer_add__ai" onClick={()=> {
            getRepairingFaultsByIDMethod();
          }}>
            getById method
          </Button> */}

          <Box className="div__scroll" height={smDown ? "70vh" : "80vh"}>
            <Grid container className="card__ai">
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                item
                paddingTop={query(2, 2, 2)}
                paddingLeft={query(5, 2, 1)}
                paddingRight={query(5, 2, 1)}
                id="addOrderStyle"
              >
                <Grid container display="flex" justifyContent="center">
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>Name</div>
                    <CustomTextField
                      placeholder="Enter your Name"
                      className="textField__AddInventory"
                      onChange={handleServiceFields}
                      name="name"
                      value={serviceInput.name}
                      type="text"
                    />
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>Phone Number</div>
                    <CustomTextField
                      placeholder="Enter your Phone number"
                      className="textField__AddInventory"
                      onChange={handleServiceFields}
                      value={serviceInput.phone_number}
                      name="phone_number"
                      type="number"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    xs={6}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <Box
                      paddingLeft={smDown ? "10px" : "0"}
                      marginTop={smDown ? "22px" : "22px"}
                    >
                      <div className="field_text_main_global">Amount</div>
                      <CustomTextField
                        placeholder="Enter your Amount"
                        className="textField__AddInventory"
                        onChange={handleServiceFields}
                        name="amount"
                        value={serviceInput.amount}
                        type="number"
                      />
                    </Box>
                  </Grid>

                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <Box mt={2.3}>
                      <div className="field_text_main_global">Service Date</div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="bnkmnu_field__bank"
                          value={serviceDateInput}
                          onChange={(newValue) => {
                            setServiceDateInput(newValue);
                          }}
                          renderInput={(params) => (
                            <CustomTextField error={false} {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    xs={6}
                    item
                    paddingTop={3}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div className="field_text_main_global">
                      Description (Optional)
                    </div>
                    <FocusedTextField
                      multiline={true}
                      rows={3}
                      placeholder={"Issues"}
                      className={"textField__services"}
                      onChange={handleServiceFields}
                      name="description"
                      value={serviceInput.description}
                      type="text"
                    />
                  </Grid>
                  <Grid xs={6} xl={6} lg={6} md={6} sm={6} item mt={2} pl={3}>
                    <IconButton onClick={handleCashDialogOpen}>
                      <Box className="servise__plus_icon">
                        <AddIcon />
                      </Box>
                    </IconButton>
                    <Box display="flex" className="chip_cont__adrepser">
                      {chips.map((object, index) => (
                        <Box ml={1} mt={1}>
                          <Chip
                            key={object.key + index}
                            label={object.name}
                            style={{
                              color: object.textColor,
                              backgroundColor: object.active
                                ? "#1e2738"
                                : "#8d9aaa",
                              color: "white",
                            }}
                            clickable
                            onClick={() => {
                              handleClick(index);
                            }}
                            onDelete={() => {
                              handleDelete(index);
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container paddingTop={5} paddingBottom={5}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    item
                    paddingLeft={smDown ? 1 : 0}
                    display="flex"
                    justifyContent="start"
                  >
                    <Button
                      variant="contained"
                      id="discard_btn_create_repairing_service"
                      className="footer_dis__ai"
                      onClick={() => {
                        navigate("/services/mobile-repairing-services");
                      }}
                    >
                      Discard
                    </Button>
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    item
                    paddingRight={smDown ? 1 : 0}
                    display="flex"
                    justifyContent="end"
                  >
                    <Button
                      id="repairing_btn_create_repairing_service"
                      variant="contained"
                      onClick={createRepairingMethod}
                      className="footer_add__ai"
                    >
                      {edit ? "Update" : "Save"} Repairing
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

const FocusedTextField = (props) => {
  return (
    <CustomTextField
      multiline={props.multiline}
      rows={props.rows}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      key={props.key}
      placeholder={props.placeholder}
      className={props.className}
    />
  );
};
export default CreateRepairingService;
