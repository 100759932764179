
import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TableCell,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Menu,
} from "@mui/material";
import { IoMdArrowDropleft } from "react-icons/io";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import {debounce}from'lodash';
import { ReactComponent as ForwardIcon } from "../../assets/SVG/ForwardIconInventory.svg";
import { ReactComponent as BackwardIcon } from "../../assets/SVG/BackwardIconInventory.svg";
import './ViewSelectedInventoryOnline.css';
import {moveProductsToOnline,GetOnlineProductsbyIdsArray} from '../../api/MoveInventoryAPI'
import ViewInventoryCard from '../../widgets/ViewInventoryCard'
function ViewSelectedInventoryOnline() {
  
  const {
    smDown,
    navigate,
    location,
    showLoader,
    flashMessage,
    hideLoader,
    query,
    onlyXs,
  } = useContext(GlobalData);
const[totalInventoryArray,setTotalInventoryArray]=useState(location.state.ids)
const[totalIndex,setTotalIndex]=useState(totalInventoryArray.length)
const [allProducts, setAllProducts] = useState([]);
const [toMoveProducts, setToMoveProducts] = useState([]);
const[product,setProduct]=useState('');
const[variant,setVariant]=useState([]);
const[currentIndex,setCurrentIndex]=useState(0);
const [variantLength,setVariantLength]=useState(0);
const [qunatity,setQuantity]=useState()

const handlegetProduct=(arr)=>{
  if(allProducts.length){

   setProduct(allProducts[arr])
   setVariant(allProducts[arr].variants) 
  setVariantLength(allProducts[arr].variants.length)
  }
else{}

}
const getAllProducts=()=>{

  showLoader();
  //("totalInventoryArray",totalInventoryArray)
  let data={id:JSON.stringify(totalInventoryArray)};
  GetOnlineProductsbyIdsArray(data,(resp)=>{
    if(resp.statusCode===200)
    {  hideLoader();
      setAllProducts(resp.successData.product)
      setProduct(resp.successData.product[currentIndex])
      setVariant(resp.successData.product[currentIndex].variants) 
      setVariantLength(resp.successData.product[currentIndex].variants.length)
     }
    else{
      hideLoader();
      flashMessage("error",resp.message)}})
}
const handleProductDelete=()=>
{showLoader();
const tempPro=[...allProducts]
//const temp=[...totalInventoryArray];
//console.log("CCCCC",tempPro);
//const x=temp.splice(currentIndex, 1);
const y=tempPro.splice(currentIndex,1)
 //console.log("CCCCC",y);
//setTotalInventoryArray(temp);
setAllProducts(tempPro)
setTotalIndex(tempPro.length)
if(currentIndex > 0 && currentIndex<totalIndex)
{
  setCurrentIndex(currentIndex-1);
  handlegetProduct(currentIndex-1);
}
else if
(currentIndex === 0)
{
  handlegetProduct(0);
}
else{
  handlegetProduct(currentIndex)}
//console.log("The value after removing",totalInventoryArray);
hideLoader()}

const handleRemoveAllVariants=(temp)=>{
  
  if(variant.length===1)
  {//console.log("Thi sis variants",variant.length)
    handleProductDelete()
  }
  else
  {//console.log("Thi sis variants",variant.length)
  }
}
useEffect(()=>{

 // handlegetProduct(currentIndex)

},[allProducts.length])

useEffect(()=>{

  getAllProducts()
 
},[]) ;

 
const handleAllProducts=(index)=>{
  product.variants=variant;
//console.log("they called when changing variants",product)
let ProductsData = [...allProducts];
ProductsData[index]=product;
setAllProducts(ProductsData)
  // setAllProducts((existingProducts) => {

  //   return existingProducts.map((item, ind) => {

  //     return ind === index ? { ...item,...product} : item;

  //   });
  // });
 }

const handleForwardProduct=()=>{
  handleAllProducts(currentIndex)
  if(currentIndex+1<allProducts.length)
  {
    setCurrentIndex(currentIndex+1);
   
    handlegetProduct(currentIndex+1);}
  else{
    flashMessage("error","Selected Products Ended");
}

}

const handleBackwardProduct=()=>{
  handleAllProducts(currentIndex)
  if(currentIndex+1>1)
  {setCurrentIndex(currentIndex-1);
   
    handlegetProduct(currentIndex-1);
  }

  else
  {  flashMessage("error","Selected Products Ended");
}
}
const moveToOnline=(type)=>{
  if(type ==='indivisual'){
    product.variants=variant;
//setProduct({...product,variants:variant})
// console.log("Product",product)
// console.log('variant',variant)
const formData=new FormData()
formData.append("variants",JSON.stringify([product]));
formData.append("from","onlineinventory")
// console.log("Variantsssssssssss",variant)
moveProductsToOnline(formData,(resp)=>{
  if(resp.statusCode===200){
    flashMessage("success",resp.message)
    handleProductDelete(currentIndex);
  }else{
    flashMessage("error",resp.message)
  }
})
  }
  else
  { 
    // console.log("Variantsssssssssss",allProducts)  
      handleAllProducts(currentIndex)
    const formData=new FormData()
    formData.append("variants",JSON.stringify(allProducts));
    formData.append("from","onlineinventory")
      moveProductsToOnline(formData,(resp)=>{
        if(resp.statusCode===200){
          flashMessage("success",resp.message)
          navigate('/products/online-product')
        }else{
          flashMessage("error",resp.message)
        }
      })
 
  }



  //console.log("Variant to Move",variant)
}

  return (  
    <Grid container height="100vh" className="padn_invoice__icon" >
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
    <Box height={smDown ? "30vh" : "20vh"}>
            <NavigationBar
              path={
                "Dashboard >Online Inventory Management > View Selected Inventory"
              }
              paddingBottom={1}
            />
            <Grid
    display="flex"
    justifyContent="center"
    container
    mb={2}
    mt={2}
  >
    <Grid
      className="header__ai__view_sel"
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      item
    >
      <div
        className="header_back_btn__ai"
        onClick={() => {
            navigate('/products/online-product');
        }}
      >
        <IoMdArrowDropleft
        className="add__icon_orders_sty"
        />
        Back
      </div>
      <div className="header_text__ai">
        Move Selected Inventory to Store 
      </div>
    </Grid>
  </Grid>
  </Box>
  {/* ====================================Main Page Content======================================= */}
  <Grid container  xl={12}
lg={12}
md={12}
sm={12}
xs={12}  
    marginTop="10px"
   
    
    className="main_back padng_global_projct">
    <Grid   xl={12}

      lg={12}
      md={12}
      sm={12}
      xs={12} item
      justifyContent="space-evenly" 
      display="flex"
      marginBottom="15px"
 >
        <div  
      xl={4}
      lg={4}
      md={4}
      sm={6}
      xs={6}
      className={"inv_frwd"}
      onClick={()=>{handleBackwardProduct()}}>
<BackwardIcon/>
      </div>
      <div  
      xl={4}
      lg={4}
      md={4}
      sm={6}
      xs={6} className={"inv_head"}>
        Selected Inventory <br/><div> ( {currentIndex+1} /  {allProducts.length} )</div>
  </div>

      <div 
      xl={4}
      lg={4}
      md={4}
      sm={6}
      xs={6}
      className={"inv_frwd"}
      onClick={()=>{handleForwardProduct()}}  >
<ForwardIcon   />
      </div>
      </Grid>
      <Grid xl={10} lg={10} md={10} sm={12} xs={12} marginLeft={"90px"}
      marginTop={"10px"} >  
       {product ? (
         allProducts.length ?( 
           <ViewInventoryCard 
          product={product}
          variants={product.variants}
          variantLength={variantLength}
         changeProductValue={(temp)=>{setTimeout(()=>{setVariant(temp)},200)}}
         removeVariantValue={(temp)=>{
          setVariant(temp);
          handleRemoveAllVariants(temp)}}
         handleProductRemove={()=>{handleProductDelete()}}
         
         />
         ):(<Grid xl={10} lg={10} md={10} sm={12} xs={12} 
          marginTop={"20px"} marginBottom={"20px"}justifyContent={"center"} display="flex" marginLeft={"55px"}>
            <Box className={'no_prod_txt'}  >No Product Found!</Box></Grid>)
       
       ):(<Grid ></Grid>)}
 </Grid>  
 {allProducts.length!==0?(<Grid xl={12} lg={12} md={12} sm={12} xs={12}  item
      justifyContent="space-evenly"
      paddingBottom={"10px"}
      marginTop="5px" 
      display="flex">
        <Button
                    onClick={() => {
                        navigate('/products/online-product');
                    }}
                    variant="contained"
                    className="discard_btn"
                  >
                  Discard
                  </Button>
           <Button
                    onClick={() => {moveToOnline('indivisual')
                     
                    }}
                    variant="contained"
                    className="move_btn"
                  >
                  Move
                  </Button>
                  <Button
                    onClick={() => {moveToOnline('all')
                     
                    }}
                    variant="contained"
                    className="move_all_btn"
                  >
                  Move All
                  </Button>

      </Grid>):(<></>)}
 
  </Grid>
  
          </Grid>
       
          </Grid>
  )
}

export default ViewSelectedInventoryOnline