import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TableCell,
  Dialog,
  DialogContent,
} from "@mui/material";
import { IoMdArrowDropleft } from "react-icons/io";
import { styled } from "@mui/material/styles";
import { GlobalData } from "../../App";
import { addProduct } from "../../api/InventoryAPI";
import "./AddInventory.css";
import NavigationBar from "../NavigationBar";
import AutoScrollContainer from "auto-scroll-container";
import { getAllColors } from "../../api/ColorAPI";
import { getAllMaterials } from "../../api/MaterialAPI";
import { getAllCategories } from "../../api/CategoryAPI";
import { ReactComponent as WhitePenIcon } from "../../assets/SVG/WhitePenIcon.svg";
import { ReactComponent as SquareAddIcon } from "../../assets/SVG/SquareAddIcon.svg";
import { ReactComponent as UploadImageIcon } from "../../assets/SVG/UploadImageIcon.svg";
import { IoMdCloseCircle } from "react-icons/io";

const variantMobile = [
  {
    id: "imei",
    label: "IMEI No.",

    align: "left",
  },
  {
    id: "color",
    label: "Color",

    align: "left",
  },
  {
    id: "storage",
    label: "Storage",

    align: "left",
  },
  {
    id: "ram",
    label: "RAM",

    align: "left",
  },
  {
    id: "quantity",
    label: "Quantity",

    align: "left",
  },
  {
    id: "cost_price",
    label: "C.Price",

    align: "left",
  },
  {
    id: "sale_price",
    label: "S.Price",

    align: "left",
  },
  {
    id: "min_sale_price",
    label: "Min-S.Price",

    align: "left",
  },
];

const productObject = {
  name: "",
  source: "",
  material: "",
  category: "",
  image: "",
  variants: [],
};

const variantObject = {
  imei: "",
  color: "",
  quantity: 1,
  storage: "",
  ram: "",
  cost_price: "0",
  sale_price: "",
  min_sale_price: "",
  description: "",
};

const CustomTextFieldInventory = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
  },
});

const CustomTextFieldVariant = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,${true ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,${true ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,${true ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
  },
});

const FocusedTextFieldMultiline = (props) => {
  return (
    <CustomTextFieldVariant
      multiline
      rows={props.rows}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      key={props.key}
      placeholder={props.placeholder}
      className={props.className}
    />
  );
};

const FocusedTextField = (props) => {
  return (
    <CustomTextFieldInventory
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      key={props.key}
      placeholder={props.placeholder}
      className={props.className}
    />
  );
};

const FocusedTextFieldVariant = (props) => {
  return (
    <CustomTextFieldVariant
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      key={props.key}
      placeholder={props.placeholder}
      className={props.className}
      type={props.type}
    />
  );
};

function AddInventory(props) {
  const {
    flashMessage,
    smDown,
    query,
    navigate,
    onlyXs,
    showLoader,
    hideLoader,
    userObject,
  } = useContext(GlobalData);
  const [imageUri, setImageUri] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [product, setProduct] = useState(productObject);
  const [variant, setVariant] = useState(variantObject);
  const [editVariantId, setEditVariantId] = useState("");
  const [imeiChipsInput, setImeiChipsInput] = useState("");
  const [variantDialog, setVariantDialog] = useState(false);
  const [variantDialogMode, setVariantDialogMode] = useState("add");

  const [materials, setMaterials] = useState([]);
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);

  const checkNullUndefinedReturnEmpty = (value) => {
    if (value == null || value == "") {
      return "0";
    } else {
      return value;
    }
  };

  const checkNullUndefined = (value) => {
    if (value == null || value == "") {
      return "0";
    } else {
      return value;
    }
  };

  // Use Effect

  useEffect(() => () => handleOpenAddVariantDialog(), [product.category]);

  useEffect(() => {
    setProduct({
      ...product,
      ["variants"]: [],
    });

    getAllMaterials({ shop_id: userObject.shop.id }, (resp) => {
      let materials = [];
      resp.successData.materials.map((value, index) => {
        materials.push({
          id: index + 1,
          material: value,
        });
      });
      setMaterials(materials);
    });
    getAllCategories({ shop_id: userObject.shop.id }, (resp) => {
      let categories = [];
      resp.successData.categories.map((value, index) => {
        categories.push({
          id: index + 1,
          category: value,
        });
      });
      setCategories(categories);
    });
    getAllColors({ shop_id: userObject.shop.id }, (resp) => {
      let colors = [];
      resp.successData.colors.map((value, index) => {
        colors.push({
          id: index + 1,
          color: value,
        });
      });
      setColors(colors);
    });
  }, []);

  // Input Change Handling Methods

  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const handleVariantInputChange = (e) => {
    const { name, value } = e.target;
    setVariant({
      ...variant,
      [name]: value,
    });
  };

  // Image File Saving Method for Product

  function saveImageFile(e) {
    let image = document.getElementById("productImage").files;
    setImageFile(image[0]);
    setImageUri(window.URL.createObjectURL(new Blob(image, { type: "*" })));
  }

  //######################################################
  // *** Start Variant Methods ***

  // *** Variant Dialog Box ***

  // Add Variant Dialog Box

  const handleOpenAddVariantDialog = () => {
    setVariant({
      ...variant,
      ["imei"]: "",
      ["color"]: "",
      ["quantity"]: 1,
      ["storage"]: "",
      ["ram"]: "",
      ["cost_price"]: 0,
      ["sale_price"]: "",
      ["min_sale_price"]: "",
      ["description"]: "",
    });
    setImeiChipsInput("");
    setVariantDialogMode("add");
    setVariantDialog(true);
  };

  // Close Variant Dialog Box

  const handleCloseAddVariantDialog = () => {
    setVariantDialog(false);
  };

  // Set Variant Data for Edit Variant Dialog Box and also Open Variant Dialog Box

  const handleOpenEditVariantDialog = (index) => {
    let variantData = product.variants;
    setVariant({
      ...variant,
      ["imei"]: "",
      ["color"]: variantData[index].color,
      ["quantity"]: variantData[index].quantity,
      ["storage"]: variantData[index].storage,
      ["ram"]: variantData[index].ram,
      ["cost_price"]: variantData[index].cost_price,
      ["sale_price"]: variantData[index].sale_price,
      ["min_sale_price"]: variantData[index].min_sale_price,
      ["description"]: variantData[index].description,
    });
    setImeiChipsInput(variantData[index].imei);
    setEditVariantId(index);
    setVariantDialogMode("edit");
    setVariantDialog(true);
  };

  // *** Variant CRUD ***
  // Delete Variant

  const handleDeleteVariant = () => {
    let variantData = product.variants;
    variantData.splice(editVariantId, 1);
    setProduct({
      ...product,
      ["variants"]: variantData,
    });
    handleCloseAddVariantDialog();
  };

  // Edit Variant

  const handleEditVariant = () => {
    let categoryName = product.category.split(",")[0];
    let variantData = variant;
    if (
      imeiChipsInput === "" &&
      (categoryName === "Mobile" || categoryName === "Laptop")
    ) {
      flashMessage("error", "Please Enter IMEI/MAC");
    } else if (variantData.color === "") {
      flashMessage("error", "Please Select Color");
    } else if (variantData.quantity === "") {
      flashMessage("error", "Please Enter Quantity");
    } else if (variantData.cost_price === "") {
      flashMessage("error", "Please Enter Cost Price");
    } else if (variantData.sale_price === "") {
      flashMessage("error", "Please Enter Sale Price");
    } else if (
      !(
        Number(variantData.sale_price) > Number(variantData.cost_price) &&
        Number(variantData.sale_price) > Number(variantData.min_sale_price)
      )
    ) {
      flashMessage("error", "Invalid Sale Price");
    } else if (variantData.min_sale_price === "") {
      flashMessage("error", "Please Enter Min. Sale Price");
    } else if (
      !(
        Number(variantData.min_sale_price) > Number(variantData.cost_price) &&
        Number(variantData.min_sale_price) < Number(variantData.sale_price)
      )
    ) {
      flashMessage(
        "error",
        "Minimum sale price should less than sale price but greater than cost price."
      );
    } else {
      variantData = product.variants[editVariantId];
      variantData.imei = checkNullUndefinedReturnEmpty(imeiChipsInput);
      variantData.color = variant.color;
      variantData.quantity = variant.quantity;
      variantData.storage = checkNullUndefinedReturnEmpty(variant.storage);
      variantData.ram = checkNullUndefinedReturnEmpty(variant.ram);
      variantData.cost_price = variant.cost_price;
      variantData.sale_price = variant.sale_price;
      variantData.min_sale_price = variant.min_sale_price;
      variantData.description = variant.description;
      handleCloseAddVariantDialog();
    }
  };

  // Add Variant

  const addVariantToProduct = () => {
    let categoryName = product.category.split(",")[0];
    let productVariant = product.variants;
    let variantData = variant;
    if (
      imeiChipsInput === "" &&
      (categoryName === "Mobile" || categoryName === "Laptop")
    ) {
      flashMessage("error", "Please Enter IMEI/MAC");
    } else if (variantData.color === "") {
      flashMessage("error", "Please Select Color");
    } else if (variantData.quantity === "") {
      flashMessage("error", "Please Enter Quantity");
    } else if (variantData.cost_price === "") {
      flashMessage("error", "Please Enter Cost Price");
    } else if (variantData.sale_price === "") {
      flashMessage("error", "Please Enter Sale Price");
    } else if (
      !(
        Number(variantData.sale_price) > Number(variantData.cost_price) &&
        Number(variantData.sale_price) > Number(variantData.min_sale_price)
      )
    ) {
      flashMessage("error", "Invalid Sale Price");
    } else if (variantData.min_sale_price === "") {
      flashMessage("error", "Please Enter Min. Sale Price");
    } else if (
      !(
        Number(variantData.min_sale_price) > Number(variantData.cost_price) &&
        Number(variantData.min_sale_price) < Number(variantData.sale_price)
      )
    ) {
      flashMessage(
        "error",
        "Minimum sale price should less than sale price but greater than cost price."
      );
    } else {
      variantData.imei = checkNullUndefinedReturnEmpty(imeiChipsInput);
      variantData.ram = checkNullUndefinedReturnEmpty(variantData.ram);
      variantData.storage = checkNullUndefinedReturnEmpty(variantData.storage);
      productVariant.push(variantData);
      setProduct({
        ...product,
        ["variants"]: productVariant,
      });
      handleCloseAddVariantDialog();
    }
  };

  // *** Create Variant Methods ***
  // #########################################################################

  const createProduct = () => {
    showLoader();
    if (product.variants.length === 0) {
      hideLoader();
      flashMessage("error", "Please add variant!");
    } else {
      let formData = new FormData();
      formData.append("name", product.name);
      formData.append("source", product.source);
      formData.append("material", product.material);
      formData.append("category", product.category.split(",")[0]);
      formData.append("category_id", product.category.split(",")[1]);
      if (imageFile) {
        formData.append("image", imageFile, imageFile.name);
      }
      formData.append("variants", JSON.stringify(product.variants));
      addProduct(formData, (resp) => {
        hideLoader();
        if (resp.statusCode === 200) {
          setProduct({
            ...product,
            ["variants"]: [],
          });
          navigate("/products");
          flashMessage("success", resp.message);
        } else {
          flashMessage("error", resp.message);
        }
      });
    }
  };

  // ##################################################
  // *** IMEI Chips Methods Start ***

  // Chip Auto Scroll To End

  useEffect(() => {
    let chip = document.getElementById("chip__chip");
    if (chip) {
      chip.scrollLeft = chip.scrollWidth - chip.scrollLeft;
      localStorage.setItem("chipScroll", chip.scrollLeft);
      localStorage.setItem("chipEndScroll", chip.scrollLeft);
    }
  });

  // Delete IMEI Chip

  const deleteIMEIChip = (index) => {
    let chips = imeiChipsInput.split(",");
    chips.splice(index, 1);
    chips = chips.reverse();
    setImeiChipsInput(String(chips));
  };

  // Edit IMEI Chip

  const editIMEIChip = (index) => {
    let chips = imeiChipsInput.split(",");
    setVariant({
      ...variant,
      ["imei"]: chips[index],
    });
    deleteIMEIChip(index);
  };

  const createIMEIChip = (e) => {
    if (["ArrowLeft"].includes(e.key)) {
      let chip = document.getElementById("chip__chip");
      if (chip) {
        let chipScroll = Number(localStorage.getItem("chipScroll"));
        console.log(chipScroll);
        if (chipScroll > 0) {
          localStorage.setItem("chipScroll", chipScroll - 10);
        }
        chip.scrollLeft = Number(localStorage.getItem("chipScroll"));
      }
    }
    if (["ArrowRight"].includes(e.key)) {
      let chip = document.getElementById("chip__chip");
      if (chip) {
        let chipScroll = Number(localStorage.getItem("chipScroll"));
        console.log(chipScroll, chip.scrollWidth);
        if (chipScroll < Number(localStorage.getItem("chipEndScroll"))) {
          localStorage.setItem("chipScroll", chipScroll + 10);
        }
        chip.scrollLeft = Number(localStorage.getItem("chipScroll"));
      }
    }
    if ([","].includes(e.key)) {
      e.preventDefault();
      setImeiChipsInput(
        imeiChipsInput.trim() +
          `${imeiChipsInput !== "" ? "," : ""}` +
          variant.imei.trim()
      );
      handleVariantInputChange(e);
      setVariant({
        ...variant,
        ["imei"]: "",
      });
    }
  };

  // *** Chips Methods End ***
  // ####################################################

  return (
    <Grid container height="100vh" className="padn_invoice__icon">
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <Box className="addVariantDialog__ai">
          <Dialog
            fullWidth
            maxWidth="lg"
            open={variantDialog}
            onClose={handleCloseAddVariantDialog}
          >
            <Box
              style={{ position: "absolute", right: "8px", top: "8px" }}
              onClick={handleCloseAddVariantDialog}
            >
              <IoMdCloseCircle
                size={28}
                color="red"
                style={{ cursor: "pointer" }}
              />
            </Box>
            <DialogContent id="addVariantDialogContent">
              {(product.category.split(",")[0] === "Mobile" ||
                product.category.split(",")[0] === "Laptop") && (
                <>
                  <Grid container padding={0}>
                    <Grid
                      xl={8}
                      lg={8}
                      md={8}
                      sm={12}
                      xs={12}
                      item
                      paddingRight={1}
                      paddingLeft={smDown ? 1 : 0}
                      id="imei_row"
                    >
                      <div style={{ marginBottom: "8px" }}>
                        {product.category.split(",")[0] === "Laptop"
                          ? "MAC Address"
                          : "IMEI No."}
                      </div>
                      <CustomTextFieldVariant
                        onKeyDown={createIMEIChip}
                        value={variant.imei}
                        onChange={handleVariantInputChange}
                        name="imei"
                        id="imei"
                        key="imei"
                        InputProps={{
                          startAdornment: (
                            <>
                              {imeiChipsInput ? (
                                <div id="chip__chip">
                                  {imeiChipsInput
                                    .split(",")
                                    .map((chip, index) => {
                                      let chipWidth = chip.length * 14;
                                      let chipCount = imeiChipsInput.split(",")
                                        .length;
                                      return (
                                        <>
                                          <Chip
                                            key={index}
                                            label={
                                              <Box textAlign="start">
                                                {chip}
                                              </Box>
                                            }
                                            variant="outlined"
                                            onClick={() => {
                                              editIMEIChip(index);
                                            }}
                                            onDelete={() => {
                                              deleteIMEIChip(index);
                                            }}
                                            style={{
                                              backgroundColor: "white",
                                              width: `${
                                                chipCount > 1
                                                  ? "auto"
                                                  : chipWidth.toString() + "px"
                                              }`,
                                            }}
                                          />
                                        </>
                                      );
                                    })}
                                  <span style={{ marginRight: "10px" }} />
                                </div>
                              ) : null}
                            </>
                          ),
                        }}
                        // helperText={

                        // }
                        placeholder={
                          imeiChipsInput === "" &&
                          `${
                            product.category.split(",")[0] === "Laptop"
                              ? "MAC Address"
                              : "IMEI No"
                          } 1, ${
                            product.category.split(",")[0] === "Laptop"
                              ? "MAC Address"
                              : "IMEI No"
                          } 2, ${
                            product.category.split(",")[0] === "Laptop"
                              ? "MAC Address"
                              : "IMEI No"
                          } 3`
                        }
                        className="textField_variant__AddInventory"
                      />

                      <Box color="rgb(0,0,0,.6)" padding={1} paddingTop={0.5}>
                        {product.category.split(",")[0] === "Laptop"
                          ? "MAC Address"
                          : "IMEI Number"}{" "}
                        must be separated by comma{" "}
                        <strong>(&nbsp;,&nbsp;)</strong>
                      </Box>
                    </Grid>

                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={1}
                      paddingRight={smDown ? 1 : 0}
                      paddingTop={smDown ? 2 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Color</div>
                      <FormControl className="textField_variant__AddInventory">
                        {variant.color === "" && (
                          <InputLabel sx={{ opacity: 0.6 }}>
                            Select Product Color
                          </InputLabel>
                        )}

                        <CustomTextFieldVariant
                          select
                          value={variant.color}
                          onChange={handleVariantInputChange}
                          name="color"
                          id="color"
                          key="color"
                        >
                          {colors.map((option) => (
                            <MenuItem key={option.color} value={option.color}>
                              {option.color}
                            </MenuItem>
                          ))}
                        </CustomTextFieldVariant>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    display="flex"
                    paddingTop={2}
                    justifyContent="space-around"
                  >
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingRight={1}
                      paddingLeft={smDown ? 1 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>RAM</div>
                      <FocusedTextFieldVariant
                        value={variant.ram}
                        onChange={handleVariantInputChange}
                        name="ram"
                        id="ram"
                        key="ram"
                        placeholder="Device RAM"
                        className="textField_variant__AddInventory"
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={1}
                      paddingRight={1}
                      paddingTop={smDown ? 2 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Storage</div>

                      <FocusedTextFieldVariant
                        value={variant.storage}
                        onChange={handleVariantInputChange}
                        name="storage"
                        id="storage"
                        key="storage"
                        placeholder="Device Storage"
                        className="textField_variant__AddInventory"
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={1}
                      paddingRight={smDown ? 1 : 0}
                      paddingTop={smDown ? 2 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Quantity</div>
                      <CustomTextFieldVariant
                        value={variant.quantity}
                        onChange={handleVariantInputChange}
                        name="quantity"
                        id="quantity"
                        key="quantity"
                        type="number"
                        className="textField_variant__AddInventory"
                        InputProps={{
                          inputProps: { min: 1 },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    display="flex"
                    paddingTop={2}
                    justifyContent="space-around"
                  >
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingRight={1}
                      paddingLeft={smDown ? 1 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Cost Price</div>
                      <FocusedTextFieldVariant
                        value={variant.cost_price}
                        onChange={handleVariantInputChange}
                        name="cost_price"
                        id="cost_price"
                        key="cost_price"
                        placeholder="Product Cost Price"
                        className="textField_variant__AddInventory"
                        type="number"
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={1}
                      paddingRight={1}
                      paddingTop={smDown ? 2 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Sale Price</div>

                      <FocusedTextFieldVariant
                        value={variant.sale_price}
                        onChange={handleVariantInputChange}
                        name="sale_price"
                        id="sale_price"
                        key="sale_price"
                        placeholder="Product Sale Price"
                        className="textField_variant__AddInventory"
                        type="number"
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={1}
                      paddingRight={smDown ? 1 : 0}
                      paddingTop={smDown ? 2 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Min. Sale Price</div>
                      <FocusedTextFieldVariant
                        value={variant.min_sale_price}
                        onChange={handleVariantInputChange}
                        name="min_sale_price"
                        id="min_sale_price"
                        key="min_sale_price"
                        placeholder="Product Min. Sale Price"
                        className="textField_variant__AddInventory"
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {product.category.split(",")[0] !== "Mobile" &&
                product.category.split(",")[0] !== "Laptop" && (
                  <>
                    <Grid
                      container
                      display="flex"
                      paddingTop={2}
                      justifyContent="space-around"
                    >
                      <Grid
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        item
                        paddingRight={1}
                        paddingLeft={smDown ? 1 : 0}
                      >
                        <div style={{ marginBottom: "8px" }}>Color</div>
                        <FormControl className="textField_variant__AddInventory">
                          {variant.color === "" && (
                            <InputLabel sx={{ opacity: 0.6 }}>
                              Select Product Color
                            </InputLabel>
                          )}

                          <CustomTextFieldVariant
                            select
                            value={variant.color}
                            onChange={handleVariantInputChange}
                            name="color"
                            id="varient_color__inventory"
                            key="color"
                          >
                            {colors.map((option) => (
                              <MenuItem key={option.color} value={option.color}>
                                {option.color}
                              </MenuItem>
                            ))}
                          </CustomTextFieldVariant>
                        </FormControl>
                      </Grid>
                      <Grid
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        item
                        paddingLeft={1}
                        paddingRight={1}
                        paddingTop={smDown ? 2 : 0}
                      >
                        <div style={{ marginBottom: "8px" }}>Quantity</div>
                        <CustomTextFieldVariant
                          value={variant.quantity}
                          onChange={handleVariantInputChange}
                          name="quantity"
                          id="varient_quantity__inventory"
                          key="quantity"
                          type="number"
                          className="textField_variant__AddInventory"
                          InputProps={{
                            inputProps: { min: 1 },
                          }}
                        />
                      </Grid>
                      <Grid
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        item
                        paddingLeft={1}
                        paddingRight={smDown ? 1 : 0}
                        paddingTop={smDown ? 2 : 0}
                      >
                        <div style={{ marginBottom: "8px" }}>Cost Price</div>
                        <FocusedTextFieldVariant
                          value={variant.cost_price}
                          onChange={handleVariantInputChange}
                          name="cost_price"
                          id="varient_cost_price__inventory"
                          key="cost_price"
                          placeholder="Product Cost Price"
                          className="textField_variant__AddInventory"
                          type="number"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      display="flex"
                      paddingTop={2}
                      justifyContent="space-around"
                    >
                      <Grid
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        item
                        paddingRight={1}
                      >
                        <div style={{ marginBottom: "8px" }}>Sale Price</div>

                        <FocusedTextFieldVariant
                          value={variant.sale_price}
                          onChange={handleVariantInputChange}
                          name="sale_price"
                          id="varient_sale_price__inventory"
                          key="sale_price"
                          placeholder="Product Sale Price"
                          className="textField_variant__AddInventory"
                          type="number"
                        />
                      </Grid>
                      <Grid
                        xl={6}
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        item
                        paddingLeft={1}
                        paddingRight={smDown ? 1 : 0}
                        paddingTop={smDown ? 2 : 0}
                      >
                        <div style={{ marginBottom: "8px" }}>
                          Min. Sale Price
                        </div>
                        <FocusedTextFieldVariant
                          value={variant.min_sale_price}
                          onChange={handleVariantInputChange}
                          name="min_sale_price"
                          id="varient_min_sale_price__inventory"
                          key="min_sale_price"
                          placeholder="Product Min. Sale Price"
                          className="textField_variant__AddInventory"
                          type="number"
                        />
                      </Grid>
                    </Grid>
                  </>
                )}

              <Grid container padding={0} paddingTop={2}>
                <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                  <div style={{ marginBottom: "8px" }}>
                    Product Description (Optional)
                  </div>
                  <FocusedTextFieldMultiline
                    rows={4}
                    id="varient_description__inventory"
                    value={variant.description}
                    onChange={handleVariantInputChange}
                    name="description"
                    key="description"
                    placeholder="Write about product here"
                    className="textField_variant__AddInventory"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                padding={0}
                paddingTop={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                  {variantDialogMode === "edit" ? (
                    <>
                      <Grid container>
                        <Grid
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          paddingRight={onlyXs ? 0 : 1}
                          item
                        >
                          <Button
                            id="delete_varient_btn__inventory"
                            className="editVariantBtn__ai"
                            style={{ width: "100%" }}
                            onClick={handleDeleteVariant}
                          >
                            Delete Variant
                          </Button>
                        </Grid>
                        <Grid
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          paddingLeft={onlyXs ? 0 : 1}
                          item
                        >
                          <Button
                            id="save_changes_btn__inventory"
                            className="addVariantBtn__ai"
                            onClick={handleEditVariant}
                            style={{ width: "100%" }}
                          >
                            Save Changes
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Button
                      id="add-varient_btn__inventory"
                      className="addVariantBtn__ai"
                      onClick={addVariantToProduct}
                      style={{
                        width: "100%",
                      }}
                    >
                      Add Variant
                    </Button>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Box>

        <Box height={smDown ? "30vh" : "20vh"}>
          <NavigationBar
            path={"Dashboard>Inventory Management>Products>Add New Product"}
            paddingBottom={1}
          />
          <Grid display="flex" justifyContent="center" container mb={2} mt={2}>
            <Grid
              className="header__ai"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
            >
              <div
                className="header_back_btn__ai"
                onClick={() => {
                  navigate("/products");
                }}
              >
                <IoMdArrowDropleft
                  style={{ marginBottom: "-5px", fontSize: "20px" }}
                />
                Back
              </div>
              <div className="header_text__ai">
                Inventory Management {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                <span style={smDown ? { fontSize: "14px" } : {}}>
                  Add New Product
                </span>
              </div>
            </Grid>
          </Grid>
        </Box>

        <Box className="div__scroll" height={smDown ? "70vh" : "80vh"}>
          <Grid container className="card__ai">
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingTop={query(2, 2, 2)}
              paddingBottom={query(3, 3, 3)}
              style={smDown ? { zIndex: 1 } : {}}
              paddingLeft={query(5, 2, 1)}
              paddingRight={query(5, 2, 1)}
            >
              <AutoScrollContainer marginTop={0.01}>
                <Grid container display="flex" justifyContent="center">
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    <div style={{ marginBottom: "8px" }}>Product Name</div>
                    <FocusedTextField
                      key="name"
                      value={product.name}
                      onChange={handleProductInputChange}
                      name="name"
                      id="product_name__inventory"
                      placeholder="Product Name"
                      className="textField__AddInventory"
                    />
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    <div style={{ marginBottom: "8px" }}>
                      Product Image (Optional)
                    </div>

                    <input
                      style={{ display: "none" }}
                      id="productImage"
                      type="file"
                      onChange={saveImageFile}
                    />
                    <label htmlFor="productImage">
                      <Button
                        id="product_image__inventory"
                        variant="outlined"
                        component="span"
                        style={{
                          width: "100%",
                          height: "54.5px",
                          backgroundColor: "white",
                          borderColor: "rgb(0,0,0,0.25)",
                          color: "rgb(0,0,0,0.35)",
                          textTransform: "none",
                          fontSize: "16px",
                          justifyContent: "flex-start",
                          borderRadius: "10px",
                        }}
                        endIcon={
                          <Box
                            sx={
                              imageUri === ""
                                ? {
                                    position: "absolute",
                                    right: "20px",
                                    bottom: "8px",
                                  }
                                : {
                                    position: "absolute",
                                    right: "0px",
                                    bottom: "-10px",
                                  }
                            }
                          >
                            {imageUri === "" && <UploadImageIcon />}
                            {imageUri !== "" && (
                              <img
                                alt=""
                                style={{ borderRadius: "10px" }}
                                src={imageUri}
                                width="50px"
                                height="50px"
                              />
                            )}
                          </Box>
                        }
                      >
                        Upload Image
                      </Button>
                    </label>
                  </Grid>
                </Grid>

                <Grid
                  container
                  display="flex"
                  paddingTop={query(2, 2, 0)}
                  justifyContent="center"
                  paddingBottom={3}
                >
                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>Source (Optional)</div>
                    <FocusedTextField
                      value={product.source}
                      onChange={handleProductInputChange}
                      name="source"
                      id="source__inventory"
                      key="source"
                      placeholder="Product Source"
                      className="textField__AddInventory"
                    />
                  </Grid>
                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingRight={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    <div style={{ marginBottom: "8px" }}>Material</div>

                    <FormControl className="textField__AddInventory">
                      {product.material === "" && (
                        <InputLabel sx={{ opacity: 0.6 }}>
                          Select Product Material
                        </InputLabel>
                      )}

                      <CustomTextFieldInventory
                        select
                        value={product.material}
                        onChange={handleProductInputChange}
                        name="material"
                        id="material__inventory"
                        key="material"
                      >
                        {materials.map((option) => (
                          <MenuItem
                            key={option.material}
                            value={option.material}
                          >
                            {option.material}
                          </MenuItem>
                        ))}
                      </CustomTextFieldInventory>
                    </FormControl>
                  </Grid>
                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    <div style={{ marginBottom: "8px" }}>Category</div>
                    <FormControl className="textField__AddInventory">
                      {product.category.split(",")[0] === "" && (
                        <InputLabel sx={{ opacity: 0.6 }}>
                          Select Product Category
                        </InputLabel>
                      )}
                      <CustomTextFieldInventory
                        id="category__inventory"
                        key="category"
                        select
                        value={product.category}
                        onChange={handleProductInputChange}
                        name="category"
                        className="textField__AddInventory"
                      >
                        {categories.map((option) => (
                          <MenuItem
                            key={option.category}
                            value={option.category + "," + option.id}
                          >
                            {option.category}
                          </MenuItem>
                        ))}
                      </CustomTextFieldInventory>
                    </FormControl>
                  </Grid>
                </Grid>

                <hr />

                <Box className="variant_cont__addinv">
                  <Box className="variant_body__addinv">
                    {product.variants.length > 0 ? (
                      <Grid container>
                        <Grid
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          item
                          paddingRight={1}
                          paddingLeft={1}
                          className=""
                        >
                          <Box className="variant_title__ai">Variants</Box>

                          {product.variants.map((variant, vi) => (
                            <Box id="variant__ai">
                              <Box
                                className="variant_edit_btn_con__ai"
                                onClick={() => {
                                  handleOpenEditVariantDialog(vi);
                                }}
                              >
                                <IconButton className="variant_edit_btn__ai">
                                  <WhitePenIcon />
                                </IconButton>
                              </Box>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {variantMobile.map((column) =>
                                      product.category.split(",")[0] ===
                                      "Laptop" ? (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                          className="variant_cell_head__ai"
                                        >
                                          {column.label === "IMEI No."
                                            ? "MAC Address"
                                            : column.label}
                                        </TableCell>
                                      ) : product.category.split(",")[0] ===
                                        "Mobile" ? (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                          className="variant_cell_head__ai"
                                        >
                                          {column.label}
                                        </TableCell>
                                      ) : column.label !== "IMEI No." &&
                                        column.label !== "Storage" &&
                                        column.label !== "RAM" ? (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                          className="variant_cell_head__ai"
                                        >
                                          {column.label}
                                        </TableCell>
                                      ) : null
                                    )}

                                    <TableCell
                                      key="description"
                                      align="left"
                                      className="variant_cell_head__ai"
                                    >
                                      Description
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {[variant].map((row) => {
                                    return product.category.split(",")[0] ===
                                      "Mobile" ||
                                      product.category.split(",")[0] ===
                                        "Laptop" ? (
                                      <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.code}
                                      >
                                        <TableCell
                                          className="variant_imei__ai"
                                          key="imei"
                                          align="left"
                                        >
                                          {row["imei"].split(",").join("\n")}
                                        </TableCell>
                                        {variantMobile.map((column) => {
                                          const value =
                                            row[column.id] !== null
                                              ? row[column.id]
                                                  .toString()
                                                  .split(",")
                                                  .join("\n")
                                              : "";

                                          return (
                                            <>
                                              {column.id !== "imei" ? (
                                                <TableCell
                                                  className="variant_cell__ai"
                                                  key={column.id}
                                                  align={column.align}
                                                >
                                                  {value}{" "}
                                                  {column.label.includes(
                                                    "Price"
                                                  )
                                                    ? userObject.currency
                                                    : null}
                                                </TableCell>
                                              ) : null}
                                            </>
                                          );
                                        })}
                                        <TableCell
                                          className="variant_desc__ai"
                                          key="description"
                                          align="left"
                                        >
                                          {row.description}
                                        </TableCell>
                                      </TableRow>
                                    ) : (
                                      <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.code}
                                      >
                                        {variantMobile.map((column) => {
                                          const value =
                                            row[column.id] !== null
                                              ? row[column.id]
                                                  .toString()
                                                  .split(",")
                                                  .join("\n")
                                              : "";

                                          return column.label !== "IMEI No." &&
                                            column.label !== "Storage" &&
                                            column.label !== "RAM" ? (
                                            <TableCell
                                              className="variant_cell__ai"
                                              key={column.id}
                                              align={column.align}
                                            >
                                              {value}{" "}
                                              {column.label.includes("Price")
                                                ? userObject.currency
                                                : null}
                                            </TableCell>
                                          ) : null;
                                        })}

                                        <TableCell
                                          className="variant_desc__ai"
                                          key="description"
                                          align="left"
                                        >
                                          {row.description}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </Box>
                          ))}

                          <Button
                            className="add_varient_btn__ai"
                            variant="contained"
                            onClick={handleOpenAddVariantDialog}
                            endIcon={<SquareAddIcon />}
                          >
                            Add More Varients
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Box height="20vh" style={{ marginTop: "20px" }}>
                        <Button
                          id="add_varients_btn__inventory"
                          className="add_varient_btn__ai"
                          variant="contained"
                          onClick={handleOpenAddVariantDialog}
                          endIcon={<SquareAddIcon />}
                        >
                          Add Varients
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>

                <Grid container paddingTop={5} paddingBottom={5}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    item
                    paddingLeft={smDown ? 1 : 0}
                    display="flex"
                    justifyContent="start"
                  >
                    <Button
                      id="discard_btn__inventory"
                      onClick={() => {
                        navigate("/products");
                      }}
                      variant="contained"
                      className="footer_dis__ai"
                    >
                      Discard
                    </Button>
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    item
                    paddingRight={smDown ? 1 : 0}
                    display="flex"
                    justifyContent="end"
                  >
                    <Button
                      id="add_product_btn__inventory"
                      onClick={() => {
                        createProduct();
                      }}
                      variant="contained"
                      className="footer_add__ai"
                    >
                      Add Product
                    </Button>
                  </Grid>
                </Grid>
              </AutoScrollContainer>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AddInventory;
