import "./Orders.css";
import {
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import Button from "@mui/material/Button";
import { AiFillCaretDown } from "react-icons/ai";
import CustomTable from "../../widgets/CustomTable";
import SearchField from "../../widgets/SearchField";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useEffect, useContext, useMemo } from "react";
import Divider from "@mui/material/Divider";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import {
  getAllOrders,
  changeOrderStatus,
  deleteOrder,
} from "../../api/OrderAPI";

import { orderInvoice } from "../../api/ReportAPI";
import { debounce } from "lodash";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import PrintIcon from "@mui/icons-material/Print";
import ProtectComponent from "../../middleware/ProtectComponent";
import { DateRangePicker } from "mui-daterange-picker";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { ReactComponent as EditIcon } from "../../assets/SVG/EditIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import { ReactComponent as OrderDataCreditIcon } from "../../assets/SVG/OrderDataCredit.svg";
import { ReactComponent as OderReportIcon } from "../../assets/SVG/OrdersReportbtnIcon.svg";
import { ReactComponent as OrderAllReportIcon } from "../../assets/SVG/OrdersAllReportIcon.svg";
import { ReactComponent as OrderDeleteDialogIcon } from "../../assets/SVG/OrderDeleteDialogBox.svg";
import DeleteDialog from "../../widgets/DeleteDialog";
import DateFilter from "../../widgets/DateFilter";
import DownloadReportButton from "../../widgets/DownloadReportButton";
import MainTitle from "../../widgets/MainTitle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import AddNewButton from "../../widgets/AddNewButton";

import { getAllTransferOrders } from "../../api/TransferOrdersAPI";

const options = [
  {
    icon: <EyeIcon />,
    label: "View",
    method: "view",
    type: "icon",
  },
  // {
  //   icon: (
  //     <PublishedWithChangesIcon
  //       style={{ opacity: "0.8", marginLeft: "-2.5px" }}
  //     />
  //   ),
  //   label: <span style={{ marginLeft: "-4.5px" }}>Status</span>,
  //   method: "status",
  //   type: "icon",
  // },
  // {
  //   icon: <EditIcon />,
  //   label: "Edit",
  //   method: "edit",
  //   type: "icon",
  // },
  // {
  //   icon: <PrintIcon style={{ opacity: "0.7", marginLeft: "-2.5px" }} />,
  //   label: <span style={{ marginLeft: "-4.5px" }}>Print</span>,
  //   method: "print",
  //   type: "icon",
  // },
  // {
  //   icon: <DeleteIcon />,
  //   label: "Delete",
  //   method: "delete",
  //   type: "icon",
  // },
];

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    align: "left",
  },

  // {
  //   id: "type",
  //   label: "Type",
  //   align: "left",
  // },
  // {
  //   id: "description",
  //   label: "Description",
  //   align: "center",
  // },
  {
    id: "order_date",
    label: "Date",
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    align: "left",
  },
];

// const columns = [
//   {
//     id: "sr_no",
//     label: "Sr. No",
//     align: "left",
//   },
//   {
//     id: "invoice_code",
//     label: "Order ID",
//     align: "left",
//   },
//   {
//     id: "total",
//     label: "Total Cash",
//     align: "left",
//   },
//   {
//     id: "customer_name",
//     label: "Customer Name",
//     align: "left",
//   },
//   {
//     id: "customer_phone",
//     label: "Phone Number",
//     align: "left",
//   },
//   {
//     id: "quantity",
//     label: "Total Products",
//     align: "left",
//   },
//   {
//     id: "order_date",
//     label: "Order Date",
//     align: "left",
//   },
//   {
//     id: "profit",
//     label: "Profit",
//     align: "left",
//   },
//   {
//     id: "order_type",
//     label: "Type",
//     align: "left",
//   },
//   {
//     id: "status",
//     label: "Status",
//     align: "left",
//   },
// ];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

function TransferOrders(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
    mdDown,
    onlyMd,
    baseUrl,
    location,
  } = useContext(GlobalData);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  //
  const [ordersDate, setOrdersDate] = useState("This Month");
  //--
  const [dateMenuElement, setDateMenuElement] = useState(null);
  const isDateMenuOpen = Boolean(dateMenuElement);
  const ITEM_HEIGHT = 48;
  const handleOpenDateMenu = (event) => {
    setDateMenuElement(event.currentTarget);
  };
  const handleCloseDateMenu = () => {
    setDateMenuElement(null);
  };
  //--
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [dateRange, setDateRange] = useState({});

  const toggleDateRangerPicker = () =>
    setOpenDateRangePicker(!openDateRangePicker);

  const handleOrdersDateFilter = (option) => {
    handleCloseDateMenu();
    if (option === "Custom") {
      toggleDateRangerPicker();
    } else {
      setOrdersDate(option);
    }
  };

  const handleDateRange = (range) => {
    setDateRange(range);
    setOrdersDate(range);
    setTimeout(() => {
      toggleDateRangerPicker();
    }, 500);
  };
  //

  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState("All");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [orderType, setOrderType] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [orderStatusCount, setOrderStatusCount] = useState("");
  const [orderTypeCount, setOrderTypeCount] = useState("");
  const [
    isChangeOrderStatusDialogOpen,
    setIsChangeOrderStatusDialogOpen,
  ] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const [orderDescription, setOrderDescription] = useState("");

  const [allOrderStatus, setAllOrderStatus] = useState([
    "Paid",
    "Unpaid",
    "Cancelled",
  ]);

  const [deleteProductDialog, setDeleteProductDialog] = useState(false);

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const handleDeleteOrder = () => {
    handleCloseDeleteDialog();
    showLoader();
    let formData = new FormData();
    formData.append("order_id", selectedOrder.id);
    deleteOrder(formData, (resp) => {
      if (resp.statusCode === 200) {
        updateDataUsingPageNumber();
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const openDeleteOrderDialog = (order) => {
    setSelectedOrder(order);
    setDeleteProductDialog(true);
  };

  const getAllOrdersMethod = (pn) => {
    showLoader();

    // formData.append("search_text", searchText);
    // formData.append("order_type", orderType);
    // formData.append("status", orderStatus);
    // formData.append("date", JSON.stringify(ordersDate));
    // formData.append("per_page", process.env.REACT_APP_PER_PAGE);
    // formData.append("page", pn);
    // if (location.pathname === "/staff/orders") {
    //   formData.append("staff_user_id", location.state.memberData.user_id);
    // }

    let data = {
      date: JSON.stringify(ordersDate),
      search_text: searchText,
    };

    getAllTransferOrders(data, (resp) => {
      if (resp.statusCode === 200) {
        // setOrderStatusCount(resp.successData.order_status_count);
        // setOrderTypeCount(resp.successData.order_type_count);
        if (resp.statusCode === 200) {
          if (pn === 1) {
            setOrders(resp.successData.transfer_orders);
            hideLoader();
          } else {
            setOrders((dt) => {
              dt.push(...resp.successData.transfer_orders);
              hideLoader();
              return dt;
            });
          }
          setLastPage(resp.successData.last_page);
        }
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const changeOrderDescription = (e) => {
    setOrderDescription(e.target.value);
  };

  const handleOrderStatus = (order) => {
    setSelectedOrder(order);
    setSelectedOrderStatus("");
    setOrderDescription(order.description);
    handleChangeOrderStatusDialogOpen();
  };

  const handleViewOrder = (data) => {
    navigate("/orders/transfer-order-detail", {
      state: {
        orderData: data,
        orderStatus: orderStatus,
        path: location.pathname,
        role: "user",
      },
    });
  };

  // Order Status Dialog Methods

  const handleChangeOrderStatusDialogOpen = () => {
    setIsChangeOrderStatusDialogOpen(true);
  };

  const handleChangeOrderStatusDialogClose = () => {
    setIsChangeOrderStatusDialogOpen(false);
  };

  const handleChangeOrderStatus = () => {
    if (selectedOrderStatus === "") {
      flashMessage("error", "Please Select Order Status");
    } else {
      handleChangeOrderStatusDialogClose();
      showLoader();
      let formData = new FormData();
      formData.append("order_id", selectedOrder.id);
      formData.append("order_status", selectedOrderStatus);
      formData.append("description", orderDescription);
      changeOrderStatus(formData, (resp) => {
        if (resp.statusCode === 200) {
          updateDataUsingPageNumber();
        } else {
          flashMessage("error", resp.message);
          hideLoader();
        }
      });
    }
  };

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const handleEditOrder = (data) => {
    if (location.pathname === "/staff/orders") {
      navigate("/orders/update-order", {
        state: {
          orderData: data,
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/orders/update-order", {
        state: {
          orderData: data,
          path: location.pathname,
        },
      });
    }
  };

  const handlePrintSingleOrder = (order) => {
    showLoader();
    let formData = new FormData();
    formData.append("orders", JSON.stringify([order]));
    orderInvoice(formData, (resp) => {
      if (resp.statusCode === 200) {
        window.open(baseUrl + "public/" + resp.successData.file_path, "_blank");
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const handlePrintAllOrders = () => {
    if (orders.length > 0) {
      showLoader();
      let formData = new FormData();
      formData.append("orders", JSON.stringify(orders));
      orderInvoice(formData, (resp) => {
        if (resp.statusCode === 200) {
          window.open(baseUrl + "public/" + resp.successData.file_path, "_blank");
          hideLoader();
        } else {
          flashMessage("error", resp.message);
          hideLoader();
        }
      });
    }
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    getAllOrdersMethod(page);
  }, [page]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      getAllOrdersMethod(page);
    }
  };

  useEffect(() => {
    updateDataUsingPageNumber();
  }, [searchText, orderType, orderStatus, ordersDate]);

  const handleNavigateToAddOrder = () => {
    if (location.pathname === "/staff/orders") {
      navigate("/orders/add-new-order", {
        state: {
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/orders/add-new-order", {
        state: {
          path: location.pathname,
        },
      });
    }
  };

  return (
    <>
      {/* <Dialog
        fullWidth
        maxWidth="xs"
        open={deleteProductDialog}
        onClose={handleCloseDeleteDialog}
        id="deleteProductDialog"
      >
        <DialogContent id="deleteProductDialogContent__inv">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              style={{ textAlign: "center" }}
            >
              style="margin-top: 60px"  this style is SVG
              <OrderDeleteDialogIcon />
              <Box className="deleteProductDialogActionText1_inv">
                Remove this Order?
              </Box>
              <Box className="deleteProductDialogActionText2_inv">
                You are about to remove a Order. Click on the “Remove” button to
                remove it.
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="deleteDialogActions">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              className="deleteDialogBtn__inv deleteDialogBtnNo__inv"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={handleCloseDeleteDialog}
            >
              Don’t Remove
            </Grid>
            <Grid
              className="deleteDialogBtn__inv deleteDialogBtnYes__inv"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={handleDeleteOrder}
            >
              Remove
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>  */}

      <DeleteDialog
        fullWidth
        maxWidth="xs"
        showDialog={deleteProductDialog}
        onClose={handleCloseDeleteDialog}
        handleRemove={handleDeleteOrder}
        handleClose={handleCloseDeleteDialog}
        id="deleteProductDialog"
        title={" Remove this Order?"}
        sub_title={
          "You are about to remove a Order. Click on the “Remove” button to remove it"
        }
      />

      <Dialog
        open={isChangeOrderStatusDialogOpen}
        onClose={handleChangeOrderStatusDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Order Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ marginBottom: "8px", marginTop: "16px" }}>Status</div>
            <FormControl className="textField__AddInventory">
              {selectedOrderStatus === "" && (
                <InputLabel sx={{ opacity: 0.6 }}>
                  Select Order Status
                </InputLabel>
              )}

              {selectedOrder.order_type === "Card" ||
              selectedOrder.order_type === "Cash" ? (
                <ChangeOrderStatus
                  setSelectedOrderStatus={setSelectedOrderStatus}
                  selectedOrderStatus={selectedOrderStatus}
                  orderStatus={["Cancelled"]}
                />
              ) : true ? (
                <ChangeOrderStatus
                  setSelectedOrderStatus={setSelectedOrderStatus}
                  selectedOrderStatus={selectedOrderStatus}
                  orderStatus={["Cancelled"]}
                />
              ) : null}
            </FormControl>

            <div style={{ marginBottom: "8px", marginTop: "16px" }}>
              Description
            </div>

            <CustomTextField
              multiline
              rows={4}
              style={{ width: "" }}
              value={orderDescription}
              onChange={changeOrderDescription}
              name="description"
              id="description"
              key="description"
              placeholder="Write about product here"
              className="textField__AddInventory"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="download_report_btn__orders"
            onClick={handleChangeOrderStatusDialogClose}
          >
            Cancel
          </Button>
          <Button
            className="add_order_btn__orders"
            onClick={handleChangeOrderStatus}
          >
            Change Status
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <NavigationBar
            path={"Dashboard>Transfer Orders Management>Orders"}
            paddingBottom={3}
          />

          <Grid container marginBottom={2}>
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingRight={onlyXs ? "5px" : "10px"}
            >
              <MainTitle title={"Transfer Orders Management"} />
            </Grid>
          </Grid>

          <Grid container marginBottom={2}>
            <Grid xl={6} lg={6} md={5} sm={12} xs={12} item paddingRight="5px">
              <SearchField
                id="search_input_field__order"
                onChange={updateProductList}
                tableDataCount={orders.length}
                totalAvailableDataCount={orders.length}
              />
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={7}
              sm={12}
              xs={12}
              item
              display="flex"
              justifyContent={smDown ? "center" : "end"}
              paddingTop={smDown ? 1 : 0}
              paddingRight={smDown ? "0px" : "10px"}
            >
              <Button
                variant="contained"
                endIcon={<ArrowDropDownIcon fontSize="large" />}
                className="add_order_btn__orders"
              >
                {mdDown ? (
                  <div>
                    <div>Download</div>
                    <div style={{ marginTop: "-8px" }}>Report</div>
                  </div>
                ) : (
                  "Download Report"
                )}
              </Button>

              <Box ml={1}>
                <DateFilter
                  setDateObject={setOrdersDate}
                  dateObject={ordersDate}
                  className={"download_report_btn__orders"}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CustomTable
            view={handleViewOrder}
            edit={handleEditOrder}
            delete={openDeleteOrderDialog}
            print={handlePrintSingleOrder}
            status={handleOrderStatus}
            columns={columns}
            data={orders}
            options={options}
            firstRowMargin={true}
          />
        </Grid>
      </Grid>
    </>
  );
}

const ChangeOrderStatus = (props) => {
  return (
    <CustomTextField
      select
      value={props.selectedOrderStatus}
      onChange={(e) => {
        props.setSelectedOrderStatus(e.target.value);
      }}
      name="order_status"
      id="order_status"
      key="order_status"
    >
      {props.orderStatus.map((value, index) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </CustomTextField>
  );
};

export default TransferOrders;
