import { useState } from "react";
import { Menu, MenuItem, Box, Button } from "@mui/material";
import { ReactComponent as DownloadIcon } from "../assets/SVG/DownloadIcon.svg";

const SimpleMenu = (props) => {
  const [menu, setMenu] = useState(null);
  const isMenuOpen = Boolean(menu);

  const handleOpenMenu = (e) => {
    setMenu(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenu(null);
  };
  return (
    <Box>
      <Box mr={props.mr}>
        <Button
        id={props.id}
          onClick={handleOpenMenu}
          variant="contained"
          endIcon={<DownloadIcon style={{ marginRight: "5px" }} />}
          className={props.className}
        >
          <Box className="lngtxt_bnkslec__bank" width="100%" padding="2px">
            {props.selectedValue}
          </Box>
        </Button>
      </Box>
      <Menu
        anchorEl={menu}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: 48 * 6,
            width: props.width,
            borderRadius: "10px",
            boxShadow: "none",
            border: "1px solid rgba(0,0,0,0.09)",
          },
        }}
      >
        {props.data.map((value, index) => (
          <Box
            style={{
              borderTop: `${index === 0 ? "1px solid rgba(0,0,0,0.09)" : ""}`,
              paddingTop: `${index === 0 ? "3px" : ""}`,
            }}
          >
            {value !== props.selectedValue ? (
              <MenuItem
                onClick={() => {
                  props.handleSetData(value);
                  handleCloseMenu();
                }}
                style={{
                  borderBottom: "1px solid rgba(0,0,0,0.09)",
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                {value}
              </MenuItem>
            ) : null}
          </Box>
        ))}
      </Menu>
    </Box>
  );
};

export default SimpleMenu;
