import { get, post } from "./API";

export function orderInvoice(data, callback) {
  post({ endPoint: "order/report/orderInvoice" }, data, function(response) {
    callback(response);
  });
}

export function inventoryReport(data, callback) {
  post({ endPoint: "inventory/report/inventoryReport" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function bankReport(data, callback) {
  post({ endPoint: "bank/report/bankReport" }, data, function(response) {
    callback(response);
  });
}

export function expensesReport(data, callback) {
  post({ endPoint: "expenses/report/expensesReport" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function vendorReport(data, callback) {
  post({ endPoint: "vendor/report/vendorReport" }, data, function(response) {
    callback(response);
  });
}

export function vendorNameReport(data, callback) {
  post({ endPoint: "vendor/report/vendorNameReport" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function staffReport(data, callback) {
  post({ endPoint: "staff/report/staffReport" }, data, function(response) {
    callback(response);
  });
}

export function storeOrderReport(data, callback) {
  post({ endPoint: "storeOrder/report/storeOrderReport" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function partnerReport(data, callback) {
  post({ endPoint: "patner/report/PartnerReport" }, data, function(response) {
    callback(response);
  });
}

export function PartnerNameReport(data, callback) {
  post({ endPoint: "partner/report/PartnerNameReport" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function accountReport(data, callback) {
  post({ endPoint: "accounts/report/accountReport" }, data, function(response) {
    callback(response);
  });
}

export function onlineVariantReport(data, callback) {
  post({ endPoint: "onlinevariant/report/onlineVariantReport" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function onlinespecificVariantReport(data, callback) {
  post(
    { endPoint: "onlinesecifucvariant/report/onlinespecificVariantReport" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function dashboardReport(data, callback) {
  post({ endPoint: "dashboard/report/dashboardReport" }, data, function(
    response
  ) {
    callback(response);
  });
}
