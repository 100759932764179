import React from 'react'
import {
    Box,
    Grid,
    Dialog,
    DialogContent, 
    DialogActions,
  } from "@mui/material";
import {ReactComponent as DialogCrossIcon} from '../assets/SVG/DialogBoxDeleteIcon.svg';
function DeleteDialog(props) {
   
  return (
    <Dialog
    fullWidth
    maxWidth="xs"
    open={props.showDialog}
    onClose={()=>props.handleClose()}
    id="deleteProductDialog"
  >
    <DialogContent id="deleteProductDialogContent__inv">
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          item
          style={{ textAlign: "center" }}
        >
          <div><DialogCrossIcon/></div>
          <Box className="deleteProductDialogActionText1_inv">
          {props.title}
          </Box>
          <Box className="deleteProductDialogActionText2_inv">
            {props.sub_title}
          </Box>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions className="deleteDialogActions">
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          className="deleteDialogBtn__inv deleteDialogBtnNo__inv"
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={6}
          item
          onClick={()=>props.handleClose()}
          id={props.DontRemoveId}
        >
          Don’t Remove
        </Grid>
        <Grid
          className="deleteDialogBtn__inv deleteDialogBtnYes__inv"
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={6}
          item
          onClick={()=>{props.handleRemove()}}
          id={props.RemoveId}
        >
          Remove
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>
  )
}

export default DeleteDialog