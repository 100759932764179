import React, { useContext, useState, useEffect } from "react";
import { Box, Grid, Button, MobileStepper } from "@mui/material";
import { IoMdArrowDropleft } from "react-icons/io";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import ProtectComponent from "../../middleware/ProtectComponent";
import { getTransferOrderProductsById } from "../../api/TransferOrdersAPI";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";

function ViewTransferOrder(props) {
  const {
    smDown,
    navigate,
    baseUrl,
    location,
    showLoader,
    hideLoader,
    query,
    queryx,
    onlyXs,
    userObject,
    flashMessage,
  } = useContext(GlobalData);

  const theme = useTheme();

  const order = location.state.orderData;

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const handleGetTransferOrderProducts = () => {
    showLoader();
    getTransferOrderProductsById(
      { transfer_order_id: order.id, branch_id: order.branch_id },
      (resp) => {
        hideLoader();
        if (resp.statusCode == 200) {
          console.log(resp.successData.transfer_order_products);
          setProducts(resp.successData.transfer_order_products);
          if (resp.successData.transfer_order_products.length > 0) {
            setProduct(resp.successData.transfer_order_products[0]);
          }
          flashMessage("success", resp.message);
        } else {
          flashMessage("error", resp.message);
        }
      }
    );
  };

  useEffect(() => {
    handleGetTransferOrderProducts();
  }, []);

  const general = (product) => {
    if (product.category === "Mobile" || product.category === "Laptop") {
      return true;
    } else {
      return false;
    }
  };

  const handleViewVendor = () => {
    navigate("/vendors/view-khata", {
      state: { vendorData: order.vendor_id },
    });
  };

  const handleBackToOrders = () => {
    if (location.state.path === "/staff/orders") {
      navigate("/staff/orders", {
        state: {
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/orders");
    }
  };

  function getDate(created_at) {
    let date = new Date(created_at);
    var d = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var strDate = d + "-" + month + "-" + year;
    return strDate;
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (products.length > 0) {
      setProduct(products[activeStep]);
    }
  }, [activeStep]);

  return (
    <>
      <NavigationBar
        path={`Dashboard>Transfer Order Management>Products>${
          product.serial_no
        }`}
        paddingBottom={1}
      />
      {product === "" ? (
        <Grid />
      ) : (
        <Grid
          container
          className="container__inven_view"
          style={{ paddingRight: `${onlyXs ? 5 : 5}px` }}
          paddingBottom={3}
        >
          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            item
            paddingTop={smDown && 3}
            style={smDown ? { zIndex: 1 } : {}}
          >
            <Box className="container__viewinv">
              <Box className="header__viewinv">
                <Grid display="flex" justifyContent="center" container mb={2}>
                  <Grid
                    className="header__inven_view"
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    item
                  >
                    <div
                      className="header_back_btn__inven_view"
                      onClick={() => {
                        navigate(
                          location.state.role == "admin"
                            ? "/transfer-orders"
                            : "/transfer/orders"
                        );
                      }}
                    >
                      <IoMdArrowDropleft className="add__icon_orders_sty" />
                      Back
                    </div>
                    <div className="header_text__inven_view">
                      Transfer Order Management{" "}
                      {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                      <span style={smDown ? { fontSize: "14px" } : {}}>
                        Products
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box className="body__viewinv">
                <MobileStepper
                  variant="text"
                  steps={products.length}
                  position="static"
                  activeStep={activeStep}
                  style={{ backgroundColor: "transparent" }}
                  id="stepper_com__vsi"
                  nextButton={
                    <Button
                      size="small"
                      disabled={activeStep === products.length - 1}
                      id="stepper_next_btn__vsi"
                      onClick={handleNext}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      disabled={activeStep === 0}
                      id="stepper_back_btn__vsi"
                      onClick={handleBack}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                    </Button>
                  }
                />

                <Grid container>
                  <Grid
                    className="card__inven_view"
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(10, 4, 0.5)}
                    paddingRight={query(10, 4, 0.5)}
                    paddingTop="30px"
                    paddingBottom="60px"
                  >
                    <Grid
                      container
                      className="page__inven_view"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      paddingTop="30px"
                      paddingBottom="30px"
                      paddingLeft={query(10, 4, 1)}
                      paddingRight={query(10, 4, 1)}
                    >
                      <Grid
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        item
                        display="flex"
                        justifyContent="center"
                        paddingBottom={3}
                      >
                        <img
                          alt=""
                          src={baseUrl + product.image}
                          width="120px"
                          height="120px"
                          style={{
                            borderRadius: "50%",
                            border: "1px solid rgba(0,0,0,0.2)",
                          }}
                        />
                      </Grid>

                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid
                          container
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid
                            xl={3}
                            lg={3}
                            md={3}
                            sm={3}
                            xs={7}
                            display="flex"
                            justifyContent={onlyXs ? "start" : "center"}
                            item
                          >
                            <Box>
                              <Box className="product_title_text__ordview">
                                Product Name
                              </Box>
                              <Box className="product_text__ordview">
                                {product.name}
                              </Box>
                            </Box>
                          </Grid>

                          <ProtectComponent
                            id="product_source"
                            componentLocation={true}
                            componentNavigate={false}
                            component={
                              <Grid
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={5}
                                item
                                display="flex"
                                justifyContent={onlyXs ? "start" : "center"}
                              >
                                <Box>
                                  <Box className="product_title_text__ordview">
                                    Source
                                  </Box>
                                  <Box className="product_text__ordview">
                                    {product.source}
                                  </Box>
                                </Box>
                              </Grid>
                            }
                          />

                          <Grid
                            xl={3}
                            lg={3}
                            md={3}
                            sm={3}
                            xs={7}
                            item
                            display="flex"
                            justifyContent={onlyXs ? "start" : "center"}
                          >
                            <Box>
                              <Box className="product_title_text__ordview">
                                Material
                              </Box>
                              <Box className="product_text__ordview">
                                {product.material}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            xl={3}
                            lg={3}
                            md={3}
                            sm={3}
                            xs={5}
                            item
                            display="flex"
                            justifyContent={onlyXs ? "start" : "center"}
                          >
                            <Box>
                              <Box className="product_title_text__ordview">
                                Category
                              </Box>
                              <Box className="product_text__ordview">
                                {product.category}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <hr style={{ marginTop: "20px", opacity: "0.5" }} />
                        <Grid container>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid
                              container
                              paddingTop="20px"
                              className="product_text__ordview"
                            >
                              Variants
                            </Grid>
                            <Grid container className="padng_global_projct">
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                {product.variants.map((variant) => (
                                  <>
                                    <Grid container>
                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="variant_box_con__inven_view"
                                      >
                                        <Grid
                                          container
                                          paddingLeft={1}
                                          paddingRight={1}
                                        >
                                          <Grid
                                            item
                                            xl={
                                              product.category === "Mobile" ||
                                              product.category === "Laptop"
                                                ? 3
                                                : 2
                                            }
                                            lg={
                                              product.category === "Mobile" ||
                                              product.category === "Laptop"
                                                ? 3
                                                : 2
                                            }
                                            md={
                                              product.category === "Mobile" ||
                                              product.category === "Laptop"
                                                ? 3
                                                : 2
                                            }
                                            sm={
                                              product.category === "Mobile" ||
                                              product.category === "Laptop"
                                                ? 3
                                                : 2
                                            }
                                            xs={
                                              product.category === "Mobile" ||
                                              product.category === "Laptop"
                                                ? 6
                                                : 4
                                            }
                                          >
                                            <Box
                                              fontSize="12px"
                                              className="variant_title_text__inven_view"
                                            >
                                              {product.category === "Mobile" ||
                                              product.category === "Laptop"
                                                ? "Specifications"
                                                : "Color"}
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {variant.color}

                                              {product.category === "Mobile" ||
                                              product.category === "Laptop" ? (
                                                <>
                                                  {variant.ram.length > 0
                                                    ? ","
                                                    : ""}
                                                  {variant.ram}
                                                  {variant.storage.length > 0
                                                    ? ","
                                                    : ""}
                                                  {variant.storage}
                                                </>
                                              ) : null}
                                            </Box>
                                          </Grid>
                                          <Grid
                                            item
                                            xl={2}
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={general ? 4 : 6}
                                          >
                                            <Box
                                              fontSize="12px"
                                              className="product_title_text__ordview"
                                            >
                                              Quantity
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {variant.quantity}
                                            </Box>
                                          </Grid>

                                          <ProtectComponent
                                            id="product_cost_price"
                                            componentLocation={true}
                                            componentNavigate={false}
                                            component={
                                              <Grid
                                                item
                                                xl={2}
                                                lg={2}
                                                md={2}
                                                sm={2}
                                                xs={general ? 4 : 4}
                                              >
                                                <Box
                                                  fontSize="12px"
                                                  className="variant_title_text__inven_view"
                                                >
                                                  Cost Price
                                                </Box>
                                                <Box className="product_text__ordview">
                                                  {variant.cost_price}
                                                </Box>
                                              </Grid>
                                            }
                                          />

                                          <Grid
                                            item
                                            xl={2}
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={general ? 4 : 4}
                                          >
                                            <Box
                                              fontSize="12px"
                                              className="variant_title_text__inven_view"
                                            >
                                              Sale Price
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {variant.sale_price}
                                            </Box>
                                          </Grid>
                                          <Grid
                                            item
                                            xl={3}
                                            lg={3}
                                            md={3}
                                            sm={3}
                                            xs={general ? 4 : 4}
                                          >
                                            <Box
                                              fontSize="12px"
                                              className="variant_title_text__inven_view"
                                            >
                                              M.Sale Price
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {variant.min_sale_price}
                                            </Box>
                                          </Grid>
                                        </Grid>
                                        <hr />
                                        <Grid
                                          container
                                          paddingLeft={1}
                                          paddingRight={1}
                                          paddingBottom={1}
                                        >
                                          {product.category === "Mobile" ||
                                          product.category === "Laptop" ? (
                                            <>
                                              <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                md={6}
                                                sm={6}
                                                xs={12}
                                              >
                                                <Box
                                                  fontSize="12px"
                                                  className="variant_title_text__inven_view"
                                                >
                                                  {product.category === "Mobile"
                                                    ? "IMEI No."
                                                    : "MAC Address"}
                                                </Box>
                                                <Box className="product_text__ordview">
                                                  {variant.imei
                                                    .split(",")
                                                    .join("\n")}
                                                </Box>
                                              </Grid>

                                              <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                md={6}
                                                sm={6}
                                                xs={12}
                                              >
                                                <Box
                                                  fontSize="12px"
                                                  className="variant_title_text__inven_view"
                                                >
                                                  Description
                                                </Box>

                                                <Box className="product_text__ordview">
                                                  {variant.description}
                                                </Box>
                                              </Grid>
                                            </>
                                          ) : (
                                            <Grid
                                              item
                                              xl={12}
                                              lg={12}
                                              md={12}
                                              sm={12}
                                              xs={12}
                                            >
                                              <Box
                                                fontSize="12px"
                                                className="variant_title_text__inven_view"
                                              >
                                                Description
                                              </Box>
                                              <Box className="product_text__ordview">
                                                {variant.description}
                                              </Box>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <br />
                                  </>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ViewTransferOrder;
