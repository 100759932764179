import React from "react";
import { ButtonBase } from "@mui/material";
import WhatsappPNG from "../assets/Images/whatsapp.png";
import "./HelpCenter.css";

const HelpCenter = () => {
  const handleClick = () => {
    const phoneNumber = "+971505495334";
    const message = "Hello, how are you?";
    const url = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: "99",
      }}
    >
      <ButtonBase onClick={handleClick}>
        <img
          width="60px"
          height="60px"
          className="breathing-icon"
          src={WhatsappPNG}
          alt="Help"
        />
      </ButtonBase>
    </div>
  );
};

export default HelpCenter;
