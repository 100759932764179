import { getWithParams, post, delte } from "./API";

export function getAllBranchesById(data, callback) {
  getWithParams({ endPoint: "shop/getAllBranchesById" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function updateBranchById(data, callback) {
  post({ endPoint: "shop/updateBranchById" }, data, function(response) {
    callback(response);
  });
}

export function createBranch(data, callback) {
  post({ endPoint: "shop/createBranch" }, data, function(response) {
    callback(response);
  });
}

export function deleteBranchById(data, callback) {
  delte({ endPoint: "shop/deleteBranchById" }, data, function(response) {
    callback(response);
  });
}
