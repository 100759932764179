import axios from "axios";
const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const logoutWhen401 = (resp) => {
  if (localStorage.getItem("user") != null) {
    if (resp.response.status == 401 || resp.response.status == 404) {
      localStorage.clear();
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      window.location.reload(true);
    }
  }
};

const activityLog = (responseData) => {
  logoutWhen401(responseData);
  let user = JSON.parse(localStorage.getItem("user"));
  let accessToken = "";
  if (user) {
    accessToken = user.access_token;
  }
  let formData = new FormData();
  formData.append("user", JSON.stringify(user));
  formData.append("log", JSON.stringify(responseData));
  axios({
    method: "post",
    url: baseUrl + "api/activity-logs/createActivityLog",
    data: formData,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    },
  })
    .then(function(response) {
      if (response.data.statusCode === 200) {
      }
    })
    .catch(function(response) {
      console.log(response);
    });
};

export function get(api, callback) {
  let user = JSON.parse(localStorage.getItem("user"));
  let branch = JSON.parse(localStorage.getItem("shopBranch"));
  let accessToken = "";
  let data = {};
  if (user) {
    accessToken = user.access_token;
  }
  if (branch) {
    data.branch_id = branch.id;
    data.shop_id = branch.shop_id;
  }
  axios({
    method: "get",
    url: baseUrl + "api/" + api.endPoint,
    params: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    },
  })
    .then(function(response) {
      callback(response.data);
    })
    .catch(function(response) {
      // activityLog(response);
      logoutWhen401(response);
    });
}

export function post(api, data, callback) {
  let user = JSON.parse(localStorage.getItem("user"));
  let branch = JSON.parse(localStorage.getItem("shopBranch"));
  let accessToken = "";
  if (user) {
    accessToken = user.access_token;
  }
  if (branch) {
    data.append("branch_id", branch.id);
    data.append("shop_id", branch.shop_id);
  }
  axios({
    method: "post",
    url: baseUrl + "api/" + api.endPoint,
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    },
  })
    .then(function(response) {
      callback(response.data);
    })
    .catch(function(response) {
      // activityLog(response);
      logoutWhen401(response);
    });
}
export function getWithParams(api, data, callback) {
  let user = JSON.parse(localStorage.getItem("user"));
  let branch = JSON.parse(localStorage.getItem("shopBranch"));
  let accessToken = "";
  if (user) {
    accessToken = user.access_token;
  }
  if (branch) {
    data.branch_id = branch.id;
    data.shop_id = branch.shop_id;
  }
  axios({
    method: "get",
    url: baseUrl + "api/" + api.endPoint,
    params: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    },
  })
    .then(function(response) {
      callback(response.data);
    })
    .catch(function(response) {
      // activityLog(response);
      logoutWhen401(response);
    });
}

export function delte(api, data, callback) {
  let user = JSON.parse(localStorage.getItem("user"));
  let branch = JSON.parse(localStorage.getItem("shopBranch"));
  let accessToken = "";
  if (user) {
    accessToken = user.access_token;
  }
  if (branch) {
    data.branch_id = branch.id;
    data.shop_id = branch.shop_id;
  }
  console.log("data fro deleteing", data);
  axios({
    method: "delete",
    url: baseUrl + "api/" + api.endPoint,
    params: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + accessToken,
    },
  })
    .then(function(response) {
      console.log("this is message", response);
      callback(response.data);
    })
    .catch(function(response) {
      // activityLog(response);
      logoutWhen401(response);
    });
}
