import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
} from "@mui/material";
import branchesIcon from "../../assets/Images/branchesIcon.png";
import { ReactComponent as BranchesStIcon } from "../../assets/SVG/BranchesSt.svg";
import { GlobalData } from "../../App";
import { styled } from "@mui/material/styles";
import { MuiChipsInput } from "mui-chips-input";
import CloseIcon from "@mui/icons-material/Close";
import { getAllMaterials, updateMaterials } from "../../api/MaterialAPI";
import { getAllColors, updateColors } from "../../api/ColorAPI";
import { getAllCategories, updateCategories } from "../../api/CategoryAPI";
import { updateSettings } from "../../api/SettingsAPI";
import { ReactComponent as BranchUploadIcon } from "../../assets/SVG/BranchSettingUploadIcon.svg";

const FocusedTextField = (props) => {
  return (
    <CustomTextField
      multiline={props.multiline}
      rows={props.rows}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      key={props.key}
      placeholder={props.placeholder}
      className={props.className}
    />
  );
};
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});
const CustomSelectField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: `1px solid rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: `1px solid rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      border: `1px solid rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});
function BrancheSetting(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
    mdDown,
    onlyLg,
    onlyMd,
    onlySm,
    query,
    baseUrl,
    userData,
  } = useContext(GlobalData);
  const [chipsMaterial, setChipsMaterial] = React.useState([]);
  const [chipsCategory, setChipsCategory] = React.useState([]);
  const [chipsColor, setChipsColor] = React.useState([]);
  const [selectedColor, setSelectedColor] = useState(false);
  const [imageUri, setImageUri] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [currencySelection, setCurrencySelection] = useState("");
  const [selectInventory, setSelectInventory] = useState("General");
  const [selectSettings, setSelectSettings] = useState("General");

  const [settingsTab, setSettingsTab] = useState("general");

  const [openCashDialog, setOpenCashDialog] = useState(false);

  const [shop, setShop] = useState(null);
  const [user, setUser] = useState(null);

  const [taxPercentage, setTaxPercentage] = useState(null);

  const handleSetSettingsTab = (value) => {
    setSettingsTab(value);
  };

  const handleSettings = (index) => {
    setSelectInventory(selectInventory);
  };

  function saveImageFile(e) {
    let image = document.getElementById("productImage").files;
    setImageFile(image[0]);
    setImageUri(window.URL.createObjectURL(new Blob(image, { type: "*" })));
  }
  // const handleChangeOne = (newChips) => {
  //   setChipsMaterial(newChips);
  // };
  // const handleChangeTwo = (newChips) => {
  //   setChipsCategory(newChips);
  // };
  // const handleChangeThree = (newChips) => {
  //   setChipsColor(newChips);
  // };

  const getAllMaterialsSettings = () => {
    getAllMaterials({ shop_id: shop.id }, (resp) => {
      setChipsMaterial(resp.successData.materials);
      console.log(resp.successData.materials);
    });
  };

  const getAllCategorySettings = () => {
    getAllCategories({ shop_id: shop.id }, (resp) => {
      setChipsCategory(resp.successData.categories);
      console.log(resp.successData.categories);
    });
  };

  const handleGetAllColors = () => {
    getAllColors({ shop_id: shop.id }, (resp) => {
      setChipsColor(resp.successData.colors);
    });
  };

  useEffect(() => {
    if (shop) {
      getAllMaterialsSettings();
      getAllCategorySettings();
      handleGetAllColors();
      setImageUri(baseUrl + shop.image);
    }
    if (user) {
      setTaxPercentage(user.tax_percentage);
      setCurrencySelection(user.currency);
    }
  }, [shop]);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    setShop(user.shop);
  }, []);

  const handleCashDialogClose = () => {
    props.setShowSettingsScreen(false);
  };

  const handleChangeOne = (newChips) => {
    setChipsMaterial(newChips);

    let formData = new FormData();
    formData.append("shop_id", shop.id);
    formData.append("materials", newChips);
    updateMaterials(formData, (resp) => {
      if (resp.statusCode === 200) {
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const handleChangeTwo = (newChips) => {
    setChipsColor(newChips);
    let formData = new FormData();
    formData.append("shop_id", shop.id);
    formData.append("colors", newChips);
    updateColors(formData, (resp) => {
      if (resp.statusCode === 200) {
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const handleChangeThree = (newChips) => {
    setChipsCategory(newChips);

    let formData = new FormData();
    formData.append("shop_id", shop.id);
    formData.append("categories", newChips);
    updateCategories(formData, (resp) => {
      if (resp.statusCode === 200) {
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  function saveImageFile(e) {
    let image = document.getElementById("productImage").files;
    setImageFile(image[0]);
    setImageUri(window.URL.createObjectURL(new Blob(image, { type: "*" })));
  }

  const handleSaveSettings = () => {
    showLoader();
    let formData = new FormData();
    formData.append("shop_id", shop.id);
    formData.append("user_id", user.id);
    if (imageFile) {
      formData.append("image", imageFile, imageFile.name);
    }
    formData.append("tax_percentage", taxPercentage);
    formData.append("currency", currencySelection);
    updateSettings(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(resp.successData.user));
        //handleCashDialogClose();
        props.handleCloseSettings();
        flashMessage("success", "Settings Updated Successfully");
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  return (
    <>
      <Box
        style={selectInventory ? { height: "683px" } : { height: "600px" }}
        className="main_con_branchSettings"
      >
        <Box className="main_img_branch">
          <Grid container pt={3} display="flex" justifyContent="center">
            <Box>
              <Box display="flex" justifyContent="center" mb={1}>
                <img
                  alt=""
                  style={{ borderRadius: "50%" }}
                  src={baseUrl + shop?.image}
                  width="80px"
                  height="80px"
                />
              </Box>
              <Box className="bran_setting_txt_name">{shop?.brand_name}</Box>
              <Box className="bran_setting_txt_Alc" pt={2} px={1}>
                {user?.address}
              </Box>
            </Box>
            <Box pt={7}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Button
                  className="setting_branc_btn"
                  onClick={() => {
                    handleSetSettingsTab("general");
                  }}
                  style={
                    settingsTab == "general"
                      ? { backgroundColor: "#0f354c", color: "white" }
                      : { backgroundColor: "white", color: "#0f354c" }
                  }
                >
                  General Settings
                </Button>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Button
                  className="setting_branc_btn"
                  onClick={() => {
                    handleSetSettingsTab("inventory");
                  }}
                  style={
                    settingsTab == "inventory"
                      ? { backgroundColor: "#0f354c", color: "white" }
                      : { backgroundColor: "white", color: "#0f354c" }
                  }
                >
                  {" "}
                  Inventory Settings
                </Button>
              </Box>
            </Box>
            <Box
              style={
                selectInventory
                  ? {
                    paddingTop: "270px",
                  }
                  : { paddingTop: "200px" }
              }
              display="flex"
              justifyContent="center"
            >
              <Button
                id="logout__btn_branches"
                 className="branche_seting_log"
                onClick={props.handleLogout}
              >
                Logout &nbsp; <BranchesStIcon />{" "}
              </Button>
            </Box>
          </Grid>
        </Box>
        <Box style={{ width: "600px" }}>
          {settingsTab == "inventory" ? (
            <Box pt={3} pl={2} pr={1}>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Box className="branch_st_genral">Inventory Settings</Box>
                  <Box className="update_brach_set" pt={0.7}>
                    Update your Inventory settings
                  </Box>
                </Box>

                <Box className="box_close_icon">
                  <IconButton
                    id="close_inventory_settings__branches"
                    onClick={() => {
                      props.handleCloseSettings();
                    }}
                  >
                    <CloseIcon className="ft_sixe" />
                  </IconButton>
                </Box>
              </Box>

              <Box pt={3}>
                <Box className="text1_branchSettings">
                  Add Material for inventory
                </Box>
                <Box className="text1_branchSettings">
                  Add Category for inventory
                </Box>
                <Box className="text1_branchSettings">
                  Add Color for inventory
                </Box>
              </Box>
              <Box>
                <Box pt={2}>
                  <Grid container display="flex" justifyContent="center" pt={2}>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>Material</div>
                      <FormControl className="text__filed__notes">
                        <MuiChipsInput
                          id="material_inputField__settings"
                          value={chipsMaterial}
                          onChange={handleChangeOne}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container display="flex" justifyContent="center" pt={2}>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>Category</div>
                      <FormControl className="text__filed__notes">
                        <MuiChipsInput
                          id="category_inputField__settings"
                          value={chipsCategory}
                          onChange={handleChangeThree}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container display="flex" justifyContent="center" pt={2}>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>Color</div>
                      <FormControl className="text__filed__notes">
                        <MuiChipsInput
                          id="color_inputField__settings"
                          value={chipsColor}
                          onChange={handleChangeTwo}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                {/* <Box pt={3}>
                  <Button
                    className="up_brn_st_btn_one"
                    onClick={handleSaveSettings}
                  >
                    Update
                  </Button>
                  <Button
                    className="up_brn_st_btn_two"
                    onClick={() => {
                      props.handleCloseSettings();
                    }}
                  >
                    Cancel
                  </Button>
                </Box> */}
              </Box>
            </Box>
          ) : (
            <Box pt={3} pl={2} pr={1}>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Box className="branch_st_genral">General Settings</Box>
                  <Box className="update_brach_set" pt={0.7}>
                    Update your General settings
                  </Box>
                </Box>

                <Box className="box_close_icon">
                  <IconButton
                    id="close_general_settings__branches"
                    onClick={() => {
                      props.handleCloseSettings();
                    }}
                  >
                    <CloseIcon className="ft_sixe" />
                  </IconButton>
                </Box>
              </Box>

              <Box>
                <Box pt={2}>
                  <Grid container display="flex" justifyContent="center" pt={2}>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>Tax Percentage</div>
                      {/* <FormControl className="text__filed__notes">
                        {currencySelection === "" ? (
                          <InputLabel sx={{ opacity: 0.6 }}>
                            Enter tax percentage
                          </InputLabel>
                        ) : null}
                        <CustomSelectField select name={"priority"}>
                          {["10%", "10%", "10%"].map((option, oi) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </CustomSelectField>
                      </FormControl> */}
                      <CustomTextField
                        id="tax_percentage_general__settings"
                        className="text__filed__notes"
                        placeholder=" Enter the tax percentage"
                        name={"title"}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={taxPercentage}
                        onChange={(e) => {
                          setTaxPercentage(e.target.value);
                        }}
                        type="number"
                      />
                    </Grid>
                  </Grid>

                  <Grid container display="flex" justifyContent="center" pt={2}>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>Currency</div>
                      {/* <FormControl className="text__filed__notes">
                        {currencySelection === "" ? (
                          <InputLabel sx={{ opacity: 0.6 }}>
                            Select Currency
                          </InputLabel>
                        ) : null}
                        <CustomSelectField select name={"priority"}>
                          {["PKR", "INR", "AED"].map((option, oi) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </CustomSelectField>
                      </FormControl> */}
                      <FormControl className="text__filed__notes">
                        {currencySelection === "" ? (
                          <InputLabel sx={{ opacity: 0.6 }}>
                            Select Currency
                          </InputLabel>
                        ) : null}
                        <CustomSelectField

                          select
                          value={currencySelection}
                          name={"priority"}
                        >
                          {["PKR", "INR", "AED"].map((option, oi) => (
                            <MenuItem
                              key={option}
                              value={option}
                              onClick={() => {
                                setCurrencySelection(option);
                              }}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </CustomSelectField>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* <Grid container display="flex" justifyContent="center" pt={2}>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>Language</div>
                      <FormControl className="text__filed__notes">
                        {currencySelection === "" ? (
                          <InputLabel sx={{ opacity: 0.6 }}>
                            Select language
                          </InputLabel>
                        ) : null}
                        <CustomSelectField select name={"priority"}>
                          {["English", "English", "English"].map(
                            (option, oi) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            )
                          )}
                        </CustomSelectField>
                      </FormControl>
                    </Grid>
                  </Grid> */}

                  <Grid container display="flex" justifyContent="center" pt={2}>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>Logo</div>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        onChange={saveImageFile}
                        accept="image/png, image/gif, image/jpeg"
                      />
                      <label htmlFor="productImage">
                        <Button
                          id="productImage__general__settings"
                          variant="outlined"
                          component="span"
                          style={{
                            width: "100%",
                            height: "54.5px",
                            backgroundColor: "white",
                            borderColor: "rgb(0,0,0,0.25)",
                            color: "rgb(0,0,0,0.35)",
                            textTransform: "none",
                            fontSize: "16px",
                            justifyContent: "flex-start",
                            borderRadius: "10px",
                          }}
                          endIcon={
                            <Box
                              sx={
                                imageUri === ""
                                  ? {
                                    position: "absolute",
                                    right: "20px",
                                    bottom: "8px",
                                  }
                                  : {
                                    position: "absolute",
                                    right: "0px",
                                    bottom: "-10px",
                                  }
                              }
                            >
                              {imageUri === "" && (
                                <Box mb="-5px">
                                  <BranchUploadIcon />
                                </Box>
                              )}
                              {imageUri !== "" && (
                                <img
                                  alt=""
                                  style={{ borderRadius: "10px" }}
                                  src={imageUri}
                                  width="50px"
                                  height="50px"
                                />
                              )}
                            </Box>
                          }
                        >
                          Upload login screen Logo
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                </Box>
                <Box pt={3}>
                  <Button
                    id="update__btn_general_settings"
                    className="up_brn_st_btn_one"
                    onClick={handleSaveSettings}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default BrancheSetting;
