import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { Box, Grid, TextField, Button } from "@mui/material";
import { IoMdArrowDropleft } from "react-icons/io";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import { styled } from "@mui/material/styles";
import { addBank } from "../../api/BankAPI";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const FocusedTextField = (props) => {
  return (
    <CustomTextField
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      key={props.key}
      placeholder={props.placeholder}
      className={props.className}
    />
  );
};

function AddBank() {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    location,
    onlyXs,
    query,
  } = useContext(GlobalData);

  const [bank, setBank] = useState({
    bank_name: "",
    account_name: "",
    account_number: "",
  });

  const handleBankInputChange = (e) => {
    const { name, value } = e.target;
    setBank({
      ...bank,
      [name]: value,
    });
  };

  const handleAddBank = () => {
    showLoader();
    let formData = new FormData();
    formData.append("bank_name", bank.bank_name);
    formData.append("account_name", bank.account_name);
    formData.append("account_number", bank.account_number);
    addBank(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        navigate("/bank");
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  return (
    <Grid container height="100vh" className="padn_invoice__icon">
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box height={smDown ? "30vh" : "20vh"}>
          <NavigationBar
            path={"Dashboard>Bank Management>Bank>Add New Bank"}
            paddingBottom={1}
          />

          <Grid display="flex" justifyContent="center" container mb={2} mt={2}>
            <Grid
              className="header__ai__vendor_khata"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
            >
              <div
                className="header_back_btn__ai"
                onClick={() => {
                  navigate("/bank");
                }}
              >
                <IoMdArrowDropleft
                  style={{ marginBottom: "-5px", fontSize: "20px" }}
                />
                Back
              </div>
              <div className="header_text__ai">
                Bank Management {smDown ? <br /> : <>&nbsp;-&nbsp;</>} Add New
                Bank
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box className="div__scroll" height={smDown ? "70vh" : "80vh"}>
          <Grid container className="card__ai">
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingTop={query(2, 2, 2)}
              paddingBottom={query(3, 3, 3)}
              paddingLeft={query(5, 2, 1)}
              paddingRight={query(5, 2, 1)}
              id="addOrderStyle"
            >
              <Grid container display="flex" justifyContent="center">
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Bank Name</div>
                  <FocusedTextField
                    value={bank.bank_name}
                    onChange={handleBankInputChange}
                    name={"bank_name"}
                    id={"bank_name"}
                    key={"banl_name"}
                    placeholder={"Bank Name"}
                    className={"textField__AddInventory"}
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Account Name</div>
                  <FocusedTextField
                    value={bank.account_name}
                    onChange={handleBankInputChange}
                    name={"account_name"}
                    id={"account_name"}
                    key={"account_name"}
                    placeholder={"Account Name"}
                    className={"textField__AddInventory"}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                display="flex"
                paddingTop={query(2, 2, 0)}
                justifyContent="start"
                paddingBottom={3}
              >
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div style={{ marginBottom: "8px" }}>Account Number</div>
                  <FocusedTextField
                    value={bank.account_number}
                    onChange={handleBankInputChange}
                    name={"account_number"}
                    id={"account_number"}
                    key={"account_number"}
                    placeholder={"Account Number"}
                    className={"textField__AddInventory"}
                  />
                </Grid>
              </Grid>

              <Grid container paddingTop={5} paddingBottom={5}>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  item
                  paddingLeft={smDown ? 1 : 0}
                  display="flex"
                  justifyContent="start"
                >
                  <Button
                    id="discard_btn__add_bank"
                    variant="contained"
                    className="footer_dis__ai"
                    onClick={() => {
                      navigate("/bank");
                    }}
                  >
                    Discard
                  </Button>
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  item
                  paddingRight={smDown ? 1 : 0}
                  display="flex"
                  justifyContent="end"
                >
                  <Button
                    id="add_bank_btn__bank"
                    onClick={handleAddBank}
                    variant="contained"
                    className="footer_add__ai"
                  >
                    Add Bank
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default AddBank;
