import React, { useContext, useState, useEffect } from "react";
import { Box, Grid, Button } from "@mui/material";
import "./ViewOrder.css";
import { IoMdArrowDropleft } from "react-icons/io";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import ProtectComponent from "../../middleware/ProtectComponent";

function ViewOrder(props) {
  const {
    smDown,
    navigate,
    baseUrl,
    location,
    showLoader,
    hideLoader,
    query,
    queryx,
    onlyXs,
    userObject,
  } = useContext(GlobalData);

  const order = location.state.orderData;
  const orderStatus = location.state.orderStatus;

  useEffect(() => {
    showLoader();
    setTimeout(() => {
      hideLoader();
    }, 500);
  }, []);

  const general = (product) => {
    if (product.category === "Mobile" || product.category === "Laptop") {
      return true;
    } else {
      return false;
    }
  };

  const handleViewVendor = () => {
    navigate("/vendors/view-khata", {
      state: { vendorData: order.vendor_id },
    });
  };

  const handleBackToOrders = () => {
    if (location.state.path === "/staff/orders") {
      navigate("/staff/orders", {
        state: {
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/orders");
    }
  };

  function getDate(created_at) {
    let date = new Date(created_at);
    var d = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var strDate = d + "-" + month + "-" + year;
    return strDate;
  }

  return (
    <>
      <NavigationBar
        path={`Dashboard>Order Management>Orders>Order Detail>${
          order.invoice_code
        }`}
        paddingBottom={1}
      />
      <Grid
        container
        className="container__inven_view"
        style={{ paddingRight: `${onlyXs ? 5 : 5}px` }}
        paddingBottom={3}
      >
        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          item
          paddingTop={smDown && 3}
          style={smDown ? { zIndex: 1 } : {}}
        >
          <Box className="container__viewinv">
            <Box className="header__viewinv">
              <Grid display="flex" justifyContent="center" container mb={2}>
                <Grid
                  className="header__inven_view"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  item
                >
                  <div
                    className="header_back_btn__inven_view"
                    onClick={handleBackToOrders}
                  >
                    <IoMdArrowDropleft className="add__icon_orders_sty" />
                    Back
                  </div>
                  <div className="header_text__inven_view">
                    Order Management {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                    <span style={smDown ? { fontSize: "14px" } : {}}>
                      Order Detail
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Box className="body__viewinv">
              <Grid container>
                <Grid
                  className="card__inven_view"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  item
                  paddingLeft={query(10, 4, 0.5)}
                  paddingRight={query(10, 4, 0.5)}
                  paddingTop="30px"
                  paddingBottom="60px"
                >
                  <Grid
                    container
                    className="page__inven_view"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    paddingBottom="5px"
                    paddingLeft={query(5, 4, 1)}
                    paddingRight={query(5, 4, 1)}
                  >
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box
                        className="order_ti__ordview"
                        justifyContent={queryx(
                          "start",
                          "start",
                          "start",
                          "space-between"
                        )}
                      >
                        <Box
                          paddingTop="20px"
                          width="160px"
                          paddingRight={queryx("40px", "40px", "40px", "40px")}
                        >
                          <Box className="product_title_text__ordview">
                            Staff Member Name
                          </Box>
                          <Box className="product_text__ordview">
                            {order.user.name}
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="120px"
                          paddingRight={queryx("40px", "40px", "40px", "40px")}
                        >
                          <Box className="product_title_text__ordview">
                            Invoice Code
                          </Box>
                          <Box className="product_text__ordview">
                            {order.invoice_code}
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="130px"
                          paddingRight={queryx("40px", "40px", "40px", 0)}
                        >
                          <Box className="product_title_text__ordview">
                            Customer Name
                          </Box>
                          <Box className="product_text__ordview">
                            {order.customer_name}
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="132px"
                          paddingRight="40px"
                        >
                          <Box className="product_title_text__ordview">
                            Customer Phone
                          </Box>
                          <Box className="product_text__ordview">
                            {order.customer_phone}
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="90px"
                          paddingRight={queryx("40px", "40px", "40px", "40px")}
                        >
                          <Box className="product_title_text__ordview">
                            Sub Total
                          </Box>
                          <Box className="product_text__ordview">
                            {order.sub_total} {userObject.currency}
                          </Box>
                        </Box>
                        {order.status === "Returned" ? (
                          <Box
                            paddingTop="20px"
                            width="120px"
                            paddingRight={queryx(
                              "40px",
                              "40px",
                              "40px",
                              "40px"
                            )}
                          >
                            <Box className="product_title_text__ordview">
                              Return Amount
                            </Box>
                            <Box className="product_text__ordview">
                              {order.return_order_balance} {userObject.currency}
                            </Box>
                          </Box>
                        ) : null}

                        <Box
                          paddingTop="20px"
                          width="90px"
                          paddingRight={queryx("40px", "40px", "40px", "40px")}
                        >
                          <Box className="product_title_text__ordview">
                            Discount
                          </Box>
                          <Box className="product_text__ordview">
                            {order.order_discount} {userObject.currency}
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="120px"
                          paddingRight={queryx("40px", "40px", "40px", "10px")}
                        >
                          <Box className="product_title_text__ordview">Tax</Box>
                          <Box className="product_text__ordview">
                            {order.tax_amount} {userObject.currency}{" "}
                            {order.tax.length > 3 ? order.tax * 100 : order.tax}
                            %
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="108px"
                          paddingRight={queryx("40px", "40px", "40px", "25px")}
                        >
                          <Box className="product_title_text__ordview">
                            Total Amount
                          </Box>
                          <Box className="product_text__ordview">
                            {order.total} {userObject.currency}
                          </Box>
                        </Box>
                        <Box paddingTop="20px" width="90px" paddingRight="40px">
                          <Box className="product_title_text__ordview">
                            Order Date
                          </Box>
                          <Box className="product_text__ordview">
                            {order.order_date}
                          </Box>
                        </Box>

                        <Box
                          paddingTop="20px"
                          width="115px"
                          paddingRight={queryx("40px", "40px", "40px", "20px")}
                        >
                          <Box className="product_title_text__ordview">
                            Total Products
                          </Box>
                          <Box className="product_text__ordview">
                            {order.quantity}
                          </Box>
                        </Box>
                        <Box paddingTop="20px" width="90px" paddingRight="40px">
                          <Box className="product_title_text__ordview">
                            Order Type
                          </Box>
                          <Box className="product_text__ordview">
                            {order.order_type}
                          </Box>
                        </Box>
                        <Box paddingTop="20px" width="90px" paddingRight="40px">
                          <Box className="product_title_text__ordview">
                            Bank Name
                          </Box>
                          <Box className="product_text__ordview">
                            {order.bank_name}
                          </Box>
                        </Box>
                        <Box
                          paddingTop="20px"
                          width="100px"
                          paddingRight={queryx("40px", "40px", "40px", "34px")}
                        >
                          <Box className="product_title_text__ordview">
                            Order Status
                          </Box>
                          <Box className="product_text__ordview">
                            {order.status}
                          </Box>
                        </Box>

                        {order.tre_no !== "" &&
                          order.tre_no !== null &&
                          order.tre_no !== "null" &&
                          order.tre_no.length > 4 && (
                            <Box
                              paddingTop="20px"
                              width="60px"
                              paddingRight={queryx(
                                "40px",
                                "40px",
                                "40px",
                                "34px"
                              )}
                            >
                              <Box className="product_title_text__ordview">
                                TRE No.
                              </Box>
                              <Box className="product_text__ordview">
                                {order.tre_no}
                              </Box>
                            </Box>
                          )}
                        {order.order_type === "Credit" ? (
                          <Box alignSelf="center" paddingTop="20px">
                            <Button
                              variant="contained"
                              className="viwKhataBtn__ordview"
                              onClick={handleViewVendor}
                            >
                              View Khata
                            </Button>
                          </Box>
                        ) : null}
                      </Box>

                      <hr className="hr_margn_opacty" />
                      <Grid container>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Grid container className="padng_global_projct">
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              {order.order_products.map((product, pio) => (
                                <>
                                  {(true || orderStatus === "All") && (
                                    <>
                                      {pio === 0 && (
                                        <Grid
                                          container
                                          paddingTop="20px"
                                          paddingBottom="20px"
                                          paddingLeft="5px"
                                          className="product_text__ordview"
                                        >
                                          Products
                                        </Grid>
                                      )}
                                      <Box
                                        className="bg__ordview"
                                        style={{
                                          backgroundColor: `${
                                            product.order_returned === 1
                                              ? "rgb(255,0,0,0.3)"
                                              : ""
                                          }`,
                                        }}
                                      >
                                        <Box
                                          paddingLeft="5px"
                                          className="order_ti__ordview"
                                          justifyContent={queryx(
                                            "start",
                                            "start",
                                            "start",
                                            "space-between"
                                          )}
                                        >
                                          <Box
                                            className="padng_global_projct"
                                            width="50px"
                                            paddingRight={queryx(
                                              "40px",
                                              "40px",
                                              "40px",
                                              "40px"
                                            )}
                                          >
                                            <img
                                              alt=""
                                              src={baseUrl + product.image}
                                              width="60px"
                                              height="60px"
                                              style={{
                                                borderRadius: "50%",
                                                border:
                                                  "1px solid rgba(0,0,0,0.2)",
                                              }}
                                            />
                                          </Box>
                                          <Box
                                            paddingTop="3px"
                                            width="160px"
                                            paddingRight={queryx(
                                              "40px",
                                              "40px",
                                              "40px",
                                              "10px"
                                            )}
                                            alignSelf="center"
                                          >
                                            <Box className="product_title_text__ordview">
                                              Product Name
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {product.name}
                                            </Box>
                                          </Box>
                                          <Box
                                            paddingTop="3px"
                                            alignSelf="center"
                                            width="100px"
                                            paddingRight={queryx(
                                              "40px",
                                              "40px",
                                              "40px",
                                              "40px"
                                            )}
                                          >
                                            <Box className="product_title_text__ordview">
                                              Material
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {product.material}
                                            </Box>
                                          </Box>
                                          <Box
                                            width="100px"
                                            paddingRight="40px"
                                            paddingTop="3px"
                                            alignSelf="center"
                                          >
                                            <Box className="product_title_text__ordview">
                                              Category
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {product.category}
                                            </Box>
                                          </Box>
                                          <Box
                                            paddingTop="3px"
                                            alignSelf="center"
                                            width="70px"
                                            paddingRight={queryx(
                                              "0px",
                                              "0px",
                                              "0px",
                                              "70px"
                                            )}
                                          >
                                            <Box className="product_title_text__ordview">
                                              Status
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {product.status}
                                            </Box>
                                          </Box>
                                        </Box>

                                        <Grid container marginTop="10px">
                                          <Grid
                                            item
                                            xl={12}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className="variant_box_con__ordview"
                                          >
                                            <Grid
                                              container
                                              paddingLeft={1}
                                              paddingRight={1}
                                            >
                                              <Grid
                                                item
                                                xl={
                                                  product.category ===
                                                    "Mobile" ||
                                                  product.category === "Laptop"
                                                    ? 3
                                                    : 2
                                                }
                                                lg={
                                                  product.category ===
                                                    "Mobile" ||
                                                  product.category === "Laptop"
                                                    ? 3
                                                    : 2
                                                }
                                                md={
                                                  product.category ===
                                                    "Mobile" ||
                                                  product.category === "Laptop"
                                                    ? 3
                                                    : 2
                                                }
                                                sm={
                                                  product.category ===
                                                    "Mobile" ||
                                                  product.category === "Laptop"
                                                    ? 3
                                                    : 2
                                                }
                                                xs={
                                                  product.category ===
                                                    "Mobile" ||
                                                  product.category === "Laptop"
                                                    ? 6
                                                    : 4
                                                }
                                              >
                                                <Box
                                                  fontSize="12px"
                                                  className="product_title_text__ordview"
                                                >
                                                  {product.category ===
                                                    "Mobile" ||
                                                  product.category === "Laptop"
                                                    ? "Specifications"
                                                    : "Color"}
                                                </Box>
                                                <Box className="product_text__ordview">
                                                  {product.color}

                                                  {product.category ===
                                                    "Mobile" ||
                                                  product.category ===
                                                    "Laptop" ? (
                                                    <>
                                                      {product.ram.length > 0
                                                        ? ","
                                                        : ""}
                                                      {product.ram}
                                                      {product.storage.length >
                                                      0
                                                        ? ","
                                                        : ""}
                                                      {product.storage}
                                                    </>
                                                  ) : null}
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                xl={2}
                                                lg={2}
                                                md={2}
                                                sm={2}
                                                xs={general(product) ? 4 : 6}
                                              >
                                                <Box
                                                  fontSize="12px"
                                                  className="product_title_text__ordview"
                                                >
                                                  Quantity
                                                </Box>
                                                <Box className="product_text__ordview">
                                                  {product.quantity}
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                xl={2}
                                                lg={2}
                                                md={2}
                                                sm={2}
                                                xs={general(product) ? 4 : 4}
                                              >
                                                <Box
                                                  fontSize="12px"
                                                  className="product_title_text__ordview"
                                                >
                                                  Price
                                                </Box>
                                                <Box className="product_text__ordview">
                                                  {product.total_order_price}{" "}
                                                  {userObject.currency}
                                                </Box>
                                              </Grid>
                                              <Grid
                                                item
                                                xl={1}
                                                lg={1}
                                                md={1}
                                                sm={1}
                                                xs={general(product) ? 4 : 4}
                                              >
                                                <Box
                                                  fontSize="12px"
                                                  className="product_title_text__ordview"
                                                >
                                                  Gifted
                                                </Box>
                                                <Box className="product_text__ordview">
                                                  {product.order_gifted ===
                                                  1 ? (
                                                    <Box>Yes</Box>
                                                  ) : (
                                                    <Box>No</Box>
                                                  )}
                                                </Box>
                                              </Grid>
                                              {product.order_returned_quantity >
                                              0 ? (
                                                <Grid
                                                  item
                                                  xl={3}
                                                  lg={3}
                                                  md={3}
                                                  sm={3}
                                                  xs={general(product) ? 4 : 4}
                                                >
                                                  <Box
                                                    fontSize="12px"
                                                    className="product_title_text__ordview"
                                                  >
                                                    Returned Quantity
                                                  </Box>
                                                  <Box className="product_text__ordview">
                                                    {
                                                      product.order_returned_quantity
                                                    }
                                                  </Box>
                                                </Grid>
                                              ) : null}
                                            </Grid>
                                            <hr />
                                            <Grid
                                              container
                                              paddingLeft={1}
                                              paddingRight={1}
                                            >
                                              {product.category === "Mobile" ||
                                              product.category === "Laptop" ? (
                                                <>
                                                  <Grid
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    md={6}
                                                    sm={6}
                                                    xs={12}
                                                  >
                                                    <Box
                                                      fontSize="12px"
                                                      className="product_title_text__ordview"
                                                    >
                                                      {product.category ===
                                                      "Mobile"
                                                        ? "IMEI No."
                                                        : "MAC Address"}
                                                    </Box>
                                                    <Box className="product_text__ordview">
                                                      {product.imei
                                                        .split(",")
                                                        .join("\n")}
                                                    </Box>
                                                  </Grid>

                                                  <Grid
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    md={6}
                                                    sm={6}
                                                    xs={12}
                                                  >
                                                    <Box
                                                      fontSize="12px"
                                                      className="product_title_text__ordview"
                                                    >
                                                      Description
                                                    </Box>

                                                    <Box className="product_text__ordview">
                                                      {product.description}
                                                    </Box>
                                                  </Grid>
                                                  {product.order_returned_quantity >
                                                  0 ? (
                                                    <Grid
                                                      item
                                                      xl={6}
                                                      lg={6}
                                                      md={6}
                                                      sm={6}
                                                      xs={12}
                                                    >
                                                      <Box
                                                        fontSize="12px"
                                                        className="product_title_text__ordview"
                                                      >
                                                        Description
                                                      </Box>

                                                      <Box className="product_text__ordview">
                                                        {product.description}
                                                      </Box>
                                                    </Grid>
                                                  ) : null}
                                                </>
                                              ) : (
                                                <>
                                                  <Grid item md sm={12} xs={12}>
                                                    <Box
                                                      fontSize="12px"
                                                      className="product_title_text__ordview"
                                                    >
                                                      Description
                                                    </Box>
                                                    <Box className="product_text__ordview">
                                                      {product.description}
                                                    </Box>
                                                  </Grid>

                                                  {product.order_returned_quantity >
                                                  0 ? (
                                                    <Grid
                                                      item
                                                      md
                                                      sm={12}
                                                      xs={12}
                                                    >
                                                      <Box
                                                        fontSize="12px"
                                                        className="product_title_text__ordview"
                                                      >
                                                        Returned Description
                                                      </Box>

                                                      <Box className="product_text__ordview">
                                                        {
                                                          product.order_returned_description
                                                        }
                                                      </Box>
                                                    </Grid>
                                                  ) : null}
                                                </>
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                      <br />
                                    </>
                                  )}
                                </>
                              ))}
                              <br />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ViewOrder;
