import "./MobileMenu.css";
import { Grid } from "@mui/material";
import "@fontsource/poppins";
import Routes from "../Routes";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AiOutlinePoweroff, AiOutlineMenu } from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";
import { logout } from "../api/AuthAPI";
import { GlobalData } from "../App";
import ProtectComponent from "../middleware/ProtectComponent";

function MobileMenu(props) {
  const {
    smDown,
    flashMessage,
    navigate,
    showLoader,
    hideLoader,
    setIsUserAuthenticated,
    location,
  } = useContext(GlobalData);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
    props.toggleContentPadding(isOpen);
  };

  // function handleLogout() {
  //   showLoader();
  //   logout([], (resp) => {
  //     if (resp.statusCode === 200) {
  //       localStorage.removeItem("user");
  //       setIsUserAuthenticated(false);
  //       navigate("/");
  //       setTimeout(() => {
  //         hideLoader();
  //         flashMessage("success", resp.message);
  //       }, 3000);
  //     } else {
  //       hideLoader();
  //       flashMessage("error", resp.message);
  //     }
  //   });
  // }

  function handleLogout() {
    showLoader();
    localStorage.removeItem("user");
    setIsUserAuthenticated(false);
    navigate("/");
    setTimeout(() => {
      hideLoader();
      flashMessage("success", "User Logout Successfully");
    }, 3000);
    logout([], (resp) => {});
  }
  const currentPath = (path) => {
    if (
      path === location.pathname ||
      (location.pathname.includes("products") && path === "/products") ||
      (location.pathname.includes("orders") &&
        location.pathname !== "/staff/orders" &&
        path === "/orders") ||
      (location.pathname.includes("accounts") && path === "/accounts") ||
      (location.pathname.includes("vendors") && path === "/vendors") ||
      (location.pathname.includes("partners") && path === "/partners") ||
      (location.pathname.includes("users") && path === "/users") ||
      (location.pathname.includes("staff") && path === "/staff") ||
      (location.pathname.includes("bank") && path === "/bank")
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {}, []);

  const logoAnimation = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  const menuAnimation = {
    hidden: {
      opacity: 0,
      height: 0,
      transition: { duration: 0.3 },
    },
    show: {
      opacity: 1,
      height: "auto",
      transition: {
        duration: 0.3,
      },
    },
  };

  return (
    <motion.div
      animate={{
        height: isOpen ? "530px" : "45px",
        transition: {
          duration: 1,
          type: "spring",
          damping: 15,
        },
      }}
      className="mobile_menu"
    >
      <div className="menuBtnContainer__mtm">
        {!isOpen ? (
          <AiOutlineMenu
            onClick={() => {
              toggle();
            }}
            className="menuBtn__mtm"
          />
        ) : null}
      </div>
      {isOpen && (
        <>
          <AnimatePresence>
            <div
              initial="hidden"
              animate="show"
              exit="hidden"
              variants={logoAnimation}
            >
              <Grid container display="flex" justifyContent="center" mt={1.5}>
                <Grid item className="logo__mtm" />
              </Grid>
            </div>
          </AnimatePresence>
          <br />
        </>
      )}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            variants={menuAnimation}
            initial="hidden"
            animate="show"
            exit="hidden"
          >
            {Routes.map((value, index) => (
              <>
                {value.text !== "" ? (
                  <ProtectComponent
                    id={value.text}
                    componentLocation={false}
                    componentNavigate={true}
                    component={
                      <Link
                        onClick={() => {
                          toggle();
                        }}
                        key={index}
                        to={value.path}
                        className="nav_links__mtm"
                      >
                        <Grid
                          container
                          mt={0.3}
                          display="flex"
                          justifyContent="center"
                        >
                          <Grid
                            item
                            display="flex"
                            alignItem="center"
                            className="menuButton__mtm"
                            style={
                              currentPath(value.path)
                                ? {
                                    backgroundColor: "#ECAE33",
                                    color: "#1E2738",
                                  }
                                : {}
                            }
                          >
                            <div
                              className="icon__mtm"
                              style={
                                isOpen
                                  ? { marginRight: "12px" }
                                  : { marginRight: 0 }
                              }
                            >
                              {currentPath(value.path)
                                ? value.active
                                : value.icon}
                            </div>
                            {isOpen && (
                              <motion.div
                                variants={menuAnimation}
                                initial="hidden"
                                animate="show"
                                exit="hidden"
                              >
                                {value.text}
                              </motion.div>
                            )}
                          </Grid>
                        </Grid>
                      </Link>
                    }
                  />
                ) : null}
              </>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
      {isOpen && (
        <Grid container display="flex" mt={2} justifyContent="center">
          <Grid
            item
            display="flex"
            alignItem="center"
            justifyContent="center"
            className="mobile-logout-btn__mtm"
          >
            <motion.div
              variants={menuAnimation}
              initial="hidden"
              animate="show"
              exit="hidden"
              onClick={handleLogout}
            >
              Logout
            </motion.div>
            <div
              className="icon__mtm"
              style={isOpen ? { marginLeft: "10px" } : {}}
            >
              <AiOutlinePoweroff style={{ fontSize: "20px" }} />
            </div>
          </Grid>
        </Grid>
      )}
    </motion.div>
  );
}

export default MobileMenu;
