import { getWithParams, post, delte } from "./API";

export function getAllShops(data, callback) {
  getWithParams({ endPoint: "shop/getAllShops" }, data, function(response) {
    callback(response);
  });
}

export function createShop(data, callback) {
  post({ endPoint: "shop/createShop" }, data, function(response) {
    callback(response);
  });
}

export function updateShop(data, callback) {
  post({ endPoint: "shop/updateShop" }, data, function(response) {
    callback(response);
  });
}
