import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  DialogActions,
  Chip,
  TableCell,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { IoMdArrowDropleft } from "react-icons/io";
import { styled } from "@mui/material/styles";
import "./EditOnlineInventory.css";
import { GlobalData } from "../../App";
import { editProductOnline } from "../../api/OnlineInventoryAPI";
import imagePlaceholder from "../../assets/Images/imagePlaceholder.png";
import { addOnlineVariant, editOnlineVariant, deleteOnlineVariant } from "../../api/OnlineVariantAPI";
import NavigationBar from "../NavigationBar";
import AutoScrollContainer from "auto-scroll-container";
import { getAllColors } from "../../api/ColorAPI";
import { getAllMaterials } from "../../api/MaterialAPI";
import { getAllCategoriesObjects } from "../../api/CategoryAPI";
import ProtectComponent from "../../middleware/ProtectComponent";
import {ReactComponent as WhitePenIcon} from '../../assets/SVG/WhitePenIcon.svg';
import {ReactComponent as SquareAddIcon} from '../../assets/SVG/SquareAddIcon.svg';
import {ReactComponent as UploadImageIcon} from '../../assets/SVG/UploadImageIcon.svg';
import {ReactComponent as BlackPenIcon} from '../../assets/SVG/BlackPenIcon.svg'

import { IoMdCloseCircle } from "react-icons/io";
import DeleteDialog from '../../widgets/DeleteDialog'

const variantMobile = [
  {
    id: "imei",
    label: "IMEI No.",
    protectionId: "imei",
    align: "left",
  },
  {
    id: "color",
    label: "Color",
    protectionId: "color",
    align: "left",
  },
  {
    id: "storage",
    label: "Storage",
    protectionId: "storage",
    align: "left",
  },
  {
    id: "ram",
    label: "RAM",
    protectionId: "ram",
    align: "left",
  },
  {
    id: "quantity",
    label: "Quantity",
    protectionId: "quantity",
    align: "left",
  },
  {
    id: "cost_price",
    label: "C.Price",
    protectionId: "product_cost_price",
    align: "left",
  },
  {
    id: "sale_price",
    label: "S.Price",
    protectionId: "sale_price",
    align: "left",
  },
  {
    id: "min_sale_price",
    label: "Min-S.Price",
    protectionId: "min_sale_price",
    align: "left",
  },
];

const productObject = {
  id: "",
  name: "",
  source: "",
  material: "",
  category: "",
  category_id: "",
  image: "",
  variants: [],
};

const variantObject = {
  id: "",
  imei: "",
  color: "",
  quantity: 1,
  storage: "",
  ram: "",
  cost_price: "",
  sale_price: "",
  min_sale_price: "",
  description: "",
};

const CustomTextFieldInventory = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
  },
});

const CustomTextFieldVariant = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,${true ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,${true ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,${true ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
  },
});

function EditOnlineInventory(props) {
  const {
    flashMessage,
    smDown,
    location,
    showLoader,
    hideLoader,
    baseUrl,
    navigate,
    query,
    onlyXs,
  } = useContext(GlobalData);
  const [imageUri, setImageUri] = useState("");
  const [imageFile, setImageFile] = useState("");
 
  const [product, setProduct] = useState(productObject);
  const [variant, setVariant] = useState(variantObject);
  const [editVariantId, setEditVariantId] = useState("");
  const [imeiChipsInput, setImeiChipsInput] = useState("");
  const [variantDialog, setVariantDialog] = useState(false);
  const [variantDialogMode, setVariantDialogMode] = useState("add");
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(
    location.state.product.quantity
  );

  const [materials, setMaterials] = useState([]);
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);

  //

  const checkNullUndefined = (value) => {
    if (value == null || value == "") {
      return "0";
    } else {
      return value;
    }
  };

  // Use Effect

  useEffect(() => {
    showLoader();
    setImageUri(baseUrl + location.state.product.image);

    let productEditObject = {
      id: "",
      name: "",
      source: "",
      material: "",
      category: "",
      category_id: "",
      image: "",
      variants: [],
    };
    Object.keys(location.state.product).forEach(function(key) {
      productEditObject[key] = location.state.product[key];
    });
    setTimeout(() => {
      setProduct(productEditObject);
      hideLoader();
    }, 500);

    getAllMaterials((resp) => {
      setMaterials(resp.successData.materials);
    });
    getAllCategoriesObjects((resp) => {
      setCategories(resp.successData.categories);
    });
    getAllColors((resp) => {
      setColors(resp.successData.colors);
    });
  }, []);

  // Input Change Handling Methods

  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const handleVariantInputChange = (e) => {
    const { name, value } = e.target;
    setVariant({
      ...variant,
      [name]: value,
    });
  };

  // Image File Saving Method for Product

  function saveImageFile(e) {
    let image = document.getElementById("productImage").files;
    setImageFile(image[0]);
    setImageUri(window.URL.createObjectURL(new Blob(image, { type: "*" })));
  }

  //######################################################
  // *** Start Variant Methods ***

  // *** Variant Dialog Box ***

  // Add Variant Dialog Box

  const handleOpenAddVariantDialog = () => {
    setVariant({
      ...variant,
      ["imei"]: "",
      ["color"]: "",
      ["quantity"]: "",
      ["storage"]: "",
      ["ram"]: "",
      ["cost_price"]: "",
      ["sale_price"]: "",
      ["min_sale_price"]: "",
      ["description"]: "",
    });
    setImeiChipsInput("");
    setVariantDialogMode("add");
    setVariantDialog(true);
  };

  // Close Variant Dialog Box

  const handleCloseAddVariantDialog = () => {
    setVariantDialog(false);
  };

  // Set Variant Data for Edit Variant Dialog Box and also Open Variant Dialog Box

  const handleOpenEditVariantDialog = (index) => {
    let variantData = product.variants;
    setVariant({
      ...variant,
      ["imei"]: "",
      ["color"]: variantData[index].color,
      ["quantity"]: variantData[index].quantity,
      ["storage"]: variantData[index].storage,
      ["ram"]: variantData[index].ram,
      ["cost_price"]: variantData[index].cost_price,
      ["sale_price"]: variantData[index].sale_price,
      ["min_sale_price"]: variantData[index].min_sale_price,
      ["description"]: variantData[index].description,
    });
    setImeiChipsInput(variantData[index].imei);
    setEditVariantId(index);
    setVariantDialogMode("edit");
    setVariantDialog(true);
  };

  // *** Variant CRUD ***
  // Delete Variant

  const handleDeleteVariant = () => {
    handleCloseDeleteDialogVariant();
    let variantData = product.variants;
    let formData = new FormData();
    const data={variant_id: variantData[editVariantId].id}
    
    showLoader();
    deleteOnlineVariant(data, (resp) => {
      if (resp.statusCode === 200) {
      //  console.log("vaaaaaaaaa",resp.successData.variants)
        setProduct({
          ...product,
          ["variants"]: resp.successData.variants,
        });
        setTotalQuantity(resp.successData.total_quantity);
        hideLoader();
        flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  // Edit Variant

  const handleEditVariant = () => {
    let variantData = product.variants;
    if (variant.color === "") {
      flashMessage("error", "Please Select Color");
    } else if (variant.quantity === "") {
      flashMessage("error", "Please Enter Quantity");
    } else if (variant.cost_price === "") {
      flashMessage("error", "Please Enter Cost Price");
    } else if (variant.sale_price === "") {
      flashMessage("error", "Please Enter Sale Price");
    } else if (
      Number(variant.sale_price) < Number(variant.cost_price) ||
      Number(variant.sale_price) < Number(variant.min_sale_price)
    ) {
      flashMessage("error", "Invalid Sale Price");
    } else if (variant.min_sale_price === "") {
      flashMessage("error", "Please Enter Min. Sale Price");
    } else if (
      Number(variant.min_sale_price) < Number(variant.cost_price) ||
      Number(variant.min_sale_price) > Number(variant.sale_price)
    ) {
      flashMessage("error", "Invalid Min. Sale Price");
    } else {
      let formData = new FormData();
      formData.append("variant_id", variantData[editVariantId].id);
      formData.append("color", variant.color);
      formData.append("quantity", variant.quantity);
      formData.append("online_inventory_id", product.id);

      if (product.category=== "Mobile" || product.category === "Laptop") {
        formData.append("imei", checkNullUndefined(imeiChipsInput));
        formData.append("storage", checkNullUndefined(variant.storage));
        formData.append("ram", checkNullUndefined(variant.ram));
      }

      formData.append("cost_price", checkNullUndefined(variant.cost_price));
      formData.append("sale_price", checkNullUndefined(variant.sale_price));
      formData.append(
        "min_sale_price",
        checkNullUndefined(variant.min_sale_price)
      );
      formData.append("description", variant.description);
      showLoader();
      editOnlineVariant(formData, (resp) => {
        if (resp.statusCode === 200) {
        //  console.log("Variants",resp.successData)
          setProduct({
            ...product,
            ["variants"]: resp.successData.variants,
          });
          setTotalQuantity(resp.successData.total_quantity);
          hideLoader();
          flashMessage("success", resp.message);
        } else {
          hideLoader();
          flashMessage("error", resp.message);
        }
      });
      handleCloseAddVariantDialog();
    }
  };

  // Add Variant

  const addVariantToProduct = () => {
    let variantData = variant;
    variantData.imei = imeiChipsInput;
    if (variantData.color === "") {
      flashMessage("error", "Please Select Color");
    } else if (variantData.quantity === "") {
      flashMessage("error", "Please Enter Quantity");
    } else if (variantData.cost_price === "") {
      flashMessage("error", "Please Enter Cost Price");
    } else if (variantData.sale_price === "") {
      flashMessage("error", "Please Enter Sale Price");
    } else if (
      Number(variantData.sale_price) < Number(variantData.cost_price) ||
      Number(variantData.sale_price) < Number(variantData.min_sale_price)
    ) {
      flashMessage("error", "Invalid Sale Price");
    } else if (variantData.min_sale_price === "") {
      flashMessage("error", "Please Enter Min. Sale Price");
    } else if (
      Number(variantData.min_sale_price) < Number(variantData.cost_price) ||
      Number(variantData.min_sale_price) > Number(variantData.sale_price)
    ) {
      flashMessage("error", "Invalid Min. Sale Price");
    } else {
      let formData = new FormData();
      formData.append("online_inventory_id", location.state.product_id);
      formData.append("imei", checkNullUndefined(variantData.imei));
      formData.append("color", variantData.color);
      formData.append("quantity", checkNullUndefined(variantData.quantity));
      formData.append("storage", checkNullUndefined(variantData.storage));
      formData.append("ram", checkNullUndefined(variantData.ram));
      formData.append("cost_price", checkNullUndefined(variantData.cost_price));
      formData.append("sale_price", checkNullUndefined(variantData.sale_price));
      formData.append(
        "min_sale_price",
        checkNullUndefined(variantData.min_sale_price)
      );
      formData.append("description", variantData.description);
      formData.append("category", product.category);
      formData.append("category_id", product.category_id);
      showLoader();
      addOnlineVariant(formData, (resp) => {
        if (resp.statusCode === 200) {
        //("Console The variants",resp.successData)
          setProduct({
            ...product,
            ["variants"]: resp.successData.variants,
          });
          setTotalQuantity(resp.successData.total_quantity);
          hideLoader();
          flashMessage("success", resp.message);
        } else {
          hideLoader();
          flashMessage("error", resp.message);
        }
      });
      handleCloseAddVariantDialog();
    }
  };

  // *** Variant Delete Dialog Box ***

  // Open Delete Dialog Box

  const handleCloseDeleteDialogVariant = () => {
    setDeleteProductDialog(false);
  };

  const handleOpenDeleteDialogVariant = () => {
    handleCloseAddVariantDialog();
    setDeleteProductDialog(true);
  };

  // *** Update Variant Methods ***
  // #########################################################################

  const updateProduct = () => {
    showLoader();
    let formData = new FormData();
    formData.append("product_id", location.state.product_id);
    formData.append("name", product.name);
    formData.append("source", product.source);
    formData.append("material", product.material);
    formData.append("category", product.category);
    //formData.append("category_id", product.category.split(',')[1]);
    if (imageFile) {
      formData.append("image", imageFile, imageFile.name);
    }
    editProductOnline(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        navigate("/products/online-product");
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  // ##################################################
  // *** IMEI Chips Methods Start ***

  // Chip Auto Scroll To End

  useEffect(() => {
  
    let chip = document.getElementById("chip__chip");
    if (chip) {
      chip.scrollLeft = chip.scrollWidth - chip.scrollLeft;
      localStorage.setItem("chipScroll", chip.scrollLeft);
      localStorage.setItem("chipEndScroll", chip.scrollLeft);
    }
  });

  // Delete IMEI Chip

  const deleteIMEIChip = (index) => {
    let chips = imeiChipsInput.split(",");
    chips.splice(index, 1);
    chips = chips.reverse();
    setImeiChipsInput(String(chips));
  };

  // Edit IMEI Chip

  const editIMEIChip = (index) => {
    let chips = imeiChipsInput.split(",");
    setVariant({
      ...variant,
      ["imei"]: chips[index],
    });
    deleteIMEIChip(index);
  };

  // Create IMEI Chip

  const createIMEIChip = (e) => {
    if (["ArrowLeft"].includes(e.key)) {
      let chip = document.getElementById("chip__chip");
      if (chip) {
        let chipScroll = Number(localStorage.getItem("chipScroll"));
       // console.log(chipScroll);
        if (chipScroll > 0) {
          localStorage.setItem("chipScroll", chipScroll - 10);
        }
        chip.scrollLeft = Number(localStorage.getItem("chipScroll"));
      }
    }
    if (["ArrowRight"].includes(e.key)) {
      let chip = document.getElementById("chip__chip");
      if (chip) {
        let chipScroll = Number(localStorage.getItem("chipScroll"));
       // console.log(chipScroll, chip.scrollWidth);
        if (chipScroll < Number(localStorage.getItem("chipEndScroll"))) {
          localStorage.setItem("chipScroll", chipScroll + 10);
        }
        chip.scrollLeft = Number(localStorage.getItem("chipScroll"));
      }
    }
    if ([","].includes(e.key)) {
      e.preventDefault();
      setImeiChipsInput(
        imeiChipsInput.trim() +
          `${imeiChipsInput !== "" ? "," : ""}` +
          variant.imei.trim()
      );
      handleVariantInputChange(e);
      setVariant({
        ...variant,
        ["imei"]: "",
      });
    }
  };

  // *** Chips Methods End ***
  // ####################################################

  return (
    <>
        <DeleteDialog
      
      
      handleRemove={handleDeleteVariant}
      handleClose={handleCloseDeleteDialogVariant}
      showDialog={deleteProductDialog}
      title={"Remove this Variant?"}
      sub_title={"You are about to remove a Variant. Click on the Remove button to remove it."}
    />
      <Grid container height="100vh" className="padn_invoice__icon">
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
       

          <Dialog
            fullWidth
            maxWidth="lg"
            open={variantDialog}
            onClose={handleCloseAddVariantDialog}
          >
            <Box
              style={{ position: "absolute", right: "8px", top: "8px" }}
              onClick={handleCloseAddVariantDialog}
            >
              <IoMdCloseCircle
                size={28}
                color="red"
                style={{ cursor: "pointer" }}
              />
            </Box>
            <DialogContent id="addVariantDialogContent">
              {(product.category === "Mobile" ||
                product.category === "Laptop") && (
                <>
                  <Grid container padding={0}>
                    <Grid
                      xl={8}
                      lg={8}
                      md={8}
                      sm={12}
                      xs={12}
                      item
                      paddingRight={1}
                      paddingLeft={smDown ? 1 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>
                        {product.category === "Laptop"
                          ? "MAC Address"
                          : "IMEI No."}
                      </div>
                      <CustomTextFieldVariant
                        onKeyDown={createIMEIChip}
                        value={variant.imei}
                        onChange={handleVariantInputChange}
                        name="imei"
                        id="imei"
                        key="imei"
                        InputProps={{
                          startAdornment: (
                            <>
                              {imeiChipsInput ? (
                                <div id="chip__chip">
                                  {imeiChipsInput
                                    .split(",")
                                    .map((chip, index) => {
                                      let chipWidth = chip.length * 14;
                                      let chipCount = imeiChipsInput.split(",")
                                        .length;
                                      return (
                                        <>
                                          <Chip
                                            key={index}
                                            label={
                                              <Box textAlign="start">
                                                {chip}
                                              </Box>
                                            }
                                            variant="outlined"
                                            onClick={() => {
                                              editIMEIChip(index);
                                            }}
                                            onDelete={() => {
                                              deleteIMEIChip(index);
                                            }}
                                            style={{
                                              backgroundColor: "white",
                                              width: `${
                                                chipCount > 1
                                                  ? "auto"
                                                  : chipWidth.toString() + "px"
                                              }`,
                                            }}
                                          />
                                        </>
                                      );
                                    })}
                                  <span style={{ marginRight: "10px" }} />
                                </div>
                              ) : null}
                            </>
                          ),
                        }}
                        // helperText={

                        // }
                        placeholder={
                          imeiChipsInput === "" &&
                          `${
                            product.category === "Laptop"
                              ? "MAC Address"
                              : "IMEI No"
                          } 1, ${
                            product.category === "Laptop"
                              ? "MAC Address"
                              : "IMEI No"
                          } 2, ${
                            product.category === "Laptop"
                              ? "MAC Address"
                              : "IMEI No"
                          } 3`
                        }
                        className="textField_variant__AddInventory"
                      />

                      <Box color="rgb(0,0,0,.6)" padding={1} paddingTop={0.5}>
                        {product.category === "Laptop"
                          ? "MAC Address"
                          : "IMEI Number"}{" "}
                        must be separated by comma{" "}
                        <strong>(&nbsp;,&nbsp;)</strong>
                      </Box>
                    </Grid>

                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={1}
                      paddingRight={smDown ? 1 : 0}
                      paddingTop={smDown ? 2 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Color</div>
                      <FormControl className="textField_variant__AddInventory">
                        {variant.color === "" && (
                          <InputLabel sx={{ opacity: 0.6 }}>
                            Select Product Color
                          </InputLabel>
                        )}

                        <CustomTextFieldVariant
                          select
                          value={variant.color}
                          onChange={handleVariantInputChange}
                          name="color"
                          id="color"
                          key="color"
                        >
                          {colors.map((option) => (
                            <MenuItem key={option.color} value={option.color}>
                              {option.color}
                            </MenuItem>
                          ))}
                        </CustomTextFieldVariant>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    display="flex"
                    paddingTop={2}
                    justifyContent="space-around"
                  >
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingRight={1}
                      paddingLeft={smDown ? 1 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>RAM</div>
                      <CustomTextFieldVariant
                        value={variant.ram}
                        onChange={handleVariantInputChange}
                        name="ram"
                        id="ram"
                        key="ram"
                        placeholder="Device RAM"
                        className="textField_variant__AddInventory"
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={1}
                      paddingRight={1}
                      paddingTop={smDown ? 2 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Storage</div>

                      <CustomTextFieldVariant
                        value={variant.storage}
                        onChange={handleVariantInputChange}
                        name="storage"
                        id="storage"
                        key="storage"
                        placeholder="Device Storage"
                        className="textField_variant__AddInventory"
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={1}
                      paddingRight={smDown ? 1 : 0}
                      paddingTop={smDown ? 2 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Quantity</div>
                      <CustomTextFieldVariant
                        value={variant.quantity}
                        onChange={handleVariantInputChange}
                        name="quantity"
                        id="quantity"
                        key="quantity"
                        type="number"
                        className="textField_variant__AddInventory"
                        InputProps={{
                          inputProps: { min: 1 },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    display="flex"
                    paddingTop={2}
                    justifyContent="space-around"
                  >
                    <ProtectComponent
                      id="product_cost_price"
                      componentLocation={true}
                      componentNavigate={false}
                      component={
                        <Grid
                          xl={4}
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          item
                          paddingRight={1}
                          paddingLeft={smDown ? 1 : 0}
                        >
                          <div style={{ marginBottom: "8px" }}>Cost Price</div>
                          <CustomTextFieldVariant
                            value={variant.cost_price}
                            onChange={handleVariantInputChange}
                            name="cost_price"
                            id="cost_price"
                            key="cost_price"
                            placeholder="Product Cost Price"
                            className="textField_variant__AddInventory"
                          />
                        </Grid>
                      }
                    />
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={1}
                      paddingRight={1}
                      paddingTop={smDown ? 2 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Sale Price</div>

                      <CustomTextFieldVariant
                        value={variant.sale_price}
                        onChange={handleVariantInputChange}
                        name="sale_price"
                        id="sale_price"
                        key="sale_price"
                        placeholder="Product Sale Price"
                        className="textField_variant__AddInventory"
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={1}
                      paddingRight={smDown ? 1 : 0}
                      paddingTop={smDown ? 2 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Min. Sale Price</div>
                      <CustomTextFieldVariant
                        value={variant.min_sale_price}
                        onChange={handleVariantInputChange}
                        name="min_sale_price"
                        id="min_sale_price"
                        key="min_sale_price"
                        placeholder="Product Min. Sale Price"
                        className="textField_variant__AddInventory"
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {product.category !== "Mobile" && product.category !== "Laptop" && (
                <>
                  <Grid
                    container
                    display="flex"
                    paddingTop={2}
                    justifyContent="space-around"
                  >
                    <Grid xs item paddingRight={1} paddingLeft={smDown ? 1 : 0}>
                      <div style={{ marginBottom: "8px" }}>Color</div>
                      <FormControl className="textField_variant__AddInventory">
                        {variant.color === "" && (
                          <InputLabel sx={{ opacity: 0.6 }}>
                            Select Product Color
                          </InputLabel>
                        )}

                        <CustomTextFieldVariant
                          select
                          value={variant.color}
                          onChange={handleVariantInputChange}
                          name="color"
                          id="color"
                          key="color"
                        >
                          {colors.map((option) => (
                            <MenuItem key={option.color} value={option.color}>
                              {option.color}
                            </MenuItem>
                          ))}
                        </CustomTextFieldVariant>
                      </FormControl>
                    </Grid>
                    <Grid
                      xs
                      item
                      paddingLeft={1}
                      paddingRight={1}
                      paddingTop={smDown ? 2 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Quantity</div>
                      <CustomTextFieldVariant
                        value={variant.quantity}
                        onChange={handleVariantInputChange}
                        name="quantity"
                        id="quantity"
                        key="quantity"
                        type="number"
                        className="textField_variant__AddInventory"
                        InputProps={{
                          inputProps: { min: 1 },
                        }}
                      />
                    </Grid>

                    <ProtectComponent
                      id="product_cost_price"
                      componentLocation={true}
                      componentNavigate={false}
                      component={
                        <Grid
                          xs
                          item
                          paddingLeft={1}
                          paddingRight={smDown ? 1 : 0}
                          paddingTop={smDown ? 2 : 0}
                        >
                          <div style={{ marginBottom: "8px" }}>Cost Price</div>
                          <CustomTextFieldVariant
                            value={variant.cost_price}
                            onChange={handleVariantInputChange}
                            name="cost_price"
                            id="cost_price"
                            key="cost_price"
                            placeholder="Product Cost Price"
                            className="textField_variant__AddInventory"
                          />
                        </Grid>
                      }
                    />
                  </Grid>

                  <Grid
                    container
                    display="flex"
                    paddingTop={2}
                    justifyContent="space-around"
                  >
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      item
                      paddingRight={1}
                    >
                      <div style={{ marginBottom: "8px" }}>Sale Price</div>

                      <CustomTextFieldVariant
                        value={variant.sale_price}
                        onChange={handleVariantInputChange}
                        name="sale_price"
                        id="sale_price"
                        key="sale_price"
                        placeholder="Product Sale Price"
                        className="textField_variant__AddInventory"
                      />
                    </Grid>
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={1}
                      paddingRight={smDown ? 1 : 0}
                      paddingTop={smDown ? 2 : 0}
                    >
                      <div style={{ marginBottom: "8px" }}>Min. Sale Price</div>
                      <CustomTextFieldVariant
                        value={variant.min_sale_price}
                        onChange={handleVariantInputChange}
                        name="min_sale_price"
                        id="min_sale_price"
                        key="min_sale_price"
                        placeholder="Product Min. Sale Price"
                        className="textField_variant__AddInventory"
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid container padding={0} paddingTop={2}>
                <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                  <div style={{ marginBottom: "8px" }}>
                    Product Description (Optional)
                  </div>
                  <CustomTextFieldVariant
                    multiline
                    rows={4}
                    style={{ width: "" }}
                    value={variant.description}
                    onChange={handleVariantInputChange}
                    name="description"
                    id="description"
                    key="description"
                    placeholder="Write about product here"
                    className="textField_variant__AddInventory"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                padding={0}
                paddingTop={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
                  {variantDialogMode === "edit" ? (
                    <>
                      <Grid container>
                        <Grid
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          paddingRight={onlyXs ? 0 : 1}
                          item
                        >
                          <Button
                            className="editVariantBtn__ai"
                            style={{ width: "100%" }}
                            onClick={handleOpenDeleteDialogVariant}
                          >
                            Delete Variant
                          </Button>
                        </Grid>
                        <Grid
                          xl={6}
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          paddingLeft={onlyXs ? 0 : 1}
                          item
                        >
                          <Button
                            className="addVariantBtn__ai"
                            onClick={handleEditVariant}
                            style={{ width: "100%" }}
                          >
                            Save Changes
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Button
                      className="addVariantBtn__ai"
                      onClick={addVariantToProduct}
                      style={{
                        width: "100%",
                      }}
                    >
                      Add Variant
                    </Button>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

          <Box height={smDown ? "30vh" : "20vh"}>
            <NavigationBar
              path={"Dashboard>Inventory Management>Online Products>Edit Online Product"}
              paddingBottom={1}
            />

            <Grid
              display="flex"
              justifyContent="center"
              container
              mb={2}
              mt={2}
            >
              <Grid
                className="header__ai"
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                item
              >
                <div
                  className="header_back_btn__ai"
                  onClick={() => {
                    navigate("/products/online-product");
                  }}
                >
                  <IoMdArrowDropleft
                    className="add__icon_orders_sty"
                  />
                  Back
                </div>
                <div className="header_text__ai">
                Online  Inventory Management {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                  <span style={smDown ? { fontSize: "14px" } : {}}>
                    Update Product
                  </span>
                </div>
              </Grid>
            </Grid>
          </Box>

          <Box className="div__scroll" height={smDown ? "70vh" : "80vh"}>
            <Grid container className="card__ai">
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                item
                paddingTop={query(2, 2, 2)}
                paddingBottom={query(3, 3, 3)}
                paddingLeft={query(5, 2, 1)}
                paddingRight={query(5, 2, 1)}
              >
                <AutoScrollContainer marginTop={0.01}>
                  <Grid container display="flex" justifyContent="center">
                    <Grid
                      xl={2}
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      item
                      paddingRight={query(1, 1, 0)}
                      paddingTop={query(1, 1, 1)}
                      display="flex"
                      justifyContent="center"
                    >
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <input
                          style={{ display: "none" }}
                          id="productImage"
                          type="file"
                          onChange={saveImageFile}
                        />

                        {imageUri !== "" ? (
                          <Box position="relative">
                            <img
                              alt=""
                              style={{
                                borderRadius: "50%",
                                border: "1px solid rgba(0,0,0,0.2)",
                              }}
                              src={imageUri}
                              width={query("80px", "80px", "120px")}
                              height={query("80px", "80px", "120px")}
                            />
                            <Box position="absolute" top="0" right="0">
                       <BlackPenIcon/>
                      
                            </Box>
                          </Box>
                        ) : (
                          <Box position="relative">
                            <img
                              alt=""
                              style={{
                                borderRadius: "50%",
                                border: "1px solid rgba(0,0,0,0.2)",
                              }}
                              src={imagePlaceholder}
                              width="120px"
                              height="120px"
                            />
                            <Box position="absolute" top="0" right="0">
                             
                            </Box>
                          </Box>
                        )}
                      </IconButton>
                    </Grid>
                    <Grid
                      xl={5}
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                      paddingLeft={query(1, 1, 0)}
                      paddingRight={query(1, 1, 0)}
                      item
                    >
                      <div style={{ marginBottom: "8px" }}>Product Name</div>
                      <CustomTextFieldInventory
                        key="name"
                        value={product.name}
                        onChange={handleProductInputChange}
                        name="name"
                        id="name"
                        placeholder="Product Name"
                        className="textField__AddInventory"
                      />
                    </Grid>

                    <Grid
                      xl={5}
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={query(1, 1, 0)}
                      paddingTop={query(0, 0, 2)}
                    >
                      <div style={{ marginBottom: "8px" }}>
                        Total Product Quantity
                      </div>
                      <CustomTextFieldInventory
                        key="total_quantity"
                        value={totalQuantity}
                        name="total_quantity"
                        id="total_quantity"
                        className="textField__AddInventory"
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    display="flex"
                    paddingTop={query(2, 2, 0)}
                    justifyContent="center"
                    paddingBottom={3}
                  >
                    <ProtectComponent
                      id="product_source"
                      componentLocation={true}
                      componentNavigate={false}
                      component={
                        <Grid
                          sm
                          xs={12}
                          item
                          paddingTop={query(0, 0, 2)}
                          paddingRight={query(1, 1, 0)}
                        >
                          <div style={{ marginBottom: "8px" }}>
                            Source (Optional)
                          </div>
                          <CustomTextFieldInventory
                            value={product.source}
                            onChange={handleProductInputChange}
                            name="source"
                            id="source"
                            key="source"
                            placeholder="Product Source"
                            className="textField__AddInventory"
                          />
                        </Grid>
                      }
                    />

                    <Grid
                      sm
                      xs={12}
                      item
                      paddingLeft={query(1, 1, 0)}
                      paddingRight={query(1, 1, 0)}
                      paddingTop={query(0, 0, 2)}
                    >
                      <div style={{ marginBottom: "8px" }}>Material</div>

                      <FormControl className="textField__AddInventory">
                        {product.material === "" && (
                          <InputLabel sx={{ opacity: 0.6 }}>
                            Select Product Material
                          </InputLabel>
                        )}

                        <CustomTextFieldInventory
                          select
                          value={product.material}
                          onChange={handleProductInputChange}
                          name="material"
                          id="material"
                          key="material"
                        >
                          {materials.map((option) => (
                            <MenuItem
                              key={option.material}
                              value={option.material}
                            >
                              {option.material}
                            </MenuItem>
                          ))}
                        </CustomTextFieldInventory>
                      </FormControl>
                    </Grid>
                    <Grid
                      sm
                      xs={12}
                      item
                      paddingLeft={query(1, 1, 0)}
                      paddingTop={query(0, 0, 2)}
                    >
                      <div style={{ marginBottom: "8px" }}>Category</div>
                      {/* <FormControl className="textField__AddInventory">
                        {product.category === "" && (
                          <InputLabel sx={{ opacity: 0.6 }}>
                            Select Product Category
                          </InputLabel>
                        )}
                        <CustomTextField
                          id="category"
                          key="category"
                          select
                          value={product.category}
                          onChange={handleProductInputChange}
                          name="category"
                          className="textField__AddInventory"
                        >
                          {categories.map((option) => (
                            <MenuItem
                              key={option.category}
                              value={option.category+','+option.id}
                            >
                              {option.category}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                      </FormControl> */}
                      <CustomTextFieldInventory
                        value={product.category}
                        onChange={handleProductInputChange}
                        name="category"
                        id="category"
                        key="category"
                        placeholder="Product Category"
                        className="textField__AddInventory"
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <hr />

                  <Box className="variant_cont__addinv">
                    <Box className="variant_body__addinv">
                      {product.variants.length > 0 ? (
                        <Grid container>
                          <Grid
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            item
                            paddingRight={1}
                            paddingLeft={1}
                            className=""
                          >
                            <Box className="variant_title__ai">Variants</Box>

                            {product.variants.map((variant, vi) => (
                              <Box id="variant__ai">
                                <Box
                                  className="variant_edit_btn_con__ai"
                                  onClick={() => {
                                    handleOpenEditVariantDialog(vi);
                                  }}
                                >
                                  <IconButton className="variant_edit_btn__ai">
                                 <WhitePenIcon/>
                                  </IconButton>
                                </Box>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      {variantMobile.map((column) =>
                                        product.category === "Laptop" ? (
                                          <ProtectComponent
                                            id={column.protectionId}
                                            componentLocation={true}
                                            componentNavigate={false}
                                            component={
                                              <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className="variant_cell_head__ai"
                                              >
                                                {column.label === "IMEI No."
                                                  ? "MAC Address"
                                                  : column.label}
                                              </TableCell>
                                            }
                                          />
                                        ) : product.category === "Mobile" ? (
                                          <ProtectComponent
                                            id={column.protectionId}
                                            componentLocation={true}
                                            componentNavigate={false}
                                            component={
                                              <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className="variant_cell_head__ai"
                                              >
                                                {column.label}
                                              </TableCell>
                                            }
                                          />
                                        ) : column.label !== "IMEI No." &&
                                          column.label !== "Storage" &&
                                          column.label !== "RAM" ? (
                                          <ProtectComponent
                                            id={column.protectionId}
                                            componentLocation={true}
                                            componentNavigate={false}
                                            component={
                                              <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className="variant_cell_head__ai"
                                              >
                                                {column.label}
                                              </TableCell>
                                            }
                                          />
                                        ) : null
                                      )}

                                      <TableCell
                                        key="description"
                                        align="left"
                                        className="variant_cell_head__ai"
                                      >
                                        Description
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {[variant].map((row) => {
                                      return product.category === "Mobile" ||
                                        product.category === "Laptop" ? (
                                        <TableRow
                                          hover
                                          tabIndex={-1}
                                          key={row.code}
                                        >
                                          <TableCell
                                            className="variant_imei__ai"
                                            key="imei"
                                            align="left"
                                          >
                                            {row["imei"].split(",").join("\n")}
                                          </TableCell>
                                          {variantMobile.map((column) => {
                                            const value =
                                              row[column.id] !== null
                                                ? row[column.id]
                                                    .toString()
                                                    .split(",")
                                                    .join("\n")
                                                : "";

                                            return (
                                              <>
                                                {column.id !== "imei" ? (
                                                  <TableCell
                                                    className="variant_cell__ai"
                                                    key={column.id}
                                                    align={column.align}
                                                  >
                                                    {value}{" "}
                                                    {column.label.includes(
                                                      "Price"
                                                    )
                                                      ? "AED"
                                                      : null}
                                                  </TableCell>
                                                ) : null}
                                              </>
                                            );
                                          })}
                                          <TableCell
                                            className="variant_desc__ai"
                                            key="description"
                                            align="left"
                                          >
                                            {row.description}
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        <TableRow
                                          hover
                                          tabIndex={-1}
                                          key={row.code}
                                        >
                                          {variantMobile.map((column) => {
                                            const value =
                                              row[column.id] !== null
                                                ? row[column.id]
                                                    .toString()
                                                    .split(",")
                                                    .join("\n")
                                                : "";

                                            return column.label !==
                                              "IMEI No." &&
                                              column.label !== "Storage" &&
                                              column.label !== "RAM" ? (
                                              <ProtectComponent
                                                id={column.protectionId}
                                                componentLocation={true}
                                                componentNavigate={false}
                                                component={
                                                  <TableCell
                                                    className="variant_cell__ai"
                                                    key={column.id}
                                                    align={column.align}
                                                  >
                                                    {value}{" "}
                                                    {column.label.includes(
                                                      "Price"
                                                    )
                                                      ? "AED"
                                                      : null}
                                                  </TableCell>
                                                }
                                              />
                                            ) : null;
                                          })}

                                          <TableCell
                                            className="variant_desc__ai"
                                            key="description"
                                            align="left"
                                          >
                                            {row.description}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </Box>
                            ))}

                            <Button
                              className="add_varient_btn__ai"
                              variant="contained"
                              onClick={handleOpenAddVariantDialog}
                              endIcon={
                            <SquareAddIcon/>
                              }
                            >
                              Add More Varients
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        <Box height="20vh" style={{ marginTop: "20px" }}>
                          <Button
                            className="add_varient_btn__ai"
                            variant="contained"
                            onClick={handleOpenAddVariantDialog}
                            endIcon={
                           <SquareAddIcon/>
                            }
                          >
                            Add Varients
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Grid container paddingTop={5} paddingBottom={5}>
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      item
                      paddingLeft={smDown ? 1 : 0}
                      display="flex"
                      justifyContent="start"
                    >
                      <Button
                        onClick={() => {
                          navigate("/products/online-product");
                        }}
                        variant="contained"
                        className="footer_dis__ai"
                      >
                        Discard
                      </Button>
                    </Grid>
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      item
                      paddingRight={smDown ? 1 : 0}
                      display="flex"
                      justifyContent="end"
                    >
                      <Button
                        onClick={() => {
                          updateProduct();
                        }}
                        variant="contained"
                        className="footer_add__ai"
                      >
                        Update Product
                      </Button>
                    </Grid>
                  </Grid>
                </AutoScrollContainer>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default EditOnlineInventory;
