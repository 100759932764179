import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Menu,
  ButtonGroup,
} from "@mui/material";
import "./AddOrder.css";
import { IoMdArrowDropleft } from "react-icons/io";
import { styled } from "@mui/material/styles";
import { GlobalData } from "../../App";
import { createOrder } from "../../api/OrderAPI";
import NavigationBar from "../NavigationBar";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import AutoScrollContainer from "auto-scroll-container";
import ProductMultiLevelMenu from "../../widgets/ProductMultiLevelMenu";
import { orderInvoice } from "../../api/ReportAPI";
import { registerVendor } from "../../api/VendorAPI";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FcRating, FcPaid } from "react-icons/fc";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as GiftRedIcon } from "../../assets/SVG/GiftRedIcon.svg";
import { ReactComponent as SquareIcon } from "../../assets/SVG/SquareAddIconDark.svg";
import { getAllBanks } from "../../api/BankAPI";
import Autocomplete from "@mui/material/Autocomplete";
import { COUNTRIES } from "../../constants/Constants";
import PhoneNumber from "libphonenumber-js";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import { getTodayDate, pad } from "../../utils/common";
import { debounce } from "lodash";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const orderObject = {
  order_type: "Cash",
  invoice_type: "",
  tre_no: "",
  invoice_code_type: "",
  invoice_code: "",
  customer_name: "",
  customer_phone: "",
  order_date: "",
  bank_id: "",
  bank_name: "",
};

const productObject = {
  order: "0",
  product: "",
  variant: "",
  order_price: 0,
  order_quantity: 1,
  total_order_price: 0,
  product_imei: "",
  product_description: "",
  order_discount: 0,
  gifted: false,
};

// Text Field Styling

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const CustomTextFieldForMobile1 = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "10px 0px 0px 10px",
      borderRight: "none",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "10px 0px 0px 10px",
      borderRight: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "10px 0px 0px 10px",
      borderRight: "none",
    },
  },
});

const CustomTextFieldForMobile2 = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "0px 10px 10px 0px",
      borderLeft: "none",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "0px 10px 10px 0px",
      borderLeft: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "0px 10px 10px 0px",
      borderLeft: "none",
    },
  },
});

function AddOrder() {
  const {
    flashMessage,
    smDown,
    query,
    navigate,
    showLoader,
    hideLoader,
    baseUrl,
    location,
    userObject,
  } = useContext(GlobalData);

  const TAX_PERCENTAGE = Number(userObject.tax_percentage);

  const [order, setOrder] = useState(orderObject);

  const [disableDiscount, setDisableDiscount] = useState();

  const [orderProducts, setOrderProducts] = useState([]);
  const [orderProductsCount, setOrderProductsCount] = useState(0);
  const [product, setProduct] = useState(productObject);
  const [invoiceCodeDropDown, setInvoiceCodeDropDown] = useState(null);
  const isInvoiceCodeDropDownOpen = Boolean(invoiceCodeDropDown);

  const [tax, setTax] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  const [returnOrderBalance, setReturnOrderBalance] = useState(0);
  const [returnOrderTax, setReturnOrderTax] = useState(0);
  const [returnOrderDiscount, setReturnOrderDiscount] = useState(0);

  const [countryCodeText, setCountryCodeText] = useState("");

  const [discountAmount, setDiscountAmount] = useState(0);

  const [orderHasGift, setOrderHasGift] = useState(false);
  const [previousInvoiceTypeValue, setPreviousInvoiceTypeValue] = useState("");

  const [isProductSelected, setIsProductSelected] = useState(false);
  const [quantityGreaterThanError, setQuantityGreaterThanError] = useState(
    false
  );
  const [orderPriceSmallerThanError, setOrderPriceSmallerThanError] = useState(
    false
  );
  const [searchText, setSearchText] = useState("");
  const [value, setValue] = useState();
  const [userCountry, setUserCountry] = useState(COUNTRIES[0]);

  const [banks, setBanks] = useState([]);

  const [clickBoder, setClickBorder] = useState("");
  const handleGetAllBanks = () => {
    let data = {
      search_text: "",
    };
    getAllBanks(data, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        setBanks(resp.successData.banks);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  // Input Change Handling Methods

  const handleOrderInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "bank") {
      let bank = banks.find((b) => b.bank_name === value);
      setOrder({
        ...order,
        ["bank_id"]: bank.id,
        ["bank_name"]: bank.bank_name,
      });
    } else {
      setOrder({
        ...order,
        [name]: value,
      });
    }
    console.log(order);
  };

  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };

  // Invoice Code Drop Down Methods

  const handleInvoiceCodeDropDownOpen = (event) => {
    if (returnOrderBalance + returnOrderTax === 0) {
      setInvoiceCodeDropDown(event.currentTarget);
    }
  };

  const handleInvoiceCodeDropDownClose = (value) => {
    setInvoiceCodeDropDown(null);
    setOrder({
      ...order,
      ["invoice_code_type"]: value,
    });
  };

  function validatePhoneNumber(phoneNumber, countryCode) {
    const completePhoneNumber = `+${countryCode}${phoneNumber}`;
    const parsedNumber = PhoneNumber(completePhoneNumber);
    return parsedNumber.isValid();
  }

  // *** Create Order Methods ***
  // #########################################################################

  const handleCreateOrder = () => {
    // try {
    //   if (!isPossiblePhoneNumber(order.customer_phone, userCountry.code)) {
    //     flashMessage("error", "Customer phone number is not correct.");
    //     return 0;
    //   }
    // } catch {
    //   flashMessage(
    //     "error",
    //     "Please select country code for customer phone number."
    //   );
    //   return 0;
    // }
    if (orderProducts.length === 0) {
      flashMessage("error", "Please Add Product");
      return 0;
    }
    showLoader();
    let formData = new FormData();
    formData.append("order_type", order.order_type);
    formData.append("invoice_type", order.invoice_type);
    if (order.invoice_type === "With VAT") {
      formData.append("tre_no", order.tre_no);
    }
    if (order.invoice_code_type !== "system_generated_code") {
      formData.append("invoice_code_type", "manual");
    } else {
      formData.append("invoice_code_type", order.invoice_code_type);
    }
    formData.append("invoice_code", order.invoice_code);
    formData.append("customer_name", order.customer_name);
    formData.append("customer_phone", "+" + order.customer_phone);
    formData.append("sub_total", subTotal);
    formData.append("total", totalOrderPrice);
    formData.append("tax", tax);
    if (orderProducts.length >= 0) {
      formData.append("products", JSON.stringify(orderProducts));
    }

    if (location.state.path === "/staff/orders") {
      formData.append("staff_user_id", location.state.memberData.user_id);
    }

    if (order.order_type === "Card") {
      formData.append("bank_name", order.bank_name);
      formData.append("bank_id", order.bank_id);
    } else {
      formData.append("bank_name", "0");
      formData.append("bank_id", "0");
    }

    var date = new Date(order.order_date);
    formData.append(
      "order_date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );

    formData.append(
      "order_discount",
      orderProducts.reduce(
        (n, { order_discount }) => n + Number(order_discount),
        0
      )
    );

    if (location.state.order !== undefined) {
      formData.append(
        "returnOrderBalance",
        returnOrderBalance + returnOrderTax
      );
      formData.append("returnOrder", JSON.stringify(location.state.order));
      formData.append(
        "returnOrderProducts",
        JSON.stringify(location.state.returnOrderProducts)
      );
      formData.append("order_id", JSON.stringify(location.state.order_id));
    }

    let taxAmount = (Number(subTotal) - Number(discountAmount)) * tax;

    formData.append(
      "total_without_tax",
      Number(subTotal) - (Number(discountAmount) + Number(returnOrderBalance))
    );

    formData.append("tax_amount", Number(taxAmount));
    formData.append(
      "total_tax_amount",
      Number(taxAmount) - Number(returnOrderTax)
    );

    formData.append("return_order_discount", returnOrderDiscount);

    if (totalOrderPrice >= 0) {
      createOrder(formData, (resp) => {
        if (resp.statusCode === 200) {
          console.log(resp.successData.order);
          handlePrintSingleOrder(resp.successData.order);
          hideLoader();
          if (location.state.path === "/staff/orders") {
            navigate("/staff/orders", {
              state: {
                path: location.pathname,
                memberData: location.state.memberData,
              },
            });
          } else {
            navigate("/orders", {
              state: {
                path: location.pathname,
              },
            });
          }
          flashMessage("success", resp.message);
        } else {
          hideLoader();
          flashMessage("error", resp.message);
        }
      });
    } else {
      hideLoader();
      flashMessage("error", "Negative order total amount is not allowed!");
    }
  };

  const handlePrintSingleOrder = (order) => {
    showLoader();
    let formData = new FormData();
    formData.append("orders", JSON.stringify([order]));
    orderInvoice(formData, (resp) => {
      if (resp.statusCode === 200) {
        window.open(baseUrl + "public/" + resp.successData.file_path, "_blank");
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  /* Get All Inventory */

  // Use Effect

  useEffect(() => {
    if (Number(product.order_quantity) > Number(product.variant.quantity)) {
      setQuantityGreaterThanError(true);
    } else {
      setQuantityGreaterThanError(false);
      let total = Number(product.order_quantity) * product.order_price;
      setProduct({
        ...product,
        ["total_order_price"]: total,
      });
    }
  }, [product.order_quantity, product.order_price]);

  useEffect(() => {
    if (Number(product.order_price) < Number(product.variant.min_sale_price)) {
      setOrderPriceSmallerThanError(true);
    } else {
      setOrderPriceSmallerThanError(false);
    }
  }, [product.order_price]);

  // Add Product to Products and create Empty Product Fields

  const handleAddProduct = () => {
    if (product.product === "") {
      flashMessage("error", "Please Select Product");
    } else if (product.variant === "") {
      flashMessage("error", "Please Select Product Variant");
    } else if (product.order_price === "") {
      flashMessage("error", "Please Enter Product Price");
    } else if (product.order_quantity < 1) {
      flashMessage("error", "Please Enter Product Quantity");
    } else if (product.variant.cost_price === 0) {
      flashMessage("error", "Product Cost Price is 0");
    } else if (quantityGreaterThanError) {
      flashMessage(
        "error",
        "Product quantity is greater than the available stock!"
      );
    } else if (orderPriceSmallerThanError) {
      flashMessage(
        "error",
        "Product price must be greater than Min. Sale Price"
      );
    } else {
      let productsData = orderProducts;

      if (product.gifted === 1 || product.gifted) {
        setDiscountAmount((amount) => {
          if (orderProducts.length === 0) {
            amount = Number(product.total_order_price);
            setDisableDiscount(true);
          } else {
            amount = Number(amount) + Number(product.total_order_price);
          }
          return amount;
        });
        setPreviousInvoiceTypeValue(order.invoice_type);
        setOrder({
          ...order,
          ["invoice_type"]: "Without VAT",
        });
        setOrderHasGift(true);
      }

      productsData.push(product);
      setSubTotal(Number(subTotal) + Number(product.total_order_price));
      setOrderProducts(productsData);
      setProduct(productObject);
    }
  };

  // Delete Product

  const handleDeleteProduct = (id) => {
    if (orderProducts[id].gifted === 1 || orderProducts[id].gifted) {
      setDiscountAmount((amount) => {
        amount = amount - orderProducts[id].total_order_price;
        if (amount === 0 && disableDiscount) {
          setDisableDiscount(false);
        }
        return amount;
      });
      setOrder({
        ...order,
        ["invoice_type"]: previousInvoiceTypeValue,
      });
      setOrderHasGift(false);
    }
    setOrderProducts((current) =>
      current.filter((value, index) => {
        return index !== id;
      })
    );
    setSubTotal(subTotal - orderProducts[id].total_order_price);
  };

  // Edit Product

  const handleEditProduct = (id) => {
    if (orderProducts[id].gifted === 1 || orderProducts[id].gifted) {
      setDiscountAmount((amount) => {
        amount = amount - orderProducts[id].total_order_price;
        if (amount === 0 && disableDiscount) {
          setDisableDiscount(false);
        }
        return amount;
      });
      setOrder({
        ...order,
        ["invoice_type"]: previousInvoiceTypeValue,
      });
      setOrderHasGift(false);
    }
    setIsProductSelected(true);
    setSubTotal(subTotal - orderProducts[id].total_order_price);
    setProduct(orderProducts[id]);
    setOrderProducts((current) =>
      current.filter((value, index) => {
        return index !== id;
      })
    );
  };

  // Add Selected Product to Product From Product Multi Level Menu Component

  const addSelectedProduct = (p, v, i) => {
    setProduct({
      ...product,
      ["product"]: p,
      ["variant"]: v,
      ["product_imei"]: i,
      ["order_price"]: v.sale_price,
      ["order_quantity"]: 1,
    });
  };

  const handleBackToOrders = () => {
    if (location.state.path === "/staff/orders") {
      navigate("/staff/orders", {
        state: {
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/orders");
    }
  };

  //

  const handleSearchTextInputField = (value) => {
    setSearchText(value);
  };

  const updateNumberField = useMemo(() => {
    return debounce(handleSearchTextInputField, 300);
  }, []);

  useEffect(() => {
    fetch("http://ip-api.com/json")
      .then((response) => response.json())
      .then((data) => {
        let country = COUNTRIES.filter((obj) => {
          return obj.name == data.country;
        });
        setUserCountry(country[0]);
      });

    handleGetAllBanks();
    if (location.state.order !== undefined) {
      setOrder({
        ...order,
        ["order_type"]: location.state.order.order_type,
        ["invoice_code"]: location.state.order.invoice_code,
        ["tre_no"]: location.state.order.tre_no,
        ["invoice_type"]: location.state.order.invoice_type,
        ["customer_name"]: location.state.order.customer_name,
        ["customer_phone"]: location.state.order.customer_phone,
        ["order_date"]: getTodayDate(),
        ["invoice_code_type"]: "manual",
      });
      setReturnOrderBalance(location.state.returnOrderBalance);
      setReturnOrderTax(location.state.returnOrderTax);
      setReturnOrderDiscount(location.state.returnOrderDiscount);
    } else {
      setOrder({
        ...order,
        ["order_date"]: getTodayDate(),
      });
    }
  }, []);

  useEffect(() => {
    let banksData = banks.reverse();
    if (banks.length > 0) {
      setOrder({
        ...order,
        ["bank_name"]: banksData[0].bank_name,
        ["bank_id"]: banksData[0].id,
      });
    }
  }, [banks]);

  useEffect(() => {
    if (order.invoice_type === "With VAT") {
      setTotalOrderPrice((total) => {
        let totalAmount = subTotal - discountAmount;
        totalAmount = totalAmount * (TAX_PERCENTAGE / 100) + totalAmount;
        total = totalAmount - (returnOrderBalance + returnOrderTax);
        return total;
      });
      setTax(TAX_PERCENTAGE / 100);
    } else {
      setTotalOrderPrice((total) => {
        let totalAmount = subTotal - discountAmount;
        totalAmount = totalAmount * 0 + totalAmount;
        total = totalAmount - (returnOrderBalance + returnOrderTax);
        return total;
      });
      setTax(0);
    }
  }, [subTotal, order.invoice_type, discountAmount]);

  useEffect(() => {
    let orderProductsData = [...orderProducts];

    let amount = 0;

    orderProductsData.map((product, index) => {
      if (product.gifted === 1 || product.gifted) {
        product.order_discount =
          Number(product.order_discount) + Number(product.total_order_price);
      }
    });

    amount = orderProductsData.reduce(
      (n, { order_discount }) => n + Number(order_discount),
      0
    );

    setDiscountAmount(amount);
  }, [orderProducts.length]);

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  return (
    <Grid container height="100vh" className="padn_invoice__icon">
      {/* START ******************************* Container Column ******************************************** */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        {/* START ******************************* 
                          Header Box that includes header and navigation bar

        ******************************************** */}

        <Box height={smDown ? "30vh" : "20vh"}>
          {/* START ******************************* Navigation Bar ******************************************** */}

          <NavigationBar
            path={"Dashboard>Orders Management>Orders>Add New Order"}
            paddingBottom={1}
          />

          {/* END ******************************* Navigation Bar ******************************************** */}

          {/* START ******************************* Header Row ******************************************** */}

          <Grid display="flex" justifyContent="center" container mb={2} mt={2}>
            <Grid
              className="header__ai"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
            >
              <div className="header_back_btn__ai" onClick={handleBackToOrders}>
                <IoMdArrowDropleft className="add__icon_orders_sty" />
                Back
              </div>
              <div className="header_text__ai">
                Orders Management {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                <span style={smDown ? { fontSize: "14px" } : {}}>
                  Add New Order
                </span>
              </div>
            </Grid>
          </Grid>

          {/* END ******************************* Header Row ******************************************** */}
        </Box>

        {/* END ******************************* 
        
                          Header Box that includes header and navigation bar

        ******************************************** */}

        {/* START ******************************* Content Box ******************************************** */}

        <Box className="div__scroll" height={smDown ? "70vh" : "80vh"}>
          {/* START ******************************* 
                
                      Content Gradiant Box
                
                ******************************************** */}
          <Grid container className="card__ai">
            {/* START ******************************* 
                
                      Content container Inside the Gradiant Box
                
                ******************************************** */}
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingTop={query(2, 2, 2)}
              paddingBottom={query(3, 3, 3)}
              paddingLeft={query(5, 2, 1)}
              paddingRight={query(5, 2, 1)}
              id="addOrderStyle"
            >
              {/* START ******************************* 
                
                          Auto Scroll Container for vertical scrolling
                
                ******************************************** */}

              <AutoScrollContainer marginTop={0.01}>
                {/* START ******************************* 
                
                          Order Type, Invoice Type and Invoice Code Fields
                
                ******************************************** */}

                <Grid container display="flex" justifyContent="center">
                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    <div style={{ marginBottom: "8px" }}>Order Type</div>
                    <FormControl className="textField__AddInventory">
                      {order.order_type === "" && (
                        <InputLabel sx={{ opacity: 0.6 }}>
                          Select Order Type
                        </InputLabel>
                      )}

                      <CustomTextField
                        select
                        value={order.order_type}
                        onChange={handleOrderInputChange}
                        name="order_type"
                        id="order_type"
                        key="order_type"
                      >
                        {["Cash", "Credit", "Card"].map((option, oi) => (
                          <MenuItem
                            key={option}
                            value={option}
                            style={
                              oi + 1 < 4
                                ? {
                                    borderBottom: "1px solid rgba(0,0,0,0.15)",
                                  }
                                : {}
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                    </FormControl>
                  </Grid>
                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingRight={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    <div style={{ marginBottom: "8px" }}>Invoice Type</div>

                    <FormControl className="textField__AddInventory">
                      {order.invoice_type === "" && (
                        <InputLabel sx={{ opacity: 0.6 }}>
                          Select Invoice Type
                        </InputLabel>
                      )}

                      <CustomTextField
                        select
                        value={order.invoice_type}
                        onChange={handleOrderInputChange}
                        name="invoice_type"
                        id="invoice_type"
                        key="invoice_type"
                        disabled={
                          orderHasGift || returnOrderBalance > 0 ? true : false
                        }
                      >
                        {["With VAT", "Without VAT"].map((option, oi) => (
                          <MenuItem
                            key={option}
                            value={option}
                            style={
                              oi + 1 < 2
                                ? { borderBottom: "1px solid rgba(0,0,0,0.15)" }
                                : {}
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                    </FormControl>
                  </Grid>

                  <Grid
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    <div style={{ marginBottom: "8px" }}>Invoice Code</div>

                    <Button
                      id="select_invoice_code__add_order"
                      variant="outlined"
                      component="span"
                      style={{
                        width: "100%",
                        height: "54.5px",
                        backgroundColor: "white",
                        borderColor: "rgb(0,0,0,0.25)",
                        color: "rgb(0,0,0,0.35)",
                        textTransform: "none",
                        fontSize: "16px",
                        justifyContent: "flex-start",
                        borderRadius: "10px",
                      }}
                      onClick={handleInvoiceCodeDropDownOpen}
                    >
                      {order.invoice_code_type === "" ? (
                        "Select Invoice Code Type"
                      ) : order.invoice_code_type !==
                        "system_generated_code" ? (
                        <Box className="product_inv__addord">
                          {order.invoice_code}
                        </Box>
                      ) : (
                        <Box className="product_inv__addord">
                          System Generated Code
                        </Box>
                      )}
                    </Button>
                    <Menu
                      id="invoice_code_type"
                      aria-labelledby="invoice_code_type"
                      name="invoice_code_type"
                      key="invoice_code_type"
                      anchorEl={invoiceCodeDropDown}
                      open={isInvoiceCodeDropDownOpen}
                      onClose={handleInvoiceCodeDropDownClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem
                        key="system_generated_code"
                        value="system_generated_code"
                        style={{ borderBottom: "1px solid rgba(0,0,0,0.15)" }}
                        onClick={() => {
                          handleInvoiceCodeDropDownClose(
                            "system_generated_code"
                          );
                        }}
                      >
                        System Generated Code
                      </MenuItem>
                      <MenuItem key="manually" value="manually">
                        <Box mt={1}>
                          <div style={{ marginBottom: "8px" }}>
                            Enter Manually
                          </div>
                          <CustomTextField
                            value={order.invoice_code}
                            onChange={handleOrderInputChange}
                            name="invoice_code"
                            id="invoice_code"
                            key="invoice_code"
                            placeholder="Enter Invoice Code"
                            className="textField__AddInventory"
                          />
                        </Box>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>

                {/* END ******************************* 
                
                          Order Type, Invoice Type and Invoice Code Fields
                
                ******************************************** */}

                {/* START ******************************* 
                
                          TRE NO. Field
                
                ******************************************** */}

                <Grid container mt={2}>
                  {order.invoice_type === "With VAT" ? (
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      paddingRight={query(1, 1, 0)}
                      item
                    >
                      <div style={{ marginBottom: "8px" }}>
                        TRE NO. (Optional)
                      </div>
                      <CustomTextField
                        value={order.tre_no}
                        onChange={handleOrderInputChange}
                        name="tre_no"
                        id="tre_no"
                        key="tre_no"
                        placeholder="Enter TRE Number"
                        className="textField__AddInventory"
                      />
                    </Grid>
                  ) : null}
                  {order.order_type === "Card" ? (
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      paddingLeft={
                        order.invoice_type === "With VAT"
                          ? query(1, 1, 0)
                          : null
                      }
                      item
                    >
                      <div style={{ marginBottom: "8px" }}>Select Bank</div>
                      <FormControl className="textField__AddInventory">
                        {order.bank_name === "" && (
                          <InputLabel sx={{ opacity: 0.6 }}>
                            Please Select Bank
                          </InputLabel>
                        )}
                        <CustomTextField
                          select
                          value={order.bank_name}
                          onChange={handleOrderInputChange}
                          name="bank"
                          id="bank"
                          key="bank"
                        >
                          {banks.map((option, oi) => (
                            <MenuItem
                              key={option.id}
                              value={option.bank_name}
                              style={
                                oi + 1 < 2
                                  ? {
                                      borderBottom:
                                        "1px solid rgba(0,0,0,0.15)",
                                    }
                                  : {}
                              }
                            >
                              {option.bank_name}
                            </MenuItem>
                          ))}
                        </CustomTextField>
                      </FormControl>
                    </Grid>
                  ) : null}
                </Grid>

                {/* END ******************************* 
                
                          TRE NO. Field
                
                ******************************************** */}

                {/* START ******************************* 
                
                          Customer Name and Phone Fields
                
                ******************************************** */}

                <Grid
                  container
                  display="flex"
                  paddingTop={query(2, 2, 0)}
                  justifyContent="center"
                  paddingBottom={3}
                >
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>Customer Name</div>
                    <CustomTextField
                      value={order.customer_name}
                      onChange={handleOrderInputChange}
                      name="customer_name"
                      id="customer_name"
                      key="customer_name"
                      placeholder="Customer Name"
                      className="textField__AddInventory"
                      disabled={
                        returnOrderBalance + returnOrderTax > 0 ? true : false
                      }
                    />
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingLeft={query(1, 1, 0)}
                  >
                    {/* <div style={{ marginBottom: "8px" }}>Customer Phone</div>
                    <Box
                      display="flex"
                      width="100%"
                      className="box_border__addorder"
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pl={1}
                        className="country_code_label__addorder"
                      >
                        {userCountry?.code ? (
                          <Box className="country_code_label_text__addorder">
                            <img
                              loading="lazy"
                              width="40"
                              height="20px"
                              src={`https://flagcdn.com/w40/${userCountry?.code.toLowerCase()}.png`}
                              alt=""
                              style={{
                                border: "1px solid rgb(0, 0, 0, 0.2)",
                                borderRadius: "5px",
                              }}
                            />
                            &nbsp;
                            <Box className="country_code_text1__addorder">
                              {userCountry?.mobileCode} ({userCountry?.code})
                            </Box>
                          </Box>
                        ) : (
                          <Box className="select_code_text__addorder">
                            {countryCodeText === "" ? `Select Code` : ""}
                          </Box>
                        )}
                      </Box>
                      <Box width="50%">
                        <Autocomplete
                          disablePortal
                          id="selected_country__add_order"
                          options={COUNTRIES}
                          
                          filterOptions={filterOptions}
                          onChange={(event, newValue) => {
                            setUserCountry(newValue); 
                          }}
                          
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                                
                              />
                              {option.name} ({option.code}) +{option.mobileCode}
                            </Box>
                          )}
                         
                          value={userCountry}
                          getOptionLabel={(option) => option.mobileCode}
                          renderInput={(params) => (
                            <CustomTextFieldForMobile1
                              {...params}
                              onChange={(e) => {
                                setCountryCodeText(e.target.value);
                                
                              }}
                              className="textField__AddInventory_right_radius"
                            />
                          )}
                        />
                      </Box>
                      <Box width="50%">
                        <CustomTextFieldForMobile2
                          value={order.customer_phone}
                          onChange={handleOrderInputChange}
                          name="customer_phone"
                          id="customer_phone"
                          key="customer_phone"
                          placeholder="Customer Phone"
                          className="textField__AddInventory_left_radius"
                          disabled={
                            returnOrderBalance + returnOrderTax > 0
                              ? true
                              : false
                          }customer_phone
                          type="number"
                        />
                      </Box>
                    </Box> */}

                    <div style={{ marginBottom: "8px" }}>Customer Phone</div>
                    <PhoneInput
                      country={"Us"}
                      value={order.customer_phone}
                      onChange={(value) => {
                        setOrder({
                          ...order,
                          ["customer_phone"]: value,
                        });
                      }}
                      placeholder="Customer Phone"
                      inputProps={{
                        name: "customer_phone",
                        id: "customer_phone",
                        key: "customer_phone",
                      }}
                      inputClass="phone_input_search"
                      buttonClass="button_input_field"
                      disabled={
                        returnOrderBalance + returnOrderTax > 0 ? true : false
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container display="flex" paddingBottom={3}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingRight={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>Order Date</div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="bnkmnu_field__bank"
                        value={order.order_date}
                        onChange={(newValue) => {
                          setOrder({
                            ...order,
                            ["order_date"]: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            id="order_date_field__add_order"
                            error={false}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {/* <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    item
                    paddingTop={query(0, 0, 2)}
                    paddingLeft={query(1, 1, 0)}
                  >
                    <div style={{ marginBottom: "8px" }}>Discount</div>
                    <CustomTextField
                      value={discountAmount}
                      onChange={(e) => {
                        setDiscountAmount(e.target.value);
                      }}
                      name="order_discount"
                      id="order_discount"
                      key="order_discount"
                      placeholder="Discount"
                      className="textField__AddInventory"
                      type="number"
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                      disabled={disableDiscount}
                    />
                  </Grid> */}
                </Grid>

                {/* END ******************************* 
                
                          Customer Name and Phone Fields
                
                ******************************************** */}

                {!smDown ? <hr /> : null}

                {/* START ******************************* 

                            Order Products Heading in Mobile Screen
                
                ******************************************** */}

                {smDown ? (
                  <>
                    <hr style={{ marginTop: "40px" }} />
                    <div style={{ marginBottom: "8px", textAlign: "center" }}>
                      Order Products
                    </div>
                    <hr style={{ marginBottom: "40px" }} />
                  </>
                ) : null}

                {/* END ******************************* 

                        Order Products Heading in Mobile Screen
                
                ******************************************** */}

                {/* START ******************************* 
                
                          Product List Fields Header

                          Like,

                          Product Name, Price, Quantity

                          For Laptop to Medium Screen
                
                
                ******************************************** */}

                {!smDown ? (
                  <Grid container mt={4}>
                    <Grid
                      xl={5}
                      lg={5}
                      md={5}
                      sm={12}
                      xs={12}
                      paddingRight={query(1, 1, 0)}
                      item
                    >
                      <div style={{ marginBottom: "8px" }}>Product Name</div>
                    </Grid>
                    <Grid
                      xl={3}
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={query(1, 1, 0)}
                      paddingRight={query(1, 1, 0)}
                      paddingTop={query(0, 0, 2)}
                    >
                      <div style={{ marginBottom: "8px" }}>Product Price</div>
                    </Grid>
                    <Grid
                      xl={2}
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={query(1, 1, 0)}
                      paddingTop={query(0, 0, 2)}
                    >
                      <div style={{ marginBottom: "8px" }}>Discount</div>
                    </Grid>
                    <Grid
                      xl={1}
                      lg={1}
                      md={1}
                      sm={12}
                      xs={12}
                      item
                      paddingLeft={query(1, 1, 0)}
                      paddingTop={query(0, 0, 2)}
                    >
                      <div style={{ marginBottom: "8px" }}>Quantity</div>
                    </Grid>
                  </Grid>
                ) : null}

                {/* END ******************************* 
                
                          Product List Fields Header

                          Like,

                          Product Name, Price, Quantity

                          For Laptop to Medium Screen
                
                
                ******************************************** */}

                {/* START ******************************* Added Product List ******************************************** */}

                {orderProducts.map((pro, proi) => (
                  <>
                    <Grid
                      key={proi}
                      container
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={2}
                    >
                      {smDown ? (
                        <Grid
                          xl={1}
                          lg={1}
                          md={1}
                          sm={12}
                          xs={12}
                          item
                          paddingLeft={query(1, 1, 0)}
                          paddingTop={query(0, 0, 2)}
                          display="flex"
                          justifyContent="center"
                        >
                          <ButtonGroup
                            orientation="horizontal"
                            variant="contained"
                            style={{
                              backgroundColor: "#ECAE33",
                              padding: "6px",
                              borderRadius: "10%",
                            }}
                          >
                            <HighlightOffIcon
                              style={{
                                cursor: "pointer",
                                paddingRight: "30px",
                                color: "white",
                              }}
                              onClick={() => {
                                handleDeleteProduct(proi);
                              }}
                            />
                            <EditIcon
                              onClick={() => {
                                handleEditProduct(proi);
                              }}
                              style={{ cursor: "pointer", color: "white" }}
                            />
                          </ButtonGroup>
                        </Grid>
                      ) : null}
                      <Grid
                        xl={5}
                        lg={5}
                        md={5}
                        sm={12}
                        xs={12}
                        paddingRight={query(1, 1, 0)}
                        item
                      >
                        {smDown ? (
                          <div style={{ marginBottom: "8px" }}>
                            Product Name
                          </div>
                        ) : null}
                        <Button
                          variant="outlined"
                          component="span"
                          style={{
                            width: "100%",
                            height: "54.5px",
                            backgroundColor: "white",
                            borderColor: "rgb(0,0,0,0.25)",
                            color: "rgb(0,0,0,0.35)",
                            textTransform: "none",
                            fontSize: "16px",
                            justifyContent: "flex-start",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            alt=""
                            src={baseUrl + pro.product.image}
                            width="30px"
                            height="30px"
                            style={{
                              borderRadius: "5px",
                              border: "1px solid rgba(0,0,0,0.2)",
                              marginRight: "10px",
                            }}
                          />
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                            alignItems="center"
                          >
                            <Box className="product_inv__addord">
                              {pro.product.name}
                              &nbsp;(
                              {pro.variant.color}
                              {pro.variant.storage !== "" &&
                              pro.variant.storage !== null
                                ? ","
                                : ""}
                              {pro.variant.storage !== null
                                ? pro.variant.storage
                                : ""}
                              {pro.variant.ram !== "" &&
                              pro.variant.ram !== null
                                ? ","
                                : ""}
                              {pro.variant.ram !== null ? pro.variant.ram : ""})
                              <br />
                              {pro.imei !== "" ? pro.product_imei : ""}
                            </Box>
                            {pro.gifted && (
                              <Box mt={1}>
                                <FcPaid size={34} />
                              </Box>
                            )}
                          </Box>
                        </Button>
                      </Grid>
                      <Grid
                        xl={3}
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        item
                        paddingLeft={query(1, 1, 0)}
                        paddingRight={query(1, 1, 0)}
                        paddingTop={query(0, 0, 2)}
                      >
                        {smDown ? (
                          <div style={{ marginBottom: "8px" }}>
                            Product Price
                          </div>
                        ) : null}
                        <CustomTextField
                          type="number"
                          value={pro.order_price}
                          className="textField__AddInventory"
                        />
                      </Grid>
                      <Grid
                        xl={2}
                        lg={2}
                        md={2}
                        sm={12}
                        xs={12}
                        item
                        paddingLeft={query(1, 1, 0)}
                        paddingTop={query(0, 0, 2)}
                      >
                        {smDown ? (
                          <div style={{ marginBottom: "8px" }}>
                            Product Discount
                          </div>
                        ) : null}
                        <CustomTextField
                          value={pro.order_discount}
                          className="textField__AddInventory"
                        />
                      </Grid>

                      <Grid
                        xl={1}
                        lg={1}
                        md={1}
                        sm={12}
                        xs={12}
                        item
                        paddingLeft={query(1, 1, 0)}
                        paddingTop={query(0, 0, 2)}
                      >
                        {smDown ? (
                          <div style={{ marginBottom: "8px" }}>
                            Product Quantity
                          </div>
                        ) : null}
                        <CustomTextField
                          value={pro.order_quantity}
                          className="textField__AddInventory"
                        />
                      </Grid>

                      {!smDown ? (
                        <Grid
                          xl={1}
                          lg={1}
                          md={1}
                          sm={12}
                          xs={12}
                          item
                          paddingLeft={query(1, 1, 0)}
                          paddingTop={query(0, 0, 2)}
                          disabled
                          display="flex"
                        >
                          <ButtonGroup
                            orientation="vertical"
                            aria-label="vertical contained button group"
                            variant="contained"
                            style={{
                              backgroundColor: "#ECAE33",
                              padding: "2px 5px 2px 5px",
                              borderRadius: "10%",
                            }}
                          >
                            <HighlightOffIcon
                              style={{
                                cursor: "pointer",
                                paddingBottom: "5px",
                                color: "white",
                              }}
                              onClick={() => {
                                handleDeleteProduct(proi);
                              }}
                            />
                            <EditIcon
                              onClick={() => {
                                handleEditProduct(proi);
                              }}
                              style={{ cursor: "pointer", color: "white" }}
                            />
                          </ButtonGroup>
                        </Grid>
                      ) : null}
                    </Grid>

                    <Grid container mt={0.2}>
                      <Grid xs={11}>
                        <Box className="prod_added_des__addord">
                          {pro.product_description}
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                ))}

                {/* END ******************************* Added Product List ******************************************** */}

                {smDown ? (
                  <>
                    <hr style={{ marginTop: "40px" }} />
                    <div style={{ marginBottom: "8px", textAlign: "center" }}>
                      Add New Product Detail
                    </div>
                    <hr style={{ marginBottom: "40px" }} />
                  </>
                ) : null}

                {/* START ******************************* Add New Product Fields ******************************************** */}

                <Grid container mt={orderProducts.length > 0 ? 2 : 0}>
                  <Grid
                    xl={5}
                    lg={5}
                    md={5}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    {smDown ? (
                      <div style={{ marginBottom: "8px" }}>Product Name</div>
                    ) : null}

                    <ProductMultiLevelMenu
                      id={"product_name_field__add_order"}
                      setIsProductSelected={setIsProductSelected}
                      setProduct={addSelectedProduct}
                      product={product}
                      productsData={orderProducts}
                    />
                  </Grid>
                  <Grid
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingRight={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    {smDown ? (
                      <div style={{ marginBottom: "8px" }}>Product Price</div>
                    ) : null}
                    <CustomTextField
                      error={orderPriceSmallerThanError ? true : false}
                      value={product.order_price}
                      onChange={handleProductInputChange}
                      name="order_price"
                      id="order_price"
                      key="order_price"
                      placeholder="Product Price"
                      type="number"
                      className="textField__AddInventory"
                      helperText={
                        orderPriceSmallerThanError
                          ? `Min. Sale Price is ${
                              product.variant.min_sale_price
                            }`
                          : ""
                      }
                      disabled={!isProductSelected}
                      
                    />
                  </Grid>
                  <Grid
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingRight={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    {smDown ? (
                      <div style={{ marginBottom: "8px" }}>
                        Product Discount
                      </div>
                    ) : null}
                    <CustomTextField
                      // disabled={
                      //   product.variant.category === "Mobile" ||
                      //   !isProductSelected
                      // }
                      // error={quantityGreaterThanError ? true : false}
                      value={product.order_discount}
                      onChange={handleProductInputChange}
                      name="order_discount"
                      id="order_discount"
                      key="order_discount"
                      type="number"
                      className="textField__AddInventory"
                      // InputProps={{
                      //   inputProps: { min: 1 },
                      // }}
                      // helperText={
                      //   quantityGreaterThanError
                      //     ? `Available Quantity is ${product.variant.quantity}`
                      //     : ""
                      // }
                    />
                  </Grid>
                  <Grid
                    xl={1}
                    lg={1}
                    md={1}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                  >
                    {smDown ? (
                      <div style={{ marginBottom: "8px" }}>
                        Product Quantity
                      </div>
                    ) : null}
                    <CustomTextField
                      disabled={
                        product.variant.category === "Mobile" ||
                        !isProductSelected
                      }
                      error={quantityGreaterThanError ? true : false}
                      value={product.order_quantity}
                      onChange={handleProductInputChange}
                      name="order_quantity"
                      id="order_quantity"
                      key="order_quantity"
                      type="number"
                      className="textField__AddInventory"
                      InputProps={{
                        inputProps: { min: 1 },
                      }}
                      helperText={
                        quantityGreaterThanError
                          ? `Available Quantity is ${product.variant.quantity}`
                          : ""
                      }
                    />
                  </Grid>

                  <Grid
                    xl={1}
                    lg={1}
                    md={1}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(1, 1, 0)}
                    paddingTop={query(0, 0, 2)}
                    disabled
                  >
                    <Checkbox
                      id="product_gift"
                      checked={product.gifted}
                      {...label}
                      icon={<GiftRedIcon />}
                      checkedIcon={<FcPaid size={34} />}
                      onClick={() => {
                        setProduct({
                          ...product,
                          ["gifted"]: !product.gifted,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container mt={1}>
                  <Grid xs={11}>
                    <CustomTextField
                      value={product.product_description}
                      onChange={handleProductInputChange}
                      name="product_description"
                      id="product_description"
                      key="product_description"
                      placeholder="Description ..."
                      className="textField__AddInventory"
                    />
                  </Grid>
                </Grid>

                {/* END **************************** Add New Product Fields ******************************************** */}

                {/* START **************************** Add Product Button ******************************************** */}

                <Grid container display="flex" justifyContent="center" mt={2}>
                  <Grid
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    <Button
                      id="add_product__add_order"
                      onClick={handleAddProduct}
                      className="add_product_btn__adord"
                      variant="contained"
                      endIcon={<SquareIcon />}
                    >
                      {orderProducts.length > 0 ? "Add Product" : "Add Product"}
                    </Button>
                  </Grid>
                </Grid>

                {/* END **************************** Add Product Button ******************************************** */}

                {/* START **************************** Total Calculation ******************************************** */}

                <Grid
                  container
                  paddingTop={4}
                  paddingBottom={1}
                  display="flex"
                  justifyContent="end"
                >
                  <Grid
                    xl={3}
                    lg={5}
                    md={6}
                    sm={8}
                    xs={8}
                    item
                    display="flex"
                    justifyContent="end"
                  >
                    <Grid container>
                      <Grid md item>
                        SUBTOTAL
                      </Grid>
                      {location.state.order !== undefined ? (
                        <Grid md item>
                          BALANCE
                        </Grid>
                      ) : null}
                      <Grid md item>
                        DISCOUNT
                      </Grid>
                      <Grid md item display="flex" justifyContent="end">
                        TAX RATE
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container display="flex" justifyContent="end">
                  <Grid
                    xl={3}
                    lg={5}
                    md={6}
                    sm={8}
                    xs={8}
                    item
                    display="flex"
                    justifyContent="end"
                    style={{ borderBottom: "1px solid black" }}
                  >
                    <Grid container>
                      <Grid className="price__addord" md item>
                        {subTotal} {userObject.currency}
                      </Grid>
                      {location.state.order !== undefined ? (
                        <Grid className="price__addord" md item>
                          {returnOrderBalance + returnOrderTax}{" "}
                          {userObject.currency}
                        </Grid>
                      ) : null}
                      <Grid className="price__addord" md item>
                        {discountAmount} {userObject.currency}
                      </Grid>
                      <Grid
                        md
                        item
                        display="flex"
                        justifyContent="end"
                        className="price__addord"
                      >
                        <span>
                          {subTotal > 0
                            ? ` ${
                                order.invoice_type === "With VAT"
                                  ? (
                                      (subTotal - discountAmount) *
                                      (TAX_PERCENTAGE / 100)
                                    ).toFixed(2)
                                  : "0"
                              } ${userObject.currency} ${
                                order.invoice_type === "With VAT"
                                  ? TAX_PERCENTAGE
                                  : "0"
                              }%`
                            : ""}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  paddingTop={1}
                  paddingBottom={1}
                  display="flex"
                  justifyContent="end"
                >
                  <Grid
                    xl={3}
                    lg={5}
                    md={6}
                    sm={8}
                    xs={8}
                    item
                    display="flex"
                    justifyContent="end"
                  >
                    TOTAL
                  </Grid>
                </Grid>
                <Grid container display="flex" justifyContent="end">
                  <Grid
                    xl={3}
                    lg={5}
                    md={6}
                    sm={8}
                    xs={8}
                    item
                    display="flex"
                    justifyContent="end"
                    style={{ borderBottom: "1px solid black" }}
                    className="price__addord"
                  >
                    {totalOrderPrice} {userObject.currency}
                  </Grid>
                </Grid>

                {/* END **************************** Total Calculation ******************************************** */}

                {/* START **************************** Discard and Place Order ******************************************** */}

                <Grid container paddingTop={5} paddingBottom={5}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    item
                    paddingLeft={smDown ? 1 : 0}
                    display="flex"
                    justifyContent="start"
                  >
                    <Button
                      id="discard_btn__add_order"
                      onClick={() => {
                        navigate("/orders");
                      }}
                      variant="contained"
                      className="footer_dis__ai"
                    >
                      Discard
                    </Button>
                  </Grid>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    item
                    paddingRight={smDown ? 1 : 0}
                    display="flex"
                    justifyContent="end"
                  >
                    <Button
                      id="place_order__add_order"
                      onClick={handleCreateOrder}
                      variant="contained"
                      className="footer_add__ai"
                    >
                      Place Order
                    </Button>
                  </Grid>
                </Grid>

                {/* END ******************************* Discard and Place Order ******************************************** */}
              </AutoScrollContainer>

              {/* END ******************************* 
                
                          Auto Scroll Container for vertical scrolling
                
                ******************************************** */}
            </Grid>
            {/* END ******************************* 
                
                      Content container Inside the Gradiant Box
                
                ******************************************** */}
          </Grid>
          {/* END ******************************* 
                
                      Content Gradiant Box
                
                ******************************************** */}
        </Box>

        {/* END ******************************* Content Box ******************************************** */}
      </Grid>
      {/* END ******************************* Container Column ******************************************** */}
    </Grid>
  );
}

export default AddOrder;
