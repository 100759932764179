import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Chip,
  MobileStepper,
} from "@mui/material";
import "./UserRoles.css";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import { EditSVG, ViewSVG, DeleteSVG } from "../../assets/SVG/GeneralSVG";
import CustomTable from "../../widgets/CustomTable";
import { styled } from "@mui/material/styles";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { encrypt, decrypt } from "../../middleware/Encryption";
import {
  addUserRole,
  updateUserRole,
  getAllUserRoles,
  deleteUserRole,
} from "../../api/UserRolesAPI";
import AddNewButton from "../../widgets/AddNewButton";
import DeleteDialog from "../../widgets/DeleteDialog";
import NoBank from "../../assets/Images/EmptyScreenImage/NoBank.png";


const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    align: "left",
  },
  {
    id: "role",
    label: "Role",
    align: "left",
  },
  {
    id: "permissions",
    label: "Permissions",
    align: "left",
  },
  {
    id: "created_at",
    label: "Date",
    align: "left",
    type: "date",
  },
];

const options = [
  // {
  //   icon: <ViewSVG />,
  //   label: "View",
  //   method: "view",
  //   type: "icon",
  // },
  {
    icon: <EditSVG />,
    label: "Edit",
    method: "edit",
    type: "icon",
  },
  {
    icon: <DeleteSVG />,
    label: "Delete",
    method: "delete",
    type: "icon",
  },
];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

function UserRoles(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
    mdDown,
    onlyMd,
    baseUrl,
  } = useContext(GlobalData);

  const [userAddNewRoleDialog, setUserAddNewRoleDialog] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const [permissions, setPermissions] = useState([]);

  const [selectedUserRoleId, setSelectedUserRoleId] = useState("");

  const [userRole, setUserRole] = useState("");

  const [userRoles, setUserRoles] = useState([]);

  const [userRoleDialogMethod, setUserRoleDialogMethod] = useState("");

  const [allowedPermissionsData, setAllowedPermissionsData] = useState([]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangePermission = (index, permission) => {
    let newPermissions = [...permissions];
    newPermissions[activeStep].permissions[index].value = permission;
    setPermissions(newPermissions);
  };

  const handleSavePermissions = () => {
    if (userRole.toLowerCase() === "admin") {
      flashMessage("error", "You cannot create another admin");
      return 0;
    }
    showLoader();
    setUserAddNewRoleDialog(false);
    let formData = new FormData();
    formData.append("role", userRole);
    formData.append("permissions", encrypt(JSON.stringify(permissions)));
    addUserRole(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleGetAllUserRoles();
        flashMessage("success", resp.message);
      } else {
        setUserAddNewRoleDialog(true);
        flashMessage("error", resp.message);
      }
    });
  };

  const handleGetAllUserRoles = () => {
    showLoader();
    getAllUserRoles((resp) => {
      hideLoader();
      setUserRoles(resp.successData.user_roles);
    });
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    let allowedPermissions = decrypt(user.user_role.permissions);
    allowedPermissions = JSON.parse(allowedPermissions);
    allowedPermissions.forEach((module) => {
      module.permissions.forEach((permission) => {
        permission.value = false;
      });
    });
    setPermissions(allowedPermissions);
    setAllowedPermissionsData(allowedPermissions);
    handleGetAllUserRoles();
  }, []);

  // const handleUserRoleDetail = (data) => {
  //   console.log(JSON.parse(decrypt(data.permissions)));
  // };

  const handleClickAddNewRoleButton = () => {
    setUserRoleDialogMethod("Add");
    setActiveStep(0);
    setUserRole("");
    let allowedPermissions = [...allowedPermissionsData];
    allowedPermissions.forEach((module) => {
      module.permissions.forEach((permission) => {
        permission.value = false;
      });
    });
    setPermissions(allowedPermissionsData);
    setUserAddNewRoleDialog(true);
  };

  const handleEditUserRole = (data) => {
    setActiveStep(0);
    setUserRole(data.role);
    setPermissions(JSON.parse(decrypt(data.permissions)));
    setUserRoleDialogMethod("Update");
    setSelectedUserRoleId(data.id);
    setUserAddNewRoleDialog(true);
  };

  const handleUpdatePermissions = () => {
    showLoader();
    setUserAddNewRoleDialog(false);
    let formData = new FormData();
    formData.append("role", userRole);
    formData.append("permissions", encrypt(JSON.stringify(permissions)));
    formData.append("user_role_id", selectedUserRoleId);
    updateUserRole(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleGetAllUserRoles();
        flashMessage("success", resp.message);
      } else {
        setUserAddNewRoleDialog(true);
        flashMessage("error", resp.message);
      }
    });
  };

  const handleDeleteUserRole = () => {
    showLoader();
    let formData = new FormData();
    formData.append("user_role_id", selectedUserRoleId);
    deleteUserRole(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleGetAllUserRoles();
        handleCloseDeleteDialog();
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const [deleteDialog, setDeleteDialog] = useState(false);

  const handleUserRoleDetail = () => {
    console.log("v");
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
  };

  const handleOpenDeleteDialog = (data) => {
    setDeleteDialog(true);
    setSelectedUserRoleId(data.id);
  };

  return (
    <Box mr="5px">
      <DeleteDialog
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteDialog}
        handleRemove={handleDeleteUserRole}
        title={"Remove this User Role?"}
        sub_title={
          "You are about to remove a User Role. Click on the “Remove” button to remove it."
        }
      />

      <Dialog
        fullWidth
        maxWidth="xs"
        open={userAddNewRoleDialog}
        onClose={() => {
          setUserAddNewRoleDialog(false);
        }}
        id="deleteProductDialog"
      >
        <DialogContent className="uinfo_con__user">
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div className="field_text_main_global">Staff Role</div>
                  <CustomTextField
                    key="user_role"
                    name="user_role"
                    id="user_role"
                    value={userRole}
                    onChange={(e) => {
                      setUserRole(e.target.value);
                    }}
                    placeholder="New User Role"
                    className="textField__AddInventory"
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <MobileStepper
                    variant="dots"
                    steps={permissions.length}
                    position="static"
                    activeStep={activeStep}
                    className="mobile_stipper_back"
                    classes={{
                      dotActive: "stp_dtstl__user",
                    }}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === permissions.length - 1}
                        className={`bnbt_stl__user ${activeStep <
                          permissions.length - 1 && "bnbt_stl_c__user"}`}
                      >
                        Next
                        <IoMdArrowDropright className="user_icon_sty" />
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        className={`bnbt_stl__user ${activeStep > 0 &&
                          "bnbt_stl_c__user"}`}
                      >
                        <IoMdArrowDropleft
                          style={{ fontSize: "20px", marginTop: "-2px" }}
                        />
                        Back
                      </Button>
                    }
                  />
                </Grid>
              </Grid>

              <Grid container mt="-5px" mb="10px">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box textAlign="center">
                    <span className="stp_txt__user">
                      {permissions.length > 0
                        ? permissions[activeStep].label
                        : "None"}
                    </span>
                  </Box>
                </Grid>
              </Grid>

              <Grid container pt="10px">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} px="10px">
                  <Box>
                    {permissions.length > 0 &&
                      permissions[activeStep].permissions.map(
                        (value, index) => (
                          <>
                            {value.value ? (
                              <Chip
                                style={{ margin: "3px" }}
                                label={value.label}
                                color="success"
                                clickable
                                onClick={() => {
                                  handleChangePermission(index, false);
                                }}
                              />
                            ) : (
                              <Chip
                                style={{ margin: "3px" }}
                                label={value.label}
                                clickable
                                onClick={() => {
                                  handleChangePermission(index, true);
                                }}
                              />
                            )}
                          </>
                        )
                      )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="deleteDialogActions">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              className="deleteDialogBtn__inv deleteDialogBtnNo__inv"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={() => {
                setUserAddNewRoleDialog(false);
              }}
            >
              Cancel
            </Grid>
            <Grid
              className="deleteDialogBtn__inv adurl_btn__user"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={() => {
                userRoleDialogMethod === "Add"
                  ? handleSavePermissions()
                  : handleUpdatePermissions();
              }}
            >
              {userRoleDialogMethod === "Add" ? "Save" : "Update"}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <NavigationBar
        path={"Dashboard>Staff Management>Staff>Staff Roles"}
        paddingBottom={1}
      />
      <Grid display="flex" justifyContent="center" container mb={2} mt={2}>
        <Grid
          className="header__ai"
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          item
        >
          <Box
            display="flex"
            className="header_back_btn__ai"
            onClick={() => {
              navigate("/staff");
            }}
          >
            <Box>
              <IoMdArrowDropleft className="user_icon_sty" />
            </Box>
            <Box mt="-2px">Back</Box>
          </Box>
          <div className="header_text__ai">Staff Roles Management</div>
        </Grid>
      </Grid>

      <Grid container marginBottom={2}>
        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          item
          display="flex"
          justifyContent={smDown ? "center" : "end"}
          paddingTop={smDown ? 1 : 0}
          paddingRight={smDown ? "0px" : "10px"}
        >
          {/* <Button
            variant="contained"
            className="add_order_btn__orders"
            style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
            onClick={handleClickAddNewRoleButton}
          >
            Add New Staff Role
          </Button> */}

          <AddNewButton
            style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
            id={"user_role_btn__user-roles"}
            name={"Add New Staff Role"}
            onClick={handleClickAddNewRoleButton}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CustomTable
            delete={handleOpenDeleteDialog}
            view={handleUserRoleDetail}
            edit={handleEditUserRole}
            columns={columns}
            data={userRoles}
            options={options}
            image={NoBank}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserRoles;
