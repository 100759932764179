import React from "react";
import { Box, Grid } from "@mui/material";
import Morefeatures from "../../../assets/Images/Morefeatures.png";
import sideImage from "../../../assets/Images/sideImage.png";

function Features() {
  return (
    <>
      <Grid container>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
          <Box display="flex" justifyContent="start" alignItems="center" pt={4}>
            <img src={sideImage} width="70%" />
          </Box>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={6}
          style={{ height: "100%", width: "100%" }}
        >
          <Box display="flex" justifyContent="end" pt={4}>
            <img src={Morefeatures} width="68%" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Features;
