import React from "react";
import { Grid , Box } from "@mui/material";
import { ReactComponent as SecndCheque } from "../assets/SVG/SecondaryChequeIcon.svg";

function DashboardTotalCheque({recipient_name,RemainingDays}) {
  return (
    <>
      <Box className="scdbone_box__dsh" marginBottom="6px">
        <Grid container>
          <Grid item padding="15px" paddingRight="10px">
            <SecndCheque />
          </Grid>
          <Grid item padding="5px" display="flex" alignItems="center">
            <Box
              marginTop="-10px"
              fontSize="20px"
              color="rgba(0,0,0,0.8)"
              className="lngtxt__dsh"
            >
              <span
                style={{
                  fontSize: "14px",
                  color: "#1E2738",
                }}
              >
               {recipient_name}
              </span>
            </Box>
            <span
              style={{
                marginTop: "-1%",
                marginLeft: "5px",
                fontSize: "25px",
                color: "#1E2738",
              }}
            >
              {" "}
              &nbsp;{RemainingDays}
            </span>
            <span
              style={{
                marginTop: "5px",
                marginLeft: "10px",
                fontSize: "8px",
                color: "black",
              }}
            >
              Days Left
            </span>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DashboardTotalCheque;
