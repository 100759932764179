import React from "react";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { Box, Typography, Grid } from "@mui/material";
import { fontSize, styled, typography } from "@mui/system";

function EmptyNotes() {
  return (
    <>
      <Grid container pt={13}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <LightbulbOutlinedIcon className="notes_icon__styl" />
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          justifyContent="center"
        >
          <Typography style={{ fontSize: "30px", color: "#cfcbcb" }}>
            Notes you add appear here
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default EmptyNotes;
