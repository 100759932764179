import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TableCell,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Menu,
} from "@mui/material";
import { IoMdArrowDropleft } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import { styled } from "@mui/material/styles";
import { Textfit } from "react-textfit";
import {
  getMemberDetailsById,
  addTransaction,
  deleteTransaction,
} from "../../api/StaffAPI";
import CustomTable from "../../widgets/CustomTable";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import { ReactComponent as DialogCrossIcon } from "../../assets/SVG/DialogBoxDeleteIcon.svg";
import { ReactComponent as BalanceIcon } from "../../assets/SVG/BalanceIcon.svg";
// import { ReactComponent as DebitIcon } from "../../assets/SVG/DebitIcon.svg";
import { ReactComponent as InvestmentIcon } from "../../assets/SVG/InvestmentIcon.svg";
// import { ReactComponent as CreditIcon } from "../../assets/SVG/CreditIcon.svg";

import { ReactComponent as DebitIcon } from "../../assets/SVG/DebitSmallIcon.svg";
import { ReactComponent as CreditIcon } from "../../assets/SVG/CreditSmallIcon.svg";

import DeleteDialog from "../../widgets/DeleteDialog";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import SearchField from "../../widgets/SearchField";
import { debounce } from "lodash";
import "./MemberKhata.css";
import SimpleMenu from "../../widgets/SimpleMenu";
import TopMidHeader from "../../widgets/TopMidHeader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SelectBank from "../../widgets/SelectBank";
import { getTodayDate, pad } from "../../utils/common";
const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    minWidth: 30,
    align: "left",
  },
  {
    id: "staff_member_name",
    label: "Name",
    minWidth: 30,
    align: "left",
  },

  {
    id: "transaction_amount",
    label: "Amount",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_date",
    label: "Date",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_description",
    label: "Description",
    minWidth: 30,
    align: "left",
  },
  {
    id: "cash_type",
    label: "Cash Type",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_type",
    label: "Status",
    minWidth: 30,
    align: "left",
  },
];

const options = [
  {
    icon: (
      <div>
        <DeleteIcon />
      </div>
    ),
    label: "Delete",
    method: "delete",
    type: "icon",
  },
];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
      height: "60px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
      height: "60px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
      height: "60px",
    },
  },
});
const CustomTextFieldM = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
      height: "103px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
      height: "103px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
      height: "103px",
    },
  },
});

const transactionObject = {
  staff_member_name: "",
  transaction_amount: "",
  transaction_description: "",
  transaction_type: "",
  staff_id: "",
};
function MemberKhata() {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    location,
    onlyXs,
    userObject,
  } = useContext(GlobalData);

  const [allBanksData, setAllBanksData] = useState([])

  const [selectedCashType, setSelectedCashType] = useState("Shop Cash");
  const [selectedTransaction_type, setSelectedTransaction_type] = useState(
    "Credit"
  );
  const [transactionDate, setTransactionDate] = useState("");
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [member, setMember] = useState("");
  const [transaction_id, setTransaction_id] = useState("");
  const [transactions, setTransactions] = useState([{}]);
  const [showForm, setShowForm] = useState(false);
  const [transactionData, setTransactionData] = useState(transactionObject);
  const [total_credit, setTotal_credit] = useState(0);
  const [total_debit, setTotal_debit] = useState(0);
  const [memberDescription, setMemberDescription] = useState("");
  const [partnersCount, setPartnersCount] = useState(0);
  const [total_balance, setTotal_balance] = useState(0);
  const [total_salary, setTotal_salary] = useState(0);
  const member_id = location.state.memberData;
  const [searchText, setSearchText] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankId, setBankId] = useState("");
  const handlegetAllTransactions = (pn) => {
    const data = {
      search_text: searchText,
      member_id: member_id,
      per_page: 10,
      page: pn,
    };
    showLoader();
    getMemberDetailsById(data, (resp) => {
      if (resp.statusCode === 200) {
        //  console.log("THIS I SI RESPONSE",resp.successData.staff)
        setMember(resp.successData.staff);
        console.log("MEMBER", resp.successData.staff);
        setTotal_credit(resp.successData.staff_debit_credit.credit);
        setTotal_debit(resp.successData.staff_debit_credit.debit);
        setTotal_balance(resp.successData.staff_debit_credit.balance);
        setTotal_salary(resp.successData.staff.member_salary);
        setTransactionData({
          ...transactionData,
          staff_member_name: resp.successData.staff.member_name,
          staff_id: resp.successData.staff.id,
          transaction_amount: "",
          transaction_description: "",
        });
        if (pn === 1) {
          setTransactions(resp.successData.staff.transactions);
          hideLoader();
        } else {
          setTransactions((dt) => {
            dt.push(...resp.successData.staff.transactions);
            hideLoader();
            return dt;
          });
        }
        setLastPage(resp.successData.last_page);
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const openDeleteProductDialog = (product) => {
    setTransaction_id(product.id);

    setDeleteProductDialog(true);
  };
  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateTransactionList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);
  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const handleDeleteProduct = () => {
    showLoader();
    const tempdata = { transaction_id: transaction_id };

    deleteTransaction(tempdata, (resp) => {
      if (resp.statusCode === 200) {
        flashMessage("success", resp.message);
        updateDataUsingPageNumber();
        setDeleteProductDialog(false);
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        setDeleteProductDialog(false);
        hideLoader();
      }
    });
  };



  const openAddTransactionDialog = (value) => {
    console.log("value", value);
    setTransactionData({
      ...transactionData,
      transaction_type: value,
    });
    setTransactionDate(getTodayDate());
    setShowForm(true);
  };

  const closeTransactionDialog = () => {
    setShowForm(false);
    setTransactionDate(null);
  };
  const handleAddTransaction = () => {
    if (
      transactionData.transaction_amount === "" ||
      isNaN(transactionData.transaction_amount)
    ) {
      flashMessage("error", "Enter Amount in Numbers");
    } else {
      if (selectedCashType === "Bank Cash") {
        if (bankName == "") {
          flashMessage("error", "Please select bank");
          return 0;
        } else if (allBanksData.length === 0) {
          flashMessage("error", "Please add bank first!");
          return 0;
        }
      }
      showLoader();
      let formData = new FormData();
      formData.append("staff_id", member_id);
      formData.append("transaction_amount", transactionData.transaction_amount);
      formData.append("transaction_description", memberDescription);
      formData.append("transaction_type", selectedTransaction_type);
      formData.append("cash_type", selectedCashType);

      formData.append("bank_id", bankId);
      formData.append("bank_name", bankName);

      var date = new Date(transactionDate);
      formData.append(
        "transaction_date",
        date.getFullYear() +
          "-" +
          pad(date.getMonth() + 1) +
          "-" +
          pad(date.getDate())
      );

      addTransaction(formData, (resp) => {
        if (resp.statusCode === 200) {
          hideLoader();
          updateDataUsingPageNumber();
          setTransactionData({
            staff_member_name: "",
            transaction_amount: "",
            transaction_description: "",
            transaction_type: "",
            staff_id: "",
          });
          setTransactionDate(null);
          setMemberDescription("");
          setShowForm(false);
        } else {
          flashMessage("error", resp.message);
          hideLoader();

          setTransactionDate(null);
        }
      });
    }
  };
  const handleTransactionInput = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setTransactionData({
      ...transactionData,
      [name]: value,
    });
  };
  const changeOrderDescription = (e) => {
    setMemberDescription(e.target.value);
  };

  const handleVendorKhataView = (transaction) => {
    navigate("/staff/staff-member-khata", {
      state: { orderData: transaction.order, orderStatus: "All" },
    });
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    updateDataUsingPageNumber();
  }, [searchText]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      handlegetAllTransactions(page);
    }
  };

  return (
    <>
      <DeleteDialog
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteProductDialog}
        handleRemove={handleDeleteProduct}
        title={"Remove this Transaction?"}
        sub_title={
          "You are about to remove a transaction. Click on the “Remove” button to remove it."
        }
      />
      {/*========================================================Add Transaction Dialog */}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showForm}
        onClose={closeTransactionDialog}
      >
        <DialogTitle>Add New Transaction</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs className="padn_invoice__icon">
              <CustomTextField
                value={transactionData.transaction_amount}
                onChange={handleTransactionInput}
                name="transaction_amount"
                id="transaction_amount"
                key="transaction_amount"
                placeholder="Transaction Amount"
                className={"textField__AddInventory"}
              />
            </Grid>

            <Grid item xs>
              <SimpleMenu
                handleSetData={setSelectedCashType}
                data={["Bank Cash", "Shop Cash"]}
                selectedValue={selectedCashType}
                className={"bnkmnu_field__bank"}
                width={"268px"}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} paddingRight="3px">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="bnkmnu_field__bank"
                  value={transactionDate}
                  onChange={(newValue) => {
                    setTransactionDate(newValue);
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      error={false}
                      {...params}
                      placeholder={"mm/dd/yyyy"}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} paddingLeft="3px">
              <SimpleMenu
                handleSetData={setSelectedTransaction_type}
                data={["Credit", "Debit", "Salary"]}
                selectedValue={selectedTransaction_type}
                className={"bnkmnu_field__bank"}
                width={"268px"}
              />
            </Grid>
          </Grid>

          {selectedCashType === "Bank Cash" ? (
            <Box mb={2}>
              <SelectBank
                setBankId={setBankId}
                setBankName={setBankName}
                bankName={bankName}
                setAllBanksData={setAllBanksData}
              />
            </Box>
          ) : null}

          <Box>
            <CustomTextFieldM
              multiline
              rows={3}
              onChange={changeOrderDescription}
              value={memberDescription}
              name="transaction_description"
              id="transaction_description"
              placeholder="Transaction Decription"
              className={"textField__AddInventory"}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="download_report_btn__orders"
            onClick={closeTransactionDialog}
          >
            Cancel
          </Button>
          <Button
            className="add_order_btn__orders"
            onClick={handleAddTransaction}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container height="100vh" className="padn_invoice__icon">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box height={smDown ? "30vh" : "20vh"}>
            <NavigationBar
              path={
                "Dashboard>Staff Management>Staff>" +
                transactionData.staff_member_name +
                ">Member Khata"
              }
              paddingBottom={1}
            />
            <TopMidHeader
              path={"staff"}
              back={"Back"}
              title1={"Staff Management"}
              name={transactionData.staff_member_name}
              title2={"Khata"}
            />
          </Box>
          <Grid
            container
            dispaly="flex"
            justifyContent="center"
            paddingTop={smDown ? "4px" : "5px"}
          >
            <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={12}
              xs={12}
              paddingTop={smDown ? "15px" : "15px"}
            >
              <Grid container p={1} className="Box_background__vendor_stf">
                <Grid
                  item
                  className="box_img__vendor_stf"
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                >
                  <div className="balanc_icon_marg">
                    <BalanceIcon height="35" width="35" />
                  </div>
                </Grid>

                <Grid item paddingLeft={2} xl={9} lg={9} md={9} sm={9} xs={9}>
                  <Box>
                    <div className="Balance_vendors__khata_stf">
                      Total Balance
                    </div>
                    <div className="AED_vendors__khata_stf">
                      <Textfit mode="single" forceSingleModeWidth={false}>
                        {total_balance} {userObject.currency}
                      </Textfit>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={12}
              xs={12}
              paddingLeft={smDown ? "0" : "10px"}
              paddingTop={smDown ? "4px" : "15px"}
            >
              <Grid
                container
                className="Box_background__vendor_stf Box_background__vendor_cred_stf"
              >
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="2px"
                  className="box_img__vendor_stf"
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                >
                  <div className="balanc_icon_marg">
                    <CreditIcon width="35" height="35" />
                  </div>
                </Grid>

                <Grid item paddingLeft={2} xl={7} lg={7} md={7} sm={7} xs={7}>
                  <Box>
                    <div className="Balance_vendors__khata_stf">
                      Total Credit
                    </div>

                    <div className="AED_vendors__khata_stf">
                      <Textfit mode="single" forceSingleModeWidth={false}>
                        {total_credit} {userObject.currency}
                      </Textfit>
                    </div>
                  </Box>
                </Grid>
                <Grid
                  item
                  pt={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="container_add_btn_stf balanc_icon_marg"
                />
              </Grid>
            </Grid>

            <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={12}
              xs={12}
              paddingLeft={smDown ? "0" : "10px"}
              paddingTop={smDown ? "4px" : "15px"}
            >
              <Grid container className="Box_background__vendor_stf">
                <Grid
                  item
                  className="box_img__vendor_stf"
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                >
                  <div className="balanc_icon_marg">
                    <DebitIcon width="35" height="35" />
                  </div>
                </Grid>

                <Grid item paddingLeft={1} xl={7} lg={7} md={7} sm={7} xs={7}>
                  <Box>
                    <div className="Balance_vendors__khata_stf">
                      Total Debit
                    </div>

                    <div className="AED_vendors__khata_stf">
                      <Textfit mode="single" forceSingleModeWidth={false}>
                        {total_debit} {userObject.currency}
                      </Textfit>
                    </div>
                  </Box>
                </Grid>

                <Grid
                  item
                  pt={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="container_add_btn balanc_icon_marg"
                />
              </Grid>
            </Grid>

            {/* <Grid
              item
              xl={3}
              lg={3}
              md={4}
              sm={12}
              xs={12}
              paddingLeft={smDown ? "0" : "10px"}
              paddingTop={smDown ? "4px" : "15px"}
            >
              <Grid
                container
                p={1}
                backgroundColor="#E2FFE9"
                className="Box_background__vendor_stf"
              >
                <Grid
                  item
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop="2px"
                  className="box_img__vendor_stf"
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                >
                  <div style={{ marginTop: "3px" }}>
                    <InvestmentIcon height="35" width="35" color="green" />
                  </div>
                </Grid>

                <Grid item paddingLeft={1} xl={7} lg={7} md={7} sm={7} xs={7}>
                  <Box>
                    <div className="Balance_vendors__khata_stf">
                      Total Salary
                    </div>
                    <div className="AED_vendors__khata_stf">
                      <Textfit mode="single" forceSingleModeWidth={false}>
                        {total_salary} {userObject.currency}
                      </Textfit>
                    </div>
                  </Box>
                </Grid>

                <Grid
                  item
                  pt={2}
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="container_add_btn_stf"
                  style={{ marginTop: "5px" }}
                />
              </Grid>
            </Grid> */}
          </Grid>
          <Grid container display="flex">
            <Grid
              xl={6}
              lg={6}
              md={smDown ? 4 : 3}
              sm={smDown ? 4 : 3}
              xs={12}
              item
              marginTop="20px"
            >
              <SearchField
                onChange={updateTransactionList}
                tableDataCount={transactions.length}
                totalAvailableDataCount={partnersCount}
              />
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={7}
              sm={12}
              xs={12}
              item
              display="flex"
              justifyContent={smDown ? "center" : "end"}
              marginTop="20px"
              paddingRight={smDown ? "0px" : "10px"}
            >
              <Button
                variant="contained"
                className="add_order_btn__orders"
                style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                onClick={() => {
                  openAddTransactionDialog("Credit");
                }}
              >
                Add Transaction
              </Button>
            </Grid>
          </Grid>

          <Grid container>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <CustomTable
                view={handleVendorKhataView}
                // edit={handleEditProduct}
                delete={openDeleteProductDialog}
                options={options}
                columns={columns}
                data={transactions}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default MemberKhata;
