import { getWithParams, post, delte } from "./API";

export function getAllPartners(data, callback) {
  getWithParams({ endPoint: "partner/getAllPartners" }, data, function(
    response
  ) {
    callback(response);
  });
}
export function registerPartner(data, callback) {
  post({ endPoint: "partner/registerPartner" }, data, function(response) {
    callback(response);
  });
}

export function deletePartner(data, callback) {
  delte({ endPoint: "partner/deletePartner" }, data, function(response) {
    callback(response);
  });
}

export function updatePartner(data, callback) {
  post({ endPoint: "partner/updatePartner" }, data, function(response) {
    callback(response);
  });
}

export function getPartnerDetailsById(data, callback) {
  getWithParams({ endPoint: "partner/getPartnerDetailsById" }, data, function(
    response
  ) {
    callback(response);
  });
}
  export function addPartnerTransaction(data, callback) {
    post({ endPoint: "partner/transaction/createTransaction" }, data, function(
      response
    ) {
      callback(response);
    });
  }

  export function deletePartnerTransaction(data, callback) {
    delte({ endPoint: "partner/transaction/deleteTransaction" }, data, function(
      response
    ) {
      callback(response);
    });
  }
