import {
  Grid,
  DialogActions,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  TextField,
  MenuItem,
  Button,
  Box,
  Menu,
  InputLabel,
  FormControl,
  ButtonBase,
  Zoom,
} from "@mui/material";
import "./Notes.css";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { GlobalData } from "../../App";
import {
  getAllNotes,
  createNotes,
  deleteNotesById,
  updateNotesById,
} from "../../api/NotesAPI";
import SearchField from "../../widgets/SearchField";
import { AiFillCaretDown } from "react-icons/ai";
import { styled } from "@mui/material/styles";
import NavigationBar from "../NavigationBar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Fade from "@mui/material/Fade";
import DeleteDialog from "../../widgets/DeleteDialog";
import { debounce } from "lodash";
import DateFilter from "../../widgets/DateFilter";
import EmptyNotes from "./EmptyNotes";
import { Textfit } from "react-textfit";
import MainTitle from "../../widgets/MainTitle";
import AddNewButton from "../../widgets/AddNewButton";
import { pad } from "../../utils/common";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const CustomSelectField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: `1px solid rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      border: `1px solid rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      border: `1px solid rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const noteObject = {
  title: "",
  description: "",
  priority: "",
};
function Notes() {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
  } = useContext(GlobalData);
  const [openCardsDialog, setOpenCardsDialog] = useState(false);
  const [notesDate, setNotesDate] = useState("");
  const [openCashDialog, setOpenCashDialog] = useState(false);
  const [note, setNote] = useState(noteObject);
  const [notes, setNotes] = useState([]);
  const [user, setUser] = useState("");
  const [searchText, setSearchText] = useState("");
  const [noteMethod, setNoteMethod] = useState("");
  const [selectedNoteId, setSelectedNoteId] = useState();
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
  }, []);

  useEffect(() => {
    if (user) {
      getAllNotesMethod(user.id);
    }
  }, [searchText, notesDate]);

  const getAllNotesMethod = (userId) => {
    let Data = {
      user_id: userId,
      search_text: searchText,
      date: JSON.stringify(notesDate),
    };
    showLoader();
    getAllNotes(Data, (resp) => {
      if (resp.statusCode === 200) {
        console.log(resp.successData);
        setNotes(resp.successData.notes);
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const createNotesMethod = () => {
    showLoader();
    if (noteMethod === "Add") {
      let formData = new FormData();
      formData.append("title", note.title);
      formData.append("description", note.description);
      formData.append("priority", note.priority);
      formData.append("user_id", user.id);

      var date = new Date();
      formData.append(
        "date",
        date.getFullYear() +
          "-" +
          pad(date.getMonth() + 1) +
          "-" +
          pad(date.getDate())
      );

      createNotes(formData, (resp) => {
        if (resp.statusCode === 200) {
          handleCashDialogClose();
          getAllNotesMethod(user.id);
        } else {
          hideLoader();
          flashMessage("error", resp.message);
        }
      });
    } else {
      let formData = new FormData();
      formData.append("notes_id", selectedNoteId);
      formData.append("title", note.title);
      formData.append("priority", note.priority);
      formData.append("description", note.description);
      formData.append("user_id", user.id);
      updateNotesById(formData, (resp) => {
        if (resp.statusCode === 200) {
          handleCashDialogClose();
          getAllNotesMethod(user.id);
        } else {
          hideLoader();
          flashMessage("error", resp.message);
        }
      });
    }
  };

  const openDeleteTransactionDialog = (id) => {
    setSelectedNoteId(id);
    setDeleteProductDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const deleteNote = (id) => {
    handleCloseDeleteDialog();
    showLoader();
    let noteData = {
      notes_id: selectedNoteId,
    };
    deleteNotesById(noteData, (resp) => {
      if (resp.statusCode === 200) {
        getAllNotesMethod(user.id);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const editNoteMethod = (index) => {
    setNote({
      ...note,
      ["title"]: notes[index].title,
      ["description"]: notes[index].description,
      ["priority"]: notes[index].priority,
    });
    handleCashDialogOpen("Edit");
    setSelectedNoteId(notes[index].id);
  };

  const handleCashDialogOpen = (method) => {
    setNoteMethod(method);
    setOpenCashDialog(true);
  };

  const handleCashDialogClose = () => {
    setNote({ title: "", description: "", priority: "" });
    setOpenCashDialog(false);
  };

  const handleCardsDialogOpen = () => {
    setOpenCardsDialog(true);
  };

  const handleCardsDialogClose = () => {
    setOpenCardsDialog(false);
  };

  const noteData = (e) => {
    const { name, value } = e.target;
    setNote({
      ...note,
      [name]: value,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateNotesList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  return (
    <>
      <DeleteDialog
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteProductDialog}
        handleRemove={deleteNote}
        DontRemoveId={"dont_remove__notes"}
        RemoveId={"remove__notes"}
        title={"Remove this Note?"}
        sub_title={
          "You are about to remove a note. Click on the “Remove” button to remove it."
        }
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openCashDialog}
        onClose={handleCashDialogClose}
      >
        <DialogTitle>{noteMethod} Notes</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs pr={1}>
              <FormControl className="text__filed__notes">
                {note.priority === "" && (
                  <InputLabel sx={{ opacity: 0.6 }}>
                    Select Note Priority
                  </InputLabel>
                )}
                <CustomSelectField
                  id="notes_priority"
                  select
                  value={note.priority}
                  onChange={noteData}
                  name={"priority"}
                >
                  {["High", "Medium", "Low"].map((option, oi) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </CustomSelectField>
              </FormControl>
            </Grid>
            <Grid item xs pl={1}>
              <CustomTextField
                id="notes_title"
                onChange={noteData}
                className="text__filed__notes"
                placeholder={"Title"}
                name={"title"}
                value={note.title}
                autoFocus={true}
                inputProps={{
                  maxLength: 24,
                }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box pt={2}>
                <CustomTextField
                  id="description__notes"
                  onChange={noteData}
                  multiline={true}
                  rows={3}
                  name={"description"}
                  placeholder={"Description"}
                  className="text__filed__notes"
                  value={note.description}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className="download_report_btn__orders"
            onClick={handleCashDialogClose}
            id="cancel_btn__notes"
          >
            Cancel
          </Button>
          <Button
            className="add_order_btn__orders"
            id="save_btn__notes"
            onClick={createNotesMethod}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openCardsDialog}
        onClose={handleCardsDialogClose}
      >
        <h1>hello</h1>
      </Dialog>

      <Grid id="header__orderm" container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <NavigationBar
            path={"Dashboard>Notes Management>Notes"}
            paddingBottom={3}
          />

          <Grid container marginBottom={2}>
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingRight={onlyXs ? "5px" : "10px"}
            >
              <MainTitle title={"Notes"} />
            </Grid>
          </Grid>

          <Grid container marginBottom={2}>
            <Grid
              xl={6}
              lg={6}
              md={smDown ? 7 : 5}
              sm={smDown ? 7 : 5}
              xs={12}
              item
              className="padn_invoice__icon"
            >
              <SearchField
                id="search_field_notes"
                onChange={updateNotesList}
                tableDataCount={notes.length}
                totalAvailableDataCount={notes.length}
              />
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={smDown ? 5 : 7}
              sm={smDown ? 5 : 7}
              xs={12}
              item
              display="flex"
              justifyContent={onlyXs ? "center" : "end"}
              paddingTop={onlyXs ? 1 : 0}
              paddingRight={smDown ? "0px" : "10px"}
            >
              {/* <Button
                variant="contained"
                className="add_order_btn__orders"
                style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                onClick={() => {
                  handleCashDialogOpen("Add");
                }}
              >
                Add New Notes
              </Button> */}
              <AddNewButton
                id={"add_new_notes__notes"}
                name={"Add New Notes"}
                onClick={() => {
                  handleCashDialogOpen("Add");
                }}
              />

              <DateFilter
                id={"date_filter_notes"}
                setDateObject={setNotesDate}
                dateObject={notesDate}
                className={"download_report_btn__orders"}
              />
            </Grid>
          </Grid>
          {notes.length > 0 ? (
            <Grid container display="flex" justifyContent="center" my={6}>
              {notes.map((object, index) => (
                <Box mt="10px" mr="10px">
                  <ButtonBase>
                    <NotesCard
                      object={object}
                      index={index}
                      editNoteMethod={editNoteMethod}
                      openDeleteTransactionDialog={openDeleteTransactionDialog}
                      openDialog={true}
                    />
                  </ButtonBase>
                </Box>
              ))}
            </Grid>
          ) : (
            <EmptyNotes />
          )}
        </Grid>
      </Grid>
    </>
  );
}

const NotesCard = ({
  object,
  editNoteMethod,
  index,
  openDeleteTransactionDialog,
  openDialog,
}) => {
  const [openCardsDialog, setOpenCardsDialog] = useState(false);

  const handleCardsDialogOpen = () => {
    setOpenCardsDialog(true);
  };

  const handleCardsDialogClose = () => {
    setOpenCardsDialog(false);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openCardsDialog}
        onClose={handleCardsDialogClose}
        className="notesCardDialog"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Zoom
          in={openCardsDialog}
          style={{ transitionDelay: openCardsDialog ? "300ms" : "0ms" }}
        >
          <DialogContent className="notesCardDialogScroll">
            <NotesCard
              object={object}
              index={index}
              editNoteMethod={editNoteMethod}
              openDeleteTransactionDialog={openDeleteTransactionDialog}
              openDialog={false}
            />
          </DialogContent>
        </Zoom>
      </Dialog>

      <Box
        p={2}
        className={`${openDialog ? "boxes__notes" : "boxes_od_notes"}`}
        style={{
          backgroundColor: `${
            object.priority === "High"
              ? "#FFE8DE"
              : object.priority === "Medium"
              ? "#E4F3F1"
              : object.priority === "Low"
              ? "#F4F1DC"
              : "transparent"
          }`,
          color: `${
            object.priority === "High"
              ? "black"
              : object.priority === "Medium"
              ? "black"
              : object.priority === "Low"
              ? "black"
              : "black"
          }`,
        }}
      >
        <Grid container>
          <Grid
            onClick={openDialog ? handleCardsDialogOpen : ""}
            item
            xl={8}
            lg={8}
            md={8}
            sm={8}
            xs={8}
            className="notes__text_i"
          >
            {object.title}
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={4}
            xs={4}
            justifyContent="end"
            display="flex"
          >
            <IconButton
              onClick={() => {
                editNoteMethod(index);
              }}
              className="notes__icon_sz"
              style={{
                color: `${
                  object.priority === "High"
                    ? "black"
                    : object.priority === "Medium"
                    ? "black"
                    : object.priority === "Low"
                    ? "black"
                    : "black"
                }`,
              }}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              onClick={() => {
                openDeleteTransactionDialog(object.id);
              }}
              className="notes__icon_sz"
              style={{
                color: `${
                  object.priority === "High"
                    ? "black"
                    : object.priority === "Medium"
                    ? "black"
                    : object.priority === "Low"
                    ? "black"
                    : "black"
                }`,
              }}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>

        <Box
          onClick={openDialog ? handleCardsDialogOpen : ""}
          className={`${openDialog ? "tex_notes__sx" : "tex_notes_od__sx"}`}
        >
          {object.description}
        </Box>
      </Box>
    </>
  );
};

export default Notes;
