import "./Brands.css";
import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Box,
  DialogActions,
  Menu,
  MenuItem,
  Fade,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  MobileStepper,
  Chip,
} from "@mui/material";
import { GlobalData } from "../../App";
import { debounce } from "lodash";
import NavigationBar from "../NavigationBar";
import SearchField from "../../widgets/SearchField";
import CustomTable from "../../widgets/CustomTable";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import MainTitle from "../../widgets/MainTitle";
import TriStateButton from "../../widgets/TriStateButton.jsx";
import { getAllShops, createShop, updateShop } from "../../api/ShopAPI";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SimpleMenu from "../../widgets/SimpleMenu";
import { ReactComponent as UploadImageIcon } from "../../assets/SVG/UploadImageIcon.svg";
import DateFilter from "../../widgets/DateFilter";

import { encrypt, decrypt } from "../../middleware/Encryption";
import {
  addUserRole,
  updateUserRole,
  getAllUserRoles,
} from "../../api/UserRolesAPI";

import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import { EditSVG, ViewSVG, DeleteSVG } from "../../assets/SVG/GeneralSVG";
import "../Users/UserRoles.css";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/SVG/EditIcon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/SVG/DownloadIcon.svg";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import { getTodayDate,pad } from "../../utils/common";
const options = [
  // {
  //   icon: <EyeIcon />,
  //   label: "View",
  //   method: "view",
  //   type: "icon",
  // },
  {
    icon: <EditIcon />,
    label: "Edit",
    method: "edit",
    type: "icon",
  },
  {
    icon: (
      <DeveloperBoardIcon style={{ opacity: "0.7", marginLeft: "-2.5px" }} />
    ),
    label: "Update Features",
    method: "updateFeatures",
    type: "icon",
  },
  // {
  //   icon: <DeleteIcon />,
  //   label: "Delete",
  //   method: "delete",
  //   type: "icon",
  // },
];

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    align: "left",
  },
  {
    id: "brand_name",
    label: "Brand Name",
    align: "left",
  },
  {
    id: "mobile",
    label: "Contact Info",
    align: "left",
  },
  {
    id: "email",
    label: "Email",
    align: "left",
  },
  {
    id: "user_password",
    label: "Password",
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    align: "left",
  },
  {
    id: "date",
    label: "Date",
    align: "left",
  },
];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: "10px",
  },
});

const CustomTextFieldForDate = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    borderRadius: "10px",
  },
});

const shopObject = {
  name: "",
  brand_name: "",
  mobile: "",
  address: "",
  date: "",
  password: "",
  status: "",
  personal_email: "",
};

const PermissionObject = [
  {
    module: "/",
    label: "Dashboard",
    permissions: [
      {
        id: "Dashboard",
        value: false,
        label: "Dashboard Module",
      },
    ],
  },
  {
    module: "products",
    label: "Inventory",
    permissions: [
      {
        id: "Inventory",
        value: false,
        label: "Inventory Module",
      },
      {
        id: "download-all-btn",
        value: false,
        label: "Download All Inventory PDF Button",
      },
      {
        id: "action_view",
        value: false,
        label: "Product View Option",
      },
      {
        id: "action_status",
        value: false,
        label: "Product Change Status Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Product Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Product Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Product Delete Option",
      },
      {
        id: "product_cost_price",
        value: false,
        label: "Show Product Cost Price",
      },
      {
        id: "product_source",
        value: false,
        label: "Show Product Source",
      },
    ],
  },
  {
    module: "orders",
    label: "Orders",
    permissions: [
      {
        id: "Orders",
        value: false,
        label: "Orders Module",
      },
      {
        id: "download-all-btn",
        value: false,
        label: "Download All Orders PDF Button",
      },
      {
        id: "action_view",
        value: false,
        label: "Order View Option",
      },
      {
        id: "action_status",
        value: false,
        label: "Order Change Status Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Order Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Order Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Order Delete Option",
      },
      {
        id: "product_cost_price",
        value: false,
        label: "Show Product Cost Price",
      },
      {
        id: "product_source",
        value: false,
        label: "Show Product Source",
      },
    ],
  },
  {
    module: "accounts",
    label: "Accounts",
    permissions: [
      {
        id: "Accounts",
        value: false,
        label: "Accounts Module",
      },
      {
        id: "total_orders_sale",
        value: false,
        label: "Total Orders Sale Card",
      },
      {
        id: "total_orders_profit",
        value: false,
        label: "Total Orders Profit Card",
      },
      {
        id: "total_orders_return",
        value: false,
        label: "Total Orders Return Card",
      },
      {
        id: "total_cash_out_amount",
        value: false,
        label: "Total Cash Out Amount Card",
      },
      {
        id: "total_cash_in_amount",
        value: false,
        label: "Total Cash In Amount Card",
      },
      {
        id: "total_balance",
        value: false,
        label: "Total Balance Card",
      },
      {
        id: "total_bank_balance",
        value: false,
        label: "Total Account Cash Card",
      },
      {
        id: "action_view",
        value: false,
        label: "View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Delete Option",
      },
    ],
  },
  {
    module: "expense",
    label: "Expense",
    permissions: [
      {
        id: "Expense",
        value: false,
        label: "Expense Module",
      },
      {
        id: "action_view",
        value: false,
        label: "View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Delete Option",
      },
    ],
  },
  {
    module: "staff",
    label: "Staff",
    permissions: [
      {
        id: "Staff",
        value: false,
        label: "Staff Module",
      },
      {
        id: "action_view",
        value: false,
        label: "View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Delete Option",
      },
      {
        id: "action_viewOrders",
        value: false,
        label: "View Orders Option",
      },
    ],
  },
  {
    module: "partners",
    label: "Partners",
    permissions: [
      {
        id: "Partners",
        value: false,
        label: "Partners Module",
      },
      {
        id: "action_view",
        value: false,
        label: "View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Delete Option",
      },
    ],
  },
  {
    module: "vendors",
    label: "Vendors",
    permissions: [
      {
        id: "Vendors",
        value: false,
        label: "Vendors Module",
      },
      {
        id: "action_view",
        value: false,
        label: "View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Delete Option",
      },
    ],
  },
  {
    module: "bank",
    label: "Bank",
    permissions: [
      {
        id: "Bank",
        value: false,
        label: "Bank Module",
      },
      {
        id: "action_view",
        value: false,
        label: "View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Delete Option",
      },
    ],
  },
  {
    module: "notes",
    label: "Notes",
    permissions: [
      {
        id: "Notes",
        value: false,
        label: "Notes Module",
      },
    ],
  },
  {
    module: "invoices",
    label: "Invoices and Services",
    permissions: [
      {
        id: "Invoices and Services",
        value: false,
        label: "Invoices and Services Module",
      },
      {
        id: "download-all-btn",
        value: false,
        label: "Download All Orders PDF Button",
      },
      {
        id: "action_view",
        value: false,
        label: "Order View Option",
      },
      {
        id: "action_status",
        value: false,
        label: "Order Change Status Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Order Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Order Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Order Delete Option",
      },
      {
        id: "product_cost_price",
        value: false,
        label: "Show Product Cost Price",
      },
      {
        id: "product_source",
        value: false,
        label: "Show Product Source",
      },
    ],
  },
  {
    module: "services",
    label: "Services",
    permissions: [
      {
        id: "Services",
        value: false,
        label: "Services Module",
      },
      {
        id: "action_view",
        value: false,
        label: "Service View Option",
      },
      // {
      //   id: "action_status",
      //   value: false,
      //   label: "Service Change Status Option",
      // },
      {
        id: "action_edit",
        value: false,
        label: "Service Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Service Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Service Delete Option",
      },
    ],
  },
  {
    module: "whatsapp",
    label: "Whatsapp",
    permissions: [
      {
        id: "Whatsapp",
        value: false,
        label: "Whatsapp Module",
      },
      {
        id: "action_view",
        value: false,
        label: "Whatsapp View Option",
      },
      {
        id: "action_edit",
        value: false,
        label: "Whatsapp Edit Option",
      },
      {
        id: "action_print",
        value: false,
        label: "Whatsapp Print Option",
      },
      {
        id: "action_delete",
        value: false,
        label: "Whatsapp Delete Option",
      },
    ],
  },
  {
    module: "chat",
    label: "Chat",
    permissions: [
      {
        id: "Chat",
        value: false,
        label: "Chat Module",
      },
    ],
  },
  {
    module: "users",
    label: "Users",
    permissions: [
      {
        id: "Users",
        value: false,
        label: "Users Module",
      },
    ],
  },
];

const Brands = () => {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    onlyXs,
    smDown,
    mdDown,
    baseUrl,
  } = useContext(GlobalData);

  const [shops, setShops] = useState([]);
  const [createShopDialog, setCreateShopDialog] = useState(false);
  const [shop, setShop] = useState(shopObject);
  const [edit, setEdit] = useState(false);
  const [imageUri, setImageUri] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [status, setStatus] = useState("All");
  const [lastPage, setLastPage] = useState(1);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState("All");
  const [totalShopsCount, setTotalShopsCount] = useState(0);
  const [roleId, setRoleId] = useState(-1);
  const mounted = useRef(false);

  

  const [shopDate, setShopDate] = useState(getTodayDate());

  const handleGetAllShop = () => {
    showLoader();
    let data = {
      search_text: searchText,
      date: JSON.stringify(date),
      status: status,
      page: page,
      per_page: 7,
    };
    getAllShops(data, (resp) => {
      if (resp.statusCode === 200) {
        setTotalShopsCount(resp.successData.total_shops_count);
        if (page === 1) {
          setShops(resp.successData.shops);
          hideLoader();
        } else {
          setShops((dt) => {
            dt.push(...resp.successData.shops);
            hideLoader();
            return dt;
          });
        }
        setLastPage(resp.successData.last_page);
        //flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  const resetPermissions = () => {
    setPermissions([
      {
        module: "/",
        label: "Dashboard",
        permissions: [
          {
            id: "Dashboard",
            value: false,
            label: "Dashboard Module",
          },
        ],
      },
      {
        module: "products",
        label: "Inventory",
        permissions: [
          {
            id: "Inventory",
            value: false,
            label: "Inventory Module",
          },
          {
            id: "download-all-btn",
            value: false,
            label: "Download All Inventory PDF Button",
          },
          {
            id: "action_view",
            value: false,
            label: "Product View Option",
          },
          {
            id: "action_status",
            value: false,
            label: "Product Change Status Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Product Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Product Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Product Delete Option",
          },
          {
            id: "product_cost_price",
            value: false,
            label: "Show Product Cost Price",
          },
          {
            id: "product_source",
            value: false,
            label: "Show Product Source",
          },
        ],
      },
      {
        module: "orders",
        label: "Orders",
        permissions: [
          {
            id: "Orders",
            value: false,
            label: "Orders Module",
          },
          {
            id: "download-all-btn",
            value: false,
            label: "Download All Orders PDF Button",
          },
          {
            id: "action_view",
            value: false,
            label: "Order View Option",
          },
          {
            id: "action_status",
            value: false,
            label: "Order Change Status Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Order Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Order Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Order Delete Option",
          },
          {
            id: "product_cost_price",
            value: false,
            label: "Show Product Cost Price",
          },
          {
            id: "product_source",
            value: false,
            label: "Show Product Source",
          },
        ],
      },
      {
        module: "accounts",
        label: "Accounts",
        permissions: [
          {
            id: "Accounts",
            value: false,
            label: "Accounts Module",
          },
          {
            id: "total_orders_sale",
            value: false,
            label: "Total Orders Sale Card",
          },
          {
            id: "total_orders_profit",
            value: false,
            label: "Total Orders Profit Card",
          },
          {
            id: "total_orders_return",
            value: false,
            label: "Total Orders Return Card",
          },
          {
            id: "total_cash_out_amount",
            value: false,
            label: "Total Cash Out Amount Card",
          },
          {
            id: "total_cash_in_amount",
            value: false,
            label: "Total Cash In Amount Card",
          },
          {
            id: "total_balance",
            value: false,
            label: "Total Balance Card",
          },
          {
            id: "total_bank_balance",
            value: false,
            label: "Total Account Cash Card",
          },
          {
            id: "action_view",
            value: false,
            label: "View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Delete Option",
          },
        ],
      },
      {
        module: "expense",
        label: "Expense",
        permissions: [
          {
            id: "Expense",
            value: false,
            label: "Expense Module",
          },
          {
            id: "action_view",
            value: false,
            label: "View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Delete Option",
          },
        ],
      },
      {
        module: "staff",
        label: "Staff",
        permissions: [
          {
            id: "Staff",
            value: false,
            label: "Staff Module",
          },
          {
            id: "action_view",
            value: false,
            label: "View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Delete Option",
          },
          {
            id: "action_viewOrders",
            value: false,
            label: "View Orders Option",
          },
        ],
      },
      {
        module: "partners",
        label: "Partners",
        permissions: [
          {
            id: "Partners",
            value: false,
            label: "Partners Module",
          },
          {
            id: "action_view",
            value: false,
            label: "View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Delete Option",
          },
        ],
      },
      {
        module: "vendors",
        label: "Vendors",
        permissions: [
          {
            id: "Vendors",
            value: false,
            label: "Vendors Module",
          },
          {
            id: "action_view",
            value: false,
            label: "View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Delete Option",
          },
        ],
      },
      {
        module: "bank",
        label: "Bank",
        permissions: [
          {
            id: "Bank",
            value: false,
            label: "Bank Module",
          },
          {
            id: "action_view",
            value: false,
            label: "View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Delete Option",
          },
        ],
      },
      {
        module: "notes",
        label: "Notes",
        permissions: [
          {
            id: "Notes",
            value: false,
            label: "Notes Module",
          },
        ],
      },
      {
        module: "invoices",
        label: "Invoices and Services",
        permissions: [
          {
            id: "Invoices and Services",
            value: false,
            label: "Invoices and Services Module",
          },
          {
            id: "download-all-btn",
            value: false,
            label: "Download All Orders PDF Button",
          },
          {
            id: "action_view",
            value: false,
            label: "Order View Option",
          },
          {
            id: "action_status",
            value: false,
            label: "Order Change Status Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Order Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Order Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Order Delete Option",
          },
          {
            id: "product_cost_price",
            value: false,
            label: "Show Product Cost Price",
          },
          {
            id: "product_source",
            value: false,
            label: "Show Product Source",
          },
        ],
      },
      {
        module: "services",
        label: "Services",
        permissions: [
          {
            id: "Services",
            value: false,
            label: "Services Module",
          },
          {
            id: "action_view",
            value: false,
            label: "Service View Option",
          },
          // {
          //   id: "action_status",
          //   value: false,
          //   label: "Service Change Status Option",
          // },
          {
            id: "action_edit",
            value: false,
            label: "Service Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Service Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Service Delete Option",
          },
        ],
      },
      {
        module: "whatsapp",
        label: "Whatsapp",
        permissions: [
          {
            id: "Whatsapp",
            value: false,
            label: "Whatsapp Module",
          },
          {
            id: "action_view",
            value: false,
            label: "Whatsapp View Option",
          },
          {
            id: "action_edit",
            value: false,
            label: "Whatsapp Edit Option",
          },
          {
            id: "action_print",
            value: false,
            label: "Whatsapp Print Option",
          },
          {
            id: "action_delete",
            value: false,
            label: "Whatsapp Delete Option",
          },
        ],
      },
      {
        module: "chat",
        label: "Chat",
        permissions: [
          {
            id: "Chat",
            value: false,
            label: "Chat Module",
          },
        ],
      },
      {
        module: "users",
        label: "Users",
        permissions: [
          {
            id: "Users",
            value: false,
            label: "Users Module",
          },
        ],
      },
      {
        module: "activity",
        label: "Activity Logs",
        permissions: [
          {
            id: "Activity Logs",
            value: false,
            label: "Activity Logs Module",
          },
        ],
      },
    ]);
  };

  const handleOpenCreateShopDialog = () => {
    setShopDate(getTodayDate());
    setEdit(false);
    setImageUri("");
    resetPermissions();
    setCreateShopDialog(true);
    setShop({
      name: "",
      brand_name: "",
      mobile: "",
      address: "",
      date: "",
      password: "",
      status: "",
      personal_email: "",
    });
  };

  const handleCloseCreateShopDialog = () => {
    setCreateShopDialog(false);
  };

  const handleSaveShopChanges = (e) => {
    const { name, value } = e.target;
    setShop({
      ...shop,
      [name]: value,
    });
  };

  const getRandomPassword = (len) => {
    var length = len ? len : 10;
    var string = "abcdefghijklmnopqrstuvwxyz";
    var numeric = "0123456789";
    var punctuation = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
    var password = "";
    var character = "";
    while (password.length < length) {
      let entity1 = Math.ceil(string.length * Math.random() * Math.random());
      let entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
      let entity3 = Math.ceil(
        punctuation.length * Math.random() * Math.random()
      );
      let hold = string.charAt(entity1);
      hold = password.length % 2 == 0 ? hold.toUpperCase() : hold;
      character += hold;
      character += numeric.charAt(entity2);
      character += punctuation.charAt(entity3);
      password = character;
    }
    password = password
      .split("")
      .sort(function() {
        return 0.5 - Math.random();
      })
      .join("");
    return password.substr(0, len);
  };

  const handleShop = () => {
    if (edit) {
    } else {
      showLoader();
      let formData = new FormData();
      formData.append("name", shop.name);
      formData.append("brand_name", shop.brand_name);
      formData.append("mobile", shop.mobile);
      formData.append("address", shop.address);
      let password = getRandomPassword(8);
      formData.append("password", password);
      formData.append("user_password", encrypt(password));
      formData.append("personal_email", shop.personal_email);
      let activePermissions = [];
      permissions.map((value, index) => {
        let permissionsData = [];
        value.permissions.map((p, i) => {
          if (p.value) {
            permissionsData.push(p);
          }
        });
        if (permissionsData.length > 0) {
          let object = {
            module: value.module,
            label: value.label,
            permissions: permissionsData,
          };
          activePermissions.push(object);
        }
      });

      if (activePermissions.length === 0) {
        formData.append("permissions", encrypt(JSON.stringify(permissions)));
      } else {
        console.log(activePermissions);
        formData.append(
          "permissions",
          encrypt(JSON.stringify(activePermissions))
        );
      }

      var date = new Date(shopDate);
      formData.append(
        "date",
        date.getFullYear() +
          "-" +
          pad(date.getMonth() + 1) +
          "-" +
          pad(date.getDate())
      );
      if (imageFile) {
        formData.append("image", imageFile, imageFile.name);
      }
      formData.append("status", shop.status);
      createShop(formData, (resp) => {
        if (resp.statusCode === 200) {
          hideLoader();
          handleGetAllShop();
          updateDataUsingPageNumber();
          handleCloseCreateShopDialog();
          flashMessage("success", resp.message);
        } else {
          hideLoader();
          flashMessage("error", resp.message);
        }
      });
    }
  };

  function saveImageFile(e) {
    let image = document.getElementById("productImage").files;
    setImageFile(image[0]);
    setImageUri(window.URL.createObjectURL(new Blob(image, { type: "*" })));
  }

  const handleTriStateFilter = (value) => {
    setStatus(value);
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      handleGetAllShop();
    }
  };

  useEffect(() => {
    if (mounted.current) {
      updateDataUsingPageNumber();
    } else {
      mounted.current = true;
    }
  }, [searchText, status, date, page]);
  

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const handleUpdateData = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const [userAddNewRoleDialog, setUserAddNewRoleDialog] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const [permissions, setPermissions] = useState(PermissionObject);

  const [selectedUserRoleId, setSelectedUserRoleId] = useState("");

  const [userRole, setUserRole] = useState("Admin");

  const [userRoles, setUserRoles] = useState([]);

  const [userRoleDialogMethod, setUserRoleDialogMethod] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangePermission = (index, permission) => {
    let newPermissions = [...permissions];
    newPermissions[activeStep].permissions[index].value = permission;
    setPermissions(newPermissions);
  };

  const handleSavePermissions = () => {
    showLoader();
    setUserAddNewRoleDialog(false);
    let formData = new FormData();
    formData.append("role", userRole);
    formData.append("branch_id", 0);
    formData.append("permissions", encrypt(JSON.stringify(permissions)));
    addUserRole(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        setRoleId(resp.successData.user_role.id);
        flashMessage("success", resp.message);
      } else {
        setUserAddNewRoleDialog(true);
        flashMessage("error", resp.message);
      }
    });
  };

  const handleGetAllUserRoles = () => {
    showLoader();
    getAllUserRoles((resp) => {
      hideLoader();
      setUserRoles(resp.successData.user_roles);
    });
  };

  const handleUserRoleDetail = (data) => {
    console.log(JSON.parse(decrypt(data.permissions)));
  };

  const handleClickAddNewRoleButton = () => {
    setUserRoleDialogMethod("Add");
    setActiveStep(0);
    //setUserRole("");
    setUserAddNewRoleDialog(true);
  };

  const handleEditUserRole = (data) => {
    setActiveStep(0);
    setUserRole(data.role);
    setPermissions(JSON.parse(decrypt(data.permissions)));
    setUserRoleDialogMethod("Update");
    setSelectedUserRoleId(data.id);
    setUserAddNewRoleDialog(true);
  };

  const handleUpdatePermissions = () => {
    showLoader();
    setUserAddNewRoleDialog(false);
    let formData = new FormData();
    formData.append("role", userRole);
    formData.append("permissions", encrypt(JSON.stringify(permissions)));
    formData.append("user_role_id", selectedUserRoleId);
    updateUserRole(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleGetAllUserRoles();
        flashMessage("success", "Shop Features Updated Successfully");
      } else {
        setUserAddNewRoleDialog(true);
        flashMessage("error", resp.message);
      }
    });
  };

  const handleUpdateBrandFeatures = (row) => {
    setSelectedUserRoleId(row.role_id);
    setActiveStep(0);
    resetPermissions();
    //setPermissions(JSON.parse(decrypt(row.permissions)));
    setUserAddNewRoleDialog(true);
  };

  const handleEditBrand = (row) => {
    setEdit(true);
    setUserRoleDialogMethod("Update");
    setCreateShopDialog(true);
    setImageUri(baseUrl + row.image);
    setShopDate(row.date);
    setShop(row);
  };

  const handleUpdateShop = () => {
    showLoader();
    let formData = new FormData();
    formData.append("shop_id", shop.shop_id);
    formData.append("name", shop.name);
    formData.append("brand_name", shop.brand_name);
    formData.append("mobile", shop.mobile);
    formData.append("address", shop.address);
    formData.append("personal_email", shop.personal_email);
    var date = new Date(shopDate);
    formData.append(
      "date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );
    if (imageFile) {
      formData.append("image", imageFile, imageFile.name);
    }
    formData.append("status", shop.status);
    updateShop(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        setCreateShopDialog(false);
        updateDataUsingPageNumber();
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const handleDeleteBrand = (row) => {};

  const handleViewBrand = (row) => {};

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={createShopDialog}
        onClose={handleCloseCreateShopDialog}
      >
        <DialogTitle>{edit ? "Update" : "Add New"} Shop</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs pr={0.5}>
              <CustomTextField
                value={shop.name}
                onChange={handleSaveShopChanges}
                name="name"
                id="name"
                key="name"
                placeholder="User Name"
                className="textField__AddInventory"
              />
            </Grid>
            <Grid item xs pl={0.5}>
              <CustomTextField
                value={shop.brand_name}
                onChange={handleSaveShopChanges}
                name="brand_name"
                id="brand_name"
                key="brand_name"
                placeholder="Brand Name"
                className="textField__AddInventory"
              />
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs pr={0.5}>
              <CustomTextField
                value={shop.mobile}
                onChange={handleSaveShopChanges}
                name="mobile"
                id="mobile"
                key="mobile"
                placeholder="Mobile"
                className="textField__AddInventory"
                type="number"
              />
            </Grid>
            <Grid item xs pl={0.5}>
              <CustomTextField
                value={shop.personal_email}
                onChange={handleSaveShopChanges}
                name="personal_email"
                id="personal_email"
                key="personal_email"
                placeholder="Email"
                className="textField__AddInventory"
              />
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs pr={0.5}>
              <input
                style={{ display: "none" }}
                id="productImage"
                type="file"
                onChange={saveImageFile}
              />
              <label htmlFor="productImage">
                <Button
                  variant="outlined"
                  component="span"
                  style={{
                    width: "100%",
                    height: "54.5px",
                    backgroundColor: "white",
                    borderColor: "rgb(0,0,0,0.25)",
                    color: "rgb(0,0,0,0.35)",
                    textTransform: "none",
                    fontSize: "16px",
                    justifyContent: "flex-start",
                    borderRadius: "10px",
                  }}
                  endIcon={
                    <Box
                      sx={
                        imageUri === ""
                          ? {
                              position: "absolute",
                              right: "20px",
                              bottom: "8px",
                            }
                          : {
                              position: "absolute",
                              right: "0px",
                              bottom: "-12px",
                            }
                      }
                    >
                      {imageUri === "" && <UploadImageIcon />}
                      {imageUri !== "" && (
                        <img
                          alt=""
                          style={{ borderRadius: "10px" }}
                          src={imageUri}
                          width="50px"
                          height="50px"
                        />
                      )}
                    </Box>
                  }
                >
                  Upload Image
                </Button>
              </label>
            </Grid>
            <Grid item xs pl={0.5}>
              <CustomTextField
                value={shop.address}
                onChange={handleSaveShopChanges}
                name="address"
                id="address"
                key="address"
                placeholder="Address"
                className="textField__AddInventory"
              />
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs pr={0.5}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="bnkmnu_field__bank"
                  value={shopDate}
                  onChange={(newValue) => {
                    setShopDate(newValue);
                  }}
                  renderInput={(params) => (
                    <CustomTextFieldForDate
                      error={false}
                      {...params}
                      placeholder={"mm/dd/yyyy"}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs pl={0.5}>
              <FormControl style={{ width: "100%" }}>
                {shop.status === "" && (
                  <InputLabel sx={{ opacity: 0.6 }}>Status</InputLabel>
                )}
                <SimpleMenu
                  handleSetData={(value) => {
                    setShop({
                      ...shop,
                      ["status"]: value,
                    });
                  }}
                  data={["Active", "Blocked"]}
                  selectedValue={shop.status}
                  className={"bnkmnu_field__bank"}
                  width={"268px"}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: `${edit ? "end" : "space-between"}`,
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingTop: "0",
          }}
        >
          {!edit && (
            <Button
              onClick={handleClickAddNewRoleButton}
              variant="contained"
              className="download_report_btn__orders"
            >
              Shop Features
            </Button>
          )}
          <Box>
            <Button
              className="download_report_btn__orders"
              onClick={handleCloseCreateShopDialog}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button
              className="add_order_btn__orders"
              onClick={edit ? handleUpdateShop : handleShop}
            >
              {edit ? "Update" : "Save"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={userAddNewRoleDialog}
        onClose={() => {
          setUserAddNewRoleDialog(false);
        }}
        id="deleteProductDialog"
      >
        <DialogContent className="uinfo_con__user">
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              {/* <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div style={{ marginBottom: "8px" }}>Staff Role</div>
                  <CustomTextField
                    key="user_role"
                    name="user_role"
                    id="user_role"
                    value={userRole}
                    onChange={(e) => {
                      setUserRole(e.target.value);
                    }}
                    placeholder="New User Role"
                    className="textField__AddInventory"
                  />
                </Grid>
              </Grid> */}

              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <MobileStepper
                    variant="dots"
                    steps={permissions.length}
                    position="static"
                    activeStep={activeStep}
                    style={{ backgroundColor: "transparent" }}
                    classes={{
                      dotActive: "stp_dtstl__user",
                    }}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === permissions.length - 1}
                        className={`bnbt_stl__user ${activeStep <
                          permissions.length - 1 && "bnbt_stl_c__user"}`}
                      >
                        Next
                        <IoMdArrowDropright
                          style={{ fontSize: "20px", marginTop: "-2px" }}
                        />
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        className={`bnbt_stl__user ${activeStep > 0 &&
                          "bnbt_stl_c__user"}`}
                      >
                        <IoMdArrowDropleft
                          style={{ fontSize: "20px", marginTop: "-2px" }}
                        />
                        Back
                      </Button>
                    }
                  />
                </Grid>
              </Grid>

              <Grid container mt="-5px" mb="10px">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box textAlign="center">
                    <span className="stp_txt__user">
                      {permissions[activeStep].label}
                    </span>
                  </Box>
                </Grid>
              </Grid>

              <Grid container pt="10px">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} px="10px">
                  <Box>
                    {permissions[activeStep].permissions.map((value, index) => (
                      <>
                        {value.value ? (
                          <Chip
                            style={{ margin: "3px" }}
                            label={value.label}
                            color="success"
                            clickable
                            onClick={() => {
                              handleChangePermission(index, false);
                            }}
                          />
                        ) : (
                          <Chip
                            style={{ margin: "3px" }}
                            label={value.label}
                            clickable
                            onClick={() => {
                              handleChangePermission(index, true);
                            }}
                          />
                        )}
                      </>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="deleteDialogActions">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {/* <Grid
              className="deleteDialogBtn__inv deleteDialogBtnNo__inv"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={() => {
                setUserAddNewRoleDialog(false);
              }}
            >
              Cancel
            </Grid> */}
            <Grid
              className="deleteDialogBtn__inv adurl_btn__user"
              xl={12}
              // lg={6}
              // md={6}
              // sm={6}
              // xs={6}
              item
              onClick={() => {
                userRoleDialogMethod === "Add"
                  ? setUserAddNewRoleDialog(false)
                  : handleUpdatePermissions();
              }}
              // onClick={() => {
              //   setUserAddNewRoleDialog(false);
              // }}
            >
              {userRoleDialogMethod === "Add" ? "Save" : "Update"}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <NavigationBar
            path={"Dashboard>Brands Management>Brands"}
            paddingBottom={3}
          />

          <MainTitle title={"Brands Management"} />

          <Grid container marginBottom={1.5}>
            <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
              <SearchField
                onChange={handleUpdateData}
                tableDataCount={shops.length}
                totalAvailableDataCount={totalShopsCount}
              />
            </Grid>

            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              item
              display="flex"
              justifyContent="end"
              pr={"5px"}
            >
              <Box pr={"5px"}>
                <Button
                  onClick={handleOpenCreateShopDialog}
                  variant="contained"
                  className="add_order_btn__orders"
                >
                  Add New Brand
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Grid container my={2}>
            <Grid
              xs
              item
              display="flex"
              justifyContent="space-between"
              pr={"5px"}
            >
              <Box display="inline-block">
                <TriStateButton
                  values={["All", "Active", "Blocked"]}
                  selected={"All"}
                  handleTriStateFilter={handleTriStateFilter}
                />
              </Box>
              <Box>
                <DateFilter
                  setDateObject={setDate}
                  dateObject={date}
                  className={"download_report_btn__orders"}
                  all={true}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <CustomTable
                view={handleViewBrand}
                edit={handleEditBrand}
                delete={handleDeleteBrand}
                updateFeatures={handleUpdateBrandFeatures}
                columns={columns}
                data={shops}
                options={options}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Brands;
