import {
  Grid,
  DialogActions,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  DialogTitle,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import CustomTable from "../../widgets/CustomTable";
import SearchField from "../../widgets/SearchField";
import Box from "@mui/material/Box";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import { ReactComponent as DownloadIcon } from "../../assets/SVG/DownloadIcon.svg";
import { ReactComponent as BalanceIcon } from "../../assets/SVG/BalanceSmallIcon.svg";
import { ReactComponent as DebitIcon } from "../../assets/SVG/DebitSmallIcon.svg";
import { ReactComponent as CreditIcon } from "../../assets/SVG/CreditSmallIcon.svg";
import { IoMdArrowDropleft } from "react-icons/io";
import { ReactComponent as EditIcon } from "../../assets/SVG/EditIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import { ReactComponent as AddButtonIcon } from "../../assets/SVG/AddButtonIcon.svg";
import DeleteDialog from "../../widgets/DeleteDialog";
import { AiOutlinePlus } from "react-icons/ai";
import { BankNames, SelectedBankName } from "../../constants/BankConstants";
import "./Bank.css";
import PaymentsIcon from "@mui/icons-material/Payments";
import { getAllBanks } from "../../api/BankAPI";
import { styled } from "@mui/material/styles";
import {
  addBankTransaction,
  getAllBankTransactions,
  deleteBankTransaction,
  updateBankTransactionDetailById,
} from "../../api/BankTransactionAPI";
import { DateRangePicker } from "mui-daterange-picker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { debounce } from "lodash";
import {
  UtilityBillExpenseCard,
  SalaryExpenseCard,
  PurchasingExpenseCard,
  TaxExpenseCard,
  OthersExpenseCard,
} from "../../widgets/Cards";
import SimpleMenu from "../../widgets/SimpleMenu";
import MainTitle from "../../widgets/MainTitle";
import DateFilter from "../../widgets/DateFilter";
import AddNewButton from "../../widgets/AddNewButton";
import DownloadReportButton from "../../widgets/DownloadReportButton";
import {pad, getTodayDate} from '../../utils/common'
import NoBank from "../../assets/Images/EmptyScreenImage/NoBank.png";
const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    minWidth: 30,
    align: "left",
  },
  {
    id: "bank_name",
    label: "Bank Name",
    minWidth: 30,
    align: "left",
  },
  {
    id: "recipient_name",
    label: "Recipient Name",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_id",
    label: "Trans ID",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_type",
    label: "Trans Type",
    minWidth: 30,
    align: "left",
  },
  {
    id: "transaction_amount",
    label: "Amount",
    minWidth: 30,
    align: "left",
  },
  {
    id: "remaining_no_of_days",
    label: "Days Left",
    minWidth: 100,
    align: "left",
  },
  {
    id: "transaction_date",
    label: "Trans Date",
    align: "left",
    minWidth: 100,
  },

  {
    id: "transaction_status",
    label: "Status",
    minWidth: 100,
    align: "left",
    type: "dropDown",
    options: ["Pending", "Withdrawal", "Returned"],
  },
];
const options = [
  {
    icon: (
      <div>
        <EditIcon />
      </div>
    ),
    label: "Edit",
    method: "edit",
    type: "icon",
    id: "edit",
  },
  {
    icon: (
      <div>
        <DeleteIcon />
      </div>
    ),
    label: "Delete",
    method: "delete",
    type: "icon",
    id: "delete",
  },
];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const FocusedTextField = (props) => {
  return (
    <CustomTextField
      multiline={props.multiline}
      rows={props.rows}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      key={props.key}
      placeholder={props.placeholder}
      className={props.className}
      type={props.type}
    />
  );
};

function Bank() {
  const {
    showLoader,
    hideLoader,
    query,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
    userObject,
  } = useContext(GlobalData);
  const [selectedExpense, setSelectedExpense] = useState("Utility Bill");

  const [searchText, setSearchText] = useState("");
  const [dateMenuElement, setDateMenuElement] = useState(null);
  const [bankTransactionDate, setBankTransactionDate] = useState(null); ////
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [selectedBank, setSelectedBank] = useState(SelectedBankName);
  const [selectedBankId, setSelectedBankId] = useState(0);
  const [selectedTransectionId, setSelectedTransectionId] = useState(0);
  const [selectedTransectionStatus, setSelectedTransectionStatus] = useState(0);
  const [allBanks, setAllBanks] = useState([]);
  const [addChequeModal, setAddChequeModal] = useState(false);
  const [debitCreditModal, setDebitCreditModal] = useState("");
  const [bankAmountData, setBankAmountData] = useState({});
  const [statusDescModal, setStatusDescModal] = useState("");
  const [ChequeList, setChequeList] = useState([]);
  const [bankFilter, setBankFilter] = useState(0);
  const [edit, setEdit] = useState(false);
  const [bankDate, setBankDate] = useState("This Month");

  const [bankTransaction, setBankTransaction] = useState({
    bank_id: "",
    bank_name: "",
    transaction_id: "",
    transaction_type: "",
    recipient_name: "",
    transaction_amount: "",
    remaining_no_of_days: "",
    transaction_description: "",
    transaction_status: "",
  });

  const clearBankTransactionObject = () => {
    setBankTransaction({
      bank_id: "",
      bank_name: "",
      transaction_id: "",
      transaction_type: "",
      recipient_name: "",
      transaction_amount: "",
      remaining_no_of_days: "",
      transaction_description: "",
      transaction_status: "",
    });
  };

  const [bankTransactions, setBankTransactions] = useState([]);

  const [totalBankTransactionsCount, setTotalBankTransactionsCount] = useState(
    0
  );

 

  const handleAddChequeModalOpen = () => {
    setAddChequeModal(true);
    setSelectedBank("Select Bank");
    setSelectedBankId("0");
    setBankTransactionDate(getTodayDate());
  };

  const handleAddChequeModalClose = () => {
    setAddChequeModal(false);
    setSelectedBank("All Banks");
    setSelectedBankId("0");

    setEdit(false);
    clearBankTransactionObject();
    setBankTransactionDate("This Week");
  };

  const handleStatuDescModalOpen = (status, id, desc) => {
    setBankTransaction({
      ...bankTransaction,
      ["transaction_description"]: desc,
    });
    setSelectedTransectionStatus(status);
    setSelectedTransectionId(id);
    setStatusDescModal(true);
  };

  const handleStatuDescModalClose = () => {
    setStatusDescModal(false);
    clearBankTransactionObject();
  };

  const handleDebitCreditModalOpen = (type) => {
    setDebitCreditModal(type);
    setSelectedBank("Select Bank");
    setSelectedBankId("0");
    setBankTransactionDate(getTodayDate());
  };

  const handleDebitCreditModalClose = () => {
    setDebitCreditModal("");
    setSelectedBank("All Banks");
    setEdit(false);
    setSelectedBankId("0");
    clearBankTransactionObject();
  };

  const handleBankTransactionInputChange = (e) => {
    const { name, value } = e.target;
    setBankTransaction({
      ...bankTransaction,
      [name]: value,
    });
  };

  const handleGetAllBanks = () => {
    let data = {
      search_text: "",
    };
    showLoader();
    getAllBanks(data, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        let ab = resp.successData.banks;
        ab.push({
          id: 0,
          bank_name: "All Banks",
          account_name: "",
          account_number: "",
          created_at: "2023-01-18T01:04:58.000000Z",
          updated_at: "2023-01-18T01:04:58.000000Z",
          bank_transactions: [],
        });
        setAllBanks(ab.reverse());
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const handleGetAllBankTransactions = () => {
    let data = {};
    if (bankFilter === 0) {
      data = {
        search_text: searchText,
        date: JSON.stringify(bankDate),
      };
    } else {
      data = {
        search_text: searchText,
        bank_id: bankFilter,
        date: JSON.stringify(bankDate),
      };
    }
    getAllBankTransactions(data, (resp) => {
      if (resp.statusCode === 200) {
        setBankTransactions(resp.successData.bank_transactions);
        setBankAmountData(resp.successData.bank_amount_data);
        setTotalBankTransactionsCount(resp.successData.bank_transactions_count);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const handleAddChequeTransaction = () => {
    showLoader();
    let formData = new FormData();
    formData.append("bank_name", selectedBank);
    formData.append("bank_id", selectedBankId);
    formData.append("transaction_type", "Cheque");
    formData.append("recipient_name", bankTransaction.recipient_name);
    formData.append("transaction_amount", bankTransaction.transaction_amount);
    formData.append(
      "transaction_description",
      bankTransaction.transaction_description
    );

    var date = new Date(getTodayDate());
    formData.append(
      "transaction_date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );

    date = new Date(bankTransactionDate);
    formData.append(
      "cheque_date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );

    addBankTransaction(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleAddChequeModalClose();
        handleGetAllBankTransactions();
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const handleUpdateBankTransactionStatus = () => {
    showLoader();
    let formData = new FormData();
    formData.append("transaction_status", selectedTransectionStatus);
    formData.append(
      "transaction_description",
      bankTransaction.transaction_description
    );
    formData.append("bank_transaction_id", selectedTransectionId);
    updateBankTransactionDetailById(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleStatuDescModalClose();
        handleGetAllBankTransactions();
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const handleAddDebitCreditBankTransaction = () => {
    showLoader();
    let formData = new FormData();
    formData.append("bank_name", selectedBank);
    formData.append("bank_id", selectedBankId);
    formData.append("transaction_type", selectedExpense);
    if (debitCreditModal === "Expense") {
      formData.append("recipient_name", "Shop");
    } else {
      formData.append("recipient_name", bankTransaction.recipient_name);
    }
    formData.append("transaction_amount", bankTransaction.transaction_amount);
    formData.append("transaction_id", bankTransaction.transaction_id);
    formData.append(
      "transaction_description",
      bankTransaction.transaction_description
    );

    var date = new Date(bankTransactionDate);
    formData.append(
      "transaction_date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );

    addBankTransaction(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleDebitCreditModalClose();
        handleGetAllBankTransactions();
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const handleEditTransactionCheque = () => {
    handleDebitCreditModalClose();
    showLoader();
    let formData = new FormData();
    formData.append("bank_name", selectedBank);
    formData.append("bank_id", selectedBankId);
    formData.append("transaction_type", bankTransaction.transaction_type);
    formData.append("recipient_name", bankTransaction.recipient_name);
    formData.append("transaction_amount", bankTransaction.transaction_amount);
    formData.append("bank_transaction_id", bankTransaction.id);
    formData.append(
      "transaction_description",
      bankTransaction.transaction_description
    );

    var date = new Date(bankTransactionDate);

    formData.append(
      "cheque_date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );

    updateBankTransactionDetailById(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleGetAllBankTransactions();
        setEdit(false);
        handleAddChequeModalClose();
        flashMessage("success", resp.message);
      } else {
        setEdit(false);
        handleAddChequeModalClose();
        flashMessage("error", resp.message);
      }
    });
  };

  const handleEditTransactionCD = () => {
    setEdit(false);
    handleDebitCreditModalClose();
    showLoader();
    let formData = new FormData();
    formData.append("bank_name", selectedBank);
    formData.append("bank_id", selectedBankId);
    formData.append("transaction_type", debitCreditModal);
    formData.append("recipient_name", bankTransaction.recipient_name);
    formData.append("transaction_amount", bankTransaction.transaction_amount);
    formData.append("transaction_id", bankTransaction.transaction_id);
    formData.append("bank_transaction_id", bankTransaction.id);
    formData.append(
      "transaction_description",
      bankTransaction.transaction_description
    );

    var date = new Date(bankTransactionDate);
    formData.append(
      "transaction_date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );

    if (debitCreditModal === "Cheque") {
      formData.append(
        "cheque_date",
        date.getFullYear() +
          "-" +
          pad(date.getMonth() + 1) +
          "-" +
          pad(date.getDate())
      );
    }

    updateBankTransactionDetailById(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleGetAllBankTransactions();

        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };
  const openEditTransaction = (data) => {
    setSelectedBank(data.bank_name);
    setSelectedBankId(data.bank_id);
    setBankTransactionDate(data.created_at);
    setBankTransaction(data);
    if (data.transaction_type === "Cheque") {
      setBankTransactionDate(data.cheque_date);
    }
    setEdit(true);
    setDebitCreditModal(data.transaction_type);
  };

  const openDeleteTransactionDialog = (data) => {
    console.log("this is transaction", data);
    setSelectedTransectionId(data.id);
    setDeleteProductDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
    setEdit(false);
  };

  const handleDeleteTransaction = () => {
    showLoader();
    const tempdata = { bank_transaction_id: selectedTransectionId };
    deleteBankTransaction(tempdata, (resp) => {
      if (resp.statusCode === 200) {
        flashMessage("success", resp.message);
        handleGetAllBanks();
        handleGetAllBankTransactions();
        setDeleteProductDialog(false);
        setEdit(false);
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        setDeleteProductDialog(false);
        hideLoader();
      }
    });
  };
  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const ITEM_HEIGHT = 48;

  const isOpenDateMenu = Boolean(dateMenuElement);
  const handleOpenDateMenu = (event) => {
    setDateMenuElement(event.currentTarget);
  };
  const handleCloseDateMenu = () => {
    setDateMenuElement(null);
  };

  const toggleDateRangerPicker = () =>
    setOpenDateRangePicker(!openDateRangePicker);

  const handleBankTransactionDateFilter = (option) => {
    console.log("SelectedBank", selectedBank);
    if (option === "AllBanks") {
      setBankFilter(option);
      toggleDateRangerPicker();
    } else {
      setBankFilter(option);
    }
  };

  const handleDateRange = (range) => {
    setDateRange(range);
    setBankTransactionDate(range);
    setTimeout(() => {
      toggleDateRangerPicker();
    }, 500);
  };

  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  useEffect(() => {
    handleGetAllBanks();
    handleGetAllBankTransactions();
  }, [searchText, bankFilter, bankDate]);

  return (
    <Grid id="header__orderm" container>
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <Box position="absolute" top="20%" zIndex="99999">
          <DateRangePicker
            open={openDateRangePicker}
            toggle={toggleDateRangerPicker}
            onChange={(range) => handleDateRange(range)}
          />
        </Box>
        <DeleteDialog
          handleClose={handleCloseDeleteDialog}
          showDialog={deleteProductDialog}
          handleRemove={handleDeleteTransaction}
          title={"Remove this Transaction?"}
          sub_title={
            "You are about to remove a Transaction. Click on the “Remove” button to remove it."
          }
        />
        <Dialog
          fullWidth
          maxWidth="sm"
          open={addChequeModal}
          onClose={handleAddChequeModalClose}
        >
          {edit ? (
            <DialogTitle>Update Cheque</DialogTitle>
          ) : (
            <DialogTitle>Add Cheque</DialogTitle>
          )}
          <DialogContent>
            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className="padn_invoice__icon">
                <BankMenu
                  selectedBank={selectedBank}
                  setSelectedBank={setSelectedBank}
                  setSelectedBankId={setSelectedBankId}
                  allBanks={allBanks}
                  className={"bnkmnu_field__bank"}
                  width={"268px"}
                  allBanksOption={"All Banks"}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} paddingLeft="5px">
                <FocusedTextField
                  value={bankTransaction.recipient_name}
                  onChange={handleBankTransactionInputChange}
                  name={"recipient_name"}
                  id={"recipient_name"}
                  key={"recipient_name"}
                  placeholder={"Recipient Name"}
                  className={"textField__AddInventory"}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className="padn_invoice__icon">
                <FocusedTextField
                  value={bankTransaction.transaction_amount}
                  onChange={handleBankTransactionInputChange}
                  name={"transaction_amount"}
                  id={"transaction_amount"}
                  key={"transaction_amount"}
                  placeholder={"Transaction Amount"}
                  className={"textField__AddInventory"}
                  type="number"
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} paddingLeft="5px">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="bnkmnu_field__bank"
                    value={bankTransactionDate}
                    onChange={(newValue) => {
                      setBankTransactionDate(newValue);
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        error={false}
                        {...params}
                        placeholder={"mm/dd/yyyy"}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Box>
              <FocusedTextField
                value={bankTransaction.transaction_description}
                onChange={handleBankTransactionInputChange}
                multiline={true}
                rows={3}
                name={"transaction_description"}
                id={"transaction_description"}
                key={"transaction_description"}
                placeholder={"Description"}
                className={"textField__AddInventory"}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              className="download_report_btn__orders"
              onClick={handleAddChequeModalClose}
            >
              Cancel
            </Button>
            <Button
              className="add_order_btn__orders"
              onClick={
                edit ? handleEditTransactionCheque : handleAddChequeTransaction
              }
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={debitCreditModal !== ""}
          onClose={handleDebitCreditModalClose}
        >
          <DialogTitle>
            {edit ? "Edit" : "Add"} {debitCreditModal}
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className="padn_invoice__icon">
                <BankMenu
                  id={"select_bank_field__bank"}
                  selectedBank={selectedBank}
                  setSelectedBank={setSelectedBank}
                  setSelectedBankId={setSelectedBankId}
                  allBanks={allBanks}
                  className={"bnkmnu_field__bank"}
                  width={"268px"}
                  allBanksOption={"All Banks"}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} paddingLeft="5px">
                {debitCreditModal === "Transaction" ? (
                  <SimpleMenu
                    handleSetData={setSelectedExpense}
                    data={[
                      "Debit",
                      "Utility Bill",
                      "Purchase",
                      "Tax",
                      "Others",
                      "Credit",
                    ]}
                    id={"utility_bill__bank"}
                    selectedValue={selectedExpense}
                    className={"bnkmnu_field__bank"}
                    width={"268px"}
                  />
                ) : (
                  <FocusedTextField
                    value={bankTransaction.recipient_name}
                    onChange={handleBankTransactionInputChange}
                    name={"recipient_name"}
                    id={"recipient_name"}
                    key={"recipient_name"}
                    placeholder={"Recipient Name"}
                    className={"textField__AddInventory"}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className="padn_invoice__icon">
                <FocusedTextField
                  value={bankTransaction.transaction_amount}
                  onChange={handleBankTransactionInputChange}
                  name={"transaction_amount"}
                  id={"transaction_amount"}
                  key={"transaction_amount"}
                  placeholder={"Transaction Amount"}
                  className={"textField__AddInventory"}
                  type={"number"}
                />
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} paddingLeft="5px">
                <FocusedTextField
                  value={bankTransaction.transaction_id}
                  onChange={handleBankTransactionInputChange}
                  name={"transaction_id"}
                  id={"transaction_id"}
                  key={"transaction_id"}
                  placeholder={"Transaction ID"}
                  className={"textField__AddInventory"}
                  type={"number"}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12} paddingTop="13px">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="bnkmnu_field__bank"
                    value={bankTransactionDate}
                    onChange={(newValue) => {
                      setBankTransactionDate(newValue);
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                      id="transaction_date__bank"
                        error={false}
                        {...params}
                        placeholder={"mm/dd/yyyy"}
                      />
                    )}
                    disabled={edit}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Box>
              <FocusedTextField
                value={bankTransaction.transaction_description}
                onChange={handleBankTransactionInputChange}
                multiline={true}
                rows={3}
                name={"transaction_description"}
                id={"transaction_description"}
                key={"transaction_description"}
                placeholder={"Description"}
                className={"textField__AddInventory"}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
            id="cancel_btn__bank"
              className="download_report_btn__orders"
              onClick={handleDebitCreditModalClose}
            >
              Cancel
            </Button>
            <Button
            id="save_btn__bank"
              className="add_order_btn__orders"
              onClick={
                edit
                  ? handleEditTransactionCD
                  : handleAddDebitCreditBankTransaction
              }
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={statusDescModal}
          onClose={handleStatuDescModalClose}
        >
          <DialogTitle>Update Transaction</DialogTitle>
          <DialogContent>
            <FocusedTextField
              value={bankTransaction.transaction_description}
              onChange={handleBankTransactionInputChange}
              multiline={true}
              rows={3}
              name={"transaction_description"}
              id={"transaction_description"}
              key={"transaction_description"}
              placeholder={"Description"}
              className={"textField__AddInventory"}
            />
          </DialogContent>
          <DialogActions>
            <Button
              className="download_report_btn__orders"
              onClick={handleStatuDescModalClose}
            >
              Cancel
            </Button>
            <Button
              className="add_order_btn__orders"
              onClick={handleUpdateBankTransactionStatus}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <NavigationBar
          path={"Dashboard>Bank Management>Bank"}
          paddingBottom={3}
        />

        <Grid container>
          <Grid
            xl={6}
            lg={6}
            md={5}
            sm={12}
            xs={12}
            item
            paddingRight={onlyXs ? "5px" : "10px"}
          >
            <MainTitle title={"Bank Management"} />
          </Grid>
          <Grid
            xl={6}
            lg={6}
            md={7}
            sm={12}
            xs={12}
            item
            display="flex"
            justifyContent={smDown ? "center" : "end"}
            paddingTop={smDown ? 1 : 0}
            paddingRight={smDown ? "0px" : "10px"}
          >
            <DateFilter
              id={"date_filter__bank"}
              setDateObject={setBankDate}
              dateObject={bankDate}
              className={"download_report_btn__orders"}
            />
          </Grid>
        </Grid>

        <Grid container display="flex" justifyContent="start" mt={2}>
          <UtilityBillExpenseCard amount={bankAmountData.utilityBills} />
          <SalaryExpenseCard amount={bankAmountData.salary} />
          <PurchasingExpenseCard amount={bankAmountData.purchase} />
          <TaxExpenseCard amount={bankAmountData.tax} />
          <OthersExpenseCard amount={bankAmountData.others} />
        </Grid>

        <Grid container display="flex" justifyContent="start">
          <BankMenu
            selectedBank={selectedBank}
            setSelectedBank={setSelectedBank}
            setSelectedBankId={setSelectedBankId}
            handleBankTransactionDateFilter={handleBankTransactionDateFilter}
            allBanks={allBanks}
            className={"bnkmnu_btn__bank"}
            width={"155px"}
            allBanksOption={""}
            mr="10px"
          />
          <Box
            backgroundColor="rgba(236, 174, 51, 0.2)"
            className="bnkcrd_bx__bank"
            marginBottom="6px"
          >
            <Box className="bnkcrd_bx_icn_bx__bank">
              <PaymentsIcon style={{ color: "#ECAE33", fontSize: "26px" }} />
            </Box>
            <Box>
              <Box className="bnkcrd_txt1__bank">Total Cheques Amount</Box>
              <Box className="bnkcrd_txt2__bank">
                {bankAmountData.cheque} {userObject.currency}
              </Box>
            </Box>
            <Box>
              <IconButton
                aria-label="delete"
                size="small"
                className="cio_btn__bank"
                onClick={() => {
                  handleAddChequeModalOpen();
                }}
              >
                <AiOutlinePlus color={"#1E2738"} size={18} />
              </IconButton>
            </Box>
          </Box>

          <Box
            backgroundColor="#EDEBFE"
            className="bnkcrd_bx__bank"
            marginBottom="6px"
          >
            <Box className="bnkcrd_bx_icn_bx__bank">
              <BalanceIcon />
            </Box>
            <Box>
              <Box className="bnkcrd_txt1__bank">Total Balance</Box>
              <Box className="bnkcrd_txt2__bank">
                {bankAmountData.balance} {userObject.currency}
              </Box>
            </Box>
          </Box>

          <Box
            backgroundColor="#E4F3F1"
            className="bnkcrd_bx__bank"
            marginBottom="6px"
          >
            <Box className="bnkcrd_bx_icn_bx__bank">
              <DebitIcon />
            </Box>
            <Box>
              <Box className="bnkcrd_txt1__bank">Total Debit</Box>
              <Box className="bnkcrd_txt2__bank">
                {bankAmountData.debit} {userObject.currency}
              </Box>
            </Box>
            {/* <Box>
              <IconButton
                aria-label="delete"
                size="small"
                className="cio_btn__bank"
                onClick={() => {
                  handleDebitCreditModalOpen("Debit");
                }}
              >
                <AiOutlinePlus color={"#1E2738"} size={18} />
              </IconButton>
            </Box> */}
          </Box>

          <Box
            backgroundColor="#FFE8DE"
            className="bnkcrd_bx__bank"
            marginBottom="6px"
          >
            <Box className="bnkcrd_bx_icn_bx__bank">
              <CreditIcon />
            </Box>
            <Box>
              <Box className="bnkcrd_txt1__bank">Total Credit</Box>
              <Box className="bnkcrd_txt2__bank">
                {bankAmountData.credit} {userObject.currency}
              </Box>
            </Box>
            {/* <Box>
              <IconButton
                aria-label="delete"
                size="small"
                className="cio_btn__bank"
                onClick={() => {
                  handleDebitCreditModalOpen("Credit");
                }}
              >
                <AiOutlinePlus color={"#1E2738"} size={18} />
              </IconButton>
            </Box> */}
          </Box>
        </Grid>

        <Grid container marginTop={2}>
          <Grid xl={6} lg={6} md={5} sm={12} xs={12} item className="padn_invoice__icon">
            <SearchField
              id="search_field__bank"
              onChange={updateProductList}
              tableDataCount={bankTransactions.length}
              totalAvailableDataCount={totalBankTransactionsCount}
            />
          </Grid>
          <Grid
            xl={6}
            lg={6}
            md={7}
            sm={12}
            xs={12}
            item
            display="flex"
            justifyContent={smDown ? "center" : "end"}
            paddingTop={onlyXs ? 1 : 0}
            paddingRight={smDown ? "0px" : "10px"}
          >
            {/* <Button
              variant="contained"
              className="add_order_btn__orders"
              style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
              onClick={() => {
                handleDebitCreditModalOpen("Transaction");
              }}
            >
              Add Transaction
            </Button> */}
            {/* <Button
              variant="contained"
              className="add_order_btn__orders"
              style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
              onClick={() => {
                navigate("/bank/add-new-bank");
              }}
            >
              Add New Bank
            </Button> */}
            {/* <Button
              variant="contained"
              endIcon={<DownloadIcon />}
              className="download_report_btn__orders"
            >
              Download Report
            </Button> */}
            <AddNewButton
              name={"Add Transaction"}
              onClick={() => {
                handleDebitCreditModalOpen("Transaction");
              }}
              id={"add_transaction__bank"}
            />
            <AddNewButton
              id={"add_new_bank__bank"}
              name={"Add New Bank"}
              onClick={() => {
                navigate("/bank/add-new-bank");
              }}
            />
            <DownloadReportButton id={"download_report__bank"} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} mt={2} item>
            <CustomTable
              showA
              columns={columns}
              data={bankTransactions}
              options={options}
              edit={openEditTransaction}
              delete={openDeleteTransactionDialog}
              updateStatus={handleStatuDescModalOpen}
              hideActions={false}
              image={NoBank}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const BankMenu = (props) => {
  const [bankMenu, setBankMenu] = useState(null);
  const isBankMenuOpen = Boolean(bankMenu);

  const handleOpenBankMenu = (e) => {
    setBankMenu(e.currentTarget);
  };

  const handleCloseBankMenu = () => {
    setBankMenu(null);
  };
  return (
    <Box>
      <Box mr={props.mr}>
        <Button
          id={props.id}
          onClick={handleOpenBankMenu}
          variant="contained"
          endIcon={<DownloadIcon style={{ marginRight: "5px" }} />}
          className={props.className}
        >
          <Box className="lngtxt_bnkslec__bank" width="100%" padding="2px">
            {props.selectedBank}
          </Box>
        </Button>
      </Box>
      <Menu
        anchorEl={bankMenu}
        open={isBankMenuOpen}
        onClose={handleCloseBankMenu}
        PaperProps={{
          style: {
            maxHeight: 48 * 6,
            width: props.width,
            borderRadius: "10px",
            boxShadow: "none",
            border: "1px solid rgba(0,0,0,0.09)",
          },
        }}
      >
        {props.allBanks.map((value, index) => (
          <Box
            style={{
              borderTop: `${index === 0 ? "1px solid rgba(0,0,0,0.09)" : ""}`,
              paddingTop: `${index === 0 ? "3px" : ""}`,
            }}
          >
            {props.allBanksOption !== value.bank_name && (
              <MenuItem
                onClick={() => {
                  props.setSelectedBank(value.bank_name);
                  console.log(value.id);
                  props.setSelectedBankId(value.id);
                  handleCloseBankMenu();
                  props.handleBankTransactionDateFilter(value.id);
                }}
                style={{
                  borderBottom: "1px solid rgba(0,0,0,0.09)",
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                {value.bank_name}
              </MenuItem>
            )}
          </Box>
        ))}
      </Menu>
    </Box>
  );
};

export default Bank;
