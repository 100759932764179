import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ButtonBase,
  Box,
  Grid,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import BranchMainImg from "../../assets/Images/BranchMainImg.jpg";
import "./Branches.css";
import { GlobalData } from "../../App";
import { ReactComponent as BrancheShowIcon } from "../../assets/SVG/BranchesSaveLogo.svg";
import { ReactComponent as BWebSiteLogo } from "../../assets/SVG/BranchesWebSiteLogo.svg";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import { styled } from "@mui/material/styles";
import BrancheSetting from "./BrancheSetting";
import {
  createBranch,
  updateBranchById,
  deleteBranchById,
  getAllBranchesById,
} from "../../api/ShopBranchAPI";
import { IoMdArrowDropleft } from "react-icons/io";
import BranchPincodeIcon from "../../assets/SVG/BranchPincodeIcon.svg";
import HashLoader from "react-spinners/HashLoader";
import { MuiOtpInput } from "mui-one-time-password-input";
import { logout, UserData } from "../../api/AuthAPI";
import { ReactComponent as UploadImageIcon } from "../../assets/SVG/UploadImageIcon.svg";
import InputAdornment from "@mui/material/InputAdornment";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import DeleteDialog from "../../widgets/DeleteDialog";
import { AiOutlinePoweroff, AiOutlineLogout } from "react-icons/ai";
import { RiLogoutCircleRFill } from "react-icons/ri";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { Password, WifiPassword } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Popconfirm } from "antd";
import OtpInput from "react-otp-input";
const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 10px;
  min-width: 50px;
  margin-inline: auto;
  color: white;
  backgroundcolor: white;
  bordercolor: white;
`;
const BranchesObjects = [
  {
    icon: <BrancheShowIcon />,
    brancheName: "Branch-name # 1",
    brancheText: "  Address - Location - Country - City",
  },
];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const branchObject = {
  branch_name: "",
  branch_address: "",
  branch_contact: "",
  branch_pin_code: "",
  confirm_branch_pin_code: "",
};

const FocusedTextField = (props) => {
  return (
    <CustomTextField
      multiline={props.multiline}
      rows={props.rows}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      key={props.key}
      placeholder={props.placeholder}
      className={props.className}
    />
  );
};
function Branches() {
  const [addBranch, setAddBranche] = useState(false);
  const [addSettings, setAddSettings] = useState(false);
  const [branches, setBranches] = useState([]);

  const [selectedBranchIndex, setSelectedBranchIndex] = useState("");
  const [isVerified, setIsVerified] = useState(0);

  const [showPassword, setShowPassword] = React.useState(false);

  const [branchImage, setBranchImage] = useState("");
  const [oTPNew, setOTPNew] = useState("");
  const [otp, setOtp] = useState("");

  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    location,
    onlyXs,
    onlyMd,
    onlySm,
    userData,
    setIsUserAuthenticated,
    baseUrl,
    mdDown,
    query,
  } = useContext(GlobalData);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleClickSetting = () => {
    setAddSettings(true);
    setAddBranche(false);
  };

  const handleCloseSettings = () => {
    setAddSettings(false);
    setAddBranche(false);
  };

  const handleGetAllBranches = () => {
    let data = {
      search_text: "",
      user_id: userData.id,
    };
    getAllBranchesById(data, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        setBranches(resp.successData.branches);
        if (resp.successData.branches.length == 0) {
          setAddBranche(true);
        }
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  useEffect(() => {
    handleGetAllBranches();
  }, []);

  const handleLoginBranch = (index) => {
    setIsVerified(1);
    window.scrollTo(0, 0);
    setSelectedBranchIndex(index);
  };

  const handleBackToBranches = () => {
    setIsVerified(0);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (otp.length === 4) {
      if (branches[selectedBranchIndex].branch_pin_code === otp) {
        setIsVerified(2);
        setTimeout(() => {
          localStorage.setItem(
            "shopBranch",
            JSON.stringify(branches[selectedBranchIndex])
          );
          localStorage.setItem("InitialLogin", "1");
          showLoader();
        }, 3000);
        setTimeout(() => {
          localStorage.setItem("InitialLogin", "0");
          hideLoader();
          flashMessage("success", "Branch Login Successfully");
        }, 8000);
      } else {
        flashMessage("error", "Wrong Branch Pin Code");
      }
    }
  }, [otp]);

  const [imageUri, setImageUri] = useState("");
  const [imageFile, setImageFile] = useState("");

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [edit, setEdit] = useState(false);

  const [confirmPinCode, setConfirmPinCode] = React.useState(false);

  const [dialogOpenMethod, setDialogOpenMethod] = useState("Add");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openBranchDialog, setOpenBranchDialog] = useState(false);
  const open = Boolean(anchorEl);
  const pinCodeRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [inputOtp, setInputOtp] = useState("");
  const [branch, setBranch] = useState(branchObject);
  const [selectedBranch, setSelectedBranch] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleConfirmPinCode = () => setConfirmPinCode((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showSettingsScreen, setShowSettingsScreen] = useState(false);

  useEffect(() => {
    if (otp.length === 4) {
      if (branches[selectedBranchIndex].branch_pin_code === otp) {
        setIsVerified(2);
        setTimeout(() => {
          localStorage.setItem(
            "shopBranch",
            JSON.stringify(branches[selectedBranchIndex])
          );
          localStorage.setItem("InitialLogin", "1");
          showLoader();
        }, 3000);
        setTimeout(() => {
          localStorage.setItem("InitialLogin", "0");
          hideLoader();
          flashMessage("success", "Branch Login Successfully");
        }, 8000);
      } else {
        flashMessage("error", "Wrong Branch Pin Code");
      }
    }
  }, [otp]);

  useEffect(() => {
    handleGetAllBranches();
  }, []);

  function handleLogout() {
    showLoader();
    localStorage.clear();
    setIsUserAuthenticated(false);
    navigate("login");
    setTimeout(() => {
      hideLoader();
      flashMessage("success", "User Logout Successfully");
    }, 3000);
    logout([], (resp) => {});
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      window.location.reload(true);
    }
    handleClose();
  }

  const handleBranchDialogOpen = (branch) => {
    setAddSettings(false);
    if (branch) {
      setSelectedBranch(branch);

      setEdit(true);
      setBranch({
        branch_name: branch.branch_name,
        branch_address: branch.branch_address,
        branch_contact: branch.branch_contact,
        branch_pin_code: branch.branch_pin_code,
        confirm_branch_pin_code: branch.branch_pin_code,
      });
      setImageFile(null);
      setImageUri(baseUrl + branch.image);
    } else {
      setEdit(false);
      setSelectedBranch("");
      setBranch({
        branch_name: "",
        branch_address: "",
        branch_contact: "",
        branch_pin_code: "",
        confirm_branch_pin_code: "",
      });
      setImageFile(null);
      setImageUri("");
    }
    setOpenBranchDialog(true);
    setAddBranche(true);
  };

  const handleBranchDialogClose = () => {
    setOpenBranchDialog(false);
  };

  const handleBranchInputData = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setBranch({
      ...branch,
      [name]: value,
    });
  };

  const handleInputLenght = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setBranch((prevBranch) => ({
        ...prevBranch,
        [name]: value,
      }));
    }
  };

  function saveImageFile(e) {
    let image = document.getElementById("productImage").files;
    setImageFile(image[0]);
    setImageUri(window.URL.createObjectURL(new Blob(image, { type: "*" })));
  }

  const handleCreateBranch = () => {
    if (branch.branch_name == "") {
      flashMessage("error", "Branch name is required");
      return 0;
    }
    if (branch.branch_contact == "") {
      flashMessage("error", "Branch contact is required");
      return 0;
    }
    if (branch.branch_address == "") {
      flashMessage("error", "Branch address is required");
      return 0;
    }
    if (branch.branch_pin_code == "") {
      flashMessage("error", "Branch pin code is required");
      return 0;
    }
    if (branch.confirm_branch_pin_code == "") {
      flashMessage("error", "Branch confirm pin code is required");
      return 0;
    }

    if (branch.confirm_branch_pin_code != branch.branch_pin_code) {
      flashMessage("error", "Branch confirm pin code does not match");
      return 0;
    }

    showLoader();
    if (dialogOpenMethod === "Add") {
      let formData = new FormData();
      console.log("opx", userData);
      formData.append("shop_id", userData.shop.id);
      formData.append("user_id", userData.id);
      formData.append("branch_name", branch.branch_name);
      formData.append("branch_address", branch.branch_address);
      formData.append("branch_contact", branch.branch_contact);
      formData.append("branch_pin_code", Number(branch.branch_pin_code));
      formData.append(
        "confirm_branch_pin_code",
        Number(branch.confirm_branch_pin_code)
      );
      if (imageFile) {
        formData.append("image", imageFile, imageFile.name);
      }
      createBranch(formData, (resp) => {
        if (resp.statusCode === 200) {
          flashMessage("success", resp.message);
          handleBranchDialogClose();
          handleGetAllBranches();
          setAddBranche(false);
        } else {
          flashMessage("error", resp.message);
          hideLoader();
        }
      });
    } else {
      let formData = new FormData();

      formData.append("branch_name", branch.branch_name);
      formData.append("branch_address", branch.branch_address);
      formData.append("branch_contact", branch.branch_contact);
      formData.append("branch_pin_code", branch.branch_pin_code);

      updateBranchById(formData, (resp) => {
        if (resp.statusCode === 200) {
          handleGetAllBranches();
          setAddBranche(false);
        } else {
          hideLoader();
          flashMessage("error", resp.message);
        }
      });
    }
  };

  const handleOpenDeleteDialog = (branch) => {
    setIsDeleteDialogOpen(true);
    setSelectedBranch(branch);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteBranch = () => {
    showLoader();
    let data = {
      branch_id: selectedBranch.id,
    };
    deleteBranchById(data, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleCloseDeleteDialog();
        handleGetAllBranches();
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const handleUpdateBranch = () => {
    if (branch.branch_name == "") {
      flashMessage("error", "Branch name is required");
      return 0;
    }
    if (branch.branch_contact == "") {
      flashMessage("error", "Branch Contact is required");
      return 0;
    }
    if (branch.branch_address == "") {
      flashMessage("error", "Branch Address is required");
      return 0;
    }
    if (branch.branch_pin_code == "") {
      flashMessage("error", "Branch pin code is required");
      return 0;
    }
    if (branch.confirm_branch_pin_code == "") {
      flashMessage("error", "Branch confirm pin code is required");
      return 0;
    }

    if (branch.confirm_branch_pin_code != branch.branch_pin_code) {
      flashMessage("error", "Branch confirm pin code does not match");
      return 0;
    }

    showLoader();
    let formData = new FormData();
    formData.append("branch_id", selectedBranch.id);
    for (let key of Object.keys(branch)) {
      formData.append(key, branch[key]);
    }
    if (imageFile) {
      formData.append("image", imageFile, imageFile.name);
    }
    updateBranchById(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        handleBranchDialogClose();
        handleGetAllBranches();
        setAddBranche(false);
        flashMessage("success", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  return (
    <>
      <DeleteDialog
        handleClose={handleCloseDeleteDialog}
        showDialog={isDeleteDialogOpen}
        handleRemove={handleDeleteBranch}
        title={"Remove this Branch?"}
        DontRemoveId={"DontRemove__branch"}
        RemoveId={"Remove__Branch"}
        sub_title={
          "You are about to remove branch. Click on the “Remove” button to remove it."
        }
      />
      {isVerified === 2 ? (
        <Box className="loader_pincode__branch">
          <Box mt={-9}>
            <HashLoader color="#ECAE33" size={100} />
          </Box>
        </Box>
      ) : null}
      <Box className="branche__box_M">
        <Box
          className="background_image__branch"
          height={onlyXs ? "300px" : "300px"}
        >
          {isVerified === 1 ? (
            <>
              <Grid container display="flex" justifyContent="center" pt={3}>
                <Box
                  mt={10}
                  className="login_pin_code_popup__branch"
                  sx={{
                    border: {
                      xl: "1px solid rgba(0, 0, 0, 0.2)",
                      lg: "1px solid rgba(0, 0, 0, 0.2)",
                      md: "1px solid rgba(0, 0, 0, 0.2)",
                      sm: "",
                      xs: "",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    className="create_n_branche_txt"
                    pt={3}
                  >
                    <Box>
                      <Box display="flex" justifyContent="center">
                        <img
                          src={BranchPincodeIcon}
                          width="100px"
                          height="100px"
                        />
                      </Box>
                      <Box mt={2}> Branch Pin Code</Box>
                    </Box>
                  </Box>
                  <Box p={4}>
                    <Box display="flex" justifyContent="center">
                      <Box width="300px">
                        {/* <MuiOtpInputStyled
                          TextFieldsProps={{
                            type: "password",
                          }}
                          value={otp}
                          onChange={handleChange}
                        /> */}

                        <ButtonBase>
                          <OtpInput
                            shouldAutoFocus={true}
                            inputType="password"
                            value={otp}
                            onChange={handleChange}
                            numInputs={4}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => (
                              <input
                                {...props}
                                style={{
                                  width: '65px',
                                  height: '50px',
                                  border: "1px rgb(0,0,0,0.5) solid",
                                  borderRadius: '10px',
                                  boxShadow: "none",
                                  outline: "none",
                                  textAlign: 'center'
                                }}
                                autocomplete="new-password"
                              />
                            )}
                          />
                        </ButtonBase>
                      </Box>
                    </Box>

                    {/* <Box display="flex" justifyContent="center">
                      <Button
                        id="branch__continue"
                        className="branche_btn_main"
                      >
                        Continue
                      </Button>
                    </Box> */}
                    <Box display="flex" justifyContent="center" mt={2}>
                      <ButtonBase
                        id="back_to__branch"
                        onClick={handleBackToBranches}
                      >
                        <IoMdArrowDropleft style={{ fontSize: "24px" }} />
                        <span className="text1__branch">Back to branches</span>
                      </ButtonBase>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </>
          ) : null}

          <>
            {isVerified === 0 ? (
              <>
                {smDown ? (
                  <Grid container>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      display="flex"
                      justifyContent="center"
                    >
                      <Box
                        mt={1.5}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box
                          mr={2}
                          className="sm_Box_branches_icon"
                          width="30px"
                          height="30px"
                        >
                          <IconButton
                            onClick={() => {
                              handleBranchDialogOpen(null);
                            }}
                          >
                            <AddIcon
                              fontSize="large"
                              style={{ color: "black", fontSize: "20px" }}
                            />
                          </IconButton>
                        </Box>

                        <Box>
                          <Box
                            display="flex"
                            justifyContent="center"
                            className="branch_wel_txt"
                            fontSize={mdDown ? "18px" : "27px"}
                          >
                            {" "}
                            Welcome to Shopname!
                          </Box>

                          <Box
                            display="flex"
                            justifyContent="center"
                            className="branch_selec_txt"
                            fontSize={mdDown ? "11px" : "15px"}
                          >
                            {" "}
                            {/* Select your desired branch to continue */}
                          </Box>
                        </Box>

                        <Box
                          ml={2}
                          className="sm_Box_branches_icon"
                          width="30px"
                          height="30px"
                        >
                          <IconButton onClick={handleClickSetting}>
                            <SettingsIcon
                              style={{ color: "black", fontSize: "20px" }}
                            />
                          </IconButton>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid
                      item
                      xl
                      width="100%"
                      display="flex"
                      justifyContent="center"
                      position="relative"
                    >
                      <Box paddingTop={mdDown ? "8px" : "40px"} pr={5}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          className="branch_wel_txt"
                          fontSize={mdDown ? "18px" : "27px"}
                        >
                          {" "}
                          Welcome to {userData.shop.brand_name} Shop!
                        </Box>

                        <Box
                          display="flex"
                          justifyContent="center"
                          className="branch_selec_txt"
                          fontSize={mdDown ? "11px" : "15px"}
                        >
                          {" "}
                          {branches.length === 0
                            ? "Create New Branch"
                            : "Select your desired branch to continue"}
                          {/* Select your desired branch to continue */}
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        position="absolute"
                        top="40px"
                        right="40px"
                      >
                        <Box mr={1} className="sm_Box_branches_icon">
                          <Tooltip title="Add Branch">
                            <IconButton
                              onClick={() => {
                                handleBranchDialogOpen(null);
                              }}
                              id="add_branch_btn__branches"
                            >
                              <AddIcon
                                fontSize="large"
                                style={{ color: "black" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>

                        <Box className="sm_Box_branches_icon">
                          <Tooltip title="Settings">
                            <IconButton
                              id="navigate_settings_btn"
                              onClick={handleClickSetting}
                            >
                              <SettingsIcon style={{ color: "black" }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Box className="sm_Box_branches_icon" ml="8px">
                          <Popconfirm
                            title="Logout"
                            description="Are you sure you want to logout?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={handleLogout}
                            okButtonProps={{
                              className:
                                "confirm-logout-btn confirm-logout-btn-hover",
                            }}
                            cancelButtonProps={{
                              className: "confirm-logout-btn-hover",
                            }}
                          >
                            <Tooltip title="Log Out">
                              <IconButton id="navigate_login_btn">
                                <RiLogoutCircleRFill
                                  style={{
                                    fontSize: "34px",
                                    fontWeight: "bold",
                                    color: "black",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Popconfirm>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : null}
          </>

          <Grid
            container
            display="flex"
            justifyContent="center"
            pt={3}
            mt={3}
            mb="40px"
          >
            {addBranch ? (
              <Box
                className="main_new_branche"
                sx={{
                  border: {
                    xl: "1px solid rgba(0, 0, 0, 0.2)",
                    lg: "1px solid rgba(0, 0, 0, 0.2)",
                    md: "1px solid rgba(0, 0, 0, 0.2)",
                    sm: "",
                    xs: "",
                  },
                }}
              >
                <Grid container pt={2}>
                  <Grid
                    item
                    xl={11}
                    lg={11}
                    md={11}
                    sm={11}
                    xs={11}
                    display="flex"
                    justifyContent="center"
                    alignItems="end"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      className="create_n_branche_txt"
                    >
                      {edit ? "Update Branch" : "Create New Branch"}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box className="box_close_icon">
                      <IconButton
                        id="btn_navigate__branches"
                        onClick={() => {
                          setAddBranche(false);
                        }}
                      >
                        <CloseIcon className="ft_sixe" />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
                {/* <Box  display="flex"
                  justifyContent="center"
                  backgroundColor="red"
                  alignItems="center"
                  >
                <Box
                  display="flex"
                  justifyContent="center"
                  className="create_n_branche_txt"
                  pt={3}
                >
                  {edit ? "Update Branch" : "Create New Branch"}
                  
                </Box>
                <Box pl={11}>
                <Box className="box_close_icon">
                  <IconButton
                     onClick={() => {
                      setAddBranche(false);
                    }}
                  >
                    <CloseIcon className="ft_sixe" />
                  </IconButton>
                </Box>
                </Box>
                </Box> */}

                <Box p={4}>
                  <Grid container display="flex" justifyContent="center">
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>Branch Name</div>
                      <CustomTextField
                        placeholder="Add branch name"
                        className="textField__AddInventory"
                        type="text"
                        name="branch_name"
                        id="name_input_field__name"
                        onChange={handleBranchInputData}
                        value={branch.branch_name}
                      />
                    </Grid>
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>Branch Contact</div>
                      {/* <CustomTextField
                        name="branch_contact"
                        id="contact_input_field__contact"
                        placeholder="Add branch contact"
                        className="textField__AddInventory"
                        onChange={handleBranchInputData}
                        value={branch.branch_contact}
                        type="number"
                      /> */}
                      <PhoneInput
                        country={"Us"}
                        value={branch.branch_contact}
                        onChange={(value) => {
                          setBranch({
                            ...branch,
                            ["branch_contact"]: value,
                          });
                        }}
                        placeholder="Branch Contact"
                        inputProps={{
                          name: "branch_contact",
                          id: "branch_contact",
                          key: "branch_contact",
                        }}
                        inputClass="phone_input_search"
                        buttonClass="button_input_field"
                      />
                    </Grid>
                  </Grid>

                  <Grid container display="flex" justifyContent="center" pt={2}>
                    <Grid
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>Branch Address</div>
                      <FocusedTextField
                        multiline={true}
                        rows={3}
                        placeholder={"Add branch address"}
                        className={"textField__services"}
                        name="branch_address"
                        id="address_input_field__branch"
                        onChange={handleBranchInputData}
                        value={branch.branch_address}
                        type="text"
                      />
                    </Grid>
                  </Grid>

                  <Grid container display="flex" justifyContent="center" pt={2}>
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>Branch Pin Code</div>
                      <CustomTextField
                        inputRef={pinCodeRef}
                        inputProps={{ maxLength: 4 }}
                        placeholder="Add branch pin code"
                        className="textField__AddInventory"
                        name="branch_pin_code"
                        id="pin_code_input_field__branch"
                        onChange={handleInputLenght}
                        value={branch.branch_pin_code}
                        type={showPassword ? "number" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff id="show_pin_code__branch" />
                                ) : (
                                  <Visibility id="hide_pin_code__branch" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        // onInput={(e) => {
                        //   e.target.value = Math.max(0, parseInt(e.target.value))
                        //     .toString()
                        //     .slice(0, 4);
                        // }}
                      />
                    </Grid>
                    <Grid
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      item
                      paddingTop={query(0, 0, 2)}
                      paddingRight={query(1, 1, 0)}
                    >
                      <div style={{ marginBottom: "8px" }}>
                        Confirm Pin Code
                      </div>
                      <CustomTextField
                        placeholder="Confirm branch pin code"
                        className="textField__AddInventory"
                        name="confirm_branch_pin_code"
                        id="confirm_pin_code__branch"
                        onChange={handleInputLenght}
                        value={branch.confirm_branch_pin_code}
                        type={confirmPinCode ? "number" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleConfirmPinCode}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {confirmPinCode ? (
                                  <VisibilityOff id="show_confirm_pin_code__branch" />
                                ) : (
                                  <Visibility id="hide_confirm_pin_code__branch" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        // onInput={(e) => {
                        //   e.target.value = Math.max(0, parseInt(e.target.value))
                        //     .toString()
                        //     .slice(0, 4);
                        // }}
                      />
                    </Grid>
                    <Grid container>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Box pt={2}>
                          <div style={{ marginBottom: "8px" }}>Image</div>
                          <input
                            style={{ display: "none" }}
                            id="productImage"
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={saveImageFile}
                          />
                          <label htmlFor="productImage">
                            <Button
                              id="productImage__branch"
                              variant="outlined"
                              component="span"
                              style={{
                                width: "100%",
                                height: "54.5px",
                                backgroundColor: "white",
                                borderColor: "rgb(0,0,0,0.25)",
                                color: "rgb(0,0,0,0.35)",
                                textTransform: "none",
                                fontSize: "16px",
                                justifyContent: "flex-start",
                                borderRadius: "10px",
                              }}
                              endIcon={
                                <Box
                                  sx={
                                    imageUri === ""
                                      ? {
                                          position: "absolute",
                                          right: "20px",
                                          bottom: "8px",
                                        }
                                      : {
                                          position: "absolute",
                                          right: "0px",
                                          bottom: "-10px",
                                        }
                                  }
                                >
                                  {imageUri === "" && <UploadImageIcon />}
                                  {imageUri !== "" && (
                                    <img
                                      alt=""
                                      style={{ borderRadius: "10px" }}
                                      src={imageUri}
                                      width="50px"
                                      height="50px"
                                    />
                                  )}
                                </Box>
                              }
                            >
                              Upload Image
                            </Button>
                          </label>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box display="flex" justifyContent="center">
                    <Button
                      className="branche_btn_main"
                      onClick={edit ? handleUpdateBranch : handleCreateBranch}
                      id={edit ? "branch__update_btn" : "branch__create_btn"}
                    >
                      {edit ? "Update" : "Add"} Branch
                    </Button>
                    {/* <Button
                      id="branch__cancel_btn"
                      className="branche_btn_cancel_main"
                      onClick={() => {
                        setAddBranche(false);
                      }}
                    >
                      Cancel
                    </Button> */}
                  </Box>
                </Box>
              </Box>
            ) : null}

            {addSettings ? (
              <BrancheSetting
                handleCloseSettings={handleCloseSettings}
                handleLogout={handleLogout}
              />
            ) : null}

            {!addBranch && !addSettings && (
              <>
                {isVerified === 0 ? (
                  <Box display="flex" mt={3}>
                    <Grid container>
                      {branches.length <= 4 ? (
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          display="flex"
                          justifyContent="center"
                        >
                          {branches.map((object, index) => (
                            <Box className="braches_card_box_main" m={1}>
                              <ButtonBase
                                onClick={() => {
                                  handleLoginBranch(index);
                                }}
                              >
                                <Box
                                  className="branches_card__box_two"
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  {branchImage != null ? (
                                    <img
                                      alt=""
                                      src={baseUrl + object.image}
                                      className="branch_image_fit_con"
                                      id="Branch__image"
                                    />
                                  ) : (
                                    <BrancheShowIcon />
                                  )}
                                </Box>
                              </ButtonBase>
                              <Box pt={1} pl={0.5}>
                                <Box pt={0.6} className="branche_txt_name">
                                  {object.branch_name}
                                </Box>
                                <Box className="branche_txt_ALCC">
                                  {object.branch_address}
                                </Box>
                                <Box className="branche_txt_ALCC">
                                  {object.branch_contact}
                                </Box>
                              </Box>
                              <Grid
                                container
                                display="flex"
                                justifyContent="center"
                                pt={2}
                              >
                                <Grid
                                  item
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Button
                                    variant="text"
                                    className="delete_branches_btn"
                                    onClick={() => {
                                      handleOpenDeleteDialog(object);
                                    }}
                                    id="branch__delete_btn"
                                  >
                                    Delete
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Button
                                    variant="text"
                                    className="edit_branches_btn"
                                    onClick={() => {
                                      handleBranchDialogOpen(object);
                                    }}
                                    id="branch__edit_btn"
                                  >
                                    Edit
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </Grid>
                      ) : (
                        <Box className="flex_branches">
                          {branches.map((object, index) => (
                            <Box
                              style={{
                                height: "158px",
                                width: "310px",
                                backgroundColor: "white",
                                borderRadius: "15px",
                                margin: "10px",
                                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                              }}
                            >
                              <Grid container>
                                <Grid
                                  item
                                  xl={4}
                                  lg={4}
                                  md={4}
                                  sm={4}
                                  xs={4}
                                  p={1}
                                >
                                  <ButtonBase
                                    onClick={() => {
                                      handleLoginBranch(index);
                                    }}
                                  >
                                    <Box
                                      style={{
                                        backgroundColor: "#96DDB9",
                                        borderRadius: "16px",
                                      }}
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      {branchImage != null ? (
                                        <img
                                          alt=""
                                          src={baseUrl + object.image}
                                          className="branch_above_lenght_image_fit_con"
                                        />
                                      ) : (
                                        <BrancheShowIcon />
                                      )}
                                    </Box>
                                  </ButtonBase>
                                </Grid>
                                <Grid
                                  item
                                  xl={8}
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  xs={8}
                                  p={1}
                                >
                                  <Box>
                                    <Box className="branche_txt_name">
                                      {object.branch_name}
                                    </Box>
                                    <Box className="branche_txt_ALCC">
                                      {object.branch_address}
                                    </Box>
                                    <Box className="branche_txt_ALCC">
                                      {object.branch_contact}
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>

                              <Grid container pt={2}>
                                <Grid
                                  item
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Button
                                    variant="text"
                                    className="delete_branches_btn"
                                    onClick={() => {
                                      handleOpenDeleteDialog(object);
                                    }}
                                    id="branch__delete_btn"
                                  >
                                    Delete
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <Button
                                    variant="text"
                                    className="edit_branches_btn"
                                    onClick={() => {
                                      handleBranchDialogOpen(object);
                                    }}
                                    id="branch__edit_btn"
                                  >
                                    Edit
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Grid>
                  </Box>
                ) : null}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Branches;
