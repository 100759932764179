import "./CustomTable.css";
import React, { useContext, useState, useEffect } from "react";
import TableScrollbar from "react-table-scrollbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {
  Box,
  IconButton,
  Grid,
  Menu,
  MenuItem,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GlobalData } from "../App";
import { AnimatePresence, motion } from "framer-motion";
import ProtectComponent from "../middleware/ProtectComponent";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getOrderById } from "../api/OrderAPI";
import { FcPaid } from "react-icons/fc";
import debitCard from "../assets/Images/debit-card.png";
import creditCard from "../assets/Images/credit-card.png";
import cash from "../assets/Images/cash.png";
import { encrypt, decrypt } from "../middleware/Encryption";
import VisibilityIcon from "@mui/icons-material/Visibility";
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      paddingTop: "-10px",
      height: "40px",
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      paddingTop: "-10px",
      height: "40px",
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      height: "40px",
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

function CustomTable(props) {
  const {
    baseUrl,
    navigate,
    showLoader,
    hideLoader,
    flashMessage,
    userObject,
  } = useContext(GlobalData);

  const [showDesc, setShowDesc] = useState([]);
  const [showAllDesc, setShowAllDesc] = useState(false);
  const [getData, setGetData] = useState(-1);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  useEffect(() => {
    setTimeout(() => {
      setGetData(1);
    }, 1000);
  }, []);

  useEffect(() => {
    if (getData > -1) {
      setShowDesc([...props.data]);
    }
  }, [getData]);

  const handleShowDesc = (index) => {
    if (index === selectedRowIndex) {
      setSelectedRowIndex(index + 1);
    } else {
      setSelectedRowIndex(index);
    }
    setShowDesc((sd) => {
      if (sd[index] !== undefined) {
        if (
          sd[index].showDescription !== undefined
            ? sd[index].showDescription !== "true"
            : true
        ) {
          sd[index].showDescription = "true";
        } else {
          sd[index].showDescription = "false";
        }
      }
      return sd;
    });
  };

  const handleShowPass = (index) => {
    if (props.data[index].user_password.length === 8) {
      props.data[index].user_password = encrypt(
        props.data[index].user_password
      );
      setSelectedRowIndex(1);
    } else {
      props.data[index].user_password = decrypt(
        props.data[index].user_password
      );
      setSelectedRowIndex(0);
    }
  };

  const viewOptionClicked = (d) => {
    props.view(d);
  };

  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  function getDate(created_at) {
    let date = new Date(created_at);
    var d = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var strDate = d + "-" + month + "-" + year;
    return strDate;
  }

  function getTime(created_at) {
    let date = new Date(created_at);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + seconds + " " + ampm;
    return strTime;
  }

  const handleUpdateStatus = (status, id, desc) => {
    props.updateStatus(status, id, desc);
  };

  const handleNavigateToOrderDetail = (data) => {
    if (data["transaction_type"] === "Order Debit") {
      let formData = {
        order_id: data["order_id"],
      };
      showLoader();
      getOrderById(formData, (resp) => {
        if (resp.statusCode === 200) {
          navigate("/orders/order-detail", {
            state: { orderData: resp.successData.order, orderStatus: "All" },
          });
        } else {
          flashMessage("error", resp.message);
          hideLoader();
        }
      });
    } else {
    }
  };

  const handleSelectInventory = (data) => {
    //console.log("this is data", data);
    props.selectedInventoryId(data.id);
  };
  const openChequeStatusDropdown = () => {
    var dropdown = document.getElementById("cheque_status");
    var event = document.createEvent("MouseEvents");
    event.initMouseEvent("mousedown", true, true, window);
    dropdown.dispatchEvent(event);
  };

  return (
    <Box id="table_scrollbar__inventory" mb={20}>
      <TableScrollbar>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ marginBottom: "30px" }}
        >
          <TableHead>
            <TableRow>
              {props.checkBox === true && (
                <TableCell
                  className="header__custom_table fontColorDark_header__custom_table"
                  align="right"
                  style={{ minWidth: "30px" }}
                />
              )}
              {props.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className="header__custom_table fontColorLight_header__custom_table"
                >
                  {column.label}
                </TableCell>
              ))}

              {props.hideActions !== true && (
                <TableCell
                  className="header__custom_table fontColorDark_header__custom_table"
                  align="right"
                  style={{ minWidth: "60px" }}
                >
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody sx={props.data.length > 0 ? {} : { height: "500px" }}>
            <>
              {props.data.length > 0 ? (
                <>
                  {props.data.map((row, index) => {
                    return (
                      <>
                        <span className="row_space__custom_table" />
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          id="body_row__custom_table"
                          className={`${props.rowRed !== undefined &&
                            ((props.rowRed.condition === "="
                            ? row[props.rowRed.id] === props.rowRed.value
                            : props.rowRed.condition === ">"
                            ? row[props.rowRed.id] > props.rowRed.value
                            : props.rowRed.condition === "<"
                            ? row[props.rowRed.id] < props.rowRed.value
                            : props.rowRed.condition === ">="
                            ? row[props.rowRed.id] >= props.rowRed.value
                            : props.rowRed.condition === "<="
                            ? row[props.rowRed.id] <= props.rowRed.value
                            : row[props.rowRed.id] !== props.rowRed.value)
                              ? "mark_red__custom_table"
                              : "")}`}
                        >
                          {props.checkBox === true && (
                            <TableCell
                              style={{
                                borderTopLeftRadius: "20px",
                                borderBottomLeftRadius: "20px",
                                borderLeft: "1.5px solid rgba(0, 0, 0, 0.05)",
                              }}
                              align="right"
                              className="cell__custom_table"
                            >
                              <Checkbox
                                onChange={() => handleSelectInventory(row)}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            </TableCell>
                          )}

                          {props.columns.map((column, ci) => {
                            const value = row[column.id];

                            if (
                              value !== null &&
                              value !== "null" &&
                              value !== ""
                            ) {
                              return (
                                <TableCell
                                  style={
                                    ci === 0 && !props.checkBox
                                      ? {
                                          borderTopLeftRadius: "20px",
                                          borderBottomLeftRadius: "20px",
                                          borderLeft:
                                            "1.5px solid rgba(0, 0, 0, 0.05)",
                                        }
                                      : {}
                                  }
                                  key={column.id}
                                  align={column.align}
                                  className="cell__custom_table"
                                  onClick={() => {
                                    handleNavigateToOrderDetail(row);
                                  }}
                                >
                                  {column.id === "description" &&
                                  row["amount"] === undefined ? (
                                    <>
                                      {row["name"]}
                                      <br />
                                      {value}
                                    </>
                                  ) : column.id === "image" ? (
                                    <>
                                      <img
                                        alt=""
                                        src={baseUrl + value}
                                        width="40px"
                                        height="40px"
                                        style={{
                                          borderRadius: "20px",
                                          border: "1px solid rgba(0,0,0,0.2)",
                                        }}
                                      />
                                    </>
                                  ) : column.id === "sr_no" ? (
                                    <Box
                                      color={`${
                                        row[
                                          "is_any_variant_cost_price_zero"
                                        ] !== undefined &&
                                        row[
                                          "is_any_variant_cost_price_zero"
                                        ] !== null
                                          ? row[
                                              "is_any_variant_cost_price_zero"
                                            ]
                                            ? "red"
                                            : ""
                                          : ""
                                      }`}
                                    >
                                      {row["invoice_code"] !== undefined &&
                                      row["invoice_code"] !== null ? (
                                        <Box display="flex">
                                          {pad(index + 1)}
                                          {row.is_order_has_gift ? (
                                            <FcPaid
                                              size={28}
                                              style={{
                                                marginLeft: "5px",
                                                marginTop: "-5px",
                                              }}
                                            />
                                          ) : null}
                                        </Box>
                                      ) : (
                                        pad(index + 1)
                                      )}
                                    </Box>
                                  ) : column.type === "date" ||
                                    column.type === "date&time" ? (
                                    `${getDate(value)}
                              ${
                                column.type === "date&time"
                                  ? `/ ${getTime(value)}`
                                  : ""
                              }
                              `
                                  ) : (
                                    // --------------------------------------

                                    <>
                                      {column.id === "permissions" ? (
                                        <Box className="lngtxt__dsh">
                                          {value}
                                        </Box>
                                      ) : column.id === "name" ||
                                        column.id === "invoice_code" ? (
                                        <span
                                          onClick={() => {
                                            viewOptionClicked(row);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {value}
                                        </span>
                                      ) : column.id === "status" ? (
                                        <>
                                          {value === "Cancelled" ? (
                                            <>
                                              <Button
                                                variant="outlined"
                                                onClick={() => {
                                                  handleShowDesc(index);
                                                }}
                                                className="ord_st_desc__orders"
                                              >
                                                {value}
                                              </Button>
                                              <AnimatePresence>
                                                <motion.div
                                                  initial={{
                                                    height: "0px",
                                                    width: "0px",
                                                    opacity: 0,
                                                  }}
                                                  animate={
                                                    showAllDesc ||
                                                    (showDesc.length > 0 &&
                                                    showDesc[index] !==
                                                      undefined
                                                      ? showDesc[index]
                                                          .showDescription !==
                                                        undefined
                                                        ? showDesc[index]
                                                            .showDescription ===
                                                          "true"
                                                        : false
                                                      : false)
                                                      ? {
                                                          height: "100%",
                                                          width: "200px",
                                                          opacity: 1,
                                                          transition: {
                                                            duration: 0.5,
                                                          },
                                                        }
                                                      : {
                                                          height: "0px",
                                                          width: "0px",
                                                          opacity: 0,
                                                          transition: {
                                                            duration: 0.5,
                                                          },
                                                        }
                                                  }
                                                  exit={{
                                                    height: "0px",
                                                    width: "0px",
                                                    opacity: 0,
                                                  }}
                                                >
                                                  <br />
                                                  <Box className="ord_desc__orders">
                                                    {
                                                      row[
                                                        "transaction_description"
                                                      ]
                                                    }
                                                  </Box>
                                                </motion.div>
                                              </AnimatePresence>
                                            </>
                                          ) : (
                                            value
                                          )}
                                        </>
                                      ) : value === "cash_in" ? (
                                        "Cash In"
                                      ) : value === "cash_out" ? (
                                        "Cash Out"
                                      ) : column.type === "dropDown" ? (
                                        row["transaction_type"] === "Cheque" ? (
                                          <>
                                            {row["remaining_no_of_days"] < 0 ? (
                                              <Box textAlign="center">
                                                {value}
                                              </Box>
                                            ) : (
                                              <FormControl className="textField_status__bank">
                                                <CustomTextField
                                                  select
                                                  value={value}
                                                  name="status"
                                                  id="cheque_status"
                                                  key="status"
                                                  className="status_txt__bank"
                                                  SelectProps={{
                                                    IconComponent: () => (
                                                      <ExpandMoreIcon
                                                        onClick={() => {
                                                          openChequeStatusDropdown();
                                                        }}
                                                        className="bnk_sts_icon__cstbl"
                                                      />
                                                    ),
                                                  }}
                                                >
                                                  {column.options.map(
                                                    (option) => (
                                                      <MenuItem
                                                        className=""
                                                        key={option}
                                                        value={option}
                                                        onClick={() => {
                                                          handleUpdateStatus(
                                                            option,
                                                            row.id,
                                                            row.transaction_description
                                                          );
                                                        }}
                                                      >
                                                        {option}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </CustomTextField>
                                              </FormControl>
                                            )}
                                          </>
                                        ) : (
                                          <Box textAlign="center">--</Box>
                                        )
                                      ) : column.id === "transaction_type" ? (
                                        <>
                                          <Button
                                            variant="outlined"
                                            onClick={() => {
                                              handleShowDesc(index);
                                            }}
                                            className="ord_st_desc__orders"
                                          >
                                            {value}
                                          </Button>
                                          <AnimatePresence>
                                            <motion.div
                                              initial={{
                                                height: "0px",
                                                width: "0px",
                                                opacity: 0,
                                              }}
                                              animate={
                                                showAllDesc ||
                                                (showDesc.length > 0 &&
                                                showDesc[index] !== undefined
                                                  ? showDesc[index]
                                                      .showDescription !==
                                                    undefined
                                                    ? showDesc[index]
                                                        .showDescription ===
                                                      "true"
                                                    : false
                                                  : false)
                                                  ? {
                                                      height: "100%",
                                                      width: "200px",
                                                      opacity: 1,
                                                      transition: {
                                                        duration: 0.5,
                                                      },
                                                    }
                                                  : {
                                                      height: "0px",
                                                      width: "0px",
                                                      opacity: 0,
                                                      transition: {
                                                        duration: 0.5,
                                                      },
                                                    }
                                              }
                                              exit={{
                                                height: "0px",
                                                width: "0px",
                                                opacity: 0,
                                              }}
                                            >
                                              <br />
                                              <Box className="ord_desc__orders">
                                                {row["cheque_date"] != null ? (
                                                  <>
                                                    {row[
                                                      "cheque_date"
                                                    ].substring(0, 16)}{" "}
                                                    {row[
                                                      "transaction_description"
                                                    ] !== null &&
                                                    row[
                                                      "transaction_description"
                                                    ] !== "" &&
                                                    row[
                                                      "transaction_description"
                                                    ] !== "null"
                                                      ? "-"
                                                      : ""}
                                                  </>
                                                ) : (
                                                  ""
                                                )}{" "}
                                                {row[
                                                  "transaction_description"
                                                ] !== null &&
                                                row[
                                                  "transaction_description"
                                                ] !== "" &&
                                                row[
                                                  "transaction_description"
                                                ] !== "null"
                                                  ? row[
                                                      "transaction_description"
                                                    ]
                                                  : ""}
                                              </Box>
                                            </motion.div>
                                          </AnimatePresence>
                                        </>
                                      ) : column.id ===
                                        "remaining_no_of_days" ? (
                                        row["transaction_type"] === "Cheque" &&
                                        row["transaction_status"] !==
                                          "Withdrawal" ? (
                                          value > 10 ? (
                                            <Box className="chds_crd_scc__cstbl">
                                              {value}
                                            </Box>
                                          ) : value > 5 && value < 11 ? (
                                            <Box className="chds_crd_wrn__cstbl">
                                              {value}
                                            </Box>
                                          ) : value < 0 ? (
                                            <Box className="chds_crd_err__cstbl">
                                              Expired
                                            </Box>
                                          ) : (
                                            <Box className="chds_crd_err__cstbl">
                                              {value}
                                            </Box>
                                          )
                                        ) : (
                                          <Box textAlign="center">--</Box>
                                        )
                                      ) : column.id === "transaction_id" ? (
                                        value === null || value === "" ? (
                                          <Box textAlign="center">--</Box>
                                        ) : (
                                          <Box textAlign="center">{value}</Box>
                                        )
                                      ) : column.id ===
                                        "variant_sale_prices" ? (
                                        <Box className="brlngtxt__dsh">
                                          {value}
                                        </Box>
                                      ) : column.id === "specification" ? (
                                        <Box>
                                          {column.id === "specification" ? (
                                            <Box>
                                              <Box>{value}</Box>
                                              <Box>{row["IMEI"]}</Box>
                                            </Box>
                                          ) : (
                                            <>
                                              {column.id === "profit" ? (
                                                <Box>
                                                  {value} {userObject.currency}
                                                </Box>
                                              ) : row["invoice_code"] !==
                                                  null &&
                                                row["invoice_code"] !==
                                                  undefined &&
                                                column.id === "order_type" ? (
                                                <Box
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  {value}
                                                  &nbsp;
                                                  {value === "Cash" ? (
                                                    <img
                                                      src={cash}
                                                      alt=""
                                                      height="20px"
                                                      width="20px"
                                                    />
                                                  ) : value === "Card" ? (
                                                    <img
                                                      src={debitCard}
                                                      alt=""
                                                      height="20px"
                                                      width="20px"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={creditCard}
                                                      alt=""
                                                      height="20px"
                                                      width="20px"
                                                    />
                                                  )}
                                                </Box>
                                              ) : column.id ===
                                                "user_password" ? (
                                                decrypt(value)
                                              ) : column.id === "description" &&
                                                row["amount"] !== undefined ? (
                                                <Box class="serv_des__serv">
                                                  {value}
                                                </Box>
                                              ) : (
                                                value
                                              )}
                                            </>
                                          )}
                                        </Box>
                                      ) : column.id === "user_password" ? (
                                        value.length > 8 ? (
                                          <Box className="pass_eye__custtbl">
                                            <IconButton
                                              onClick={() => {
                                                handleShowPass(index);
                                              }}
                                            >
                                              <VisibilityIcon />
                                            </IconButton>
                                          </Box>
                                        ) : (
                                          <Box
                                            onClick={() => {
                                              handleShowPass(index);
                                            }}
                                          >
                                            {value}
                                          </Box>
                                        )
                                      ) : (
                                        value
                                      )}
                                    </>

                                    // -------------------------------------
                                  )}
                                </TableCell>
                              );

                              //---------------------------
                            } else {
                              return (
                                <TableCell>
                                  {" "}
                                  <Box textAlign="center">--</Box>
                                </TableCell>
                              );
                            }
                          })}

                          {props.hideActions !== true && (
                            <TableCell
                              style={{
                                borderTopRightRadius: "20px",
                                borderBottomRightRadius: "20px",
                                borderRight: "1.5px solid rgba(0, 0, 0, 0.05)",
                              }}
                              align="right"
                              className="cell__custom_table"
                            >
                              <ActionMenu props={props} row={row} />
                            </TableCell>
                          )}
                        </TableRow>
                      </>
                    );
                  })}
                </>
              ) : (
                <Box
                  width="100%"
                  height="400px"
                  display="flex"
                  pt={3}
                  justifyContent="center"
                  position="absolute"
                >
                  <img src={props.image} height="350px" />
                </Box>
              )}
            </>
          </TableBody>
        </Table>
      </TableScrollbar>
    </Box>
  );
}

const ActionMenu = (aprops) => {
  let props = aprops.props;
  let row = aprops.row;

  const [data, setData] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const ITEM_HEIGHT = 48;

  const open = Boolean(anchorEl);

  const handleClick = (event, product) => {
    setAnchorEl(event.currentTarget);
    setData(product);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuOptionClicked = (method) => {
    if (method === "view") {
      props.view(data);
    } else if (method === "edit") {
      props.edit(data);
    } else if (method === "status") {
      props.status(data);
    } else if (method === "delete") {
      props.delete(data);
    } else if (method === "return") {
      props.return(data);
    } else if (method === "print") {
      props.print(data);
    } else if (method === "viewOrders") {
      props.viewOrders(data);
    } else if (method === "updateFeatures") {
      props.updateFeatures(data);
    } else {
      console.log(method);
    }
    handleClose();
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          handleClick(e, row);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            width: "20ch",
            borderRadius: "10px",
            boxShadow: "none",
            border: "1px solid rgba(0,0,0,0.15)",
          },
        }}
      >
        {row.hasOwnProperty("bank_name") ? (
          <>
            {props.options.map((option, oi) => (
              <>
                {option.method === "edit" ? (
                  <>
                    {row["transaction_description"] !== null &&
                    row["transaction_description"] !== undefined ? (
                      <>
                        {!(
                          row["transaction_description"].includes(
                            "[Staff Module]"
                          ) ||
                          row["transaction_description"].includes(
                            "[Vendor Module]"
                          ) ||
                          row["transaction_description"].includes(
                            "[Partner Module]"
                          )
                        ) ? (
                          <ProtectedMenuItem
                            option={option}
                            index={oi}
                            menuOptionClicked={menuOptionClicked}
                            row={row}
                          />
                        ) : null}
                      </>
                    ) : (
                      <ProtectedMenuItem
                        option={option}
                        index={oi}
                        menuOptionClicked={menuOptionClicked}
                        row={row}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {option.method === "delete" ? (
                      <>
                        {row["reference_id"] !== null &&
                          row["reference_id"] !== undefined &&
                          row["reference_id"] == "0" && (
                            <ProtectedMenuItem
                              option={option}
                              index={oi}
                              menuOptionClicked={menuOptionClicked}
                              row={row}
                            />
                          )}
                      </>
                    ) : (
                      <ProtectedMenuItem
                        option={option}
                        index={oi}
                        menuOptionClicked={menuOptionClicked}
                        row={row}
                      />
                    )}
                  </>
                )}
              </>
            ))}
          </>
        ) : (
          <>
            {props.options.map((option, oi) =>
              row["status"] === "Returned" || row["status"] === "Cancelled" ? (
                option.method !== "status" && option.method !== "edit" ? (
                  <ProtectedMenuItem
                    option={option}
                    index={oi}
                    menuOptionClicked={menuOptionClicked}
                    row={row}
                  />
                ) : null
              ) : option.method !== "status" ||
                (row["status"] !== "Paid" || row["order_type"] !== "Credit") ? (
                <>
                  {option.method === "view" &&
                  row["transaction_credit_order_id"] !== undefined &&
                  row["transaction_credit_order_id"] !== null ? (
                    <ProtectedMenuItem
                      option={option}
                      index={oi}
                      menuOptionClicked={menuOptionClicked}
                      row={row}
                    />
                  ) : option.method !== "view" &&
                    row["transaction_credit_order_id"] !== undefined ? (
                    <ProtectedMenuItem
                      option={option}
                      index={oi}
                      menuOptionClicked={menuOptionClicked}
                      row={row}
                    />
                  ) : row["transaction_credit_order_id"] === undefined ? (
                    <ProtectedMenuItem
                      option={option}
                      index={oi}
                      menuOptionClicked={menuOptionClicked}
                      row={row}
                    />
                  ) : null}
                </>
              ) : null
            )}
          </>
        )}
      </Menu>
    </>
  );
};

const ProtectedMenuItem = ({ option, index, menuOptionClicked, row }) => {
  if (
    [
      "order_code",
      "order_type",
      "invoice_type",
      "warranty_days",
      "total_tax_amount",
    ].every(function(column) {
      return row.hasOwnProperty(column);
    })
  ) {
    if (row["status"] == "Cancelled") {
      if (
        option["method"] == "edit" ||
        option["method"] == "status" ||
        option["method"] == "delete"
      ) {
        return <></>;
      }
    } else if (row["status"] == "Returned") {
      if (
        option["method"] == "edit" ||
        option["method"] == "status" ||
        option["method"] == "delete"
      ) {
        return <></>;
      }
    }
  } else if (
    [
      "bank_name",
      "transaction_id",
      "transaction_type",
      "recipient_name",
      "transaction_amount",
      "remaining_no_of_days",
      "transaction_description",
    ].every(function(column) {
      return row.hasOwnProperty(column);
    })
  ) {
    if (row["transaction_status"] == "Withdrawal") {
      if (
        option["method"] == "edit" ||
        option["method"] == "delete"
      ) {
        return <></>;
      }
    } else if (row["transaction_status"] == "Returned") {
      if (
        option["method"] == "edit" ||
        option["method"] == "delete"
      ) {
        return <></>;
      }
    }
  }

  return (
    <Box
      style={{
        borderTop: `${index === 0 ? "1px solid rgba(0,0,0,0.15)" : ""}`,
        paddingTop: `${index === 0 ? "3px" : ""}`,
      }}
    >
      <ProtectComponent
        id={`action_${option.method}`}
        componentLocation={true}
        componentNavigate={false}
        component={
          <MenuItem
            id={option.id}
            key={option.method}
            selected={option === "Pyxis"}
            onClick={() => {
              menuOptionClicked(option.method);
            }}
            style={{
              borderBottom: "1px solid rgba(0,0,0,0.15)",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Box marginTop={1} marginRight={2}>
              {option.icon}
            </Box>
            {option.type === "icon" && option.label}
          </MenuItem>
        }
      />
    </Box>
  );
};

export default CustomTable;
