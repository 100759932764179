import React from "react";
import { Box, IconButton, Button } from "@mui/material";
import { ReactComponent as LandingIcon } from "../../../assets/SVG/landingCall.svg";
import { ReactComponent as Location } from "../../../assets/SVG/location.svg";

function LandingHeader() {
  return (
    <>
      <Box
        sx={{
          display: {
            xl: "block",
            lg: "block",
            md: "block",
            sm: "none",
            xs: "none",
          },
        }}
      >
        <Box className="header_landing_main">
          <Box
            className="header_btn_con__lndpg"
            sx={{
              justifyContent: {
                xl: "end",
                lg: "end",
                md: "end",
                sm: "center",
                xs: "center",
              },
              paddingRight: {
                xl: "30px",
                lg: "30px",
                md: "30px",
                sm: "0",
                xs: "0",
              },
            }}
          >
            <Box>
              <Button
                className="header_btn1__lndpg"
                variant="outlined"
                startIcon={<Location />}
              >
                UAE
              </Button>
            </Box>

            <Box>
              <Button
                className="header_btn1__lndpg"
                variant="outlined"
                startIcon={<LandingIcon />}
              >
                +01 123 456 7897
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default LandingHeader;
