import React, { useState, useEffect, useContext, useMemo } from "react";
import "./TransferOrders.css";
import {
  Box,
  Grid,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import NavigationBar from "../NavigationBar";
import { GlobalData } from "../../App";
import SearchField from "../../widgets/SearchField";
import DateFilter from "../../widgets/DateFilter";
import { styled } from "@mui/material/styles";

import { ReactComponent as DownloadIcon } from "../../assets/SVG/DownloadIcon.svg";
import { DateRangePicker } from "mui-daterange-picker";
import ProtectComponent from "../../middleware/ProtectComponent";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Icon } from "@mui/material";
import CustomTable from "../../widgets/CustomTable";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import {
  getAllTransferOrders,
  changeTransferOrderStatus,
} from "../../api/TransferOrdersAPI";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { debounce } from "lodash";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    align: "left",
  },
  {
    id: "user_name",
    label: "Vendor Name",
    align: "left",
  },
  {
    id: "order_date",
    label: "Date",
    align: "left",
  },
  // {
  //   id: "type",
  //   label: "Type",
  //   align: "left",
  // },
  // {
  //   id: "description",
  //   label: "Description",
  //   align: "center",
  // },
  {
    id: "status",
    label: "Status",
    align: "left",
  },
];

const options = [
  {
    icon: (
      <PublishedWithChangesIcon
        style={{ opacity: "0.8", marginLeft: "-2.5px" }}
      />
    ),
    label: <span className="margn_left_global__module">Status</span>,
    method: "status",
    type: "icon",
  },
  {
    icon: <EyeIcon />,
    label: "View",
    method: "view",
    type: "icon",
  },
];

function Whatsapp() {
  const [menuAccountsDate, setMenuAccountsDate] = useState(null);
  const [transferDate, setTransferDate] = useState("This Month");
  const [transferOrders, setTransferOrders] = useState([]);
  const [openCashDialog, setOpenCashDialog] = useState(false);
  const [selectedTransferOrder, setSelectedTransferOrder] = useState();
  const [searchText, setSearchText] = useState("");

  const [
    openDateRangePickerForAccounts,
    setOpenDateRangePickerForAccounts,
  ] = useState(false);
  const openMenuTransferDate = Boolean(menuAccountsDate);
  const ITEM_HEIGHT = 48;

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
    mdDown,
    onlyMd,
    baseUrl,
    location,
  } = useContext(GlobalData);

  const HandleGetAllTransferOrderData = () => {
    showLoader();
    let data = {
      date: JSON.stringify(transferDate),
      search_text: searchText,
    };

    getAllTransferOrders(data, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        console.log("hello", resp.successData);
        setTransferOrders(resp.successData.transfer_orders);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const handleChangeTransferOrderStatus = (status) => {
    showLoader();
    let formData = new FormData();
    formData.append("transfer_order_id", selectedTransferOrder.id);
    formData.append("status", status);
    changeTransferOrderStatus(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        HandleGetAllTransferOrderData();
        handleCashDialogClose();
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const handleClickTranferDate = (event) => {
    setMenuAccountsDate(event.currentTarget);
  };
  const handleCloseTranferDate = () => {
    setMenuAccountsDate(null);
  };
  const toggleDateRangerPickerForAccounts = () =>
    setOpenDateRangePickerForAccounts(!openDateRangePickerForAccounts);

  const handleDateRange = (range) => {
    setTimeout(() => {
      toggleDateRangerPickerForAccounts();
    }, 500);
  };

  const handleOpenTransferOrderDialog = (data) => {
    setSelectedTransferOrder(data);
    setOpenCashDialog(true);
  };

  const handleCashDialogClose = () => {
    setOpenCashDialog(false);
  };

  const handleViewOrder = (data) => {
    navigate("/transfer-orders/order-detail", {
      state: {
        orderData: data,
        orderStatus: "",
        path: location.pathname,
        role: "admin",
      },
    });
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    HandleGetAllTransferOrderData(page);
  }, [page, transferDate]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      HandleGetAllTransferOrderData(page);
    }
  };

  useEffect(() => {
    updateDataUsingPageNumber();
  }, [searchText]);

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openCashDialog}
        onClose={handleCashDialogClose}
      >
        <DialogTitle>Change Transfer Order Status</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box display="flex" justifyContent="center">
                <Button
                  onClick={() => {
                    handleChangeTransferOrderStatus("Approved");
                  }}
                  className="transf_btn"
                >
                  Approve
                </Button>
                <Button
                  onClick={() => {
                    handleChangeTransferOrderStatus("Rejected");
                  }}
                  className="transf_btn"
                >
                  Reject
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <NavigationBar
            path={"Dashboard>transfer order Management>Transfer order"}
            paddingBottom={3}
          />
          <Box pt={3}>
            <Grid container marginBottom={2}>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                item
                paddingRight={onlyXs ? "5px" : "10px"}
              >
                <Box className="i-title_tranfer_order">
                  Transfer Orders Management
                </Box>
              </Grid>
            </Grid>

            <Grid container marginBottom={2}>
              <Grid
                xl={6}
                lg={6}
                md={smDown ? 7 : 5}
                sm={smDown ? 7 : 5}
                xs={12}
                item
                className="padn_invoice__icon"
              >
                <SearchField
                  id="search_input_field__order"
                  onChange={updateProductList}
                  tableDataCount={transferOrders.length}
                  totalAvailableDataCount={transferOrders.length}
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={smDown ? 5 : 7}
                sm={smDown ? 5 : 7}
                xs={12}
                item
                display="flex"
                justifyContent={onlyXs ? "center" : "end"}
                paddingTop={onlyXs ? 1 : 0}
                paddingRight={smDown ? "0px" : "10px"}
              >
                {/* <Button
                variant="contained"
                endIcon={<DownloadIcon />}
                className="download_report_btn__orders"
              >
                {onlyXs || onlyMd ? (
                  <div>
                    <div>Download</div>
                    <div style={{ marginTop: "-8px" }}>Report</div>
                  </div>
                ) : (
                  "Download Report"
                )}
              </Button> */}
                <Box pr={1}>
                  <ProtectComponent
                    id="download-all-btn"
                    componentLocation={true}
                    componentNavigate={false}
                    component={
                      <Button
                        variant="contained"
                        endIcon={<ArrowDropDownIcon fontSize="large" />}
                        className="add_order_btn__orders"
                      >
                        {mdDown ? (
                          <div>
                            <div>Download</div>
                            <div style={{ marginTop: "-8px" }}>Report</div>
                          </div>
                        ) : (
                          "Download Report"
                        )}
                      </Button>
                    }
                  />
                </Box>
                <Button
                  variant="contained"
                  endIcon={<DownloadIcon />}
                  className="download_report_btn__orders"
                  onClick={handleClickTranferDate}
                >
                  {typeof transferDate === "string" ? transferDate : "Custom"}
                </Button>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={menuAccountsDate}
                  open={openMenuTransferDate}
                  onClose={handleCloseTranferDate}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 6,
                      width: "20ch",
                      borderRadius: "10px",
                      boxShadow: "none",
                      border: "1px solid rgba(0,0,0,0.15)",
                    },
                  }}
                >
                  {["Today", "This Week", "This Month", "Custom"].map(
                    (option, oi) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        style={
                          oi + 1 < 4
                            ? {
                                borderBottom: "1px solid rgba(0,0,0,0.15)",
                                paddingTop: 0,
                                paddingBottom: 0,
                              }
                            : { paddingTop: 0, paddingBottom: 0 }
                        }
                      >
                        <Box marginTop={1} marginRight={2}>
                          {option}
                        </Box>
                      </MenuItem>
                    )
                  )}
                </Menu>
                {openDateRangePickerForAccounts ? (
                  <Box position="absolute" top="20%" zIndex="5">
                    <DateRangePicker
                      open={openDateRangePickerForAccounts}
                      toggle={toggleDateRangerPickerForAccounts}
                      onChange={(range) => handleDateRange(range)}
                    />
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </Box>
          <Grid container pt={3}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <CustomTable
                view={handleViewOrder}
                columns={columns}
                data={transferOrders}
                options={options}
                status={handleOpenTransferOrderDialog}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Whatsapp;
