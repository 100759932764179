import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, Button, IconButton, ButtonBase } from "@mui/material";
import { ReactComponent as ParkourIcon } from "../../../assets/SVG/parkourExpressIcon.svg";
import { ReactComponent as GlobalIcon } from "../../../assets/SVG/globalIcon.svg";
import salyOne from "../../../assets/Images/salyOne.png";
import { ReactComponent as Location } from "../../../assets/SVG/location.svg";
import Fingerprint from "@mui/icons-material/Fingerprint";
import { GlobalData } from "../../../App";
import cartoonImage from "../../../assets/Images/cartoonImage.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";

const PageShow = ["Overview", "Features", "About", "Contact Us"];
const PageHide = [
  "Overview",
  "Features",
  "About",
  "Contact Us",
  "English",
  "Sign In",
];

function FeaturePoints() {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    smDown,
    onlyXs,
    mdDown,
    onlyMd,
    baseUrl,
    location,
    navigate,
  } = useContext(GlobalData);

  const handleLogin = () => {
    navigate("login");
  };
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: "white", paddingTop: "15px" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {PageHide.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Box onClick={page == "Sign In" ? handleLogin : null}>
                      {page}
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", lg: "flex", md: "flex", sm: "flex" },
                paddingRight: { md: "30px", sm: "280px", xs: "90px" },
              }}
            >
              <ParkourIcon />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {PageShow.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  className="feature_point"
                  sx={{ display: "block" }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              
                <Box display="flex" alignContent="center">
                  <Box
                    sx={{
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                  >
                    <Button
                      sx={{
                        paddingRight: {
                          xl: "40px",
                          lg: "40px",
                          md: "40px",
                        },
                      }}
                      className="header_btn_engls__lndpg"
                      variant="outlined"
                      startIcon={<GlobalIcon />}
                    >
                      English
                    </Button>
                  </Box>

                  <Button
                    sx={{
                      display: {
                        xl: "block",
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                    className="landing_btn"
                    variant="contained"
                    onClick={handleLogin}
                    id="sign_in_btn__landing"
                  >
                    Sign In
                  </Button>
                </Box>
            
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Box padding="15px">
        <Box className="db">
          <Grid container pt={4}>
            <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  width: {
                    sm: "650px",
                  },
                }}
              >
                <Box
                  className="landing_manage_text"
                  sx={{
                    fontSize: {
                      xl: "50px",
                      lg: "50px",
                      md: "45px",
                      sm: "42px",
                      xs: "20px",
                    },
                    paddingLeft: {
                      xl: "70px",
                      lg: "70px",
                      md: "70px",
                      sm: "50px",
                      xs: "30px",
                    },
                  }}
                >
                  Manage your store with ease!
                </Box>
                <Box
                  sx={{
                    fontSize: {
                      xl: "20px",
                      lg: "20px",
                      md: "20px",
                      sm: "15px",
                      xs: "15px",
                    },
                    paddingLeft: {
                      xl: "70px",
                      lg: "70px",
                      md: "70px",
                      sm: "50px",
                      xs: "30px",
                    },
                  }}
                  className="landing_paragh_text"
                >
                  Our simple but powerful shop management system take normal
                  business to skies. One stop for all you managements. Easily
                  manage your inventory, accounts, sales, vendors etc.
                </Box>
              </Box>
              <Box
                display="flex"
                pt={4}
                sx={{
                  paddingLeft: {
                    xl: "70px",
                    lg: "70px",
                    md: "70px",
                    sm: "50px",
                    xs: "30px",
                  },
                }}
                pb={9}
              >
                <Box>
                  <Button
                    sx={{
                      height: {
                        xl: "49px",
                        lg: "49px",
                        md: "49px",
                      },
                      width: {
                        xl: "145px",
                        lg: "145px",
                        md: "145px",
                      },
                    }}
                    className="landing_page_btn"
                  >
                    Get Started
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              xl={7}
              lg={7}
              md={12}
              sm={12}
              xs={12}
              display="flex"
              justifyContent="center"
              pl={10}
            >
              <Box
                position="absolute"
                bottom="0"
                sx={{
                  display: {
                    xl: "block",
                    lg: "block",
                    md: "block",
                    sm: "none",
                    xs: "none",
                  },
                  paddingLeft: {
                    xl: "0",
                    lg: "0",
                    md: "50%",
                    sm: "0",
                    xs: "0",
                  },
                }}
              >
                <img src={cartoonImage} height="480px" />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              position="absolute"
              bottom="0"
              right="36px"
            >
              <Box
                sx={{
                  display: {
                    xl: "none",
                    lg: "none",
                    md: "none",
                    sm: "block",
                    xs: "block",
                  },
                  left: {
                    xl: "0",
                    lg: "0",
                    md: "310px",
                    sm: "0",
                    xs: "0",
                  },
                  top: {
                    xl: "0",
                    lg: "0",
                    md: "280px",
                    sm: "0",
                    xs: "0",
                  },
                }}
              >
                <img src={cartoonImage} height="340px" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default FeaturePoints;
