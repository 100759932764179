import { getWithParams, post, delte } from "./API";
export function getAllStaffMembers(data, callback) {
  getWithParams({ endPoint: "staff/getAllMembers" }, data, function(response) {
    callback(response);
  });
}
export function registerStaffMember(data, callback) {
  post({ endPoint: "staff/createStaffMember" }, data, function(response) {
    callback(response);
  });
}

export function updateStaffMember(data, callback) {
  post({ endPoint: "staff/updateStaffMember" }, data, function(response) {
    callback(response);
  });
}

export function deleteStaffMember(data, callback) {
  delte({ endPoint: "staff/deleteStaffMember" }, data, function(response) {
    callback(response);
  });
}

export function getMemberDetailsById(data, callback) {
  getWithParams({ endPoint: "staff/getStaffMemberById" }, data, function(
    response
  ) {
    callback(response);
  });
}
export function addTransaction(data, callback) {
  post({ endPoint: "staff/transaction/createTransaction" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function deleteTransaction(data, callback) {
  delte({ endPoint: "staff/transaction/deleteTransaction" }, data, function(
    response
  ) {
    callback(response);
  });
}
