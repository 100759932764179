import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TableCell,
  Dialog,
  DialogContent,
  Menu,
} from "@mui/material";
import { IoMdArrowDropleft } from "react-icons/io";
import { styled } from "@mui/material/styles";
import { GlobalData } from "../../App";
import { registerPartner, updatePartner } from "../../api/PartnerAPI";
import NavigationBar from "../NavigationBar";
import { DateRangePicker } from "mui-daterange-picker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getTodayDate, pad } from "../../utils/common";
import PhoneInput from "react-phone-input-2";

const variantMobile = [
  {
    id: "imei",
    label: "IMEI No.",

    align: "left",
  },
  {
    id: "color",
    label: "Color",

    align: "left",
  },
  {
    id: "storage",
    label: "Storage",

    align: "left",
  },
  {
    id: "ram",
    label: "RAM",

    align: "left",
  },
  {
    id: "quantity",
    label: "Quantity",

    align: "left",
  },
  {
    id: "cost_price",
    label: "C.Price",

    align: "left",
  },
  {
    id: "sale_price",
    label: "S.Price",

    align: "left",
  },
  {
    id: "min_sale_price",
    label: "Min-S.Price",

    align: "left",
  },
];

const partnerObject = {
  partner_name: "",
  partner_phone: "",
  partner_address: "",
  partner_shop_name: "",
  partner_percentage: 0,
};

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const CustomTextFieldForDate = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0)`,
      borderRadius: "10px",
    },
  },
});

function AddPartner(props) {
  const {
    smDown,
    query,
    showLoader,
    hideLoader,
    location,
    flashMessage,
    navigate,
  } = useContext(GlobalData);

  const [dateValue, setDateValue] = useState("");
  const [edit, setEdit] = useState(false);
  const [partner, setPartner] = useState(partnerObject);
  const [invoiceCodeDropDown, setInvoiceCodeDropDown] = useState(null);
  const isInvoiceCodeDropDownOpen = Boolean(invoiceCodeDropDown);
  const handleOrderInputChange = (e) => {
    const { name, value } = e.target;
    setPartner({
      ...partner,
      [name]: value,
    });
  };

  useEffect(() => {
    if (location.state) {
      const temp = location.state.partnerData;
      setPartner({
        ...partner,
        partner_name: temp.partner_name,
        partner_phone: temp.partner_phone,
        partner_address: temp.partner_address,
        partner_shop_name: temp.partner_shop_name,
        partner_percentage: temp.partner_percentage,
      });
      setDateValue(temp.joining_date);
      setEdit(true);
    } else {
      console.log("Nothing");
      setDateValue(getTodayDate());
    }
  }, []);
  const handleAddPartner = () => {
    showLoader();
    let formData = new FormData();
    formData.append("partner_name", partner.partner_name);
    formData.append("partner_phone", partner.partner_phone);
    formData.append("partner_address", partner.partner_address);
    formData.append("partner_shop_name", partner.partner_shop_name);
    formData.append("partner_percentage", partner.partner_percentage);

    let date = new Date(dateValue);
    formData.append(
      "joining_date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );

    registerPartner(formData, (resp) => {
      if (resp.statusCode === 200) {
        hideLoader();
        navigate("/partners");
        flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };
  const handleEditPartner = () => {
    showLoader();
    let formData = new FormData();
    console.log("THI IS   DATA TI BE EDITED", partner);
    formData.append("partner_id", location.state.partnerData.id);
    formData.append("partner_name", partner.partner_name);
    formData.append("partner_phone", partner.partner_phone);
    formData.append("partner_address", partner.partner_address);
    formData.append("partner_shop_name", partner.partner_shop_name);
    formData.append("partner_percentage", partner.partner_percentage);

    let date = new Date(dateValue);
    formData.append(
      "joining_date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );

    updatePartner(formData, (resp) => {
      if (resp.statusCode === 200) {
        hideLoader();
        navigate("/partners");
        flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  return (
    <Grid container height="100vh" className="padn_invoice__icon">
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box height={smDown ? "30vh" : "20vh"}>
          {edit ? (
            <NavigationBar
              path={"Dashboard>Partners Management>Partners>Edit Partner"}
              paddingBottom={1}
            />
          ) : (
            <NavigationBar
              path={"Dashboard>Partners Management>Partners>Add New Partner"}
              paddingBottom={1}
            />
          )}

          <Grid display="flex" justifyContent="center" container mb={2} mt={2}>
            <Grid
              className="header__ai"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
            >
              <div
                className="header_back_btn__ai"
                onClick={() => {
                  navigate("partners");
                }}
              >
                <IoMdArrowDropleft className="add__icon_orders_sty" />
                Back
              </div>
              <div className="header_text__ai">
                Partner Management {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                {edit ? (
                  <span style={smDown ? { fontSize: "14px" } : {}}>
                    Edit Partner
                  </span>
                ) : (
                  <span style={smDown ? { fontSize: "14px" } : {}}>
                    Add New Partner
                  </span>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box className="div__scroll" height={smDown ? "70vh" : "80vh"}>
          <Grid container className="card__ai">
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingTop={query(2, 2, 2)}
              paddingBottom={query(3, 3, 3)}
              paddingLeft={query(5, 2, 1)}
              paddingRight={query(5, 2, 1)}
              id="addOrderStyle"
            >
              <Grid container display="flex" justifyContent="center">
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div className="field_text_main_global">Partner Name</div>
                  <CustomTextField
                    value={partner.partner_name}
                    onChange={handleOrderInputChange}
                    name="partner_name"
                    id="partner_name"
                    key="partner_name"
                    placeholder="Partner Name"
                    className="textField__AddInventory"
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div className="field_text_main_global">Phone Number</div>
                  {/* <CustomTextField
                    value={partner.partner_phone}
                    onChange={handleOrderInputChange}
                    name="partner_phone"
                    id="partner_phone"
                    key="partner_phone"
                    placeholder="Partner Phone Number"
                    className="textField__AddInventory"
                    disabled={edit}
                  /> */}
                  <PhoneInput
                    country={"Us"}
                    value={partner.partner_phone}
                    onChange={(value) => {
                      setPartner({
                        ...partner,
                        ["partner_phone"]: value,
                      });
                    }}
                    placeholder="Partner Phone Number"
                    inputProps={{
                      name: "partner_phone",
                      id: "partner_phone",
                      key: "partner_phone",
                    }}
                    inputClass="phone_input_search"
                    buttonClass="button_input_field"
                  />
                </Grid>
              </Grid>

              {partner.invoice_type === "With VAT" ? (
                <Grid container mt={2}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    <div className="field_text_main_global">TRE NO.</div>
                    {/* <CustomTextField
                      value={partner.tre_no}
                      name="tre_no"
                      id="tre_no"
                      key="tre_no"
                      placeholder="Enter TRE Number"
                      className="textField__AddInventory"
                    /> */}
                  </Grid>
                </Grid>
              ) : null}

              <Grid
                container
                display="flex"
                paddingTop={query(2, 2, 0)}
                justifyContent="center"
              >
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div className="field_text_main_global">Partner Address</div>
                  <CustomTextField
                    value={partner.partner_address}
                    name="partner_address"
                    id="partner_address"
                    onChange={handleOrderInputChange}
                    key="partner_address"
                    placeholder="Partner Address"
                    className="textField__AddInventory"
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div className="field_text_main_global">
                    Partner Shop Name
                  </div>
                  <CustomTextField
                    value={partner.partner_shop_name}
                    onChange={handleOrderInputChange}
                    name="partner_shop_name"
                    id="partner_shop_name"
                    key="partner_shop_name"
                    placeholder="Partner Shop Name"
                    className="textField__AddInventory"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                display="flex"
                paddingTop={query(2, 2, 0)}
                paddingBottom={3}
              >
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div className="field_text_main_global">
                    Partner Percentage
                  </div>
                  <CustomTextField
                    value={partner.partner_percentage}
                    name="partner_percentage"
                    id="partner_percentage"
                    onChange={handleOrderInputChange}
                    key="partner_percentage"
                    placeholder="Partner Percentage"
                    className="textField__AddInventory"
                    type="number"
                  />
                </Grid>

                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div className="field_text_main_global">Joining Date</div>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className="bnkmnu_field__bank"
                      value={dateValue}
                      onChange={(newValue) => {
                        setDateValue(newValue);
                      }}
                      renderInput={(params) => (
                        <CustomTextFieldForDate
                          id="joining_date__partner"
                          error={false}
                          {...params}
                          placeholder={"mm/dd/yyyy"}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Grid container paddingTop={5} paddingBottom={5}>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  item
                  paddingLeft={smDown ? 1 : 0}
                  display="flex"
                  justifyContent="start"
                >
                  <Button
                    id="discard_btn__partner"
                    variant="contained"
                    className="footer_dis__ai"
                    onClick={() => {
                      navigate("/partners");
                    }}
                  >
                    Discard
                  </Button>
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  item
                  paddingRight={smDown ? 1 : 0}
                  display="flex"
                  justifyContent="end"
                >
                  {edit ? (
                    <Button
                      id="save_partner_btn__partners"
                      variant="contained"
                      className="footer_add__ai"
                      onClick={handleEditPartner}
                    >
                      Save Partner Changes
                    </Button>
                  ) : (
                    <Button
                      id="add_partner_btn__partner"
                      variant="contained"
                      className="footer_add__ai"
                      onClick={handleAddPartner}
                    >
                      Add Partner
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
export default AddPartner;
