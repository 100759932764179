import { DateRangePicker } from "mui-daterange-picker";
import { MenuItem, Button, Menu, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ReactComponent as DataFiltterIcon } from "../assets/SVG/DataFiltterBtnIcon.svg";

const DateFilter = (props) => {
  const [date, setDate] = useState("This Month");
  const [dateOptions, setDateOptions] = useState([]);

  const [dateMenuElement, setDateMenuElement] = useState(null);
  const isDateMenuOpen = Boolean(dateMenuElement);
  const ITEM_HEIGHT = 48;
  const handleOpenDateMenu = (event) => {
    setDateMenuElement(event.currentTarget);
  };
  const handleCloseDateMenu = () => {
    setDateMenuElement(null);
  };
  //--
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const toggleDateRangerPicker = () =>
    setOpenDateRangePicker(!openDateRangePicker);
  const handleOrdersDateFilter = (option) => {
    handleCloseDateMenu();
    if (option === "Custom") {
      toggleDateRangerPicker();
    } else {
      setDate(option);
    }
  };

  const handleDateRange = (range) => {
    setDateRange(range);
    setDate(range);
    setTimeout(() => {
      toggleDateRangerPicker();
    }, 500);
  };

  useEffect(() => {
    if (props.all) {
      setDate("All");
      setDateOptions(["All", "Today", "This Week", "This Month", "Custom"]);
    } else {
      setDateOptions(["Today", "This Week", "This Month", "Custom"]);
    }
  }, []);

  useEffect(() => {
    props.setDateObject(date);
  }, [date]);

  return (
    <>
      <Button 
        id={props.id}
        variant="contained"
        endIcon={
         <DataFiltterIcon />
        }
        className={props.className}
        onClick={handleOpenDateMenu}
      >
        {typeof date === "string" ? date : "Custom"}
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={dateMenuElement}
        open={isDateMenuOpen}
        onClose={handleCloseDateMenu}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            width: "20ch",
            borderRadius: "10px",
            boxShadow: "none",
            border: "1px solid rgba(0,0,0,0.15)",
          },
        }}
      >
        {dateOptions.map((option, oi) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={() => {
              handleOrdersDateFilter(option);
            }}
            style={
              oi + 1 < 4
                ? {
                    borderBottom: "1px solid rgba(0,0,0,0.15)",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }
                : { paddingTop: 0, paddingBottom: 0 }
            }
          >
            <Box marginTop={1} marginRight={2}>
              {option}
            </Box>
          </MenuItem>
        ))}
      </Menu>
      {openDateRangePicker ? (
        <Box position="fixed" top="20%" left="20%" zIndex="9999">
          <DateRangePicker
            open={openDateRangePicker}
            toggle={toggleDateRangerPicker}
            onChange={(range) => handleDateRange(range)}
          />
        </Box>
      ) : null}
    </>
  );
};

export default DateFilter;
