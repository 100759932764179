import React from "react";
import { Box, Grid } from "@mui/material";
import "../LandingPage.css";
import LpageImage from "../../../assets/Images/LpageImage.png";
import Rectangle from "../../../assets/Images/Rectangle.png";
import working from "../../../assets/Images/working.png";
import accountBLanding from "../../../assets/Images/accountBLanding.png";

// const detailObjects = [
//   {
//     img: require("../../../assets/Images/Rectangle.png"),
//     title: "Accounts and Bank Management",
//     text:
//       "We also offer ocean freight, depending on your choice of transportation from 20ft to 40ft containers. We can also deliver your shipment to your doorstep either a vehicle or bulky item. Terms and conditions applies.",
//   },

// ];

function DetailBox() {
  return (
    <>
      <Box>
        <Box>
          <Box
            className="maim_box"
            sx={{
              paddingTop: {
                xl: "70px",
                lg: "70px",
                md: "70px",
                sm: "50px",
                xs: "50px",
              },
            }}
          >
            <div className="image-container">
              <img src={LpageImage} alt="Responsive Image" className="image" />
            </div>
            <Box
              sx={{
                display: {
                  xl: "block",
                  lg: "block",
                  md: "block",
                  sm: "block",
                  xs: "none",
                },
                paddingTop: {
                  xl: "10px",
                  lg: "20px",
                  md: "30px",
                  sm: "30px",
                },
                paddingRight: {
                  xl: "0",
                  lg: "0",
                  md: "30px",
                  sm: "30px",
                },
              }}
              className="text-container"
            >
              <h1 className="text">Inventory Management</h1>
              <p className="description">
                {" "}
                When you need your shipment at any destination of your choice in
                the shortest possible time; then be rest assured we will fly it
                for you. Forget about paying outrageous shipping fees as we
                offer fast, affordable and stress shipping and free package
                forwarding services. Still thinking? Don't. Just send your next
                package from Amazon, Ebay, Apple ...etc and we will deliver
                right to your doorstep anywhere within out reach. With daily and
                weekly cargo flights, you can be rest assured that we will get
                your goods to their destination in time.
              </p>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "none",
              xs: "block",
            },
          }}
        >
          <h3 className="detailText_md_down">Inventory Management</h3>
          <p className="detailText_md_down_small_tx">
            {" "}
            When you need your shipment at any destination of your choice in the
            shortest possible time; then be rest assured we will fly it for you.
            Forget about paying outrageous shipping fees as we offer fast,
            affordable and stress shipping and free package forwarding services.
            Still thinking? Don't. Just send your next package from Amazon,
            Ebay, Apple ...etc and we will deliver right to your doorstep
            anywhere within out reach. With daily and weekly cargo flights, you
            can be rest assured that we will get your goods to their destination
            in time.
          </p>
        </Box>
      </Box>

      <Box>
        <Box>
          <Box
            className="maim_box"
            sx={{
              paddingTop: {
                xl: "70px",
                lg: "70px",
                md: "70px",
                sm: "50px",
                xs: "50px",
              },
            }}
          >
            <Box
              sx={{
                display: {
                  xl: "block",
                  lg: "block",
                  md: "block",
                  sm: "block",
                  xs: "none",
                },
                paddingTop: {
                  xl: "10px",
                  lg: "20px",
                  md: "30px",
                  sm: "30px",
                },
                paddingLeft: {
                  xl: "0",
                  lg: "0",
                  md: "30px",
                  sm: "30px",
                },
              }}
              className="text-container"
            >
              <h1 className="text">Order and Sales Managenment</h1>
              <p className="description">
                {" "}
                We also offer ocean freight, depending on your choice of
                transportation from 20ft to 40ft containers. We can also deliver
                your shipment to your doorstep either a vehicle or bulky item.
                Terms and conditions applies.
              </p>
            </Box>
            <div className="image-container">
              <img src={Rectangle} alt="Responsive Image" className="image" />
            </div>
          </Box>
        </Box>

        <Box
          sx={{
            display: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "none",
              xs: "block",
            },
          }}
        >
          <h3 className="detailText_md_down">Order and Sales Managenment</h3>
          <p className="detailText_md_down_small_tx">
            {" "}
            We also offer ocean freight, depending on your choice of
            transportation from 20ft to 40ft containers. We can also deliver
            your shipment to your doorstep either a vehicle or bulky item. Terms
            and conditions applies.
          </p>
        </Box>
      </Box>

      <Box>
        <Box>
          <Box
            className="maim_box"
            sx={{
              paddingTop: {
                xl: "70px",
                lg: "70px",
                md: "70px",
                sm: "50px",
                xs: "50px",
              },
            }}
          >
            <div className="image-container">
              <img src={working} alt="Responsive Image" className="image" />
            </div>
            <Box
              sx={{
                display: {
                  xl: "block",
                  lg: "block",
                  md: "block",
                  sm: "block",
                  xs: "none",
                },
                paddingTop: {
                  xl: "10px",
                  lg: "20px",
                  md: "30px",
                  sm: "30px",
                },
                paddingRight: {
                  xl: "0",
                  lg: "0",
                  md: "30px",
                  sm: "30px",
                },
              }}
              className="text-container"
            >
              <h1 className="text">Accounts and Bank Management</h1>
              <p className="description">
                {" "}
                When you need your shipment at any destination of your choice in
                the shortest possible time; then be rest assured we will fly it
                for you. Forget about paying outrageous shipping fees as we
                offer fast, affordable and stress shipping and free package
                forwarding services. Still thinking? Don't. Just send your next
                package from Amazon, Ebay, Apple ...etc and we will deliver
                right to your doorstep anywhere within out reach. With daily and
                weekly cargo flights, you can be rest assured that we will get
                your goods to their destination in time.
              </p>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "none",
              xs: "block",
            },
          }}
        >
          <h3 className="detailText_md_down">Accounts and Bank Management</h3>
          <p className="detailText_md_down_small_tx">
            {" "}
            When you need your shipment at any destination of your choice in the
            shortest possible time; then be rest assured we will fly it for you.
            Forget about paying outrageous shipping fees as we offer fast,
            affordable and stress shipping and free package forwarding services.
            Still thinking? Don't. Just send your next package from Amazon,
            Ebay, Apple ...etc and we will deliver right to your doorstep
            anywhere within out reach. With daily and weekly cargo flights, you
            can be rest assured that we will get your goods to their destination
            in time.
          </p>
        </Box>
      </Box>

      <Box>
        <Box>
          <Box
            className="maim_box"
            sx={{
              paddingTop: {
                xl: "70px",
                lg: "70px",
                md: "70px",
                sm: "50px",
                xs: "50px",
              },
            }}
          >
            <Box
              sx={{
                display: {
                  xl: "block",
                  lg: "block",
                  md: "block",
                  sm: "block",
                  xs: "none",
                },
                paddingTop: {
                  xl: "10px",
                  lg: "20px",
                  md: "30px",
                  sm: "30px",
                },
                paddingLeft: {
                  xl: "0",
                  lg: "0",
                  md: "30px",
                  sm: "30px",
                },
              }}
              className="text-container"
            >
              <h1 className="text">Staff, Vendors, and Partners Management</h1>
              <p className="description">
                {" "}
                Package forwarding is when you order items from online stores
                and ship it to us so you can avoid outrageous international
                shipping fees. Request a service today or contact us for more
                information.
              </p>
            </Box>
            <div className="image-container">
              <img
                src={accountBLanding}
                alt="Responsive Image"
                className="image"
              />
            </div>
          </Box>
        </Box>

        <Box
          sx={{
            display: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "none",
              xs: "block",
            },
          }}
        >
          <h3 className="detailText_md_down">Staff, Vendors Management</h3>
          <p className="detailText_md_down_small_tx">
            {" "}
            Package forwarding is when you order items from online stores and
            ship it to us so you can avoid outrageous international shipping
            fees. Request a service today or contact us for more information.
          </p>
        </Box>
      </Box>
    </>
  );
}

export default DetailBox;
