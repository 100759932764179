import { getWithParams, post, delte } from "./API";

export function getAllVendors(data, callback) {
  getWithParams({ endPoint: "vendor/getAllVendors" }, data, function(response) {
    callback(response);
  });
}
export function registerVendor(data, callback) {
  post({ endPoint: "vendor/registerVendor" }, data, function(response) {
    callback(response);
  });
}

export function deleteVendor(data, callback) {
  delte({ endPoint: "vendor/deleteVendor" }, data, function(response) {
    callback(response);
  });
}

export function updateVendor(data, callback) {
  post({ endPoint: "vendor/updateVendor" }, data, function(response) {
    callback(response);
  });
}

export function getVendorDetailsById(data, callback) {
  getWithParams({ endPoint: "vendor/getVendorDetailsById" }, data, function(
    response
  ) {
    callback(response);
  });
}
export function addTransaction(data, callback) {
  post({ endPoint: "vendor/transaction/createTransaction" }, data, function(
    response
  ) {
    callback(response);
  });
}

export function deleteTransaction(data, callback) {
  delte({ endPoint: "vendor/transaction/deleteTransaction" }, data, function(
    response
  ) {
    callback(response);
  });
}
