import { get, post, getWithParams, delte } from "./API";

export function createTransferOrder(data, callback) {
  post(
    { endPoint: "transfer-order/createTransferOrder" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function getAllTransferOrders(data, callback) {
  getWithParams(
    { endPoint: "transfer-order/getAllTransferOrders" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function getTransferOrderProductsById(data, callback) {
  getWithParams(
    { endPoint: "transfer-order/getTransferOrderProductsById" },
    data,
    function(response) {
      callback(response);
    }
  );
}

export function changeTransferOrderStatus(data, callback) {
  post(
    { endPoint: "transfer-order/changeTransferOrderStatus" },
    data,
    function(response) {
      callback(response);
    }
  );
}
