import React from "react";
import { Box, Grid } from "@mui/material";
import Group from "../../../assets/Images/Group.png";
function LandingFooter() {
  return (
    <>
      <Box className="lp_footer_main">
        <Box className="main_foot_title">Our Increasing Global Prescence</Box>
        <Grid container display="flex" justifyContent="center">
          <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
            <Box className="text_foot_two">
              After experiencing the myriads of challenges sourcing from Asia we
              realised that we were not alone. Our team set out to build this
              platform to make it easy for everyone with a product dream to
              launch. We have experienced manufacturing, procurement, design,
              accounting, shipping and business management experts hailing from
              all across the globe armed with the tools that you can leverage on
              to prepare you for a truly global marketplace where low pricing
              and superior quality is key to competitiveness.
            </Box>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="center" pt={4}>
          <img src={Group} />
        </Box>
      </Box>
    </>
  );
}

export default LandingFooter;
