import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TableCell,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Menu,
  ButtonBase,
  OutlinedInput,
} from "@mui/material";
import React, { useState, useEffect, useContext, useMemo } from "react";
import CustomTable from "../../widgets/CustomTable";
import { GlobalData } from "../../App";
import { ReactComponent as EditIcon } from "../../assets/SVG/EditIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import NavigationBar from "../NavigationBar";
import SearchField from "../../widgets/SearchField";
import DateFilter from "../../widgets/DateFilter";
import PrintIcon from "@mui/icons-material/Print";
import {
  getAllRepairingServices,
  deleteRepairingServiceByID,
  getRepairingServiceByID,
} from "../../api/ServicesRepairingAPI";
import DeleteDialog from "../../widgets/DeleteDialog";
import CreateRepairingService from "./CreateRepairingService";
import { debounce } from "lodash";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    minWidth: 30,
    align: "left",
  },
  {
    id: "name",
    label: "Name",
    minWidth: 30,
    align: "left",
  },
  {
    id: "phone_number",
    label: "Phone Number",
    minWidth: 30,
    align: "left",
  },
  {
    id: "amount",
    label: "Amount",
    minWidth: 30,
    align: "left",
  },
  {
    id: "description",
    label: "Description",
    minWidth: 100,
    align: "left",
  },
  {
    id: "date",
    label: "Date",
    minWidth: 100,
    align: "left",
  },
];

const options = [
  {
    icon: <EyeIcon />,
    label: "View",
    method: "view",
    type: "icon",
  },
  {
    icon: <EditIcon />,
    label: "Edit",
    method: "edit",
    type: "icon",
  },

  {
    icon: <PrintIcon style={{ opacity: "0.7", marginLeft: "-2.5px" }} />,
    label: <span className="margn_left_global__module">Print</span>,
    method: "print",
    type: "icon",
  },
  {
    icon: <DeleteIcon />,
    label: "Delete",
    method: "delete",
    type: "icon",
  },
];

function MobileRepairing() {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
    userObject,
  } = useContext(GlobalData);
  const [servicesDate, setServicesDate] = useState("This Month");
  const [seviceInputsData, setSeviceInputsData] = useState([]);
  const [valueChange, setValueChange] = useState("Edit");
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState("This Month");
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalServicesCount, setTotalServicesCount] = useState(0);
  const [totalMobileServiceAmount, setTotalMobileServiceAmount] = useState(0);

  const [service, setService] = useState("");
  const [serviceDeleteDialog, setServiceDeleteDialog] = useState(false);

  const getAllRepairingMethods = (pn) => {
    let Data = {
      search_text: searchText,
      date: JSON.stringify(date),
      per_page: process.env.REACT_APP_PER_PAGE,
      page: pn,
    };
    showLoader();
    getAllRepairingServices(Data, (resp) => {
      if (resp.statusCode === 200) {
        setTotalMobileServiceAmount(
          resp.successData.total_mobile_service_amount
        );
        setTotalServicesCount(resp.successData.total_services_count);
        if (resp.statusCode === 200) {
          if (pn === 1) {
            setSeviceInputsData(resp.successData.service);
            hideLoader();
          } else {
            setSeviceInputsData((dt) => {
              dt.push(...resp.successData.service);
              hideLoader();
              return dt;
            });
          }
          setLastPage(resp.successData.last_page);
        }
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const deleteRepairingMethod = (id) => {
    showLoader();
    let serviceData = {
      id: 27,
    };
    deleteRepairingServiceByID(serviceData, (resp) => {
      if (resp.statusCode === 200) {
        console.log(resp.successData.service);
        setSeviceInputsData(resp.successData.service);
        hideLoader();
      } else {
        hideLoader();
        flashMessage("error", resp.notValid);
      }
    });
  };

  const getRepairingIDmethod = (id) => {
    showLoader();
    navigate("/create/repairing/service");

    let serviceData = {
      id: 22,
    };
    getRepairingServiceByID(serviceData, (resp) => {
      if (resp.statusCode === 200) {
        console.log(resp.successData.faults);
        hideLoader();
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    getAllRepairingMethods(page);
  }, [page]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      getAllRepairingMethods(page);
    }
  };

  useEffect(() => {
    updateDataUsingPageNumber();
  }, [searchText, date]);

  const handleUpdateService = (row) => {
    navigate("/services/mobile-repairing-services/create", {
      state: { service_id: row.id, edit: true },
    });
  };

  const handleDeleteService = () => {
    showLoader();
    let data = { id: service.id };
    deleteRepairingServiceByID(data, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        updateDataUsingPageNumber();
        handleCloseDeleteServiceDialog();
        flashMessage("error", resp.message);
      } else {
        flashMessage("error", resp.message);
      }
    });
  };

  const handleOpenDeleteServiceDialog = (row) => {
    setService(row);
    setServiceDeleteDialog(true);
  };

  const handleCloseDeleteServiceDialog = () => {
    setServiceDeleteDialog(false);
  };

  return (
    <>
      <DeleteDialog
        handleClose={handleCloseDeleteServiceDialog}
        showDialog={serviceDeleteDialog}
        handleRemove={handleDeleteService}
        title={"Remove this Service?"}
        sub_title={
          "You are about to remove an service. Click on the “Remove” button to remove it."
        }
      />

      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <NavigationBar
              path={"Dashboard>Service Management>Services"}
              paddingBottom={3}
            />

            <Grid container marginBottom={2}>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                item
                paddingRight={onlyXs ? "5px" : "10px"}
              >
                <Box className="i-title">Service Management</Box>
              </Grid>
              <Grid mt={1} item paddingRight={onlyXs ? "5px" : "10px"}>
                <Box className="i-title wrt__invt">
                  Total Mobile Service Worth
                </Box>

                <Box display="flex">
                  <Box className="ttwrt__inv">
                    <Box display="flex" alignItems="end">
                      <span className="mobile_service_amont_ser">
                        {totalMobileServiceAmount}
                      </span>
                      &nbsp;&nbsp;{userObject.currency}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container marginBottom={3} mt={3}>
              <Grid
                xl={6}
                lg={6}
                md={5}
                sm={12}
                xs={12}
                item
                className="padn_invoice__icon"
              >
                <SearchField
                  onChange={updateProductList}
                  tableDataCount={seviceInputsData.length}
                  totalAvailableDataCount={totalServicesCount}
                />
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={7}
                sm={12}
                xs={12}
                item
                display="flex"
                justifyContent={smDown ? "center" : "end"}
                paddingTop={smDown ? 1 : 0}
                paddingRight={smDown ? "0px" : "10px"}
              >
                {/* <Button
                  variant="contained"
                  className="add_order_btn__orders"
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  onClick={() => {
                    deleteRepairingMethod();
                  }}
                >
                  Delete object
                </Button>
                <Button
                  variant="contained"
                  className="add_order_btn__orders"
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  onClick={() => {
                    getRepairingIDmethod();
                  }}
                >
                  Edit object
                </Button> */}

                <Button
                  variant="contained"
                  className="add_order_btn__orders"
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                  onClick={() => {
                    navigate("/services/mobile-repairing-services/create");
                  }}
                >
                  Create Repairing Service
                </Button>

                <DateFilter
                  setDateObject={setDate}
                  dateObject={Date}
                  className={"download_report_btn__orders"}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container pt={4}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <CustomTable
                columns={columns}
                data={seviceInputsData}
                options={options}
                edit={handleUpdateService}
                delete={handleOpenDeleteServiceDialog}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default MobileRepairing;
