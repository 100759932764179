import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TableCell,
  Dialog,
  DialogContent,
  Menu,
} from "@mui/material";
import { IoMdArrowDropleft } from "react-icons/io";
import { styled } from "@mui/material/styles";
import { GlobalData } from "../../App";
import { registerVendor, updateVendor } from "../../api/VendorAPI";
import NavigationBar from "../NavigationBar";
import PhoneInput from "react-phone-input-2";

const variantMobile = [
  {
    id: "imei",
    label: "IMEI No.",

    align: "left",
  },
  {
    id: "color",
    label: "Color",

    align: "left",
  },
  {
    id: "storage",
    label: "Storage",

    align: "left",
  },
  {
    id: "ram",
    label: "RAM",

    align: "left",
  },
  {
    id: "quantity",
    label: "Quantity",

    align: "left",
  },
  {
    id: "cost_price",
    label: "C.Price",

    align: "left",
  },
  {
    id: "sale_price",
    label: "S.Price",

    align: "left",
  },
  {
    id: "min_sale_price",
    label: "Min-S.Price",

    align: "left",
  },
];

const vendorObject = {
  vendor_name: "",
  phone_number: "",
  vendor_address: "",
  shop_name: "",
};

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

function AddVendor(props) {
  const {
    smDown,
    query,
    showLoader,
    hideLoader,
    location,
    flashMessage,
    navigate,
  } = useContext(GlobalData);

  const [edit, setEdit] = useState(false);
  const [vendor, setVendor] = useState(vendorObject);
  const [invoiceCodeDropDown, setInvoiceCodeDropDown] = useState(null);
  const isInvoiceCodeDropDownOpen = Boolean(invoiceCodeDropDown);
  const handleOrderInputChange = (e) => {
    const { name, value } = e.target;
    console.log("orser", e.target);
    setVendor({
      ...vendor,
      [name]: value,
    });
  };
  useEffect(() => {
    if (location.state) {
      console.log("This is data coming to edit", location.state.vendorData);
      const temp = location.state.vendorData;
      setVendor({
        ...vendor,
        vendor_name: temp.vendor_name,
        phone_number: temp.vendor_phone,
        vendor_address: temp.vendor_address,
        shop_name: temp.vendor_shop_name,
      });
      setEdit(true);
    } else {
      console.log("Nothing");
    }
  }, []);
  const handleAddVendor = () => {
    showLoader();
    let formData = new FormData();
    console.log("OrderS", vendor.shop_name);
    formData.append("vendor_name", vendor.vendor_name);
    formData.append("vendor_phone", vendor.phone_number);
    formData.append("vendor_address", vendor.vendor_address);
    formData.append("vendor_shop_name", vendor.shop_name);

    registerVendor(formData, (resp) => {
      if (resp.statusCode === 200) {
        hideLoader();
        navigate("/vendors");
        flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };
  const handleEditVendor = () => {
    showLoader();
    let formData = new FormData();
    formData.append("vendor_name", vendor.vendor_name);
    formData.append("vendor_phone", vendor.phone_number);
    formData.append("vendor_address", vendor.vendor_address);
    formData.append("vendor_shop_name", vendor.shop_name);
    formData.append("vendor_id", location.state.vendorData.id);
    updateVendor(formData, (resp) => {
      if (resp.statusCode === 200) {
        hideLoader();
        navigate("/vendors");
        flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };
  // const handleInvoiceCodeDropDownOpen = (event) => {
  //   setInvoiceCodeDropDown(event.currentTarget);
  // };
  // const handleInvoiceCodeDropDownClose = (value) => {
  //   setInvoiceCodeDropDown(null);
  //   setOrder({
  //     ...order,
  //     ["invoice_code_type"]: value,
  //   });
  // };

  return (
    <Grid container height="100vh" className="padn_invoice__icon">
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box height={smDown ? "30vh" : "20vh"}>
          <NavigationBar
            path={"Dashboard>Vendors Management>Vendors>Add New Vendor"}
            paddingBottom={1}
          />

          <Grid display="flex" justifyContent="center" container mb={2} mt={2}>
            <Grid
              className="header__ai"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
            >
              <div
                className="header_back_btn__ai"
                onClick={() => {
                  navigate("vendors");
                }}
              >
                <IoMdArrowDropleft className="add_vendor_back_icon" />
                Back
              </div>
              <div className="header_text__ai">
                Vendor Management {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
                {edit ? (
                  <span style={smDown ? { fontSize: "14px" } : {}}>
                    Edit Vendor
                  </span>
                ) : (
                  <span style={smDown ? { fontSize: "14px" } : {}}>
                    Add New Vendor
                  </span>
                )}
              </div>
            </Grid>
          </Grid>
        </Box>
        <Box className="div__scroll" height={smDown ? "70vh" : "80vh"}>
          <Grid container className="card__ai">
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingTop={query(2, 2, 2)}
              paddingBottom={query(3, 3, 3)}
              paddingLeft={query(5, 2, 1)}
              paddingRight={query(5, 2, 1)}
              id="addOrderStyle"
            >
              <Grid container display="flex" justifyContent="center">
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div className="field_text_main_global">Vendor Name</div>
                  <CustomTextField
                    value={vendor.vendor_name}
                    onChange={handleOrderInputChange}
                    name="vendor_name"
                    id="vendor_name"
                    key="vendor_name"
                    placeholder="Vendor Name"
                    className="textField__AddInventory"
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div className="field_text_main_global">Phone Number</div>
                  {/* <CustomTextField
                    value={vendor.phone_number}
                    onChange={handleOrderInputChange}
                    name="phone_number"
                    id="phone_number"
                    key="phone_number"
                    placeholder="Phone Number"
                    className="textField__AddInventory"
                    type="number"
                  /> */}
                  <PhoneInput
                    country={"Us"}
                    value={vendor.phone_number}
                    onChange={(value) => {
                      setVendor({
                        ...vendor,
                        ["phone_number"]: value,
                      });
                    }}
                    placeholder="Vendor Phone Number"
                    inputProps={{
                      name: "phone_number",
                      id: "phone_number",
                      key: "phone_number",
                    }}
                    inputClass="phone_input_search"
                    buttonClass="button_input_field"
                  />
                </Grid>
              </Grid>

              {vendor.invoice_type === "With VAT" ? (
                <Grid container mt={2}>
                  <Grid
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    paddingRight={query(1, 1, 0)}
                    item
                  >
                    <div className="field_text_main_global">TRE NO.</div>
                    <CustomTextField
                      value={vendor.tre_no}
                      name="tre_no"
                      id="tre_no"
                      key="tre_no"
                      placeholder="Enter TRE Number"
                      className="textField__AddInventory"
                    />
                  </Grid>
                </Grid>
              ) : null}

              <Grid
                container
                display="flex"
                paddingTop={query(2, 2, 0)}
                justifyContent="center"
                paddingBottom={3}
              >
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div className="field_text_main_global">Address</div>
                  <CustomTextField
                    value={vendor.vendor_address}
                    name="vendor_address"
                    id="vendor_address"
                    onChange={handleOrderInputChange}
                    key="vendor_address"
                    placeholder="Vendor Address"
                    className="textField__AddInventory"
                  />
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  item
                  paddingTop={query(0, 0, 2)}
                  paddingRight={query(1, 1, 0)}
                >
                  <div className="field_text_main_global">Shop Name</div>
                  <CustomTextField
                    value={vendor.shop_name}
                    onChange={handleOrderInputChange}
                    name="shop_name"
                    id="shop_name"
                    key="shop_name"
                    placeholder="Vendor Shop Name"
                    className="textField__AddInventory"
                  />
                </Grid>
              </Grid>

              <Grid container paddingTop={5} paddingBottom={5}>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  item
                  paddingLeft={smDown ? 1 : 0}
                  display="flex"
                  justifyContent="start"
                >
                  <Button
                    id="discard_btn__vendor"
                    variant="contained"
                    className="footer_dis__ai"
                    onClick={() => {
                      navigate("/vendors");
                    }}
                  >
                    Discard
                  </Button>
                </Grid>
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  item
                  paddingRight={smDown ? 1 : 0}
                  display="flex"
                  justifyContent="end"
                >
                  {edit ? (
                    <Button
                      id="save_vendor_changes__vendor"
                      variant="contained"
                      className="footer_add__ai"
                      onClick={handleEditVendor}
                    >
                      Save Vendor Changes
                    </Button>
                  ) : (
                    <Button
                      id="add_vendor__vendors"
                      variant="contained"
                      className="footer_add__ai"
                      onClick={handleAddVendor}
                    >
                      Add Vendor
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
export default AddVendor;
