import React from "react";
import { Box, Grid } from "@mui/material";
import { ReactComponent as GoodIcon } from "../../../assets/SVG/LandingGood.svg";
import LaptopImg from "../../../assets/Images/LaptopImg.png";

function Benefit() {
  return (
    <>
      <Box pt={5} className="benefit_sc_main">
        <Grid container>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <Box pl={12}>
              <Box className="benefit_txt">What Benefit Will You get </Box>
              <Box className="benefit_points">
                <GoodIcon /> &nbsp;{"  "} Free Consulting With Experet Saving
                Money
              </Box>
              <Box className="benefit_points">
                <GoodIcon /> &nbsp;{"  "} Online Banking
              </Box>
              <Box className="benefit_points">
                <GoodIcon /> &nbsp;{"  "} Investment Report Every Month
              </Box>
              <Box className="benefit_points">
                <GoodIcon /> &nbsp;{"  "} Saving Money For The Future
              </Box>
              <Box className="benefit_points">
                <GoodIcon /> &nbsp;{"  "} Online Transection
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            pr={6}
          >
            <Box className="cr_last_lp">
              <img src={LaptopImg} height="400px" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Benefit;
