import React from "react";
import { Box, Button, ButtonBase, Grid } from "@mui/material";
import { ReactComponent as DetailArrow } from "../../../assets/SVG/DetailFooterArrow.svg";
import { ReactComponent as Unsplash } from "../../../assets/SVG/LandingUnsplash.svg";
import { ReactComponent as Notion } from "../../../assets/SVG/LandingNotion.svg";
import { ReactComponent as Intercom } from "../../../assets/SVG/LandingIntercom.svg";
import { ReactComponent as Descript } from "../../../assets/SVG/LandingDescript.svg";
import { ReactComponent as Grammarly } from "../../../assets/SVG/LandingGrammarly.svg";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { IconButton } from "rsuite";
const footerObjects = [
  {
    text: "Unsplash",
    icon: <Unsplash />,
  },
  {
    text: "Notion",
    icon: <Notion />,
  },
  {
    text: "INTERCOM",
    icon: <Intercom />,
  },
  {
    text: "descript",
    icon: <Descript />,
  },
  {
    text: "grammarly",
    icon: <Grammarly />,
  },
];
function MainFooter() {
  const [value, setValue] = React.useState("recents");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className="detail_footer" pb={4} display="flex" alignItems="center">
        <Grid container display="flex" justifyContent="center">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} textAlign="center">
            <Box
              sx={{
                fontSize: { xl: "28px", lg: "28px", md: "28px", xs: "15px" },
                paddingTop: { xl: "20px", lg: "20px", md: "20px", xs: "10px" },
              }}
              className="detail_footer_text"
            >
              More than 2500 shops use{" "}
              <span className="dbl_color">ParkourExpress</span>{" "}
            </Box>
            <Box pt={2}>
              {footerObjects.map((object) => (
                <ButtonBase>
                  <Box
                    ml={3}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {object.icon}
                    &nbsp;{" "}
                    <Box
                      sx={{
                        fontSize: {
                          xl: "20px",
                          lg: "20px",
                          md: "20px",
                          sm: "15px",
                          xs: "15px",
                        },
                      }}
                      style={{ paddingTop: "10px" }}
                    >
                      {object.text}
                    </Box>
                  </Box>
                </ButtonBase>
              ))}
            </Box>

            {/* <BottomNavigation
              sx={{
                paddingTop: { xl: "20px", lg: "20px", md: "20px", xs: "10px" },
              }}
              className="detail_footer"
              value={value}
              onChange={handleChange}
            >
              <BottomNavigationAction
              sx={{minWidth:"0px"}}
                label="Unsplash"
                value="recents"
                icon={<Unsplash />}
              />
              <BottomNavigationAction
               sx={{minWidth:"0px"}}
                label="Notion"
                value="notion"
                icon={<Notion />}
              />
              <BottomNavigationAction
               sx={{minWidth:"0px"}}
                label="INTERCOM"
                value="favorites"
                icon={<Intercom />}
              />
              <BottomNavigationAction
               sx={{minWidth:"0px"}}
                label="Descript"
                value="nearby"
                icon={<Descript />}
              />
              <BottomNavigationAction
               sx={{minWidth:"0px"}}
                label="Grammarly"
                value="folder"
                icon={<Grammarly />}
              />
            </BottomNavigation> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default MainFooter;
