import { get, post, getWithParams } from "./API";

export function moveProductsToOnline(data, callback) {
  post(
    { endPoint: "move/onlineinventory/moveProductsToOnlineInventory" },
    data,
    function(response) {
      callback(response);
    }
  );
}
export function GetProductsbyIdsArray(data, callback) {
  getWithParams(
    {
      endPoint:
        "move/onlineinventoryVariant/getAllOnlineVariantStoreProductsByIds",
    },
    data,
    function(response) {
      callback(response);
    }
  );
}
export function GetOnlineProductsbyIdsArray(data, callback) {
  getWithParams(
    {
      endPoint: "move/onlineinventory/getAllStoreProductsByIds",
    },
    data,
    function(response) {
      callback(response);
    }
  );
}
