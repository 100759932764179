import "./Dashboard.css";
import {
  InventoryWorth,
  DashboardMobile,
  OrderStatus,
  StaffImageDashBoard,
  TsaleImageDashboard,
  TcashImageDashboard,
  TotalCreditImageDashboard,
  TotalDebitImageDashboard,
  TotalCashOut,
} from "../assets/SVG/DashboardSVG";
import { ReactComponent as MainCheque } from "../assets/SVG/MainChequeIcon.svg";
import { ReactComponent as SecndCheque } from "../assets/SVG/SecondaryChequeIcon.svg";
import { Textfit } from "react-textfit";
import { Grid, Box, Button, Menu, MenuItem } from "@mui/material";
import TableScrollbar from "react-table-scrollbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { GlobalData } from "../App";
import React, { useState, useEffect, useContext, useMemo } from "react";
import NavigationBar from "../components/NavigationBar";
import TotalProductPNG from "../assets/Images/totalProduct.png";
import TotalOrdersPNG from "../assets/Images/totalOrders.png";
import AutoScrollContainer from "auto-scroll-container";
import TotalProfit from "../assets/Images/totalProfit.png";
import { getDashboardData } from "../api/DashboardAPI";
import { DateRangePicker } from "mui-daterange-picker";
import { dashboardReport } from "../api/ReportAPI";
import UnpaidDashboard from "../assets/Images/UnpaidDashboard.png";
import { ReactComponent as DownloadIcon } from "../assets/SVG/DownloadIcon.svg";
import DashboardTotalCheque from "../widgets/DashboardTotalCheque";
import DashboardOrderStatus from "../widgets/DashboardOrderStatus";
import DashboardSecndOrdStatus from "../widgets/DashboardSecndOrdStatus";
import DashboardTotalSale from "../widgets/DashboardTotalSale";
import DownloadReportButton from "../widgets/DownloadReportButton";
import MainTitle from "../widgets/MainTitle";
import DateFilter from "../widgets/DateFilter";
import { ReactComponent as PaidIcon } from "../assets/SVG/PaidIconDashboard.svg";
import { ReactComponent as CanceleIcon } from "../assets/SVG/CanceleIconDashboard.svg";
import ReturnedOrderIcon from "../assets/Images/returned_order.png";

import UnPaid from "../assets/Images/Unpaid.png";
function Dashboard(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    onlyXs,
    smDown,
    mdDown,
    onlyMd,
    baseUrl,
    userObject,
  } = useContext(GlobalData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [dashboardDate, setDashboardDate] = useState("");
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [dateRange, setDateRange] = useState({});

  const fittedTextWidth = "120px";

  const ITEM_HEIGHT = 48;

  const [dashboardData, setDashboardData] = useState("");
  const [totalInventory, setTotalInventory] = useState([]);
  const [
    totalInventoryGroupedByCategory,
    setTotalInventoryGroupedByCategory,
  ] = useState([]);
  const [ordersCount, setOrdersCount] = useState({
    Paid: 0,
    Unpaid: 0,
    Cancelled: 0,
    Returned: 0,
    All: 0,
  });

  const [accountInfo, setAccountsInfo] = useState({});

  const [allStaffMembers, setAllStaffMembers] = useState([]);

  const [cheques, setCheques] = useState([]);

  const ordersStatus = ["Paid", "Cancelled", "Unpaid", "Returned"];
  const iconArray = [
    <PaidIcon />,
    <CanceleIcon />,
    <img src={UnPaid} />,
    <img src={ReturnedOrderIcon} />,
  ];

  const ordersIcon = {
    Paid: <PaidIcon />,
    Cancelled: <CanceleIcon />,
    Unpaid: <img src={UnPaid} />,
    Returned: <img src={ReturnedOrderIcon} />,
  };

  const handleGetDashboardData = () => {
    showLoader();
    let formData = new FormData();
    formData.append("date", JSON.stringify(dashboardDate));
    getDashboardData(formData, (resp) => {
      if (resp.statusCode === 200) {
        if (localStorage.getItem("InitialLogin") === "0") {
          hideLoader();
        }
        setDashboardData(resp.successData);
        setTotalInventory(resp.successData.total_inventory);
        setTotalInventoryGroupedByCategory(
          resp.successData.total_inventory_grouped_by_category
        );
        setOrdersCount(resp.successData.orders_count);
        setAllStaffMembers(resp.successData.all_staff_members);
        setAccountsInfo(resp.successData.accounts_info);
        let chequesData = [];
        resp.successData.cheques.forEach((value, index) => {
          if (value.remaining_no_of_days >= 0) {
            chequesData.push(value);
          }
        });
        setCheques(chequesData);
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const toggleDateRangerPicker = () =>
    setOpenDateRangePicker(!openDateRangePicker);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleDashboardDateFilter = (option) => {
  //   handleClose();
  //   if (option === "Custom") {
  //     toggleDateRangerPicker();
  //   } else {
  //     setDashboardDate(option);
  //   }
  // };

  useEffect(() => {
    handleGetDashboardData();
  }, [dashboardDate]);

  // const handleDateRange = (range) => {
  //   setDateRange(range);
  //   setDashboardDate(range);
  //   setTimeout(() => {
  //     toggleDateRangerPicker();
  //   }, 500);
  // };

  const handleDownloadReport = () => {
    showLoader();
    let formData = new FormData();
    formData.append("date", JSON.stringify(dashboardDate));
    dashboardReport(formData, (resp) => {
      if (resp.statusCode === 200) {
        window.open(baseUrl + "public/" + resp.successData.file_path, "_blank");
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  return (
    <Grid container className="container__dsh">
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <NavigationBar path={"Dashboard"} paddingBottom={3} />

        <Grid container marginBottom={2}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item />
        </Grid>

        <Grid container marginBottom={2}>
          <Grid
            xl={6}
            lg={6}
            md={5}
            sm={12}
            xs={12}
            item
            paddingRight={onlyXs ? "5px" : "10px"}
          >
            <MainTitle title={"Dashboard"} />
          </Grid>
          <Grid
            xl={6}
            lg={6}
            md={7}
            sm={12}
            xs={12}
            item
            display="flex"
            justifyContent={smDown ? "center" : "end"}
            paddingTop={smDown ? 1 : 0}
            paddingRight={smDown ? "0px" : "10px"}
          >
            {/* <Button
              variant="contained"
              endIcon={<DownloadIcon />}
              className="download_report_btn__orders"
              onClick={handleClick}
            >
              {typeof dashboardDate === "string" ? dashboardDate : "Custom"}
            </Button> */}
            <Box mr={1}>
              <DateFilter
                id={"date_filter__dashboard"}
                className="download_report_btn__orders"
                setDateObject={setDashboardDate}
                dateObject={dashboardDate}
              />
            </Box>
            <DownloadReportButton
              id={"download_report_account"}
              onClick={handleDownloadReport}
            />
            {/* <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 6,
                  width: "20ch",
                  borderRadius: "10px",
                  boxShadow: "none",
                  border: "1px solid rgba(0,0,0,0.15)",
                },
              }}
            >
              {["Today", "This Week", "This Month", "Custom"].map(
                (option, oi) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={() => {
                      handleDashboardDateFilter(option);
                    }}
                    style={
                      oi + 1 < 4
                        ? {
                            borderBottom: "1px solid rgba(0,0,0,0.15)",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }
                        : { paddingTop: 0, paddingBottom: 0 }
                    }
                  >
                    <Box marginTop={1} marginRight={2}>
                      {option}
                    </Box>
                  </MenuItem>
                )
              )}
            </Menu> */}
            {/* {openDateRangePicker ? (
              <Box position="absolute" top="20%">
                <DateRangePicker
                  open={openDateRangePicker}
                  toggle={toggleDateRangerPicker}
                  onChange={(range) => handleDateRange(range)}
                />
              </Box>
            ) : null} */}
            {/* <Button
              variant="contained"
              style={{ marginLeft: "10px" }}
              endIcon={<DownloadIcon />}
              className="download_report_btn__orders"
              onClick={handleDownloadReport}
            >
              {onlyXs || onlyMd ? (
                <div>
                  <div>Download</div>
                  <div style={{ marginTop: "-8px" }}>Report</div>
                </div>
              ) : (
                "Download Report"
              )}
            </Button> */}
          </Grid>
        </Grid>
        {/*Adding another Box Row At starting*/}
        <Box id="scroll__dsh">
          <TableScrollbar>
            <Table>
              <TableBody>
                <Box
                  className={`scone_con__dsh ${!smDown && "scone_br_con__dsh"}`}
                >
                  <Box
                    className="scone_box_con__dsh"
                    onClick={() => {
                      navigate("bank");
                    }}
                  >
                    <Box className="scone_box__dsh">
                      <Grid container marginLeft="16px">
                        <Grid
                          item
                          className="dashbo_pading_global"
                          paddingRight="10px"
                        >
                          <MainCheque />
                        </Grid>
                        <Grid item className="dashbo_pading_global">
                          <Box>
                            <Box className="dash_global_font">
                              Total No. Of Cheques
                            </Box>
                            <Box className="total_numb_check_box">
                              <Textfit
                                max={18}
                                style={{ width: "200px" }}
                                mode="single"
                              >
                                {cheques.length}
                              </Textfit>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box className="scone_box_con__dsh">
                    {cheques.map((value, index) => (
                      <>
                        {index % 2 === 0 ? (
                          <Box paddingLeft="10px">
                            <DashboardTotalCheque
                              recipient_name={value.recipient_name}
                              RemainingDays={value["remaining_no_of_days"]}
                            />
                            {/* <Box
                              className="scdbone_box__dsh"
                              marginBottom="6px"
                            >
                              <Grid container>
                                <Grid item padding="15px" paddingRight="10px">
                                  <SecndCheque />
                                </Grid>
                                <Grid
                                  item
                                  padding="5px"
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Box
                                    marginTop="-10px"
                                    fontSize="20px"
                                    color="rgba(0,0,0,0.8)"
                                    className="lngtxt__dsh"
                                  >
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        color: "#1E2738",
                                      }}
                                    >
                                      {value.recipient_name}
                                    </span>
                                  </Box>
                                  <span
                                    style={{
                                      marginTop: "-1%",
                                      marginLeft: "5px",
                                      fontSize: "25px",
                                      color: "#1E2738",
                                    }}
                                  >
                                    {" "}
                                    &nbsp;{value["remaining_no_of_days"]}
                                  </span>
                                  <span
                                    style={{
                                      marginTop: "5px",
                                      marginLeft: "10px",
                                      fontSize: "8px",
                                      color: "black",
                                    }}
                                  >
                                    Days Left
                                  </span>
                                </Grid>
                              </Grid>
                            </Box> */}
                            {cheques[index + 1] !== undefined ? (
                              <Box
                                className="scdbone_box__dsh"
                                marginBottom="6px"
                              >
                                <Grid container>
                                  <Grid item padding="15px" paddingRight="10px">
                                    <SecndCheque />
                                  </Grid>
                                  <Grid item className="order_recipt_main_box">
                                    <Box className="lngtxt__dsh">
                                      <span className="lngtxt__dsh_span">
                                        {cheques[index + 1].recipient_name}
                                      </span>{" "}
                                    </Box>
                                    <span className="dashb_recipt_value">
                                      {" "}
                                      &nbsp;
                                      {cheques[index + 1].remaining_no_of_days}
                                    </span>
                                    <span className="dashb_recipt_v_text">
                                      Days Leftpppp
                                      {/* {ordersCount[value]} */}
                                    </span>
                                  </Grid>
                                </Grid>
                              </Box>
                            ) : (
                              <Box className="scdbone_box_emty__dsh" />
                            )}
                          </Box>
                        ) : null}
                      </>
                    ))}
                  </Box>
                </Box>
              </TableBody>
            </Table>
          </TableScrollbar>
        </Box>
        {/* First Box Row Start */}
        <Box id="scroll__dsh">
          <TableScrollbar>
            <Table>
              <TableBody>
                <Box
                  className={`fsone_con__dsh ${!smDown && "fsone_br_con__dsh"}`}
                >
                  <Box
                    className="fsone_box_con__dsh"
                    onClick={() => {
                      navigate("products");
                    }}
                  >
                    <Box className="fsone_box__dsh">
                      <Box>
                        <Grid container marginLeft="16px">
                          <Grid item className="dash_padin_box_two">
                            <InventoryWorth />
                          </Grid>
                          <Grid item className="dashbo_pading_global">
                            <Box>
                              <Box className="dash_global_font">
                                Inventory Worth
                              </Box>
                              <Box fontSize="20px" color="rgba(0,0,0,0.8)">
                                <Textfit
                                  max={18}
                                  style={{ width: "200px" }}
                                  mode="single"
                                >
                                  {dashboardData.total_inventory_worth_cost}{" "}
                                  {userObject.currency}
                                </Textfit>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container marginLeft="16px">
                          <Grid item className="dash_padin_box_two">
                            <img
                              alt=""
                              src={TotalProductPNG}
                              width="55"
                              height="55"
                            />
                          </Grid>
                          <Grid item className="dashbo_pading_global">
                            <Box>
                              <Box className="dash_global_font">
                                Total Products
                              </Box>
                              <Box>
                                <Textfit
                                  max={18}
                                  style={{ width: "200px" }}
                                  mode="single"
                                >
                                  {dashboardData.total_inventory_products_count}{" "}
                                  {userObject.currency}
                                </Textfit>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="fsone_box_con__dsh">
                    {totalInventoryGroupedByCategory.map((value, index) => (
                      <>
                        {index % 2 === 0 ? (
                          <Box paddingLeft="10px">
                            <Box className="fsdbone_box__dsh">
                              <Grid container className="dash_padin_box_one">
                                <Grid item className="dash_padin_box_two">
                                  <img
                                    alt=""
                                    src={baseUrl + value.image}
                                    className="fsdbone_box__dsh_image"
                                  />
                                </Grid>
                                <Grid item className="order_recipt_main_box">
                                  <Box>
                                    <Box className="lngtxt__dsh dash_global_font">
                                      {value.category}
                                    </Box>
                                    <Box
                                      fontSize="20px"
                                      color="rgba(0,0,0,0.8)"
                                    >
                                      <Textfit
                                        max={18}
                                        style={{ width: "210px" }}
                                        mode="single"
                                      >
                                        {value.total_cost !== null
                                          ? value.total_cost
                                          : 0}{" "}
                                        {userObject.currency}
                                      </Textfit>
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          color: "#1E2738",
                                        }}
                                      />

                                      <Textfit
                                        max={18}
                                        style={{ width: "210px" }}
                                        mode="single"
                                      >
                                        {value.quantity !== null
                                          ? value.quantity
                                          : 0}{" "}
                                        Pcs
                                      </Textfit>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            {totalInventoryGroupedByCategory[index + 1] !==
                            undefined ? (
                              <>
                                <Box className="fsdbone_box__dsh">
                                  <Grid
                                    container
                                    className="dash_padin_box_one"
                                  >
                                    <Grid item className="dash_padin_box_two">
                                      {/* <img
                                    alt=""
                                    src={baseUrl + value.image}
                                    className="fsdbone_box__dsh_image"
                                  /> */}

                                      <img
                                        alt=""
                                        src={
                                          baseUrl +
                                          totalInventoryGroupedByCategory[
                                            index + 1
                                          ].image
                                        }
                                        width="55"
                                        height="55"
                                        style={{ borderRadius: "50%" }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      className="order_recipt_main_box"
                                    >
                                      <Box>
                                        <Box className="lngtxt__dsh dash_global_font">
                                          {/* {value.category} */}
                                          {
                                            totalInventoryGroupedByCategory[
                                              index + 1
                                            ].category
                                          }
                                        </Box>
                                        <Box
                                          fontSize="20px"
                                          color="rgba(0,0,0,0.8)"
                                        >
                                          <Textfit
                                            max={18}
                                            style={{ width: "210px" }}
                                            mode="single"
                                          >
                                            {totalInventoryGroupedByCategory[
                                              index + 1
                                            ].total_cost !== null
                                              ? totalInventoryGroupedByCategory[
                                                  index + 1
                                                ].total_cost
                                              : 0}{" "}
                                            {userObject.currency}
                                          </Textfit>
                                          <Textfit
                                            max={18}
                                            style={{ width: "210px" }}
                                            mode="single"
                                          >
                                            {totalInventoryGroupedByCategory[
                                              index + 1
                                            ].quantity !== null
                                              ? totalInventoryGroupedByCategory[
                                                  index + 1
                                                ].quantity
                                              : 0}{" "}
                                            Pcs
                                          </Textfit>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </>
                            ) : (
                              <Box className="fsdbone_box_emty__dsh" />
                            )}
                          </Box>
                        ) : null}
                      </>
                    ))}
                  </Box>
                </Box>
              </TableBody>
            </Table>
          </TableScrollbar>
        </Box>
        {/* First Box Row End */}
        {/* Second Box Row Start */}

        <Box id="scroll__dsh">
          <TableScrollbar>
            <Table>
              <TableBody>
                <Box
                  className={`scone_con__dsh ${!smDown && "scone_br_con__dsh"}`}
                >
                  <Box
                    className="scone_box_con__dsh"
                    onClick={() => {
                      navigate("orders");
                    }}
                  >
                    <Box className="scone_box__dsh">
                      <Grid container marginLeft="16px">
                        <Grid item className="dash_padin_box_two">
                          <img
                            alt=""
                            src={TotalOrdersPNG}
                            width="55"
                            height="55"
                          />
                        </Grid>
                        <Grid item className="dashbo_pading_global">
                          <Box>
                            <Box className="dash_global_font">
                              Total No. Of Orders
                            </Box>
                            <Box className="total_numb_check_box">
                              <Textfit
                                max={18}
                                style={{ width: "200px" }}
                                mode="single"
                              >
                                <span>{ordersCount.All}</span>
                              </Textfit>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box className="scone_box_con__dsh">
                    {ordersStatus.map((value, index) => (
                      <>
                        {index % 2 === 0 ? (
                          <Box paddingLeft="10px">
                            <DashboardOrderStatus
                              ValueOrderIcon={ordersIcon[ordersStatus[index]]}
                              valueText={value}
                              countValue={ordersCount[value]}
                            />
                            {/* <Box
                              className="scdbone_box__dsh"
                              marginBottom="6px"
                            >
                              <Grid container>
                                <Grid item padding="5px" paddingRight="10px">
                                  <OrderStatus />
                                </Grid>
                                <Grid
                                  item
                                  padding="5px"
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Box
                                    marginTop="-10px"
                                    fontSize="20px"
                                    color="rgba(0,0,0,0.8)"
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        color: "#1E2738",
                                      }}
                                    >
                                      {value}
                                    </span>
                                    &nbsp; - &nbsp;
                                    <Textfit
                                      max={18}
                                      style={{ width: "140px" }}
                                      mode="single"
                                    >
                                      {ordersCount[value]}
                                    </Textfit>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box> */}
                            {ordersStatus[index + 1] !== undefined ? (
                              <DashboardSecndOrdStatus
                                OrderIcon={ordersIcon[ordersStatus[index + 1]]}
                                ordersStatusText={ordersStatus[index + 1]}
                                ordersCount={
                                  ordersCount[ordersStatus[index + 1]]
                                }
                              />
                            ) : (
                              <Box className="scdbone_box_emty__dsh" />
                              // <Box
                              //   className="scdbone_box__dsh"
                              //   marginBottom="6px"
                              // >
                              //   <Grid container>
                              //     <Grid item padding="5px" paddingRight="10px">
                              //       <OrderStatus />
                              //     </Grid>
                              //     <Grid
                              //       item
                              //       padding="5px"
                              //       display="flex"
                              //       alignItems="center"
                              //     >
                              //       <Box
                              //         marginTop="-10px"
                              //         fontSize="20px"
                              //         color="rgba(0,0,0,0.8)"
                              //         display="flex"
                              //         alignItems="center"
                              //       >
                              //         <span
                              //           style={{
                              //             fontSize: "14px",
                              //             color: "#1E2738",
                              //           }}
                              //         >
                              //           {ordersStatus[index + 1]}
                              //         </span>
                              //         &nbsp; - &nbsp;
                              //         <Textfit
                              //           max={18}
                              //           style={{ width: "120px" }}
                              //           mode="single"
                              //         >
                              //           {ordersCount[ordersStatus[index + 1]]}
                              //         </Textfit>
                              //       </Box>
                              //     </Grid>
                              //   </Grid>
                              // </Box>
                            )}
                          </Box>
                        ) : null}
                      </>
                    ))}
                  </Box>
                </Box>
              </TableBody>
            </Table>
          </TableScrollbar>
        </Box>

        {/* Second Box Row End */}
        {/* Third Box Row Start */}

        <Box id="scroll__dsh">
          <TableScrollbar>
            <Table>
              <TableBody>
                <Box
                  className={`scone_con__dsh ${!smDown && "scone_br_con__dsh"}`}
                >
                  <Box
                    className="throne_box_con__dsh"
                    onClick={() => {
                      navigate("vendors");
                    }}
                  >
                    <Box>
                      <Box className="throne_box1__dsh" marginBottom="8px">
                        <Grid container className="dash_padin_box_one">
                          <Grid item className="dash_padin_box_two">
                            <TotalCreditImageDashboard />
                          </Grid>
                          <Grid item className="order_recipt_main_box">
                            <Box>
                              <Box className="dash_global_font">
                                Total Today's Credit
                              </Box>
                              <Box className="total_numb_check_box">
                                <Textfit
                                  max={18}
                                  style={{ width: "200px" }}
                                  mode="single"
                                >
                                  {dashboardData.total_vendors_credit}{" "}
                                  {userObject.currency}
                                </Textfit>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="throne_box2__dsh">
                        <Grid container className="dash_padin_box_one">
                          <Grid item className="dash_padin_box_two">
                            <TotalDebitImageDashboard />
                          </Grid>
                          <Grid item className="order_recipt_main_box">
                            <Box>
                              <Box className="dash_global_font">
                                Total Today's Debit
                              </Box>
                              <Box className="total_numb_check_box">
                                <Textfit
                                  max={18}
                                  style={{ width: "200px" }}
                                  mode="single"
                                >
                                  {dashboardData.total_vendors_debit}{" "}
                                  {userObject.currency}
                                </Textfit>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                  <DashboardTotalSale
                    onClick={() => {
                      navigate("accounts");
                    }}
                    // Total Incoming Cash //
                    text_cash={"Total Incoming Cash"}
                    accountInfo_cash={
                      parseFloat(accountInfo.total_orders_sale_bank_amount) +
                      parseFloat(accountInfo.total_bank_vat_amount) +
                      parseFloat(accountInfo.total_debit_amount_bank) +
                      (parseFloat(accountInfo.total_orders_sale_cash_amount) +
                        parseFloat(accountInfo.total_cash_in_amount) +
                        parseFloat(accountInfo.total_cash_vat_amount))
                    }
                    userObject_cash={userObject.currency}
                    // Total Expense //
                    text_expense={"Total Expense"}
                    accountInfo_expense={
                      parseFloat(accountInfo.total_expense_amount_cash) +
                      parseFloat(accountInfo.total_expense_amount_bank)
                    }
                    userObject_expense={userObject.currency}
                    // Total Sale Profit //
                    icon_sale_Profit={TotalProfit}
                    text_sale_Profit={"Total Sale Profit"}
                    accountInfo_sale_profit={
                      accountInfo.total_orders_sale_profit
                    }
                    userObject_sale_profit={userObject.currency}
                    // Total Profit //
                    icon_totalProfit={TotalProfit}
                    text_totalProfit={"Total Profit"}
                    accountInfo_totalProfit={accountInfo.totalProfit}
                    userObject_totalProfit={userObject.currency}
                  />

                  {/* <Box
                    className="throne_box_con__dsh"
                    onClick={() => {
                      navigate("accounts");
                    }}
                  >
                    <Box paddingLeft="10px">
                   <Box>
                   <Box className="thrdbone_box__dsh" marginBottom="6px">
                        <Grid container>
                          <Grid item padding="5px" paddingRight="10px">
                            <TsaleImageDashboard />
                          </Grid>
                          <Grid
                            item
                            padding="5px"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              marginTop="-10px"
                              fontSize="20px"
                              color="rgba(0,0,0,0.8)"
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#1E2738",
                                }}
                              >
                                Total Incoming Cash
                              </span>
                              <Textfit
                                max={18}
                                style={{ width: "230px" }}
                                mode="single"
                              >
                                {parseFloat(
                                  accountInfo.total_orders_sale_bank_amount
                                ) +
                                  parseFloat(
                                    accountInfo.total_bank_vat_amount
                                  ) +
                                  parseFloat(
                                    accountInfo.total_debit_amount_bank
                                  ) +
                                  (parseFloat(
                                    accountInfo.total_orders_sale_cash_amount
                                  ) +
                                    parseFloat(
                                      accountInfo.total_cash_in_amount
                                    ) +
                                    parseFloat(
                                      accountInfo.total_cash_vat_amount
                                    ))}{" "}
                                {userObject.currency}
                              </Textfit>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                       <Box className="thrdbone_box__dsh" marginBottom="6px">
                        <Grid container>
                          <Grid item padding="5px" paddingRight="10px">
                            <TotalCashOut />
                          </Grid>
                          <Grid
                            item
                            padding="5px"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              marginTop="-10px"
                              fontSize="20px"
                              color="rgba(0,0,0,0.8)"
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#1E2738",
                                }}
                              >
                                Total Expense
                              </span>
                              <Textfit
                                max={18}
                                style={{ width: "230px" }}
                                mode="single"
                              >
                                {parseFloat(
                                  accountInfo.total_expense_amount_cash
                                ) +
                                  parseFloat(
                                    accountInfo.total_expense_amount_bank
                                  )}{" "}
                                {userObject.currency}
                              </Textfit>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="thrdbone_box__dsh" marginBottom="6px">
                        <Grid container>
                          <Grid item padding="5px" paddingRight="10px">
                            <img
                              alt=""
                              src={TotalProfit}
                              width="44"
                              height="44"
                            />
                          </Grid>
                          <Grid
                            item
                            padding="5px"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              marginTop="-10px"
                              fontSize="20px"
                              color="rgba(0,0,0,0.8)"
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#1E2738",
                                }}
                              >
                                Total Orders Sale Profit
                              </span>
                              <Textfit
                                max={18}
                                style={{ width: "230px" }}
                                mode="single"
                              >
                                {accountInfo.total_orders_sale_profit}{" "}
                                {userObject.currency}
                              </Textfit>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="thrdbone_box__dsh" marginBottom="6px">
                        <Grid container>
                          <Grid item padding="5px" paddingRight="10px">
                            <img
                              alt=""
                              src={TotalProfit}
                              width="44"
                              height="44"
                            />
                          </Grid>
                          <Grid
                            item
                            padding="5px"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              marginTop="-10px"
                              fontSize="20px"
                              color="rgba(0,0,0,0.8)"
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  color: "#1E2738",
                                }}
                              >
                                Total profit
                              </span>
                              <Textfit
                                max={18}
                                style={{ width: "230px" }}
                                mode="single"
                              >
                                {accountInfo.totalProfit} {userObject.currency}
                              </Textfit>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box> 
                   </Box>
                    </Box>
                  </Box> */}

                  <Box className="throne_box_con__dsh">
                    <Box
                      className="thrstf_box__dsh"
                      onClick={() => {
                        navigate("staff");
                      }}
                    >
                      <Grid container className="dashbord_navi_satff_box_main">
                        <Grid item>
                          <Box display="flex" alignItems="center">
                            <StaffImageDashBoard />
                            <Box paddingLeft="10px">Total Staff</Box>
                          </Box>
                        </Grid>

                        <Grid item>
                          <Box className="member_dash_box">
                            <Box className="member_dash_box_text">
                              {allStaffMembers.length}
                            </Box>
                            <Box>Members</Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box className="staf_mem_box_pading">
                        <hr className="member_hr_sty" />
                      </Box>
                      <AutoScrollContainer marginTop={0.01}>
                        <Box className="stf_con__dsh">
                          {allStaffMembers.map((value, index) => (
                            <Grid container className="all_staff_box">
                              <Grid item>
                                <Box display="flex" alignItems="center">
                                  {value.member_name}
                                </Box>
                              </Grid>

                              <Grid item>
                                <Box display="flex" alignItems="center">
                                  <Box color="rgb(0,0,0,0.8)">
                                    {value.member_phone}
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          ))}
                        </Box>
                      </AutoScrollContainer>
                    </Box>
                  </Box>
                </Box>
              </TableBody>
            </Table>
          </TableScrollbar>
        </Box>
        {/* Third Box Row End */}
      </Grid>
    </Grid>
  );
}

export default Dashboard;
