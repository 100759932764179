import React from "react";
import { Box,Button } from "@mui/material";
function AddNewButton({onClick,id,name}) {
  return (
    <>
      <Button
        id={id}
        onClick={onClick}
        variant="contained"
        className="add_order_btn__orders"
      >
        {name}
      </Button>
      <span style={{ marginLeft: "5px" }} />
    </>
  );
}

export default AddNewButton;
