import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  TableCell,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Menu,
} from "@mui/material";
import { GlobalData } from "../App";
import { ReactComponent as MinusIcon } from "../assets/SVG/MinusRedIconInventory.svg";
import "./ViewInventoryCard.css";
import { styled } from "@mui/material/styles";
import Multiselect from "multiselect-react-dropdown";
// const variantsData=[
//   {var_no:'1',imei:['012','156'],spec:'12 White',s_Price:'',c_Price:'',min_SPrice:''},
//   {var_no:'2',imei:['012','156'],spec:'12 White',s_Price:'',c_Price:'',min_SPrice:''},
//   {var_no:'3',imei:["012","156"],spec:'12 White',s_Price:'',c_Price:'',min_SPrice:''},
// {var_no:'4',imei:["012","156"],spec:'12 White',s_Price:'',c_Price:'',min_SPrice:''}
// ];
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      // borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "5px",
      height: "32.5px",
    },
    "&:hover fieldset": {
      // borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "5px",
      height: "32.5px",
    },
    "&.Mui-focused fieldset": {
      //borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "5px",
      height: "32.5px",
    },
  },
});

function ViewInventoryCard(props) {
  const {
    smDown,
    navigate,
    baseUrl,
    location,
    showLoader,
    flashMessage,
    hideLoader,
    query,
    onlyXs,
  } = useContext(GlobalData);

  const [product, setProduct] = useState(props.product);
  const [variant, setVariant] = useState(props.variants);
  const [variantLength, setVariantLength] = useState(props.variantLength);
  const [tempImei, setTempImei] = useState([]);
  const [imeiArr, setImeiArr] = useState([]);
  const [imeiSelected, setImeiSelected] = useState([]);
  const [showData, setShowData] = useState(false);
  const [quantity, setQuantity] = useState([]);
  const handleRemoveVariant = (index) => {
    const temp = [...variant];
    const x = temp.splice(index, 1);
    setVariant(temp);
    //console.log("The value after removing",temp);
    props.removeVariantValue(temp);
  };

  const handleArrayofImei = () => {
    const arr = [];
    for (var i = 0; i < variant.length; i++) {
      if (variant[i].imei !== null) {
        arr.push(variant[i].imei.split(","));
      }
    }
    console.log("Imei", arr);
    setImeiArr(arr);
  };
  const handleArrayofQuantity = () => {
    const qua = [];
    for (var i = 0; i < variant.length; i++) {
      if (variant[i].quantity !== null) {
        qua.push(variant[i].quantity);
      }
    }
    console.log("Quantity", qua);
    setQuantity(qua);
  };

  const handleChangeProduct = (index, e) => {
    const { name, value } = e.target;
    console.log(variant);
    if (name === "quantity") {
      if (value <= quantity[index]) {
        let variantsData = [...variant];
        variantsData[index]["quantity"] = value;
        setVariant(variantsData);
      } else {
        flashMessage("error", "You cannot Enter greater Quantity");
      }
    } else {
      let variantsData = [...variant];
      variantsData[index][name] = value;
      setVariant(variantsData);
    }

    // setVariant((existingVariant) => {
    //   return existingVariant.map((item, ind) => {
    //     return ind === index ? { ...item, [name]: value } : item;
    //   });
    // });
  };

  useEffect(() => {
    //console.log("variants", variant);
    props.changeProductValue(variant);
  }, [JSON.stringify(variant)]);

  useEffect(() => {
    setProduct(props.product);
    setVariant(props.variants);
    setVariantLength(props.variantLength);
    setShowData(false);
    setImeiSelected([]);
    handleArrayofImei();
    handleArrayofQuantity();
  }, [props.product]);
  // useEffect(() => {
  //   setImeiSelected([]);
  //   handleArrayofImei();
  // }, [props.product.variants]);
  useEffect(() => {
    setShowData(true);
  }, [imeiArr]);
  const handleImeiChange = (selected, index) => {
    //console.log("VAriants wiill Triggerd",variant[index].imei)
    console.log("variants", variant[index]["imei"]);
    let variantsData = [...variant];
    variantsData[index]["imei"] = selected.toString();
    setVariant(variantsData);
    // props.changeProductValue(variant);
    // //console.log("VAriants ",variant[index].imei)
    setImeiSelected(selected);
  };
  const dropStyle = {
    multiselectContainer: {
      padding: "1px",
      height: "auto",
      width: "120%",
    },
    searchBox: {
      // To change search box element look
      border: "none",
      fontSize: "10px",
      width: "100%",
    },
    chips: {
      backgroundColor: "grey",
      height: "15px",
      fontSize: "10px",
    },
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      marginTop={"5px"}
      key={props.product}
    >
      <Grid
        className={"main_back_view"}
        width={"100%"}
        item
        xl={10}
        lg={10}
        md={10}
        sm={12}
        xs={12}
      >
        <Box
          item
          display="flex"
          justifyContent="flex-end"
          className={"first_row_minus"}
        >
          <div>
            <MinusIcon
              width="24"
              height="24"
              onClick={() => {
                props.handleProductRemove();
              }}
            />
          </div>
        </Box>
        <Box display="flex" className={"first_row"}>
          <Box width={"8%"} marginLeft="15px" marginRight="15px">
            <img
              alt=""
              src={baseUrl + product.image}
              width="80px"
              height="80px"
              style={{
                borderRadius: "50%",
                border: "1px solid rgba(0,0,0,0.2)",
              }}
            />
          </Box>
          <Box
            display="flex"
            width={"80%"}
            justifyContent="space-around"
            paddingLeft="8px"
          >
            <Grid item>
              <div className={"head_name_inv"}>Product Name</div>
              <div className={"descp_name_inv"}>{product.name}</div>
            </Grid>
            <Grid item>
              <div className={"head_name_inv"}>Source</div>
              <div className={"descp_name_inv"}>{product.source}</div>
            </Grid>
            <Grid item>
              <div className={"head_name_inv"}>Material</div>
              <div className={"descp_name_inv"}>{product.material}</div>
            </Grid>
            <Grid item>
              <div className={"head_name_inv"}>Category</div>
              <div className={"descp_name_inv"}>{product.category}</div>
            </Grid>
          </Box>
        </Box>

        {/* ====================================Variants  starts Here */}

        <Grid>
          {variant &&
            variant.map((variant, ind) => (
              <>
                {showData ? (
                  <Box className={"first_row"}>
                    <Box className={"variant-head"}>Variant # {variant.id}</Box>
                    <Box container display="flex" height="100%">
                      <Box className={"var_box_back"} width="95%">
                        <Box
                          className={"var_box_row1"}
                          display="flex"
                          justifyContent={"space-evenly"}
                        >
                          <Grid
                            item
                            width={"25%"}
                            paddingLeft={"2px"}
                            paddingBottom={"2px"}
                            paddingRight={"2px"}
                          >
                            <div className={"var_box_head"}>
                              {product.category === "Mobile" ||
                              product.category === "Laptop"
                                ? " IMEI No."
                                : "Color"}
                            </div>
                            {product.category === "Mobile" ||
                            product.category === "Laptop" ? (
                              <div className="var_inp_imei">
                                <Multiselect
                                  isObject={false}
                                  options={variant.imei.split(",")}
                                  style={dropStyle}
                                  selectedValues={variant.imei.split(",")}
                                  hidePlaceholder={true}
                                  keepSearchTerm={false}
                                  onRemove={(value) => {
                                    handleImeiChange(value, ind);
                                  }}
                                  onSelect={(value) => {
                                    handleImeiChange(value, ind);
                                  }}
                                />
                              </div>
                            ) : (
                              <Box
                                className={"var_inp_clr"}
                                paddingLeft={"15px"}
                                paddingTop={"5px"}
                              >
                                {variant.color}
                              </Box>
                            )}
                          </Grid>
                          {product.category === "Mobile" ||
                          product.category === "Laptop" ? (
                            <Grid
                              item
                              width={"30%"}
                              paddingLeft={"2px"}
                              paddingRight={"2px"}
                            >
                              <div className={"var_box_head"}>
                                Specifications
                              </div>
                              <Box
                                className={"var_inp_spec"}
                                paddingLeft={"8px"}
                                paddingBottom={"-10px"}
                              >
                                {product.category === "Mobile" ||
                                product.category === "Laptop" ? (
                                  <div>
                                    color:{variant.color},RAM:{variant.ram}
                                    <br />
                                    Storage:{variant.storage}
                                  </div>
                                ) : variant.description !== null ? (
                                  <div>
                                    {variant.description.substring(0, 35)}...
                                  </div>
                                ) : (
                                  <div>null...</div>
                                )}
                              </Box>
                            </Grid>
                          ) : (
                            <></>
                          )}

                          <Grid
                            item
                            width={"15%"}
                            paddingLeft={"2px"}
                            paddingRight={"2px"}
                          >
                            <div className={"var_box_head"}>Quantity</div>
                            <CustomTextField
                              inputProps={{
                                style: {
                                  padding: 5,
                                },
                              }}
                              value={variant.quantity}
                              onChange={(e) => {
                                handleChangeProduct(ind, e);
                              }}
                              name={"quantity"}
                              type={"numeric"}
                              disabled={false}
                              // id={props.id}
                              //  key={variant.quantity}
                              className={"var_inp_quan"}
                            />
                          </Grid>

                          <Grid
                            item
                            width={"20%"}
                            paddingLeft={"2px"}
                            paddingRight={"2px"}
                          >
                            <div className={"var_box_head"}>C.Price</div>
                            <CustomTextField
                              inputProps={{
                                style: {
                                  padding: 5,
                                },
                              }}
                              value={variant.cost_price}
                              onChange={(e) => handleChangeProduct(ind, e)}
                              name={"cost_price"}
                              // id={props.id}
                              // key={variant.cost_price}
                              placeholder={"name"}
                              className={"var_inp"}
                            />
                          </Grid>

                          <Grid
                            item
                            width={"20%"}
                            paddingLeft={"2px"}
                            paddingRight={"2px"}
                          >
                            <div className={"var_box_head"}>S.Price</div>
                            <CustomTextField
                              inputProps={{
                                style: {
                                  padding: 5,
                                },
                              }}
                              value={variant.sale_price}
                              onChange={(e) => handleChangeProduct(ind, e)}
                              name={"sale_price"}
                              // id={props.id}
                              // key={variant.sale_price}
                              placeholder={"S.Price"}
                              className={"var_inp"}
                            />
                          </Grid>
                          <Grid
                            item
                            width={"20%"}
                            paddingLeft={"2px"}
                            paddingRight={"2px"}
                          >
                            <div className={"var_box_head"}>Min-S.Price</div>
                            <CustomTextField
                              inputProps={{
                                style: {
                                  padding: 5,
                                },
                              }}
                              value={variant.min_sale_price}
                              onChange={(e) => handleChangeProduct(ind, e)}
                              name={"min_sale_price"}
                              //  id={"sale"}
                              // key={variant.min_sale_price}
                              placeholder={"S.Price"}
                              className={"var_inp"}
                            />
                          </Grid>
                        </Box>
                      </Box>
                      <Box width="10%" marginTop="50px">
                        <MinusIcon
                          width="20"
                          height="20"
                          onClick={() => {
                            handleRemoveVariant(ind);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ViewInventoryCard;
