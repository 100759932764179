import "./Accounts.css";
import { Grid, Box, Card, ButtonBase, Button } from "@mui/material";
import { GlobalData } from "../App";
import NavigationBar from "./NavigationBar";
import { getAccountData } from "../api/AccountsAPI";
import React, { useState, useEffect, useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import DateFilter from "../widgets/DateFilter";
import { ReactComponent as OthersSmallIcon } from "../assets/SVG/AccountOthersSmallIcon.svg";
import { ReactComponent as TotalCredit } from "../assets/SVG/TotalCredit.svg";
import { ReactComponent as TotalSale } from "../assets/SVG/TotalSale.svg";
import { ReactComponent as TotalBankSale } from "../assets/SVG/TotalBankSale.svg";
import { ReactComponent as TotalCash } from "../assets/SVG/TotalCash.svg";
import { ReactComponent as UtilityBills } from "../assets/SVG/UtilityBills.svg";
import { ReactComponent as Purchase } from "../assets/SVG/Purchase.svg";
import { ReactComponent as TotalSalaries } from "../assets/SVG/TotalSalaries.svg";
import { ReactComponent as Taxation } from "../assets/SVG/Taxation.svg";
import { ReactComponent as Credits } from "../assets/SVG/Credits.svg";
import { ReactComponent as TotalBalance } from "../assets/SVG/TotalBalance.svg";
import { ReactComponent as TotalProfit } from "../assets/SVG/TotalProfit.svg";
import { ReactComponent as Cheques } from "../assets/SVG/Cheques.svg";
import { ReactComponent as GiftedOrders } from "../assets/SVG/GiftedOrders.svg";
import Divider from "@mui/material/Divider";
import { ReactComponent as DownloadIcon } from "../assets/SVG/DowloadIconWhite.svg";
import { accountReport } from "../api/ReportAPI";
import DownloadReportButton from "../widgets/DownloadReportButton";
import MainTitle from "../widgets/MainTitle";

const userObject = JSON.parse(localStorage.getItem("user"));

function Accounts(props) {
  const {
    smDown,
    onlyXs,
    showLoader,
    hideLoader,
    flashMessage,
    mdDown,
    baseUrl,
  } = useContext(GlobalData);

  const [accounts, setAccounts] = useState({});
  const [accountsDate, setAccountsDate] = useState("");
  const [partnersProfit, setPartnersProfit] = useState(false);

  const handleGetAccountsData = () => {
    showLoader();
    let formData = new FormData();
    formData.append("date", JSON.stringify(accountsDate));
    getAccountData(formData, (resp) => {
      if (resp.statusCode === 200) {
        setAccounts(resp.successData);
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  useEffect(() => {
    if (accountsDate !== "") {
      handleGetAccountsData();
    }
  }, [accountsDate]);

  const handlePrintReport = () => {
    showLoader();
    let formData = new FormData();
    formData.append("date", JSON.stringify("This Month"));
    accountReport(formData, (resp) => {
      if (resp.statusCode === 200) {
        window.open(baseUrl + "public/" + resp.successData.file_path, "_blank");
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  return (
    <Grid container>
      <NavigationBar
        path={"Dashboard>Accounts Management>Accounts"}
        paddingBottom={3}
      />

      <Grid container>
        <Grid
          xl={6}
          lg={6}
          md={5}
          sm={12}
          xs={12}
          item
          paddingRight={onlyXs ? "5px" : "10px"}
        >
          <MainTitle title={"Accounts Management"} />
        </Grid>
        <Grid
          xl={6}
          lg={6}
          md={7}
          sm={12}
          xs={12}
          item
          display="flex"
          justifyContent={smDown ? "center" : "end"}
          paddingTop={smDown ? 1 : 0}
          paddingRight={smDown ? "0px" : "10px"}
        >
          <Box mr="10px">
            {/* <Button
              variant="contained"
              endIcon={<DownloadIcon color="white" />}
              className="add_order_btn__orders"
              onClick={handlePrintReport}
            >
              {mdDown ? (
                <div>
                  <div>Download</div>
                  <div style={{ marginTop: "-8px" }}>Report</div>
                </div>
              ) : (
                "Download Report"
              )}
            </Button> */}
            <DownloadReportButton
              onClick={handlePrintReport}
              id={"download_report__account"}
            />
          </Box>
          <DateFilter
            setDateObject={setAccountsDate}
            dateObject={accountsDate}
            className={"download_report_btn__orders"}
          />
        </Grid>
      </Grid>

      <Grid container px={1}>
        <Grid xs item>
          <AccountCardBase
            backgroundColor={"#edebfe"}
            cardData={<TotalIncomingCashCard accounts={accounts} />}
            title={"Total Incoming Cash"}
            height={"395px"}
          />
        </Grid>

        <Grid xs item>
          <AccountCardBase
            backgroundColor={"#FFE8DE"}
            cardData={<ShopCashExpenseCard accounts={accounts} />}
            title={"Shop Cash Expense"}
            height={"395px"}
          />
        </Grid>
      </Grid>

      <Grid container px={1} pb={5}>
        <Grid xs item>
          <AccountCardBase
            backgroundColor={"#e4f3f1"}
            cardData={
              <TotalRemainingCashCard
                accounts={accounts}
                setPartnersProfit={setPartnersProfit}
                partnersProfit={partnersProfit}
              />
            }
            title={"Total Remaining Cash"}
            height={"395px"}
          />
        </Grid>

        <Grid xs item>
          <AccountCardBase
            backgroundColor={"#E4F5FF"}
            cardData={<BankCashExpenseCard accounts={accounts} />}
            title={"Bank Cash Expense"}
            height={"395px"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const AmountData = (props) => {
  return (
    <Box padding={1} paddingTop={0} className="acc_crd_am1__accounts">
      <Box>
        <ButtonBase>
          <div className={`box_img__account card_size_${props.size}__accounts`}>
            {props.icon}
          </div>
        </ButtonBase>
      </Box>
      <Box display="flex" alignItems="center">
        <Box paddingLeft="10px">
          <Box
            paddingBottom={`${props.size === 2 ? "5px" : "0"}`}
            className="txt1_2_acc_crd__accounts"
          >
            <Box>{props.amountTitle}</Box>
            <Box className="txt1_3_acc_crd__accounts">{props.secTitle}</Box>
          </Box>
          <Box
            fontSize={`${props.size === 2 ? "17px" : "16px"}`}
            className="txt2_2_acc_crd__accounts"
          >
            {props.amount} {userObject.currency}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const TotalIncomingCashCard = (props) => {
  return (
    <>
      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<TotalBankSale />}
            size={1}
            amountTitle={"Total Orders Sale in Bank"}
            secTitle={"(Without VAT)"}
            amount={props.accounts.total_orders_sale_bank_amount}
          />
        </Grid>
        <Divider sx={{ ml: "-10px" }} orientation="vertical" flexItem />
        <Grid item xs pl="15px">
          <AmountData
            icon={<TotalSale />}
            size={1}
            amountTitle={"Total Orders Sale Cash"}
            secTitle={"(Without VAT)"}
            amount={props.accounts.total_orders_sale_cash_amount}
          />
        </Grid>
      </Grid>

      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<TotalBankSale />}
            size={1}
            amountTitle={"VAT in Bank"}
            amount={props.accounts.total_bank_vat_amount}
          />
        </Grid>
        <Divider
          sx={{ mt: "-10px", ml: "-10px" }}
          orientation="vertical"
          flexItem
        />
        <Grid item xs pl="15px">
          <AmountData
            icon={<TotalSale />}
            size={1}
            amountTitle={"VAT in Cash"}
            amount={props.accounts.total_cash_vat_amount}
          />
        </Grid>
      </Grid>

      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<TotalBankSale />}
            size={1}
            amountTitle={"Total Bank Debit"}
            amount={props.accounts.total_debit_amount_bank}
          />
        </Grid>
        <Divider
          sx={{ mt: "-10px", ml: "-10px" }}
          orientation="vertical"
          flexItem
        />
        <Grid item xs pl="15px">
          <AmountData
            icon={<TotalCash />}
            size={1}
            amountTitle={"Total Cash In"}
            amount={props.accounts.total_cash_in_amount}
          />
        </Grid>
      </Grid>

      <Grid container className="cardData_con__accounts" mt="-10px" pb="10px">
        <Grid item xs>
          <Box display="block" textAlign="center">
            <Box paddingBottom="1px" className="txt1_1_acc_crd__accounts">
              Total Cash in Bank&nbsp;
              {/* <span className="txt1_4_acc_crd__accounts">(Without VAT)</span> */}
            </Box>
            <Box className="txt2_1_acc_crd__accounts">
              {parseFloat(props.accounts.total_orders_sale_bank_amount) +
                parseFloat(props.accounts.total_bank_vat_amount) +
                parseFloat(props.accounts.total_debit_amount_bank)}{" "}
              {userObject.currency}
            </Box>
          </Box>
        </Grid>
        <Divider
          sx={{ mt: "-10px", ml: "-10px" }}
          orientation="vertical"
          flexItem
        />
        <Grid item xs pl="15px">
          <Box display="block" textAlign="center">
            <Box paddingBottom="1px" className="txt1_1_acc_crd__accounts">
              Total Cash in Shop&nbsp;
              {/* <span className="txt1_4_acc_crd__accounts">(Without VAT)</span> */}
            </Box>
            <Box className="txt2_1_acc_crd__accounts">
              {parseFloat(props.accounts.total_orders_sale_cash_amount) +
                parseFloat(props.accounts.total_cash_in_amount) +
                parseFloat(props.accounts.total_cash_vat_amount)}{" "}
              {userObject.currency}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Divider />

      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<TotalCredit />}
            size={1}
            amountTitle={"Total Orders Sale Credit"}
            amount={props.accounts.total_orders_sale_credit_amount}
          />
        </Grid>
        <Grid item xs>
          <AmountData
            icon={<GiftedOrders />}
            size={1}
            amountTitle={"Total Gifted Order Cash"}
            amount={props.accounts.total_gifted_orders_cash_amount}
          />
        </Grid>
      </Grid>
    </>
  );
};

const ShopCashExpenseCard = (props) => {
  return (
    <>
      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<UtilityBills />}
            size={1}
            amountTitle={"Utility Bills"}
            amount={props.accounts.total_utility_bills_expense_amount_cash}
          />
        </Grid>
        <Grid item xs>
          <AmountData
            icon={<Purchase />}
            size={1}
            amountTitle={"Purchasing"}
            amount={props.accounts.total_purchasing_expense_amount_cash}
          />
        </Grid>
      </Grid>
      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<TotalSalaries />}
            size={1}
            amountTitle={"Salaries"}
            amount={props.accounts.total_salaries_expense_amount_cash}
          />
        </Grid>
        <Grid item xs>
          <AmountData
            icon={<Taxation />}
            size={1}
            amountTitle={"Taxation"}
            amount={props.accounts.total_tax_expense_amount_cash}
          />
        </Grid>
      </Grid>

      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<Credits />}
            size={1}
            amountTitle={"Credit"}
            amount={props.accounts.total_credit_expense_amount_cash}
          />
        </Grid>
        <Grid item xs>
          <AmountData
            icon={<OthersSmallIcon />}
            size={1}
            amountTitle={"Others"}
            amount={props.accounts.total_others_expense_amount_cash}
          />
        </Grid>
      </Grid>
      <Grid position="absolute" bottom="0" padding={1} pl={1.5} width="100%">
        <Box display="flex" justifyContent="center">
          <Box display="block" textAlign="center">
            <Box paddingBottom="1px" className="txt1_1_acc_crd__accounts">
              Total Shop Expense
            </Box>
            <Box className="txt2_1_acc_crd__accounts">
              {props.accounts.total_expense_amount_cash} {userObject.currency}
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

const BankCashExpenseCard = (props) => {
  return (
    <>
      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<TotalBalance />}
            size={1}
            amountTitle={"Utility Bills"}
            amount={props.accounts.total_utility_bills_expense_amount_bank}
          />
        </Grid>
        <Grid item xs>
          <AmountData
            icon={<Purchase />}
            size={1}
            amountTitle={"Purchasing"}
            amount={props.accounts.total_purchasing_expense_amount_bank}
          />
        </Grid>
      </Grid>
      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<TotalSalaries />}
            size={1}
            amountTitle={"Salaries"}
            amount={props.accounts.total_salaries_expense_amount_bank}
          />
        </Grid>
        <Grid item xs>
          <AmountData
            icon={<Taxation />}
            size={1}
            amountTitle={"Taxation"}
            amount={props.accounts.total_tax_expense_amount_bank}
          />
        </Grid>
      </Grid>
      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<Cheques />}
            size={1}
            amountTitle={"Cheques"}
            amount={props.accounts.total_withdrawal_cheque_amount}
          />
        </Grid>
        <Grid item xs>
          <AmountData
            icon={<Credits />}
            size={1}
            amountTitle={"Credits"}
            amount={props.accounts.total_credit_expense_amount_bank}
          />
        </Grid>
      </Grid>
      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<OthersSmallIcon />}
            size={1}
            amountTitle={"Others"}
            amount={props.accounts.total_others_expense_amount_bank}
          />
        </Grid>
      </Grid>

      <Grid position="absolute" bottom="0" padding={1} pl={1.5} width="100%">
        <Box display="flex" justifyContent="center">
          <Box display="block" textAlign="center">
            <Box paddingBottom="1px" className="txt1_1_acc_crd__accounts">
              Total Bank Expense
            </Box>
            <Box className="txt2_1_acc_crd__accounts">
              {props.accounts.total_expense_amount_bank} {userObject.currency}
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

const TotalRemainingCashCard = (props) => {
  return (
    <>
      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<TotalBalance />}
            size={1}
            amountTitle={"Total Cash Balance"}
            secTitle={"(After Expense)"}
            amount={props.accounts.total_cash_balance_amount}
          />
        </Grid>
        <Grid item xs>
          <AmountData
            icon={<TotalBankSale />}
            size={1}
            amountTitle={"Total Bank Balance"}
            secTitle={"(After Expense)"}
            amount={props.accounts.total_bank_balance_amount}
          />
        </Grid>
      </Grid>
      <Grid container className="cardData_con__accounts">
        <Grid item xs>
          <AmountData
            icon={<TotalProfit />}
            size={1}
            amountTitle={"Total Orders Sale Profit"}
            amount={props.accounts.total_orders_sale_profit}
          />
        </Grid>
        <Grid
          item
          xs
          onClick={() => {
            props.setPartnersProfit(true);
          }}
          style={{ cursor: "pointer" }}
        >
          <AmountData
            icon={<TotalProfit />}
            size={2}
            amountTitle={<Box fontSize="18px">Total Profit</Box>}
            secTitle={"(After Expense)"}
            amount={props.accounts.totalProfit}
          />
        </Grid>

        <Box className="profit_card_postin">
          <AnimatePresence>
            <motion.div
              initial={{
                width: "0px",
                opacity: 0,
              }}
              animate={
                props.partnersProfit
                  ? {
                      width: "100%",
                      opacity: 1,
                      transition: {
                        duration: 0.5,
                      },
                    }
                  : {
                      width: "0px",

                      opacity: 0,
                      transition: {
                        duration: 0.5,
                      },
                    }
              }
              exit={{
                width: "0px",
                opacity: 0,
              }}
            >
              <Box
                className="prtprft_crd__acnts"
                p={1.2}
                onClick={() => {
                  props.setPartnersProfit(!props.partnersProfit);
                }}
              >
                <Box>
                  <span className="total_prof_text">Total Profit: </span>

                  <span
                  className="total_proft_value"
                  >
                    {props.accounts.totalProfit !== undefined
                      ? props.accounts.totalProfit.toFixed(2)
                      : ""}
                  </span>
                  <span
                   className="total_proft_value"
                  >
                    &nbsp;{userObject.currency}
                  </span>
                </Box>
                {props.accounts.allPartners !== undefined &&
                  props.accounts.allPartners.map((v, i) => (
                    <Box className="prtnr_prf__acnts">
                      <Box fontWeight="bold">{v.partner_name}</Box>
                      <Box color="rgb(26, 41, 55, 0.9)" fontWeight="500">
                        {v.partner_total_pending_profit !== undefined
                          ? v.partner_total_pending_profit.toFixed(2)
                          : ""}
                        &nbsp;{userObject.currency} ({v.partner_percentage}%)
                      </Box>
                    </Box>
                  ))}
              </Box>
            </motion.div>
          </AnimatePresence>
        </Box>
      </Grid>
    </>
  );
};

const AccountCardBase = (props) => {
  const { mdDown, onlyXs } = useContext(GlobalData);

  const [accountsCardDate, setAccountsCardDate] = useState("Today");

  return (
    <Box className="accrd__acct">
      <Card
        style={{
          backgroundColor: props.backgroundColor,
          marginRight: `${onlyXs ? "6px" : "10px"}`,
          height: `${props.height}`,
        }}
        className="card__account"
      >
        <Grid container className="ac_crds_ttl__accounts">
          {props.title}
        </Grid>
        {props.cardData}
      </Card>
    </Box>
  );
};

export default Accounts;
