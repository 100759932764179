

import { getWithParams, post, delte } from "./API";

export function getAllRepairingFaults(data, callback) {
  getWithParams({ endPoint: "RepairingFaults/getAllRepairingFaults" }, data, function(response) {
    callback(response);
  });
}
export function createRepairingFaults(data, callback) {
  post({ endPoint: "RepairingFaults/createRepairingFaults" }, data, function(response) {
    callback(response);
  });
}
export function getRepairingFaultsByID(data, callback) {
    getWithParams({ endPoint: "RepairingFaults/getRepairingFaultsByID" }, data, function(response) {
      callback(response);
    });
  }

export function deleteRepairingFaultsByID(data, callback) {
  delte({ endPoint: "RepairingFaults/deleteRepairingFaultsByID" }, data, function(response) {
    callback(response);
  });
}

