import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Switch = styled.div`
  font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
  position: relative;
  height: 48px;
  
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgb(0, 0, 0, 0.5);
`;

const SwitchSelection = styled.span`
  display: block;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 90px;
  height: 48px;
  background: #1e2738;
  border-radius: 10px;
  transition: left 0.5s ease-out;
`;

const SwitchLabel = styled.label`
  position: relative;
  z-index: 2;
  float: left;
  width: 90px;
  line-height: 48px;
  font-size: 16px;
  color: #1e2738;
  text-align: center;
  cursor: pointer;
`;

const titleCase = (str) =>
  str
    .split(/\s+/)
    .map((w) => w[0].toUpperCase() + w.slice(1))
    .join(" ");

const ClickableLabel = ({ title, onChange, id }) => (
  <SwitchLabel id={title} onClick={() => onChange(title)} className={id}>
    {titleCase(title)}
  </SwitchLabel>
);

function TriStateButton(props) {
  const [selectedState, setSelectedState] = useState({
    selected: props.selected,
  });

  const handleChange = (val) => {
    setSelectedState({ selected: val });
    props.handleTriStateFilter(val);
  };

  const selectionStyle = () => {
    return {
      left: `${(props.values.indexOf(selectedState.selected) / props.values.length) * 100}%`,
    };
  };

  useEffect(() => {
    props.values.map((value) => {
      if (value === selectedState.selected) {
        document.getElementById(value).style.color = "white";
      } else {
        document.getElementById(value).style.color = "#1E2738";
      }
    });
  });

  return (
    <Switch>
      {props.values.map((val) => {
        return (
          <span key={val}>
            <ClickableLabel
              key={val}
              title={val}
              onChange={() => {
                handleChange(val);
              }}
            />
          </span>
        );
      })}
      <SwitchSelection style={selectionStyle()} />
    </Switch>
  );
}

export default TriStateButton;
