import "./Inventory.css";
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Box,
  DialogActions,
  Menu,
  MenuItem,
  Fade,
  TextField,
  DialogTitle,
  DialogContentText,
  FormControl,
  InputLabel,
} from "@mui/material";
import { IoBagAddOutline } from "react-icons/io5";
import SearchField from "../../widgets/SearchField";
import CustomTable from "../../widgets/CustomTable";
import TriStateButton from "../../widgets/TriStateButton.jsx";
import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  getAllReturnInventory,
  changeReturnInventoryStatus,
} from "../../api/InventoryAPI";
import { GlobalData } from "../../App";
import { deleteProduct } from "../../api/InventoryAPI";
import { getAllCategoriesObjects } from "../../api/CategoryAPI";
import { debounce } from "lodash";
import NavigationBar from "../NavigationBar";
import PrintIcon from "@mui/icons-material/Print";
import ProtectComponent from "../../middleware/ProtectComponent";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { inventoryReport } from "../../api/ReportAPI";
import DeleteDialog from "../../widgets/DeleteDialog";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/SVG/EditIcon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/SVG/DownloadIconWhite.svg";
import { ReactComponent as InventoryMenuFilterIcon } from "../../assets/SVG/InventoryMenuFilterIcon.svg";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { styled } from "@mui/material/styles";
import DateFilter from "../../widgets/DateFilter";
import MainTitle from "../../widgets/MainTitle";
import DownloadReportButton from "../../widgets/DownloadReportButton";
import SmallTitle from "../../widgets/SmallTitle";
const options = [
  {
    icon: (
      <PublishedWithChangesIcon
        style={{ opacity: "0.8", marginLeft: "-2.5px" }}
      />
    ),
    label: <span className="margn_left_global__module">Status</span>,
    method: "status",
    type: "icon",
  },
];

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",

    align: "left",
  },
  {
    id: "image",
    label: "Image",
    align: "left",
  },
  {
    id: "product_name",
    label: "Name",
    align: "left",
  },
  {
    id: "specification",
    label: "Specifications",
    align: "left",
  },
  {
    id: "return_product_category",
    label: "Category",
    align: "left",
  },
  {
    id: "returned_order_date",
    label: "Date",
    align: "left",
  },
  {
    id: "returned_order_description",
    label: "Description",
    align: "left",
  },
  {
    id: "returned_order_status",
    label: "Status",
    align: "left",
  },
];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

function ReturnInventory(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    onlyXs,
    smDown,
    mdDown,
    baseUrl,
    userObject,
  } = useContext(GlobalData);

  const [orderStatus, setOrderStatus] = useState("All");

  const [inventoryDate, setInventoryDate] = useState("This Month");

  const [
    isChangeOrderStatusDialogOpen,
    setIsChangeOrderStatusDialogOpen,
  ] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [orderDescription, setOrderDescription] = useState("");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");

  const [
    inventoryTriStateFilterValue,
    setInventoryTriStateFilterValue,
  ] = useState("All");
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [totalInventoryWorth, setTotalInventoryWorth] = useState(0);
  const [totalInventoryCount, setTotalInventoryCount] = useState(0);
  const [productCategories, setProductCategories] = useState([]);
  const [
    selectedValueInventoryWorthFilter,
    setSelectedValueInventoryWorthFilter,
  ] = useState("");

  const [
    totalInventoryWorthFilterMenu,
    setTotalInventoryWorthFilterMenu,
  ] = useState(null);

  const isTotalInventoryWorthFilterMenuOpen = Boolean(
    totalInventoryWorthFilterMenu
  );
  const handleTotalInventoryWorthFilterMenuOpen = (event) => {
    console.log("ok");
    setTotalInventoryWorthFilterMenu(event.currentTarget);
  };
  const handleTotalInventoryWorthFilterMenuClose = () => {
    setTotalInventoryWorthFilterMenu(null);
  };

  const handleViewProduct = (product) => {
    navigate("/products/product-detail", {
      state: { product_id: product.id, product: product },
    });
  };

  const handleEditProduct = (product) => {
    navigate("/products/edit-product", {
      state: { product_id: product.id, product: product },
    });
  };

  const openDeleteProductDialog = (product) => {
    setCurrentProductId(product.id);
    setDeleteProductDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const handleDeleteProduct = () => {
    handleCloseDeleteDialog();
    showLoader();
    let formData = new FormData();
    formData.append("product_id", currentProductId);
    deleteProduct(formData, (resp) => {
      console.log(resp);
      if (resp.statusCode === 200) {
        setProducts(resp.successData.products);
        hideLoader();
        flashMessage("success", resp.message);
      } else {
        hideLoader();
        flashMessage("error", resp.message);
      }
    });
  };

  const getAllProductsMethod = (pn) => {
    showLoader();
    let formData = new FormData();
    formData.append("date", JSON.stringify(inventoryDate));
    formData.append("search_text", searchText);
    formData.append(
      "filter_inventory_category_id",
      selectedValueInventoryWorthFilter
    );
    formData.append("status", inventoryTriStateFilterValue);
    formData.append("per_page", process.env.REACT_APP_PER_PAGE);
    formData.append("page", pn);
    getAllReturnInventory(formData, (resp) => {
      if (resp.statusCode === 200) {
        setTotalInventoryCount(resp.successData.total_return_inventory_count);
        setTotalInventoryWorth(resp.successData.total_inventory_worth_cost);
        if (pn === 1) {
          setProducts(resp.successData.return_products);
          hideLoader();
        } else {
          setProducts((dt) => {
            dt.push(...resp.successData.return_products);
            hideLoader();
            return dt;
          });
        }
        setLastPage(resp.successData.last_page);
      } else {
        flashMessage("error", resp.message);
      }
      hideLoader();
    });
  };

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const handleGetAllCategories = () => {
    showLoader();
    getAllCategoriesObjects({ shop_id: userObject.shop.id }, (resp) => {
      let c = resp.successData.categories;
      let b = c;
      b.push({
        id: b[b.length - 1].id + 1,
        category: "All",
        image: "images/image_placeholder.png",
        created_at: "2023-01-02T17:44:49.000000Z",
        updated_at: "2023-01-02T17:44:49.000000Z",
      });
      b = b.reverse();
      setProductCategories(b);
    });
  };

  const handlePrintInventoryReport = () => {
    showLoader();
    let formData = new FormData();
    formData.append("products", JSON.stringify(products));
    formData.append("inventory_cost", totalInventoryWorth);
    inventoryReport(formData, (resp) => {
      if (resp.statusCode === 200) {
        window.open(baseUrl + "public/" + resp.successData.file_path, "_blank");
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const handleFetchInventory = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(handleFetchInventory);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      getAllProductsMethod(page);
    }
  };

  useEffect(() => {
    getAllProductsMethod(page);
  }, [page]);

  useEffect(() => {
    updateDataUsingPageNumber();
    if (productCategories.length === 0) {
      handleGetAllCategories();
    }
  }, [
    searchText,
    selectedValueInventoryWorthFilter,
    inventoryTriStateFilterValue,
    inventoryDate,
  ]);

  const handleTriStateFilter = (value) => {
    setInventoryTriStateFilterValue(value);
  };

  const handleChangeStatusDialogOpen = (product) => {
    setSelectedOrder(product);
    setSelectedOrderStatus(product.returned_order_status);
    setOrderDescription(product.returned_order_description);
    setIsChangeOrderStatusDialogOpen(true);
  };

  const handleChangeOrderStatusDialogClose = () => {
    setIsChangeOrderStatusDialogOpen(false);
  };

  const handleChangeStatus = () => {
    if (selectedOrderStatus === "") {
      flashMessage("error", "Please Select Order Status");
    } else {
      handleChangeOrderStatusDialogClose();
      showLoader();
      let formData = new FormData();
      formData.append("returned_product_id", selectedOrder.id);
      formData.append("returned_order_status", selectedOrderStatus);
      formData.append("returned_order_description", orderDescription);
      changeReturnInventoryStatus(formData, (resp) => {
        if (resp.statusCode === 200) {
          updateDataUsingPageNumber();
        } else {
          flashMessage("error", resp.message);
          hideLoader();
        }
      });
    }
  };

  const changeOrderDescription = (e) => {
    setOrderDescription(e.target.value);
  };

  return (
    <>
      <Dialog
        open={isChangeOrderStatusDialogOpen}
        onClose={handleChangeOrderStatusDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Product Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ marginBottom: "8px", marginTop: "16px" }}>Status</div>
            <FormControl className="textField__AddInventory">
              {selectedOrderStatus === "" && (
                <InputLabel sx={{ opacity: 0.6 }}>Select Status</InputLabel>
              )}

              <ChangeOrderStatus
                setSelectedOrderStatus={setSelectedOrderStatus}
                selectedOrderStatus={selectedOrderStatus}
                orderStatus={["Returned", "Repaired", "Lose", "Approved"]}
              />
            </FormControl>

            <div style={{ marginBottom: "8px", marginTop: "16px" }}>
              Description
            </div>

            <CustomTextField
              multiline
              rows={4}
              style={{ width: "" }}
              value={orderDescription}
              onChange={changeOrderDescription}
              name="description"
              id="description"
              key="description"
              placeholder="Write about product here"
              className="textField__AddInventory"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="download_report_btn__orders"
            onClick={handleChangeOrderStatusDialogClose}
          >
            Cancel
          </Button>
          <Button
            className="add_order_btn__orders"
            onClick={handleChangeStatus}
          >
            Change Status
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteDialog
        handleRemove={handleDeleteProduct}
        handleClose={handleCloseDeleteDialog}
        showDialog={deleteProductDialog}
        title={"Remove this Inventory Product?"}
        sub_title={
          "You are about to remove a Product. Click on the “Remove” button to remove it."
        }
      />

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <NavigationBar
            path={"Dashboard>Inventory Management>Inventory>Return Inventory"}
            paddingBottom={3}
          />

          <MainTitle title={"Return Inventory Management"} />

          <Grid container marginBottom={2}>
            <Grid
              xl={6}
              lg={6}
              md={5}
              sm={5}
              xs={8}
              item
              paddingRight={onlyXs ? "5px" : "10px"}
            >
              <SmallTitle small={"Total Return Inventory worth"} />

              <Box display="flex">
                <Box className="ttwrt__inv">
                  <Box display="flex" alignItems="end">
                    <span style={{ fontSize: "24px", opacity: "0.8" }}>
                      {totalInventoryWorth}
                    </span>
                    &nbsp;&nbsp;{userObject.currency}
                  </Box>
                </Box>
                <InventoryWorthFilter
                  setSelectedValueInventoryWorthFilter={
                    setSelectedValueInventoryWorthFilter
                  }
                  productCategories={productCategories}
                />
              </Box>
            </Grid>

            <Grid
              xl={6}
              lg={6}
              md={7}
              sm={7}
              xs={4}
              item
              display="flex"
              justifyContent="end"
              alignItems="end"
              paddingRight={onlyXs ? "0px" : "10px"}
            >
              {onlyXs ? (
                <IconButton
                  aria-label="add"
                  size="large"
                  className="i-add-btn-icon"
                >
                  <IoBagAddOutline fontSize="inherit" />
                </IconButton>
              ) : (
                <>
                  {/* <Button
                    onClick={() => {
                      navigate("/products/add-new-product");
                    }}
                    variant="contained"
                    className="add_order_btn__orders"
                  >
                    Add New Product
                  </Button> */}

                  {/* <ProtectComponent
                    id="download-all-btn"
                    componentLocation={true}
                    componentNavigate={false}
                    component={
                      <Button
                        variant="contained"
                        endIcon={<DownloadIcon color="white" />}
                        className="add_order_btn__orders"
                      >
                        {mdDown ? (
                          <div>
                            <div>Download</div>
                            <div style={{ marginTop: "-8px" }}>Report</div>
                          </div>
                        ) : (
                          "Download Report"
                        )}
                      </Button>
                      
                    }
                  /> */}
                  <DownloadReportButton />
                  <span style={{ marginLeft: "5px" }} />
                  <DateFilter
                    setDateObject={setInventoryDate}
                    dateObject={inventoryDate}
                    className={"download_report_btn__orders"}
                  />
                </>
              )}
            </Grid>
          </Grid>

          <Grid container marginBottom={2}>
            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              item
              display="flex"
              justifyContent={onlyXs ? "center" : "start"}
              paddingRight={onlyXs ? "5px" : "10px"}
              paddingTop={onlyXs ? 1 : 0}
              paddingBottom={onlyXs ? 1 : 0}
            >
              <TriStateButton
                values={["All", "Returned", "Repaired", "Lose", "Approved"]}
                selected={"All"}
                handleTriStateFilter={handleTriStateFilter}
              />

              {/* <InventoryFilter /> */}
            </Grid>

            <Grid
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              item
              className="padn_invoice__icon"
            >
              <SearchField
                onChange={updateProductList}
                tableDataCount={products.length}
                totalAvailableDataCount={totalInventoryCount}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CustomTable
            status={handleChangeStatusDialogOpen}
            columns={columns}
            data={products}
            options={options}
          />
        </Grid>
      </Grid>
    </>
  );
}

const InventoryWorthFilter = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className="ttwrt_flt__inv "
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <InventoryMenuFilterIcon style={{ cursor: "pointer" }} />
      </IconButton>
      <Menu
        PaperProps={{
          style: {
            borderRadius: "10px",
            boxShadow: "none",
            border: "1px solid rgba(0,0,0,0.15)",
          },
        }}
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {props.productCategories.map((option, oi) => (
          <MenuItem
            style={
              oi + 1 < props.productCategories.length
                ? {
                    borderBottom: "1px solid rgba(0,0,0,0.15)",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }
                : { paddingTop: 0, paddingBottom: 0 }
            }
            onClick={() => {
              handleClose();
              props.setSelectedValueInventoryWorthFilter(option.id);
            }}
          >
            {option.category}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const InventoryFilter = (props) => {
  const [anchorE2, setAnchorE2] = React.useState(null);
  const open2 = Boolean(anchorE2);
  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };

  return (
    <>
      <Button
        style={{
          marginLeft: "5px",
          marginTop: "2px",
          borderRadius: "10px",
        }}
        className="icon__fil__invet"
        id="demo-positioned-button"
        aria-controls={open2 ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? "true" : undefined}
        onClick={handleClick2}
      >
        <FilterAltIcon
          style={{ height: "40px", width: "30px", color: "#1e2738" }}
        />

        {/* <Box fontSize="22px" color="#1e2738">
          -&nbsp;({props.products.length}/{props.totalInventoryCount})
        </Box> */}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorE2}
        open={open2}
        onClose={handleClose2}
        PaperProps={{
          style: {
            borderRadius: "10px",
            boxShadow: "none",
            border: "1px solid rgba(0,0,0,0.15)",
          },
        }}
      >
        {["Loss", "Maintenance", "Fine Inventory"].map((option, oi) => (
          <MenuItem
            style={
              oi + 1 < 3
                ? {
                    borderBottom: "1px solid rgba(0,0,0,0.15)",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }
                : { paddingTop: 0, paddingBottom: 0 }
            }
            onClick={handleClose2}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const ChangeOrderStatus = (props) => {
  return (
    <CustomTextField
      select
      value={props.selectedOrderStatus}
      onChange={(e) => {
        props.setSelectedOrderStatus(e.target.value);
      }}
      name="order_status"
      id="order_status"
      key="order_status"
    >
      {props.orderStatus.map((value, index) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </CustomTextField>
  );
};

export default ReturnInventory;
