import { getWithParams, post, delte } from "./API";

export function getAllBanks(data, callback) {
  getWithParams({ endPoint: "bank/getAllBanks" }, data, function(response) {
    callback(response);
  });
}
export function getBankById(data, callback) {
  post({ endPoint: "bank/getBankById" }, data, function(response) {
    callback(response);
  });
}
export function addBank(data, callback) {
  post({ endPoint: "bank/addBank" }, data, function(response) {
    callback(response);
  });
}
export function updateBankDetailById(data, callback) {
  post({ endPoint: "bank/updateBankDetailById" }, data, function(response) {
    callback(response);
  });
}
export function deleteBank(data, callback) {
  delte({ endPoint: "bank/deleteBank" }, data, function(response) {
    callback(response);
  });
}
