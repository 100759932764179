import "./Orders.css";
import {
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import Button from "@mui/material/Button";
import { AiFillCaretDown } from "react-icons/ai";
import CustomTable from "../../widgets/CustomTable";
import SearchField from "../../widgets/SearchField";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState, useEffect, useContext, useMemo } from "react";
import Divider from "@mui/material/Divider";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import { changeOrderStatus, deleteOrder } from "../../api/OrderAPI";

import { getAllOnlineOrders } from "../../api/OnlineOrdersAPI";

import { orderInvoice } from "../../api/ReportAPI";
import { debounce } from "lodash";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import PrintIcon from "@mui/icons-material/Print";
import ProtectComponent from "../../middleware/ProtectComponent";
import { DateRangePicker } from "mui-daterange-picker";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { ReactComponent as EditIcon } from "../../assets/SVG/EditIcon.svg";
import { ReactComponent as EyeIcon } from "../../assets/SVG/ViewEyeIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/SVG/DeleteIcon.svg";
import { ReactComponent as OrderCreditBtn } from "../../assets/SVG/OrderCreditBtn.svg";
import { ReactComponent as OnlineReportIcon } from "../../assets/SVG/OnlineOrderReportIcon.svg";
import { ReactComponent as OnlineAllReportIcon } from "../../assets/SVG/OrdersAllReportIcon.svg";
import { ReactComponent as OnlineDeleteDialogIcon } from "../../assets/SVG/OnlineDeleteIconDialog.svg";
import DeleteDialog from "../../widgets/DeleteDialog";

const options = [
  {
    icon: <EyeIcon />,
    label: "View",
    method: "view",
    type: "icon",
  },
  {
    icon: (
      <PublishedWithChangesIcon
        style={{ opacity: "0.8", marginLeft: "-2.5px" }}
      />
    ),
    label: <span className="margn_left_global__module">Status</span>,
    method: "status",
    type: "icon",
  },
  {
    icon: <EditIcon />,
    label: "Edit",
    method: "edit",
    type: "icon",
  },
  {
    icon: <PrintIcon style={{ opacity: "0.7", marginLeft: "-2.5px" }} />,
    label: <span className="margn_left_global__module">Print</span>,
    method: "print",
    type: "icon",
  },
  {
    icon: <DeleteIcon />,
    label: "Delete",
    method: "delete",
    type: "icon",
  },
];

const columns = [
  {
    id: "sr_no",
    label: "Sr. No",
    align: "left",
  },
  {
    id: "invoice_code",
    label: "Order ID",

    align: "left",
  },
  {
    id: "total",
    label: "Total Cash",

    align: "left",
  },
  {
    id: "customer_name",
    label: "Customer Name",
    align: "left",
  },
  {
    id: "customer_phone",
    label: "Phone Number",
    align: "left",
  },
  {
    id: "quantity",
    label: "Total Products",

    align: "left",
  },
  {
    id: "order_date",
    label: "Order Date",
    align: "left",
  },
  {
    id: "profit",
    label: "Profit",
    align: "left",
  },
  {
    id: "order_type",
    label: "Type",
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    align: "left",
  },
];

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

function OnlineOrders(props) {
  const {
    showLoader,
    hideLoader,
    flashMessage,
    navigate,
    smDown,
    onlyXs,
    mdDown,
    onlyMd,
    baseUrl,
    location,
  } = useContext(GlobalData);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  //
  const [ordersDate, setOrdersDate] = useState("This Month");
  //--
  const [dateMenuElement, setDateMenuElement] = useState(null);
  const isDateMenuOpen = Boolean(dateMenuElement);
  const ITEM_HEIGHT = 48;
  const handleOpenDateMenu = (event) => {
    setDateMenuElement(event.currentTarget);
  };
  const handleCloseDateMenu = () => {
    setDateMenuElement(null);
  };
  //--
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [dateRange, setDateRange] = useState({});
  const toggleDateRangerPicker = () =>
    setOpenDateRangePicker(!openDateRangePicker);
  const handleOrdersDateFilter = (option) => {
    handleCloseDateMenu();
    if (option === "Custom") {
      toggleDateRangerPicker();
    } else {
      setOrdersDate(option);
    }
  };

  const handleDateRange = (range) => {
    setDateRange(range);
    setOrdersDate(range);
    setTimeout(() => {
      toggleDateRangerPicker();
    }, 500);
  };
  //

  const [orders, setOrders] = useState([]);
  const [orderStatus, setOrderStatus] = useState("All");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [orderType, setOrderType] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [orderStatusCount, setOrderStatusCount] = useState("");
  const [orderTypeCount, setOrderTypeCount] = useState("");
  const [
    isChangeOrderStatusDialogOpen,
    setIsChangeOrderStatusDialogOpen,
  ] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const [orderDescription, setOrderDescription] = useState("");

  const [allOrderStatus, setAllOrderStatus] = useState([
    "Paid",
    "Unpaid",
    "Cancelled",
  ]);

  const [deleteProductDialog, setDeleteProductDialog] = useState(false);

  const handleCloseDeleteDialog = () => {
    setDeleteProductDialog(false);
  };

  const handleDeleteOrder = () => {
    handleCloseDeleteDialog();
    showLoader();
    let formData = new FormData();
    formData.append("order_id", selectedOrder.id);
    deleteOrder(formData, (resp) => {
      if (resp.statusCode === 200) {
        updateDataUsingPageNumber();
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const openDeleteOrderDialog = (order) => {
    setSelectedOrder(order);
    setDeleteProductDialog(true);
  };

  const getAllOrdersMethod = (pn) => {
    showLoader();
    // let formData = new FormData();
    // formData.append("search_text", searchText);
    // formData.append("order_type", orderType);
    // formData.append("status", orderStatus);
    // formData.append("date", JSON.stringify(ordersDate));
    // formData.append("per_page", process.env.REACT_APP_PER_PAGE);
    // formData.append("page", pn);
    // if (location.pathname === "/staff/orders") {
    //   formData.append("staff_user_id", location.state.memberData.user_id);
    // }

    let data = {
      search_text: searchText,
      order_type: orderType,
      status: orderStatus,
      date: JSON.stringify(ordersDate),
      per_page: process.env.REACT_APP_PER_PAGE,
      page: pn,
    };

    getAllOnlineOrders(data, (resp) => {
      if (resp.statusCode === 200) {
        setOrderStatusCount(resp.successData.order_status_count);
        setOrderTypeCount(resp.successData.order_type_count);
        if (resp.statusCode === 200) {
          if (pn === 1) {
            setOrders(resp.successData.orders);
            hideLoader();
          } else {
            setOrders((dt) => {
              dt.push(...resp.successData.orders);
              hideLoader();
              return dt;
            });
          }
          setLastPage(resp.successData.last_page);
        }
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const changeOrderDescription = (e) => {
    setOrderDescription(e.target.value);
  };

  const handleOrderStatus = (order) => {
    setSelectedOrder(order);
    setSelectedOrderStatus("");
    setOrderDescription(order.description);
    handleChangeOrderStatusDialogOpen();
  };

  const handleViewOrder = (data) => {
    if (location.pathname === "/staff/orders") {
      navigate("/orders/order-detail", {
        state: {
          orderData: data,
          orderStatus: orderStatus,
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/orders/order-detail", {
        state: {
          orderData: data,
          orderStatus: orderStatus,
          path: location.pathname,
        },
      });
    }
  };

  // Order Status Dialog Methods

  const handleChangeOrderStatusDialogOpen = () => {
    setIsChangeOrderStatusDialogOpen(true);
  };

  const handleChangeOrderStatusDialogClose = () => {
    setIsChangeOrderStatusDialogOpen(false);
  };

  const handleChangeOrderStatus = () => {
    if (selectedOrderStatus === "") {
      flashMessage("error", "Please Select Order Status");
    } else {
      handleChangeOrderStatusDialogClose();
      showLoader();
      let formData = new FormData();
      formData.append("order_id", selectedOrder.id);
      formData.append("order_status", selectedOrderStatus);
      formData.append("description", orderDescription);
      changeOrderStatus(formData, (resp) => {
        if (resp.statusCode === 200) {
          updateDataUsingPageNumber();
        } else {
          flashMessage("error", resp.message);
          hideLoader();
        }
      });
    }
  };

  const handleSearchTextInput = (value) => {
    setSearchText(value);
  };

  const updateProductList = useMemo(() => {
    return debounce(handleSearchTextInput, 300);
  }, []);

  const handleEditOrder = (data) => {
    if (location.pathname === "/staff/orders") {
      navigate("/orders/update-order", {
        state: {
          orderData: data,
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/orders/update-order", {
        state: {
          orderData: data,
          path: location.pathname,
        },
      });
    }
  };

  const handlePrintSingleOrder = (order) => {
    showLoader();
    let formData = new FormData();
    formData.append("orders", JSON.stringify([order]));
    orderInvoice(formData, (resp) => {
      if (resp.statusCode === 200) {
        window.open(baseUrl + "public/" + resp.successData.file_path, "_blank");
        hideLoader();
      } else {
        flashMessage("error", resp.message);
        hideLoader();
      }
    });
  };

  const handlePrintAllOrders = () => {
    if (orders.length > 0) {
      showLoader();
      let formData = new FormData();
      formData.append("orders", JSON.stringify(orders));
      orderInvoice(formData, (resp) => {
        if (resp.statusCode === 200) {
          window.open(baseUrl + "public/" + resp.successData.file_path, "_blank");
          hideLoader();
        } else {
          flashMessage("error", resp.message);
          hideLoader();
        }
      });
    }
  };

  const updatePageNumber = () => {
    setPage((pn) => {
      if (lastPage > page) {
        pn++;
      }
      console.log(pn);
      return pn;
    });
  };

  // Page On Scroll Ref Hook

  const scrollRefInventoryList = useBottomScrollListener(updatePageNumber);

  useEffect(() => {
    getAllOrdersMethod(page);
  }, [page]);

  const updateDataUsingPageNumber = () => {
    if (page > 1) {
      setPage(1);
    } else {
      getAllOrdersMethod(page);
    }
  };

  useEffect(() => {
    updateDataUsingPageNumber();
  }, [searchText, orderType, orderStatus, ordersDate]);

  const handleNavigateToAddOrder = () => {
    if (location.pathname === "/staff/orders") {
      navigate("/orders/add-new-order", {
        state: {
          path: location.pathname,
          memberData: location.state.memberData,
        },
      });
    } else {
      navigate("/orders/add-new-order", {
        state: {
          path: location.pathname,
        },
      });
    }
  };

  return (
    <>
      <DeleteDialog
        fullWidth
        maxWidth="xs"
        showDialog={deleteProductDialog}
        handleClose={handleCloseDeleteDialog}
        id="deleteProductDialog"
        title={"Remove this Order?"}
        sub_title={
          " You are about to remove a Order. Click on the “Remove” button to remove it"
        }
        handleRemove={handleDeleteOrder}
      />

      {/* <Dialog
        fullWidth
        maxWidth="xs"
        open={deleteProductDialog}
        onClose={handleCloseDeleteDialog}
        id="deleteProductDialog"
      >
        <DialogContent id="deleteProductDialogContent__inv">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              style={{ textAlign: "center" }}
            >
               style="margin-top: 60px"  this style is SVG 
              <OnlineDeleteDialogIcon />
              <Box className="deleteProductDialogActionText1_inv">
                Remove this Order?
              </Box>
              <Box className="deleteProductDialogActionText2_inv">
                You are about to remove a Order. Click on the “Remove” button to
                remove it.
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="deleteDialogActions">
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              className="deleteDialogBtn__inv deleteDialogBtnNo__inv"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={handleCloseDeleteDialog}
            >
              Don’t Remove
            </Grid>
            <Grid
              className="deleteDialogBtn__inv deleteDialogBtnYes__inv"
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              item
              onClick={handleDeleteOrder}
            >
              Remove
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog> */}

      <Dialog
        open={isChangeOrderStatusDialogOpen}
        onClose={handleChangeOrderStatusDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Order Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ marginBottom: "8px", marginTop: "16px" }}>Status</div>
            <FormControl className="textField__AddInventory">
              {selectedOrderStatus === "" && (
                <InputLabel sx={{ opacity: 0.6 }}>
                  Select Order Status
                </InputLabel>
              )}

              {selectedOrder.order_type === "Card" ||
              selectedOrder.order_type === "Cash" ? (
                <ChangeOrderStatus
                  setSelectedOrderStatus={setSelectedOrderStatus}
                  selectedOrderStatus={selectedOrderStatus}
                  orderStatus={["Cancelled"]}
                />
              ) : true ? (
                <ChangeOrderStatus
                  setSelectedOrderStatus={setSelectedOrderStatus}
                  selectedOrderStatus={selectedOrderStatus}
                  orderStatus={["Cancelled"]}
                />
              ) : null}
            </FormControl>

            <div style={{ marginBottom: "8px", marginTop: "16px" }}>
              Description
            </div>

            <CustomTextField
              multiline
              rows={4}
              style={{ width: "" }}
              value={orderDescription}
              onChange={changeOrderDescription}
              name="description"
              id="description"
              key="description"
              placeholder="Write about product here"
              className="textField__AddInventory"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="download_report_btn__orders"
            onClick={handleChangeOrderStatusDialogClose}
          >
            Cancel
          </Button>
          <Button
            className="add_order_btn__orders"
            onClick={handleChangeOrderStatus}
          >
            Change Status
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <NavigationBar
            path={"Dashboard>Online Orders Management>Orders"}
            paddingBottom={3}
          />

          <Grid container marginBottom={2}>
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              item
              paddingRight={onlyXs ? "5px" : "10px"}
            >
              <Box className="i-title">Online Orders Management</Box>
            </Grid>
          </Grid>

          {true && (
            <Grid container marginBottom={2}>
              <Grid
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                item
                display="flex"
                justifyContent={smDown ? "center" : "start"}
                paddingRight={onlyXs ? "5px" : "10px"}
                paddingTop={onlyXs ? 1 : 0}
              >
                <div className="order_sc__s">
                  <div className="order_sc__c ">
                    <Button
                      id="all_order__online_orders"
                      variant="contained"
                      className={`${
                        orderStatus === "All"
                          ? "add_order_btn__orders"
                          : "download_report_btn__orders"
                      }`}
                      style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                      onClick={() => {
                        setOrderStatus("All");
                      }}
                    >
                      <div>
                        <div>All Orders</div>
                        <div style={{ marginTop: "-10px" }}>
                          ({orderStatusCount.All})
                        </div>
                      </div>
                    </Button>
                  </div>

                  <div className="order_sc__c ">
                    <Button
                      id="paid_order__online_orders"
                      variant="contained"
                      className={`${
                        orderStatus === "Paid"
                          ? "add_order_btn__orders"
                          : "download_report_btn__orders"
                      }`}
                      style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                      onClick={() => {
                        setOrderStatus("Paid");
                      }}
                    >
                      <div>
                        <div>Paid Orders</div>
                        <div style={{ marginTop: "-10px" }}>
                          ({orderStatusCount.Paid})
                        </div>
                      </div>
                    </Button>
                  </div>

                  <div className="order_sc__c ">
                    <Button
                      id="unpaid_order__online_orders"
                      variant="contained"
                      className={`${
                        orderStatus === "Unpaid"
                          ? "add_order_btn__orders"
                          : "download_report_btn__orders"
                      }`}
                      style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                      onClick={() => {
                        setOrderStatus("Unpaid");
                      }}
                    >
                      <div>
                        <div>Unpaid Orders</div>
                        <div style={{ marginTop: "-10px" }}>
                          ({orderStatusCount.Unpaid})
                        </div>
                      </div>
                    </Button>
                  </div>

                  <div className="order_sc__c ">
                    <Button
                      id="returned_order__online_orders"
                      variant="contained"
                      className={`${
                        orderStatus === "Returned"
                          ? "add_order_btn__orders"
                          : "download_report_btn__orders"
                      }`}
                      style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                      onClick={() => {
                        setOrderStatus("Returned");
                      }}
                    >
                      <div>
                        <div>Returned Orders</div>
                        <div style={{ marginTop: "-10px" }}>
                          ({orderStatusCount.Returned})
                        </div>
                      </div>
                    </Button>
                  </div>

                  <div className="order_sc__c ">
                    <Button
                      id="cancelled_order__online_orders"
                      variant="contained"
                      className={`${
                        orderStatus === "Cancelled"
                          ? "add_order_btn__orders"
                          : "download_report_btn__orders"
                      }`}
                      onClick={() => {
                        setOrderStatus("Cancelled");
                      }}
                    >
                      <div>
                        <div>Cancelled Orders</div>
                        <div style={{ marginTop: "-10px" }}>
                          ({orderStatusCount.Cancelled})
                        </div>
                      </div>
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}

          {true && (
            <Grid container marginBottom={2}>
              <Grid
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={12}
                item
                display="flex"
                justifyContent={smDown ? "start" : "start"}
                paddingRight={onlyXs ? "5px" : "10px"}
                paddingTop={onlyXs ? 1 : 0}
              >
                <Button
                  id="all__online_orders"
                  variant="contained"
                  className={`${
                    orderType === "All"
                      ? "add_order_btn__orders"
                      : "download_report_btn__orders"
                  }`}
                  onClick={() => {
                    setOrderType("All");
                  }}
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                >
                  All ({orderTypeCount.All})
                </Button>
                <Button
                  id="cash__online_orders"
                  variant="contained"
                  className={`${
                    orderType === "Cash"
                      ? "add_order_btn__orders"
                      : "download_report_btn__orders"
                  }`}
                  onClick={() => {
                    setOrderType("Cash");
                  }}
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                >
                  Cash ({orderTypeCount.Cash})
                </Button>
                <Button
                  id="card__online_orders"
                  variant="contained"
                  className={`${
                    orderType === "Card"
                      ? "add_order_btn__orders"
                      : "download_report_btn__orders"
                  }`}
                  onClick={() => {
                    setOrderType("Card");
                  }}
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                >
                  Card ({orderTypeCount.Card})
                </Button>
                <Button
                  id="credit__online_orders"
                  variant="contained"
                  className={`${
                    orderType === "Credit"
                      ? "add_order_btn__orders"
                      : "download_report_btn__orders"
                  }`}
                  onClick={() => {
                    setOrderType("Credit");
                  }}
                  style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                >
                  Credit ({orderTypeCount.Credit})
                </Button>
                {onlyXs && (
                  <Button
                    variant="contained"
                    endIcon={<OrderCreditBtn />}
                    className="download_report_btn__orders"
                    onClick={handleOpenDateMenu}
                  >
                    {typeof ordersDate === "string" ? ordersDate : "Custom"}
                  </Button>
                )}

                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={dateMenuElement}
                  open={isDateMenuOpen}
                  onClose={handleCloseDateMenu}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 6,
                      width: "20ch",
                      borderRadius: "10px",
                      boxShadow: "none",
                      border: "1px solid rgba(0,0,0,0.15)",
                    },
                  }}
                >
                  {["Today", "This Week", "This Month", "Custom"].map(
                    (option, oi) => (
                      <MenuItem
                        key={option}
                        selected={option === "Pyxis"}
                        onClick={() => {
                          handleOrdersDateFilter(option);
                        }}
                        style={
                          oi + 1 < 4
                            ? {
                                borderBottom: "1px solid rgba(0,0,0,0.15)",
                                paddingTop: 0,
                                paddingBottom: 0,
                              }
                            : { paddingTop: 0, paddingBottom: 0 }
                        }
                      >
                        <Box marginTop={1} marginRight={2}>
                          {option}
                        </Box>
                      </MenuItem>
                    )
                  )}
                </Menu>
                {openDateRangePicker ? (
                  <Box position="absolute" top="20%" zIndex="9999">
                    <DateRangePicker
                      open={openDateRangePicker}
                      toggle={toggleDateRangerPicker}
                      onChange={(range) => handleDateRange(range)}
                    />
                  </Box>
                ) : null}
              </Grid>
              {!onlyXs && (
                <Grid
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  item
                  display="flex"
                  justifyContent={smDown ? "end" : "end"}
                  paddingRight={onlyXs ? "5px" : "10px"}
                  paddingTop={onlyXs ? 1 : 0}
                >
                  <Button
                    id="this_month_filter__online_order"
                    variant="contained"
                    endIcon={<OnlineReportIcon />}
                    className="download_report_btn__orders"
                    onClick={handleOpenDateMenu}
                  >
                    {typeof ordersDate === "string" ? ordersDate : "Custom"}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}

          <Grid container marginBottom={2}>
            <Grid xl={6} lg={6} md={5} sm={12} xs={12} item className="padn_invoice__icon">
              <SearchField
                id="search_field__online_order"
                onChange={updateProductList}
                tableDataCount={orders.length}
                totalAvailableDataCount={orderTypeCount.All}
              />
            </Grid>
            <Grid
              xl={6}
              lg={6}
              md={7}
              sm={12}
              xs={12}
              item
              display="flex"
              justifyContent={smDown ? "center" : "end"}
              paddingTop={smDown ? 1 : 0}
              paddingRight={smDown ? "0px" : "10px"}
            >
              {/* <Button
                variant="contained"
                className="add_order_btn__orders"
                style={{ marginRight: `${onlyXs ? 5 : 10}px` }}
                onClick={handleNavigateToAddOrder}
              >
                Add New Order
              </Button> */}
              <ProtectComponent
                id="download-all-btn"
                componentLocation={true}
                componentNavigate={false}
                component={
                  <Button
                  id="download_report_btn__online_order"
                    onClick={handlePrintAllOrders}
                    variant="contained"
                    endIcon={<OnlineAllReportIcon />}
                    className="download_report_btn__orders"
                  >
                    {onlyXs || onlyMd ? (
                      <div>
                        <div>Download</div>
                        <div style={{ marginTop: "-8px" }}>Report</div>
                      </div>
                    ) : (
                      "Download Report"
                    )}
                  </Button>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <CustomTable
            view={handleViewOrder}
            edit={handleEditOrder}
            delete={openDeleteOrderDialog}
            print={handlePrintSingleOrder}
            status={handleOrderStatus}
            columns={columns}
            data={orders}
            options={options}
            firstRowMargin={true}
          />
        </Grid>
      </Grid>
    </>
  );
}

const ChangeOrderStatus = (props) => {
  return (
    <CustomTextField
      select
      value={props.selectedOrderStatus}
      onChange={(e) => {
        props.setSelectedOrderStatus(e.target.value);
      }}
      name="order_status"
      id="order_status"
      key="order_status"
    >
      {props.orderStatus.map((value, index) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </CustomTextField>
  );
};

export default OnlineOrders;
