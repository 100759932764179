import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  MobileStepper,
  Typography,
  ButtonBase,
} from "@mui/material";
import "./ViewSelectedInventory.css";
import { IoMdArrowDropleft } from "react-icons/io";
import { GlobalData } from "../../App";
import NavigationBar from "../NavigationBar";
import AutoScrollContainer from "auto-scroll-container";
import ProtectComponent from "../../middleware/ProtectComponent";
import { getProductById } from "../../api/InventoryAPI";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { styled } from "@mui/material/styles";
import { ReactComponent as MinusIcon } from "../../assets/SVG/MinusRedIconInventory.svg";
import DeleteDialog from "../../widgets/DeleteDialog";
import { createTransferOrder } from "../../api/TransferOrdersAPI";
import { getTodayDate, pad } from "../../utils/common";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const steps = [];

const CustomTextFieldInventory = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0.25"})`,
      borderRadius: "10px",
    },
  },
});

const CustomTextFieldSmall = styled(TextField)({
  width: "70px",
  height: "10px",

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0"})`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0"})`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0"})`,
      borderRadius: "10px",
    },
    "&.Mui-disabled fieldset": {
      borderColor: `rgb(0,0,0,${false ? "0" : "0"})`,
      borderRadius: "10px",
    },
  },
});

function ViewSelectedInventory(props) {
  const {
    smDown,
    navigate,
    baseUrl,
    location,
    showLoader,
    flashMessage,
    hideLoader,
    query,
    onlyXs,
    userData,
  } = useContext(GlobalData);

  const theme = useTheme();
  const [product, setProduct] = useState("");
  const [productCopy, setProductCopy] = useState("");
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [productIds, setProductIds] = useState(location.state.ids);
  const [activeStep, setActiveStep] = useState(0);
  const [general, setGeneral] = useState(true);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteObjectName, setDeleteObjectName] = useState("");
  const [selectedObjectId, setSelectedObjectId] = useState();
  const [products, setProducts] = useState([]);
  const [productsCopy, setProductsCopy] = useState([]);
  const [fetchProducts, setFetchProducts] = useState(true);

  const [productsForMove, setProductsForMove] = useState([]);

  const handleNextProduct = (id) => {
    setProducts((pro) => {
      pro[id] = product;
      return pro;
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackProduct = (id) => {
    setProducts((pro) => {
      pro[id] = product;
      return pro;
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleGetProduct = () => {
    showLoader();
    if (fetchProducts) {
      let formData = new FormData();
      formData.append("product_id", productIds[selectedProductIndex]);
      getProductById(formData, (resp) => {
        hideLoader();
        if (resp.statusCode === 200) {
          let productData = resp.successData.product;
          setProduct(productData);
          setProductCopy(productData);

          let productsData = [...products];
          productsData.push(productData);
          setProducts(productsData);

          let productsDataCopy = [...productsCopy];
          productsDataCopy.push(productData);
          setProductsCopy(productsDataCopy);

          if (productsData.length == productIds.length) {
            setFetchProducts(false);
          }

          if (
            productData.category === "Mobile" ||
            productData.category === "Laptop"
          ) {
            setGeneral(false);
          }
        } else {
          flashMessage("error", resp.message);
        }
      });
    } else {
      setProduct(products[selectedProductIndex]);
      setProductCopy(productsCopy[selectedProductIndex]);
      setTimeout(() => {
        hideLoader();
      }, [1000]);
    }
  };

  useEffect(() => {
    handleGetProduct();
  }, [selectedProductIndex]);

  useEffect(() => {}, []);

  const handleOnChangeProduct = (e, variant, index) => {
    let { name, value } = e.target;
    let productData = JSON.parse(JSON.stringify(product));

    productData.variants[index][name] = value;
    setProduct(productData);
  };

  useEffect(() => {
    if (product) {
      // console.log('0012S','original',product.variants);
      // console.log('0012S','copy',productCopy.variants);
    }
  }, [JSON.stringify(product)]);

  const handleImeiData = (e, value, variant, index) => {
    let productData = JSON.parse(JSON.stringify(product));
    productData.variants[index].quantity = value.length;
    productData.variants[index].imei = value.join(",");
    setProduct(productData);
  };

  const handleRemoveVariant = () => {
    showLoader();
    let productIdsData = [...productIds];
    let productData = JSON.parse(JSON.stringify(product));
    if (productData.variants.length == 1) {
      productIdsData.splice(selectedObjectId, 1);
      setProductIds(productIdsData);
    } else {
      productData.variants.splice(selectedObjectId, 1);
      setProduct(productData);
    }
    handleCloseDeleteDialog();
    setTimeout(() => {
      hideLoader();
      flashMessage("success", "Variant removed successfully");
    }, 1000);
    // if (productIdsData.length == 0 && productsForMove.length == 0) {
    //   navigate("/products");
    // }
  };

  const handleRemoveProduct = (message = true) => {
    showLoader();
    let productIdsData = [...productIds];
    productIdsData.splice(selectedObjectId, 1);
    setProductIds(productIdsData);
    let productsData = [...products];
    let productsDataCopy = [...productsCopy];
    productsData.splice(selectedObjectId, 1);
    productsDataCopy.splice(selectedObjectId, 1);
    setProducts(productsData);
    setProductsCopy(productsDataCopy);
    handleCloseDeleteDialog();
    handleBackProduct();
    if (message) {
      setTimeout(() => {
        hideLoader();
        flashMessage("success", "Product removed successfully");
      }, 1000);
    }
    // if (productIdsData.length == 0 && productsForMove.length == 0) {
    //   navigate("/products");
    // }
  };

  useEffect(() => {
    if (selectedProductIndex > 0) {
      if (productIds.length == 0) {
        setProduct("");
      } else {
        setSelectedProductIndex(selectedProductIndex - 1);
      }
    } else {
      if (productIds.length == 0) {
        setProduct("");
      } else {
        handleGetProduct();
      }
    }
  }, [productIds.length]);

  const handleOpenDeleteDialog = (id, objectName) => {
    setSelectedObjectId(id);
    setDeleteObjectName(objectName);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  function validateProductVariants(variants) {
    for (let i = 0; i < variants.length; i++) {
      const variant = variants[i];
      const variantNumber = i + 1;

      if (parseFloat(variant.quantity) <= 0 || variant.quantity == "") {
        flashMessage(
          "error",
          `Variant ${variantNumber} - Quantity must be greater than 0.`
        );
        return true;
      }

      if (parseFloat(variant.sale_price) <= parseFloat(variant.cost_price)) {
        flashMessage(
          "error",
          `Variant ${variantNumber} - Sale price must be greater than the cost price.`
        );
        return true;
      }

      if (
        parseFloat(variant.min_sale_price) >= parseFloat(variant.sale_price)
      ) {
        flashMessage(
          "error",
          `Variant ${variantNumber} - Minimum sale price must be smaller than the sale price.`
        );
        return true;
      }

      if (
        parseFloat(variant.min_sale_price) <= parseFloat(variant.cost_price)
      ) {
        flashMessage(
          "error",
          `Variant ${variantNumber} - Minimum sale price must be greater than the cost price.`
        );
        return true;
      }

      if (parseFloat(variant.cost_price) <= 0 || variant.cost_price == "") {
        flashMessage(
          "error",
          `Variant ${variantNumber} - Cost price must be greater than 0.`
        );
        return true;
      }

      if (parseFloat(variant.sale_price) <= 0 || variant.sale_price == "") {
        flashMessage(
          "error",
          `Variant ${variantNumber} - Sale price must be greater than 0.`
        );
        return true;
      }

      if (
        parseFloat(variant.min_sale_price) <= 0 ||
        variant.min_sale_price == ""
      ) {
        flashMessage(
          "error",
          `Variant ${variantNumber} - Minimum sale price must be greater than 0.`
        );
        return true;
      }
    }
  }

  const handleMoveProduct = () => {
    setSelectedObjectId(selectedProductIndex);
    setDeleteObjectName("Product");
    if (validateProductVariants(product.variants)) {
      return 0;
    }
    let productsData = [...productsForMove];
    productsData.push(product);
    setProductsForMove(productsData);
    handleRemoveProduct(false);
  };

  const handleCreateTransferOrder = () => {
    showLoader();
    let formData = new FormData();

    formData.append("user_id", userData.id);

    var date = new Date(getTodayDate());
    formData.append(
      "order_date",
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate())
    );

    formData.append("from", "shop");
    formData.append("type", "-");
    formData.append("description", "-");
    formData.append("user_name", userData.name);
    formData.append("inventories", JSON.stringify(productsForMove));
    createTransferOrder(formData, (resp) => {
      hideLoader();
      if (resp.statusCode === 200) {
        setTimeout(() => {
          flashMessage("success", resp.message);
        }, 1000);
      } else {
        //flashMessage("error", resp.message);
      }
      navigate("/products");
    });
  };

  useEffect(() => {
    if (product === "" && productIds.length === 0) {
      handleCreateTransferOrder();
    }
  }, [product]);

  return (
    <>
      <DeleteDialog
        handleClose={handleCloseDeleteDialog}
        showDialog={isDeleteDialogOpen}
        handleRemove={
          deleteObjectName == "Variant"
            ? handleRemoveVariant
            : handleRemoveProduct
        }
        title={"Remove this " + deleteObjectName}
        sub_title={`You are about to remove an ${deleteObjectName} from selected list. Click on the “Remove” button to remove it.`}
      />
      <NavigationBar
        path={`Dashboard>Inventory Management>Shop Products>Selected Product Details`}
        paddingBottom={1}
      />
      {product === "" ? (
        <Grid />
      ) : (
        <Grid
          container
          className="container__inven_view"
          style={{ paddingRight: `${onlyXs ? 5 : 5}px` }}
          paddingBottom={3}
        >
          <Grid
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            item
            paddingTop={smDown && 3}
            style={smDown ? { zIndex: 1 } : {}}
          >
            <Box className="container__viewinv">
              <Box className="header__viewinv">
                <Grid display="flex" justifyContent="center" container mb={2}>
                  <Grid
                    className="header__inven_view"
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    item
                  >
                    <div
                      className="header_back_btn__inven_view"
                      onClick={() => {
                        navigate("/products");
                      }}
                    >
                      <IoMdArrowDropleft className="add__icon_orders_sty" />
                      Back
                    </div>
                    <div className="header_text__inven_view">
                      Move Selected Inventory
                      {smDown ? <br /> : <>&nbsp;to&nbsp;</>}
                      <span style={smDown ? { fontSize: "14px" } : {}}>
                        Online
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box className="body__viewinv">
                <Grid container>
                  <Grid
                    className="card__inven_view"
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    item
                    paddingLeft={query(10, 4, 0.5)}
                    paddingRight={query(10, 4, 0.5)}
                    paddingTop="30px"
                    paddingBottom="60px"
                    id="main_stepper_con__vsi"
                  >
                    <Box>
                      <Box sx={{ textAlign: "center" }}>Selected Inventory</Box>

                      <Box key={productIds.length}>
                        <MobileStepper
                          variant="text"
                          steps={productIds.length}
                          position="static"
                          activeStep={activeStep}
                          style={{ backgroundColor: "transparent" }}
                          id="stepper_com__vsi"
                          nextButton={
                            <Button
                              size="small"
                              disabled={activeStep === productIds.length - 1}
                              id="stepper_next_btn__vsi"
                              onClick={() => {
                                handleNextProduct(selectedProductIndex);
                                setSelectedProductIndex(
                                  selectedProductIndex + 1
                                );
                              }}
                            >
                              {theme.direction === "rtl" ? (
                                <KeyboardArrowLeft />
                              ) : (
                                <KeyboardArrowRight />
                              )}
                            </Button>
                          }
                          backButton={
                            <Button
                              size="small"
                              disabled={activeStep === 0}
                              id="stepper_back_btn__vsi"
                              onClick={() => {
                                handleBackProduct(selectedProductIndex);
                                setSelectedProductIndex(
                                  selectedProductIndex - 1
                                );
                              }}
                            >
                              {theme.direction === "rtl" ? (
                                <KeyboardArrowRight />
                              ) : (
                                <KeyboardArrowLeft />
                              )}
                            </Button>
                          }
                        />
                      </Box>
                    </Box>

                    <Grid
                      container
                      className="page__inven_view"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      paddingTop="30px"
                      paddingBottom="30px"
                      paddingLeft={query(10, 4, 1)}
                      paddingRight={query(10, 4, 1)}
                      position="relative"
                    >
                      <ButtonBase
                        onClick={() => {
                          handleOpenDeleteDialog(
                            selectedProductIndex,
                            "Product"
                          );
                        }}
                        sx={{
                          borderRadius: "50%",
                          position: "absolute",
                          top: "20px",
                          right: "20px",
                        }}
                      >
                        <MinusIcon width="24" height="24" />
                      </ButtonBase>
                      <Grid
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        item
                        display="flex"
                        justifyContent="center"
                        paddingBottom={3}
                      >
                        <img
                          alt=""
                          src={baseUrl + product.image}
                          width="120px"
                          height="120px"
                          style={{
                            borderRadius: "50%",
                            border: "1px solid rgba(0,0,0,0.2)",
                          }}
                        />
                      </Grid>

                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid
                          container
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid
                            xl={3}
                            lg={3}
                            md={3}
                            sm={3}
                            xs={7}
                            display="flex"
                            justifyContent={onlyXs ? "start" : "center"}
                            item
                          >
                            <Box>
                              <Box className="product_title_text__ordview">
                                Product Name
                              </Box>
                              <Box className="product_text__ordview">
                                {product.name}
                              </Box>
                            </Box>
                          </Grid>

                          <ProtectComponent
                            id="product_source"
                            componentLocation={true}
                            componentNavigate={false}
                            component={
                              <Grid
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                xs={5}
                                item
                                display="flex"
                                justifyContent={onlyXs ? "start" : "center"}
                              >
                                <Box>
                                  <Box className="product_title_text__ordview">
                                    Source
                                  </Box>
                                  <Box className="product_text__ordview">
                                    {product.source}
                                  </Box>
                                </Box>
                              </Grid>
                            }
                          />

                          <Grid
                            xl={3}
                            lg={3}
                            md={3}
                            sm={3}
                            xs={7}
                            item
                            display="flex"
                            justifyContent={onlyXs ? "start" : "center"}
                          >
                            <Box>
                              <Box className="product_title_text__ordview">
                                Material
                              </Box>
                              <Box className="product_text__ordview">
                                {product.material}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            xl={3}
                            lg={3}
                            md={3}
                            sm={3}
                            xs={5}
                            item
                            display="flex"
                            justifyContent={onlyXs ? "start" : "center"}
                          >
                            <Box>
                              <Box className="product_title_text__ordview">
                                Category
                              </Box>
                              <Box className="product_text__ordview">
                                {product.category}
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <hr style={{ marginTop: "20px", opacity: "0.5" }} />
                        <Grid container>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container className="padng_global_projct">
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                              >
                                {product.variants.map((variant, index) => (
                                  <>
                                    <Grid
                                      container
                                      paddingTop="20px"
                                      pb={1}
                                      className="product_text__ordview"
                                      display="flex"
                                      justifyContent="space-between"
                                    >
                                      <Box>Variant #{index + 1}</Box>

                                      <Box>
                                        <ButtonBase
                                          onClick={() => {
                                            handleOpenDeleteDialog(
                                              index,
                                              "Variant"
                                            );
                                          }}
                                          sx={{ borderRadius: "50%" }}
                                        >
                                          <MinusIcon width="24" height="24" />
                                        </ButtonBase>
                                      </Box>
                                    </Grid>
                                    <Grid container>
                                      <Grid
                                        item
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="variant_box_con__inven_view"
                                      >
                                        <Grid
                                          container
                                          paddingLeft={1}
                                          paddingRight={1}
                                        >
                                          <Grid
                                            item
                                            xl={
                                              product.category === "Mobile" ||
                                              product.category === "Laptop"
                                                ? 3
                                                : 2
                                            }
                                            lg={
                                              product.category === "Mobile" ||
                                              product.category === "Laptop"
                                                ? 3
                                                : 2
                                            }
                                            md={
                                              product.category === "Mobile" ||
                                              product.category === "Laptop"
                                                ? 3
                                                : 2
                                            }
                                            sm={
                                              product.category === "Mobile" ||
                                              product.category === "Laptop"
                                                ? 3
                                                : 2
                                            }
                                            xs={
                                              product.category === "Mobile" ||
                                              product.category === "Laptop"
                                                ? 6
                                                : 4
                                            }
                                          >
                                            <Box
                                              fontSize="12px"
                                              className="variant_title_text__inven_view"
                                            >
                                              {product.category === "Mobile" ||
                                              product.category === "Laptop"
                                                ? "Specifications"
                                                : "Color"}
                                            </Box>
                                            <Box className="product_text__ordview">
                                              {variant.color}

                                              {product.category === "Mobile" ||
                                              product.category === "Laptop" ? (
                                                <>
                                                  {variant.ram.length > 0
                                                    ? ","
                                                    : ""}
                                                  {variant.ram}
                                                  {variant.storage.length > 0
                                                    ? ","
                                                    : ""}
                                                  {variant.storage}
                                                </>
                                              ) : null}
                                            </Box>
                                          </Grid>
                                          <Grid
                                            item
                                            xl={2}
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={general ? 4 : 6}
                                          >
                                            <Box
                                              fontSize="12px"
                                              className="product_title_text__ordview"
                                            >
                                              Quantity
                                            </Box>
                                            <Box className="product_text__ordview">
                                              <Box
                                                borderBottom="1px solid rgb(0,0,0,0.3)"
                                                width="70px"
                                                pb={0.5}
                                                className="customSmallTextField"
                                              >
                                                <CustomTextFieldSmall
                                                  value={variant.quantity}
                                                  disabled={
                                                    product.category ==
                                                      "Mobile" ||
                                                    product.category == "Laptop"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    handleOnChangeProduct(
                                                      e,
                                                      variant,
                                                      index
                                                    );
                                                  }}
                                                  name="quantity"
                                                />
                                              </Box>
                                            </Box>
                                          </Grid>

                                          <ProtectComponent
                                            id="product_cost_price"
                                            componentLocation={true}
                                            componentNavigate={false}
                                            component={
                                              <Grid
                                                item
                                                xl={2}
                                                lg={2}
                                                md={2}
                                                sm={2}
                                                xs={general ? 4 : 4}
                                              >
                                                <Box
                                                  fontSize="12px"
                                                  className="variant_title_text__inven_view"
                                                >
                                                  Cost Price
                                                </Box>
                                                <Box className="product_text__ordview">
                                                  <Box
                                                    borderBottom="1px solid rgb(0,0,0,0.3)"
                                                    width="70px"
                                                    pb={0.5}
                                                    className="customSmallTextField"
                                                  >
                                                    <CustomTextFieldSmall
                                                      value={variant.cost_price}
                                                      onChange={(e) => {
                                                        handleOnChangeProduct(
                                                          e,
                                                          variant,
                                                          index
                                                        );
                                                      }}
                                                      name="cost_price"
                                                    />
                                                  </Box>
                                                </Box>
                                              </Grid>
                                            }
                                          />

                                          <Grid
                                            item
                                            xl={2}
                                            lg={2}
                                            md={2}
                                            sm={2}
                                            xs={general ? 4 : 4}
                                          >
                                            <Box
                                              fontSize="12px"
                                              className="variant_title_text__inven_view"
                                            >
                                              Sale Price
                                            </Box>
                                            <Box className="product_text__ordview">
                                              <Box
                                                borderBottom="1px solid rgb(0,0,0,0.3)"
                                                width="70px"
                                                pb={0.5}
                                                className="customSmallTextField"
                                              >
                                                <CustomTextFieldSmall
                                                  value={variant.sale_price}
                                                  onChange={(e) => {
                                                    handleOnChangeProduct(
                                                      e,
                                                      variant,
                                                      index
                                                    );
                                                  }}
                                                  name="sale_price"
                                                />
                                              </Box>
                                            </Box>
                                          </Grid>
                                          <Grid
                                            item
                                            xl={3}
                                            lg={3}
                                            md={3}
                                            sm={3}
                                            xs={general ? 4 : 4}
                                          >
                                            <Box
                                              fontSize="12px"
                                              className="variant_title_text__inven_view"
                                            >
                                              M.Sale Price
                                            </Box>
                                            <Box className="product_text__ordview">
                                              <Box
                                                borderBottom="1px solid rgb(0,0,0,0.3)"
                                                width="70px"
                                                pb={0.5}
                                                className="customSmallTextField"
                                              >
                                                <CustomTextFieldSmall
                                                  value={variant.min_sale_price}
                                                  onChange={(e) => {
                                                    handleOnChangeProduct(
                                                      e,
                                                      variant,
                                                      index
                                                    );
                                                  }}
                                                  name="min_sale_price"
                                                />
                                              </Box>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                        <hr />
                                        <Grid
                                          container
                                          paddingLeft={1}
                                          paddingRight={1}
                                          paddingBottom={1}
                                        >
                                          {product.category === "Mobile" ||
                                          product.category === "Laptop" ? (
                                            <>
                                              <Grid
                                                item
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                              >
                                                <Box
                                                  fontSize="12px"
                                                  className="variant_title_text__inven_view"
                                                >
                                                  {product.category === "Mobile"
                                                    ? "IMEI No."
                                                    : "MAC Address"}
                                                </Box>
                                                <Box
                                                  className="product_text__ordview"
                                                  mb={1}
                                                >
                                                  <Autocomplete
                                                    onChange={(e, value) => {
                                                      handleImeiData(
                                                        e,
                                                        value,
                                                        variant,
                                                        index
                                                      );
                                                    }}
                                                    name="imei"
                                                    key={variant.id}
                                                    multiple
                                                    id="checkboxes-tags-demo"
                                                    options={productCopy.variants[
                                                      index
                                                    ].imei
                                                      .split(",")
                                                      .filter(
                                                        (item) => item !== ""
                                                      )}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) =>
                                                      option
                                                    }
                                                    renderOption={(
                                                      props,
                                                      option,
                                                      { selected }
                                                    ) => (
                                                      <li {...props}>
                                                        <Checkbox
                                                          icon={icon}
                                                          checkedIcon={
                                                            checkedIcon
                                                          }
                                                          style={{
                                                            marginRight: 8,
                                                          }}
                                                          checked={selected}
                                                        />
                                                        {option}
                                                      </li>
                                                    )}
                                                    defaultValue={productCopy.variants[
                                                      index
                                                    ].imei
                                                      .split(",")
                                                      .filter(
                                                        (item) => item !== ""
                                                      )}
                                                    renderInput={(params) => (
                                                      <CustomTextFieldInventory
                                                        {...params}
                                                      />
                                                    )}
                                                  />
                                                </Box>
                                              </Grid>

                                              <Grid
                                                item
                                                xl={12}
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                              >
                                                <Box
                                                  fontSize="12px"
                                                  className="variant_title_text__inven_view"
                                                >
                                                  Description
                                                </Box>

                                                <Box className="product_text__ordview">
                                                  {variant.description}
                                                </Box>
                                              </Grid>
                                            </>
                                          ) : (
                                            <Grid
                                              item
                                              xl={12}
                                              lg={12}
                                              md={12}
                                              sm={12}
                                              xs={12}
                                            >
                                              <Box
                                                fontSize="12px"
                                                className="variant_title_text__inven_view"
                                              >
                                                Description
                                              </Box>
                                              <Box className="product_text__ordview">
                                                {variant.description}
                                              </Box>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <br />
                                  </>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="center" mt={3} mb={10}>
                      {/* <Button
                        variant="contained"
                        className="download_report_btn"
                        onClick={() => {
                          handleOpenDeleteDialog(
                            selectedProductIndex,
                            "Product"
                          );
                        }}
                      >
                        Discard
                      </Button> */}
                      <Button
                        variant="contained"
                        className="add_order_btn__orders"
                        onClick={handleMoveProduct}
                      >
                        Move
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ViewSelectedInventory;
