import "./NavigationBar.css";
import { Box, Grid } from "@mui/material";
import { GlobalData } from "../App";
import React, { useContext } from "react";

function NavigationBar(props) {
  const { smDown } = useContext(
    GlobalData
  );
  return (
    <Grid container paddingTop={smDown?7:1} paddingBottom={props.paddingBottom} >
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
        <Box className="header_path">
          {props.path.split(">").join("\u00a0\u00a0\u00a0>\u00a0\u00a0\u00a0")}
        </Box>
      </Grid>
    </Grid>
  );
}

export default NavigationBar;
