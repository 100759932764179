import React, { useContext, useState, useEffect } from "react";
import { TextField, MenuItem, FormControl, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getAllBanks } from "../api/BankAPI";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: `rgb(0,0,0,0.25)`,
      borderRadius: "10px",
    },
  },
});

const SelectBank = ({ bankName, setBankName, setBankId, setAllBanksData }) => {
  const [banks, setBanks] = useState([]);

  const handleGetAllBanks = () => {
    let data = {
      search_text: "",
    };
    getAllBanks(data, (resp) => {
      if (resp.statusCode === 200) {
        setBanks(resp.successData.banks);
        setAllBanksData(resp.successData.banks);
      } else {
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let bank = banks.find((b) => b.bank_name === value);
    setBankName(bank.bank_name);
    setBankId(bank.id);
  };

  useEffect(() => {
    handleGetAllBanks();
  }, []);

  return (
    <>
      {/* <div style={{ marginBottom: "8px" }}>Select Bank</div> */}
      <FormControl className="textField__AddInventory">
        {bankName === "" && (
          <InputLabel sx={{ opacity: 0.6 }}>Please Select Bank</InputLabel>
        )}
        <CustomTextField
          select
          value={bankName}
          onChange={handleInputChange}
          name="bank"
          id="bank"
          key="bank"
        >
          {banks.map((option, oi) => (
            <MenuItem
              key={option.id}
              value={option.bank_name}
              style={
                oi + 1 < 2
                  ? {
                      borderBottom: "1px solid rgba(0,0,0,0.15)",
                    }
                  : {}
              }
            >
              {option.bank_name}
            </MenuItem>
          ))}
        </CustomTextField>
      </FormControl>
    </>
  );
};

export default SelectBank;
