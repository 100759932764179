import React from "react";
import { Box, Grid,Button } from "@mui/material";
import { ReactComponent as LandingAccountsIcon } from "../../../assets/SVG/LandingAccountsBox.svg";
import { ReactComponent as TrackIcon} from "../../../assets/SVG/Landing_track.svg";
import GroupImage from "../../../assets/Images/GroupImage.png";



function Overview() {
  return (
    <>
      <Box>
        <Grid container display="flex" pt={4}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <Box className="overview_text" pt={4} textAlign="end">
              what
            </Box>
            <Box pl={12}>
              <Box>
                <LandingAccountsIcon />
              </Box>
              <Box className="overview_account">Manage Sales and Accounts </Box>
              <Box className="brand_text">
                Get better quality products for your already launched brands at
                a lower price and a better design.
              </Box>
            </Box>

            <Box pl={12} pt={5}>
              <Box>
                <TrackIcon />
              </Box>
              <Box className="overview_account">Track Inventory and Orders </Box>
              <Box className="brand_text">
              Source for high quality and price competitive
              products to increase your business profitability.
              </Box>
            </Box>
          </Grid>

          <Grid
            backgroundColor="#F4F4F5"
            item
            xl={8}
            lg={8}
            md={8}
            sm={8}
            xs={8}
            pb={5}
          >
            <Box className="overview_text" pt={4} pl={1}>
              can you do with <span className="dbl_color">ParkourExpress</span>
            </Box>
            <Box display="flex" justifyContent="center" textAlign="center"pt={10}>
            <img src={GroupImage} height="380px" />
            </Box>
            <Box pl={12}><Button className="overview_btn">Get Started</Button></Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Overview;
