import { get, post, getWithParams } from "./API";

export function getAllOrders(data, callback) {
  post({ endPoint: "orders/getAllOrders" }, data, function(response) {
    callback(response);
  });
}

export function createOrder(data, callback) {
  post({ endPoint: "orders/createOrder" }, data, function(response) {
    callback(response);
  });
}

export function changeOrderStatus(data, callback) {
  post({ endPoint: "orders/changeOrderStatus" }, data, function(response) {
    callback(response);
  });
}

export function updateOrder(data, callback) {
  post({ endPoint: "orders/updateOrder" }, data, function(response) {
    callback(response);
  });
}

export function deleteOrder(data, callback) {
  post({ endPoint: "orders/deleteOrder" }, data, function(response) {
    callback(response);
  });
}

export function getOrderById(data, callback) {
  getWithParams({ endPoint: "orders/getOrderById" }, data, function(response) {
    callback(response);
  });
}
