import React, { useState, useEffect, useContext } from "react";
import { Box, Grid } from "@mui/material";
import { ReactComponent as ServiceText } from "../../../assets/SVG/LPservice.svg";

const serviceObjects = [
  {
    img: require("../../../assets/Images/salyOne.png"),
    text: "Inventory Management",
  },
  {
    img: require("../../../assets/Images/SalyTwo.png"),
    text: "Order Management",
  },
  {
    img: require("../../../assets/Images/SalyThree.png"),
    text: "Account Expenses",
  },
  {
    img: require("../../../assets/Images/SalyFour.png"),
    text: "User Management",
  },
  {
    img: require("../../../assets/Images/SalyFive.png"),
    text: "Invoices & Services / Notes",
  },
  {
    img: require("../../../assets/Images/SalySix.png"),
    text: "Bank Management",
  },
];

function Services() {
  return (
    <>
      <Box display="flex" justifyContent="center" pt={5}>
        {" "}
        <ServiceText />
      </Box>

      <Grid container display="flex" justifyContent="center">
        <Grid
          item
          xl={10}
          lg={10}
          md={12}
          sm={12}
          xs={12}
          className="flex_branches"
          pt={4}
        >
          {serviceObjects.map((object) => (
            <Box
              className="service_cards"
              ml={3}
              mt={3}
              pt={4}
              sx={{ marginRight: { sm: "20px", xs: "20px" } }}
            >
              <Box display="flex" justifyContent="center">
                <img src={object.img} height="170px" />
              </Box>
              <Box display="flex" justifyContent="center">
                <Box className="service_inventory">{object.text}</Box>
              </Box>
            </Box>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default Services;
