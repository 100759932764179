import React,{useContext} from 'react'
import { Grid} from "@mui/material";
  import { IoMdArrowDropleft } from "react-icons/io";

  import { GlobalData } from "../App";

function TopMidHeader(props) {
    const {navigate,smDown} = useContext(GlobalData);
    
  return (
    <Grid
    display="flex"
    justifyContent="center"
    container
    mb={2}
    mt={2}
  >
    <Grid
      className="header__ai__vendor_khata"
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      item
    >
      <div
        className="header_back_btn__ai"
        onClick={() => {
          navigate(props.path);
        }}
      >
        <IoMdArrowDropleft
          className='add__icon_orders_sty'
        />
        {props.back}
      </div>
      <div className="header_text__ai">
        {props.title1} {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
        {props.name}{" "}
        {smDown ? <br /> : <>&nbsp;-&nbsp;</>}{" "}
        <span style={smDown ? { fontSize: "14px" } : {}}>{props.title2}</span>
      </div>
    </Grid>
  </Grid>
  )
}

export default TopMidHeader