import { getWithParams, post, delte } from "./API";

export function getAllBankTransactions(data, callback) {
  getWithParams(
    { endPoint: "bank/bank-transaction/getAllBankTransactions" },
    data,
    function(response) {
      callback(response);
    }
  );
}
export function getBankTransactionById(data, callback) {
  post(
    { endPoint: "bank/bank-transaction/getBankTransactionById" },
    data,
    function(response) {
      callback(response);
    }
  );
}
export function addBankTransaction(data, callback) {
  post({ endPoint: "bank/bank-transaction/addBankTransaction" }, data, function(
    response
  ) {
    callback(response);
  });
}
export function updateBankTransactionDetailById(data, callback) {
  post(
    { endPoint: "bank/bank-transaction/updateBankTransactionDetailById" },
    data,
    function(response) {
      callback(response);
    }
  );
}
export function deleteBankTransaction(data, callback) {
  delte(
    { endPoint: "bank/bank-transaction/deleteBankTransaction" },
    data,
    function(response) {
      callback(response);
    }
  );
}
