import { getWithParams, post } from "./API";

export function getAllMaterials(data, callback) {
  getWithParams({ endPoint: "inventory/material/getAllMaterials" }, data, function(response) {
    callback(response);
  });
}

export function updateMaterials(data, callback) {
  post({ endPoint: "inventory/material/updateMaterials" }, data, function(response) {
    callback(response);
  });
}
