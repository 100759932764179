import { get, post } from "./API";

export function addVariant(data, callback) {
  post({ endPoint: "inventory/variant/addVariant" }, data, function(response) {
    callback(response);
  });
}

export function editVariant(data, callback) {
  post({ endPoint: "inventory/variant/editVariant" }, data, function(response) {
    callback(response);
  });
}

export function deleteVariant(data, callback) {
  post({ endPoint: "inventory/variant/deleteVariant" }, data, function(
    response
  ) {
    callback(response);
  });
}
