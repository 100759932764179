import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import { InputAdornment, Box } from "@mui/material";
import { ReactComponent as SearchIcon } from "../assets/SVG/SearchFieldSearchIcon.svg";
import "./SearchField.css";

export default function SearchField(props) {
  const handleSearchField = (e) => {
    const search_text = e.target.value;
    props.onChange(search_text);
  };

  return (
    <Paper className="base__search_field" component="form">
      <IconButton sx={{ marginTop: "-1.5px" }} aria-label="menu">
        <SearchIcon />
      </IconButton>
      <InputBase
        className="input__search_field"
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        onChange={handleSearchField}
        name="search"
        id="search"
        key="search"
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        endAdornment={
          <InputAdornment position="end">
            <Box className="totalDataCount__srchfield">
              {props.tableDataCount}{props.tableDataCount!==undefined&&'/'}{props.totalAvailableDataCount}
            </Box>
          </InputAdornment>
        }
      />
    </Paper>
  );
}
